import React, { useState, useEffect, useMemo } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Controllers from '../Api/Controllers';
import { useSelector } from 'react-redux';
import { Select } from '../Components/Form/Form';
import Button from 'react-bootstrap/Button';

const Nivel = () => {
  const nameModulo = 'Pedidos'
  const nameController = 'orders'
  const currentYear = useSelector(store => store.session.year)

  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [reload, setReload] = useState(true);
  const [unit, setUnit] = useState([])
  const [unitSelected, setUnitSelected] = useState('')
  const [state, setState] = useState(-1)
  const [file, setFile] = useState('')

  const optionsState = [
    { value: -1, label: 'TODAS' },
    { value: 0, label: 'PENDIENTE' },
    { value: 1, label: 'PENDIENTE DE APROBACIÓN' },
    { value: 2, label: 'RECHAZADO' },
    { value: 3, label: 'CONSOLIDADO' }
  ]

  useEffect(() => {
    Controllers.unit.get_unit_public_with_pack({
      p_year: currentYear
    }).then(res => {
      res.data.unshift({
        id: '',
        description: 'TODAS'
      })
      setUnit(res.data)
      /* if (res.data.length === 2) {
        let unitFind = res.data.find(e => e.id !== '')
        if (unitFind) {
          setUnitSelected(unitFind.id)
        }
      } */
    })
  }, [currentYear])

  useEffect(() => {
    if (reload) {
      Controllers[nameController]['reporte_orders_general']().then(res => {
        setRows(res.data.data)
        setFile({
          file: res.data.file,
          name: res.data.filename
        })
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload])

  const cboUnit = useMemo(() => {
    let unitFind = unit.find(u => u.id === unitSelected)

    return <Select
      value={unitFind ? {value: unitFind.id, label: unitFind.id + (unitFind.id ? ' - ' : '') + unitFind.description} : ''}
      options={unit.map(s => {
        return {
          value: s.id,
          label: s.id + (s.id ? ' - ' : '') + s.description
        }
      })}
      onChange={e => {
        setUnitSelected(e.value)
        setReload(true)
      }}
      text="Centro de costo"
      icon="far fa-building"
      classNameParent="col-12 col-md-6 mb-2"
    />
  }, [unit, unitSelected])

  const dtRows = useMemo(() => {
    const thead = [
      { name: 'Centro Costo' },
      { name: 'Número', align: 'center' },
      { name: 'Fecha', align: 'center' },
      { name: 'Insumo' },
      { name: 'Cant. Total', align: 'center' },
      { name: 'Fecha Compra', align: 'center' },
      { name: 'Número Compra', align: 'center' },
      { name: 'Estado', align: 'center' },
      { name: 'Estado OC', align: 'center' },
    ];

    return <DataTable
      columnOrder={3}
      isProcessing={isProcessing}
      tbody={rows.map(r => {
        return {
          unit: r.unit,
          number: parseInt(r.number),
          date: r.date,
          supplie: r.supplie,
          total_qty: parseInt(r.total_qty),
          date_issue: r.date_issue,
          number_purchase: !isNaN(parseInt(r.number_purchase)) ? parseInt(r.number_purchase) : r.number_purchase,
          state: r.state,
          state_purchase: r.state_purchase,
          id: r.id,
          id_consolidate: r.id_consolidate,
          id_unit: r.id_unit,
          year: r.year,
          number_year: r.number_year,
        }
      }).filter(r => {
        if (unitSelected !== '') {
          if (state >= 0) {
            return r.id_unit === unitSelected && parseInt(r.state) === state
          } else {
            return r.id_unit === unitSelected
          }
        }

        if (state >= 0) {
          if (unitSelected !== '') {
            return r.id_unit === unitSelected && parseInt(r.state) === state
          } else {
            return parseInt(r.state) === state
          }
        }

        return r;
      })}
      thead={thead}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;
        for (let key in r) {
          if (key === 'id' || key === 'year' || key === 'id_consolidate' || key === 'id_unit' || key === 'number_year') {
            continue;
          }
          if (key === 'state') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''} className={'text-' + (parseInt(r.state) === 0 ? 'warning' : (parseInt(r.state) === 1 ? 'info' : (parseInt(r.state) === 2 ) ? 'danger' : 'success'))}>
                <strong>{parseInt(r.state) === 0 ? 'PENDIENTE' : (parseInt(r.state) === 1 ? 'PENDIENTE DE CONSOLIDACIÓN' : (parseInt(r.state) === 2 ) ? 'RECHAZADO' : 'CONSOLIDADO')}</strong>
              </td>);
          } else if (key === 'state_purchase') {
            let color = ''
            let texto = ''
            switch (parseInt(r.state_purchase)) {
              case 0:
                color = 'warning'
                texto = 'SIN ORDEN DE COMPRA'
                break;
              case 1:
                color = 'info'
                texto = 'CON ORDEN DE COMPRA'
                break;
              case 2:
                color = 'primary'
                texto = 'COMPROMISO'
                break;
              case 3:
                color = 'dark'
                texto = 'COMPLETADO'
                break;
              case 4:
                color = 'info'
                texto = 'DEVENGADO'
                break;
              case 5:
                color = 'primary'
                texto = 'ENVIADO DAF'
                break;
              case 6:
                color = 'danger'
                texto = 'RECHAZADO'
                break;
              case 7:
                color = 'success'
                texto = 'PAGADO'
                break;
              case 8:
                color = 'danger'
                texto = 'ANULADO'
                break;
              case 9:
                color = 'primary'
                texto = 'CON DOCUMENTACIÓN';
                break;
              default:
                break;
            }
                
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
            <strong className={'text-' + color}>{texto}</strong></td>)
          } else if (key === 'number') {
            rowRender.push(<td align="center">{r.number_year}</td>);
          }
          else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }

        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, rows, unitSelected, state])

  return <> 
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De {nameModulo}</span>
      </Card.Header>
      <Card.Body>
        {file ? <a href={file.file} download={file.name}>
          <Button size="sm" variant="success" className="mb-2"><i className="fa fa-file-excel mr-2"></i>Descargar Reporte Excel</Button>
        </a> : ''}
        <div className="form-row">
          {cboUnit}
          <Select
            defaultValue={optionsState.find(o => o.value === state)}
            classNameParent="col-12 col-md-6"
            icon="fa fa-list"
            text="Estado"
            options={optionsState}
            onChange={e => setState(e.value)}
          />
        </div>
        {dtRows}
      </Card.Body>
    </Card>
  </>
}

export default Nivel;