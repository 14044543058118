import React, { useState, useEffect, useMemo } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Controllers from '../Api/Controllers';
import { Select } from '../Components/Form/Form';
import Button from 'react-bootstrap/Button';

const Provider = () => {
  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [reload, setReload] = useState(true);
  const [subproductos, setSubproductos] = useState([])
  const [unit, setUnit] = useState([])
  const [unitSel, setUniSel] =  useState('')
  const [subSel, setSubSel] =  useState('')
  const [supSel, setSupSel] =  useState('')

  const [filename, setFilename] = useState('')

  useEffect(() => {
    if (reload) {
      setIsProcessing(true)
      Controllers.orders.reporte_supplies().then(res => {
        setRows(res.data)

        let u = [{
          value: '', label: 'TODOS'
        }]

        let sb = [{
          value: '', label: 'TODOS'
        }]

        res.data.forEach(r => {
          if (!u.some(z => z.value === r.unit)) {
            u.push({
              value: r.unit,
              label: r.unit
            })
          }

          if (!sb.some(z => z.value === r.id_sub_products)) {
            sb.push({
              value: r.id_sub_products,
              label: r.id_sub_products
            })
          }
        })
        setUnit(u)
        setSubproductos(sb)
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });

      Controllers.orders.get_reporte_supplies().then(res => {
        setFilename({
          file: res.data.file,
          name: res.data.filename
        })
      })
    }
  }, [reload])

  const cboUnit = useMemo(() => {
    return <Select
      classNameParent="col-12 col-md-6"
      icon="fa fa-list"
      text="Centro De Costo"
      options={unit}
      onChange={e => {
        setUniSel(e.value)
        setSubSel('')
        setSupSel('')

        let sb = [{
          value: '', label: 'TODOS'
        }]

        rows.filter(r => {
          if (e.value === '') {
            return r
          }
          return r.unit === e.value
        }).forEach(r => {
          if (!sb.some(z => z.value === r.id_sub_products)) {
            sb.push({
              value: r.id_sub_products,
              label: r.id_sub_products
            })
          }
        })

        setSubproductos(sb)
      }}
      value={unit.find(u => u.value === unitSel)}
    />
  }, [unit, unitSel, rows])

  const cboSub = useMemo(() => {
    return <Select
      classNameParent="col-12 col-md-6"
      icon="fa fa-list"
      text="Subproducto"
      options={subproductos}
      onChange={e => {
        setSubSel(e.value)
      }}
      value={subproductos.find(u => u.value === subSel)}
    />
  }, [subproductos, subSel])

  const dtRows = useMemo(() => {
    const thead = [
      { name: 'Centro Costo', align: 'center' },
      { name: 'Insumo' }
      
    ];
    thead.push({ name: 'Subproducto', align: 'center' })
    thead.push({ name: 'Cant. Inicial', align: 'center' })
    thead.push({ name: 'Cant. Ejec', align: 'center' })
    thead.push({ name: 'Saldo', align: 'center' })

    return <DataTable
      isProcessing={isProcessing}
      tbody={rows.map(r => {
        return {
          unit: r.unit,
          supplie: r.supplie,
          id_sub_products: r.id_sub_products,
          qty_start: parseFloat(r.qty_start),
          qty_order_ejec: parseFloat(r.qty_order_ejec),
          qty: parseFloat(r.qty),
        }
      }).filter(r => {
        if (unitSel === '')
          return r

        return r.unit === unitSel
      }).filter(r => {
        if (subSel === '')
          return r

        return r.id_sub_products === subSel
      }).filter(r => {
        if (supSel === '')
          return r

        return r.supplie === supSel
      })}
      thead={thead}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;

        for (let key in r) {
          if (key === 'id' || key === 'id_consolidate') {
            continue;
          }

          let color = ''
          let texto = ''
          switch (parseInt(r.state)) {
            case 0:
              color = 'warning'
              texto = 'SIN ORDEN DE COMPRA'
              break;
            case 1:
              color = 'info'
              texto = 'CON ORDEN DE COMPRA'
              break;
            case 2:
              color = 'primary'
              texto = 'COMPROMISO'
              break;
            case 3:
              color = 'dark'
              texto = 'COMPLETADO'
              break;
            case 4:
              color = 'info'
              texto = 'DEVENGADO'
              break;
            case 5:
              color = 'primary'
              texto = 'ENVIADO DAF'
              break;
            case 6:
              color = 'danger'
              texto = 'RECHAZADO'
              break;
            case 7:
              color = 'success'
              texto = 'PAGADO'
              break;
            case 8:
              color = 'danger'
              texto = 'ANULADO'
              break;
            case 9:
              color = 'primary'
              texto = 'CON DOCUMENTACIÓN';
              break;
            default:
              break;
          }

          if (key === 'state') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
            <strong className={'text-' + color}>{texto}</strong>
          </td>);
          }
          else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }

        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, rows, unitSel, subSel, supSel])

  return <>
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De Ordenes De Compra</span>
      </Card.Header>
      <Card.Body >
        {filename ? <a href={filename.file} download={filename.name}><Button variant="success" size="sm" className="align-self-center">
          <i className="fa fa-file-excel mr-2"></i> Descargar Reporte
        </Button></a> : ''}
        <div className="d-flex mb-2 form-row">
          {cboUnit}
          {cboSub}
        </div>
        
        {dtRows}
      </Card.Body>
    </Card>
  </>
}

Provider.defaultProps = {
  isPublic: false,
  added: [],
  onAdd: () => {}
}

export default Provider;