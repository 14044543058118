import React, { useEffect, useState, useCallback } from 'react'
import Form from 'react-bootstrap/Form';
import ReactPlaceholder from 'react-placeholder/lib';
import Controllers from '../../Api/Controllers';
import { FormCustom, Input } from '../../Components/Form/Form';
import Helpers from '../../Helpers/Helpers';
import UnopsFormConsolidate from './UnopsFormConsolidate';
import { toast as toastr } from 'react-toastify'
import Button from 'react-bootstrap/Button';

const UnopsFormRegularize = ({ listConsolidate, hideModal, unopsRegularize }) => {
    const [ready, setReady] = useState(false)
    const [details, setDetails] = useState([])

    useEffect(() => {
        let promises = listConsolidate.map(l => Controllers.unops.get_unops_consolidate_details({
            p_id_unops_consolidate: l.id_unops_consolidate
        }))

        Promise.all(promises).then(res => {
            let d = []
            res.forEach(r => {
                d.push(<>
                    <UnopsFormConsolidate data={r.data.unops_consolidate} details={r.data.unops} />
                    <hr />
                </>)
            })
            setDetails(<>
                <h5><strong>Listado de consolidaciones</strong></h5>
                {React.Children.toArray(d)}
            </>)
            setReady(true)
        }).catch(_ => hideModal())
    }, [listConsolidate, hideModal])

    const [row, setRow] = useState({
        p_number_curdp: '',
        p_date_pay: '',
        p_file_regularize: ''
    })

    const dataSumbit = useCallback(() => {
        let f = new FormData()
        f.append('p_number_curdp', row.p_number_curdp)
        f.append('p_date_pay', row.p_date_pay)
        f.append('p_file_regularize', row.p_file_regularize)
        f.append('p_list_consolidate', JSON.stringify(listConsolidate.map(l => l.id_unops_consolidate)))
        return f
      }, [row, listConsolidate])

    return <ReactPlaceholder showLoadingAnimation ready={ready} rows={15}>
        <FormCustom dataSubmit={dataSumbit()} viewSubmit={isNaN(parseInt(unopsRegularize.id_unops_regularize))} onSubmit={({ e, dataSubmit }) => {
            let $this = e.currentTarget

            let propsValidateForm = { form: $this, toastr };
            if (!$this.checkValidity()) {
                $this.classList.add('was-validated');
                Helpers.form.isFormCorrect(propsValidateForm);
                return;
            } else {
                $this.classList.remove('was-validated');
                if (!Helpers.form.isFormCorrect(propsValidateForm)) {
                    return;
                }
            }

            let button = $this.querySelector('button[type=submit]');
            let buttonHTML = button.innerHTML
            button.innerHTML = Helpers.icon.spin();

            Controllers.unops.unops_regularize(dataSubmit).then(res => {
                Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
                if (res.response === 'success') {
                    hideModal();
                }
            }).catch(req => {
                Helpers.promise.catch({ req, toastr });
            }).finally(() => {
                button.innerHTML = buttonHTML;
            });
        }}>
            {unopsRegularize.id_unops_regularize ? <>
                <Input
                    type="text"
                    text="CURDP"
                    classNameParent="col-12 mb-2"
                    defaultValue={unopsRegularize.number_curdp}
                    placeholder="CURDP"
                    required
                    disabled
                />
                <Input
                    type="text"
                    text="Fecha Pago"
                    classNameParent="col-12 mb-2"
                    defaultValue={unopsRegularize.date_pay}
                    placeholder="Fecha Pago"
                    required
                    disabled
                />
                <div className="col-12">
                    <Button size="sm" variant="danger" onClick={_ => {
                        window.open(unopsRegularize.file_regularize, '_blank')
                    }}><i className="fa fa-file-pdf mr-2"></i>Ver Documento</Button>
                </div>
            </> : <>
                <Input
                    type="text"
                    text="CURDP"
                    classNameParent="col-12 mb-2"
                    onChange={e => setRow({ ...row, p_number_curdp: e.currentTarget.value })}
                    placeholder="CURDP"
                    required
                    maxLength={20}
                />
                <Input
                    type="date"
                    text="Fecha Pago"
                    classNameParent="col-12 mb-2"
                    onChange={e => setRow({ ...row, p_date_pay: e.currentTarget.value })}
                    required
                />
                <div className="col-12 mb-2">
                    <Form.File id="formcheck-api-regular">
                        <Form.File.Label>Selecionar Documento</Form.File.Label>
                        <Form.File.Input required accept="application/pdf" onChange={e => {
                            setRow({ ...row, p_file_regularize: e.currentTarget.files.length > 0 ? e.currentTarget.files[0] : '' })
                        }} />
                    </Form.File>
                </div>
            </>}
            <div className="col-12 mt-2">
                {details}
            </div>
        </FormCustom>
    </ReactPlaceholder>
}

UnopsFormRegularize.defaultProps = {
    unopsRegularize: {}
}

export default UnopsFormRegularize