import React, { useState, useEffect, useMemo } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Controllers from '../Api/Controllers';
import { useSelector } from 'react-redux';
import { Select } from '../Components/Form/Form';
import Button from 'react-bootstrap/Button';

const Provider = () => {
  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [reload, setReload] = useState(true);
  const currentYear = useSelector(store => store.session.year)
  const [unit, setUnit] = useState([])
  const [unitSelected, setUnitSelected] = useState('')
  const [file, setFile] = useState({})

  useEffect(() => {
    if (reload) {
      setIsProcessing(true)
      Controllers.orders.get_reporte_invoice_pending({
        p_id_unit: unitSelected
      }).then(res => {
        setRows(res.data.data)
        setFile(res.data.data.length ? {
          file: res.data.file,
          name: res.data.filename
        } : {})
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload, unitSelected])

  useEffect(() => {
    Controllers.unit.get_unit_public_with_pack({
      p_year: currentYear
    }).then(res => {
      res.data.unshift({
        id: '',
        description: 'TODAS'
      })
      setUnit(res.data)
    })
  }, [currentYear])

  const cboUnit = useMemo(() => {
    let unitFind = unit.find(u => u.id === unitSelected)

    return <Select
      value={unitFind ? {value: unitFind.id, label: unitFind.id + (unitFind.id ? ' - ' : '') + unitFind.description + ' - ' + currentYear} : ''}
      options={unit.map(s => {
        return {
          value: s.id,
          label: s.id + (s.id ? ' - ' : '') + s.description + ' - ' + currentYear
        }
      })}
      onChange={e => {
        setUnitSelected(e.value)
        setReload(true)
      }}
      text="Centro de costo"
      icon="far fa-building"
      classNameParent="mb-2"
      append={file ? [<a href={file.file} download={file.name}>
        <Button size="sm" variant="success"><i className="fa fa-file-excel"></i><span className="ml-2 d-none d-md-inline-block">Descargar Reporte Excel</span></Button>
      </a>] : []}
    />
  }, [unit, currentYear, unitSelected, file])

  const dtRows = useMemo(() => {
    const thead = [
      
      { name: 'Nro OC', align: 'center' },
      { name: 'Nro Factura', align: 'center' },
      { name: 'Fecha Factura', align: 'center' },
      { name: 'Fecha Compra', align: 'center' },
      
    ];
    thead.push({ name: 'Insumo' })
    thead.push({ name: 'Estado', align: 'center' })
    thead.push({ name: 'Centro Costo', align: 'center' })

    return <DataTable
      isProcessing={isProcessing}
      tbody={rows.map(r => {
        return {
          number_purchase: parseInt(r.number_purchase),
          number_invoice: r.number_invoice,
          date_invoice: r.date_invoice,
          date_issue: r.date_issue,
          supplie: r.supplie,
          state: r.state,
          unit: r.unit,
        }
      })}
      thead={thead}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;

        for (let key in r) {
          if (key === 'id') {
            continue;
          }

          let color = ''
          let texto = ''
          switch (parseInt(r.state)) {
            case 0:
              color = 'warning'
              texto = 'SIN ORDEN DE COMPRA'
              break;
            case 1:
              color = 'info'
              texto = 'CON ORDEN DE COMPRA'
              break;
            case 2:
              color = 'primary'
              texto = 'COMPROMISO'
              break;
            case 3:
              color = 'dark'
              texto = 'COMPLETADO'
              break;
            case 4:
              color = 'info'
              texto = 'DEVENGADO'
              break;
            case 5:
              color = 'primary'
              texto = 'ENVIADO DAF'
              break;
            case 6:
              color = 'danger'
              texto = 'RECHAZADO'
              break;
            case 7:
              color = 'success'
              texto = 'PAGADO'
              break;
            case 8:
              color = 'danger'
              texto = 'ANULADO'
              break;
            case 9:
              color = 'primary'
              texto = 'CON DOCUMENTACIÓN';
              break;
            default:
              break;
          }

          if (key === 'state') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
            <strong className={'text-' + color}>{texto}</strong>
          </td>);
          }
          else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }

        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, rows])

  return <> 
    <Card>
      <Card.Header className="justify-content-between">
        <span>Unidades Con Recibos Pendientes</span>
      </Card.Header>
      <Card.Body>
        {cboUnit}
        {dtRows}
      </Card.Body>
    </Card>
  </>
}

Provider.defaultProps = {
  isPublic: false,
  added: [],
  onAdd: () => {}
}

export default Provider;