import Helpers from "../Helpers/Helpers";

const Controllers = {
    user: {
        get_login: (data = {}) => Helpers.axios({ url: '/users/login', method: 'POST', data }),
        get_users: () => Helpers.axios({ url: '/users/get/all' }),
        get_user_unit: ({ p_id = '' }) => Helpers.axios({ url: '/users/unit/get/all/' + p_id }),
        get_users_id: ({ p_id }) => Helpers.axios({ url: '/users/get/id/' + p_id }),
        users_insert_update: (data = {}) => Helpers.axios({ url: '/users/insert/update', method: 'POST', data }),
        users_delete: (data = {}) => Helpers.axios({ url: '/users/delete', method: 'POST', data }),
        users_change_password: (data = {}) => Helpers.axios({ url: '/users/change/passsword', method: 'POST', data }),
        get_user_information: () => Helpers.axios({ url: '/users/get/information' }),
    },
    unit: {
        get_unit: () => Helpers.axios({ url: '/unit/get/all' }),
        get_unit_user: () => Helpers.axios({ url: '/unit/get/user' }),
        get_unit_public_award: () => Helpers.axios({ url: '/unit/publicaward/get/all' }),
        get_unit_public_without_pack: ({ p_year = '' }) => Helpers.axios({ url: '/unit/publicpac/get/all/' + p_year }),
        get_unit_public_with_pack: ({ p_year = '' }) => Helpers.axios({ url: '/unit/pac/get/all/' + p_year }),
        get_unit_budget: ({ p_year = '', p_id_unit = '', p_id = '', p_id_supplie }) => Helpers.axios({ url: '/unit/buget/get/all/' + p_year + '/' + p_id_unit + '/' + p_id_supplie + '/' + p_id }),
        get_unit_id: ({ p_id }) => Helpers.axios({ url: '/unit/get/id/' + p_id }),
        unit_insert_update: (data = {}) => Helpers.axios({ url: '/unit/insert/update', method: 'POST', data }),
        unit_delete: (data = {}) => Helpers.axios({ url: '/unit/delete', method: 'POST', data })
    },
    nivel: {
        get_nivel: () => Helpers.axios({ url: '/nivel/get/all' }),
        get_nivel_id: ({ p_id }) => Helpers.axios({ url: '/nivel/get/id/' + p_id }),
        nivel_insert_update: (data = {}) => Helpers.axios({ url: '/nivel/insert/update', method: 'POST', data }),
        nivel_delete: (data = {}) => Helpers.axios({ url: '/nivel/delete', method: 'POST', data })
    },
    profile: {
        get_profile: () => Helpers.axios({ url: '/profile/get/all' }),
        get_profile_id: ({ p_id }) => Helpers.axios({ url: '/profile/get/id/' + p_id }),
        profile_insert_update: (data = {}) => Helpers.axios({ url: '/profile/insert/update', method: 'POST', data }),
        profile_delete: (data = {}) => Helpers.axios({ url: '/profile/delete', method: 'POST', data }),
        get_tags_items_profile: ({ p_id = '' }) => Helpers.axios({ url: '/profile/tagsitems/get/all/' + p_id }),
    },
    group: {
        get_group: () => Helpers.axios({ url: '/group/get/all' }),
        get_group_id: ({ p_id }) => Helpers.axios({ url: '/group/get/id/' + p_id }),
        group_insert_update: (data = {}) => Helpers.axios({ url: '/group/insert/update', method: 'POST', data }),
        group_delete: (data = {}) => Helpers.axios({ url: '/group/delete', method: 'POST', data })
    },
    row: {
        get_row: ({ p_id_group = '' }) => Helpers.axios({ url: '/row/get/all/' + p_id_group }),
        get_row_public: () => Helpers.axios({ url: '/row/get/public' }),
        get_row_id: ({ p_id }) => Helpers.axios({ url: '/row/get/id/' + p_id }),
        row_insert_update: (data = {}) => Helpers.axios({ url: '/row/insert/update', method: 'POST', data }),
        row_delete: (data = {}) => Helpers.axios({ url: '/row/delete', method: 'POST', data })
    },
    presentation: {
        get_presentation: () => Helpers.axios({ url: '/presentation/get/all' }),
        get_presentation_id: ({ p_id }) => Helpers.axios({ url: '/presentation/get/id/' + p_id }),
        presentation_insert_update: (data = {}) => Helpers.axios({ url: '/presentation/insert/update', method: 'POST', data }),
        presentation_delete: (data = {}) => Helpers.axios({ url: '/presentation/delete', method: 'POST', data })
    },
    type_purchase: {
        get_type_purchase: () => Helpers.axios({ url: '/typepurchase/get/all' }),
        get_type_purchase_id: ({ p_id }) => Helpers.axios({ url: '/typepurchase/get/id/' + p_id }),
        type_purchase_insert_update: (data = {}) => Helpers.axios({ url: '/typepurchase/insert/update', method: 'POST', data }),
        type_purchase_delete: (data = {}) => Helpers.axios({ url: '/typepurchase/delete', method: 'POST', data })
    },
    type_supplies: {
        get_type_supplies: () => Helpers.axios({ url: '/typesupplies/get/all' }),
        get_type_supplies_id: ({ p_id }) => Helpers.axios({ url: '/typesupplies/get/id/' + p_id }),
        type_supplies_insert_update: (data = {}) => Helpers.axios({ url: '/typesupplies/insert/update', method: 'POST', data }),
        type_supplies_delete: (data = {}) => Helpers.axios({ url: '/typesupplies/delete', method: 'POST', data })
    },
    supplies: {
        get_supplies: () => Helpers.axios({ url: '/supplies/get/all' }),
        get_supplies_public: () => Helpers.axios({ url: '/supplies/get/public' }),
        get_supplies_id: ({ p_id }) => Helpers.axios({ url: '/supplies/get/id/' + p_id }),
        supplies_insert_update: (data = {}) => Helpers.axios({ url: '/supplies/insert/update', method: 'POST', data }),
        supplies_delete: (data = {}) => Helpers.axios({ url: '/supplies/delete', method: 'POST', data }),
        get_supplies_pac_public: ({ p_year = '', p_id_unit }) => Helpers.axios({ url: '/packsannual/supplies/' + p_id_unit + '/' + p_year }),
        get_supplies_pac_public_serv: ({ p_year = '', p_id_unit, p_id = '' }) => Helpers.axios({ url: '/packsannual/supplies/serv/' + p_id_unit + '/' + p_year + '/' + p_id }),
    },
    provider: {
        get_provider: () => Helpers.axios({ url: '/provider/get/all' }),
        get_provider_public: () => Helpers.axios({ url: '/provider/get/public' }),
        get_provider_id: ({ p_id }) => Helpers.axios({ url: '/provider/get/id/' + p_id }),
        provider_insert_update: (data = {}) => Helpers.axios({ url: '/provider/insert/update', method: 'POST', data }),
        provider_delete: (data = {}) => Helpers.axios({ url: '/provider/delete', method: 'POST', data })
    },
    award_acts: {
        get_award_acts: () => Helpers.axios({ url: '/awardacts/get/all' }),
        get_award_acts_id: ({ p_id }) => Helpers.axios({ url: '/awardacts/get/id/' + p_id }),
        award_acts_insert_update: (data = {}) => Helpers.axios({ url: '/awardacts/insert/update', method: 'POST', data }),
        award_acts_delete: (data = {}) => Helpers.axios({ url: '/awardacts/delete', method: 'POST', data }),
        get_award_acts_provider: ({ p_id_award_acts }) => Helpers.axios({ url: '/awardacts/provider/get/all/' + p_id_award_acts }),
        get_award_acts_unit: ({ p_id_award_acts }) => Helpers.axios({ url: '/awardacts/unit/get/all/' + p_id_award_acts }),
        get_award_acts_print: ({ p_type = '', p_id_award = '' }) => Helpers.axios({ url: '/awardacts/get/pdf/' + p_type + '/' + p_id_award }),
        award_acts_business: (data = {}) => Helpers.axios({ url: '/awardacts/business', method: 'POST', data }),
        award_acts_certification: (data = {}) => Helpers.axios({ url: '/awardacts/certification', method: 'POST', data }),
        award_acts_update_templates: (data = {}) => Helpers.axios({ url: '/awardacts/update/templates', method: 'POST', data }),
        get_award_acts_firms: ({ p_id }) => Helpers.axios({ url: '/awardacts/get/firms/' + p_id }),
    },
    sub_products: {
        get_sub_products_master: () => Helpers.axios({ url: '/subproductsmaster/get/all' }),
        get_sub_products_master_id: ({ p_id }) => Helpers.axios({ url: '/subproductsmaster/get/id/' + p_id }),
        sub_products_master_insert_update: (data = {}) => Helpers.axios({ url: '/subproductsmaster/insert/update', method: 'POST', data }),
        sub_products_master_delete: (data = {}) => Helpers.axios({ url: '/subproductsmaster/delete', method: 'POST', data }),
        get_sub_products: ({ p_id = '' }) => Helpers.axios({ url: '/subproducts/get/all/' + p_id }),
        get_sub_products_public: () => Helpers.axios({ url: '/subproducts/get/public' }),
        get_sub_products_id: ({ p_id }) => Helpers.axios({ url: '/subproducts/get/id/' + p_id }),
        sub_products_insert_update: (data = {}) => Helpers.axios({ url: '/subproducts/insert/update', method: 'POST', data }),
        sub_products_delete: (data = {}) => Helpers.axios({ url: '/subproducts/delete', method: 'POST', data })
    },
    packs_annual: {
        get_packs_annual: ({ p_year = '' }) => Helpers.axios({ url: '/packsannual/get/all/' + p_year }),
        get_packs_annual_id: ({ p_year = '', p_id_unit }) => Helpers.axios({ url: '/packsannual/get/id/' + p_year + '/' + p_id_unit }),
        packs_annual_insert_update: (data = {}) => Helpers.axios({ url: '/packsannual/insert/update', method: 'POST', data }),
        packs_annual_delete: (data = {}) => Helpers.axios({ url: '/packsannual/delete', method: 'POST', data }),
        get_packs_annual_details: ({ p_year = '', p_id_unit }) => Helpers.axios({ url: '/packsannual/details/get/all/' + p_year + '/' + p_id_unit }),
        get_packs_annual_details_extension: ({ p_year = '', p_id_unit, p_id = '' }) => Helpers.axios({ url: '/packsannual/details/extension/get/all/' + p_year + '/' + p_id_unit + '/' + p_id }),
        packs_annual_send_approval: (data = {}) => Helpers.axios({ url: '/packsannual/approval/send', method: 'POST', data }),
        packs_annual_disapprove: (data = {}) => Helpers.axios({ url: '/packsannual/disapprove', method: 'POST', data }),
        packs_annual_approve: (data = {}) => Helpers.axios({ url: '/packsannual/approve', method: 'POST', data }),
        packs_annual_details_extension_header_insert: (data = {}) => Helpers.axios({ url: '/packsannual/extension/insert', method: 'POST', data }),
        get_packs_annual_details_extension_header: ({ p_year = '' }) => Helpers.axios({ url: '/packsannual/header/get/all/' + p_year }),
        packs_annual_header_send_approval: (data = {}) => Helpers.axios({ url: '/packsannual/header/approval/send', method: 'POST', data }),
        packs_annual_header_disapprove: (data = {}) => Helpers.axios({ url: '/packsannual/header/disapprove', method: 'POST', data }),
        packs_annual_header_approve: (data = {}) => Helpers.axios({ url: '/packsannual/header/approve', method: 'POST', data }),
        get_pack_details_extension: ({ p_id = '' }) => Helpers.axios({ url: '/packsannual/header/details/' + p_id }),
        get_packs_annual_consolidate: ({ p_id = '' }) => Helpers.axios({ url: '/packsannual/header/consolidate/' + p_id }),
        get_pack_annual_rows: ({ p_year = '', p_id_unit }) => Helpers.axios({ url: '/packsannual/get/rows/' + p_year + '/' + p_id_unit }),
        get_pack_annual_sub_products_by_row: ({ p_year = '', p_id_unit = '', p_id_row, p_id = '' }) => Helpers.axios({ url: '/packsannual/get/rows/subproducts/' + p_year + '/' + p_id_unit + '/' + p_id_row + '/' + p_id }),
        read_excel: (data) => Helpers.axios({ url: '/packsannual/excel', method: 'POST', hasFile: true, data }),
        get_pac_excel: ({ p_id_unit = '', p_year = '' }) => Helpers.axios({ url: '/packsannual/excel/get/' + p_id_unit + '/' + p_year }),
        get_pac_excel_history: ({ p_id_unit = '', p_year = '' }) => Helpers.axios({ url: '/packsannual/excel/general/get/' + p_id_unit + '/' + p_year }),
        get_pac_resolution: _ => Helpers.axios({ url: '/get/pac/resolution' })
    },
    orders: {
        get_orders: () => Helpers.axios({ url: '/orders/get/all' }),
        reporte_orders_general: () => Helpers.axios({ url: '/orders/general/get/all' }),
        reporte_supplies: () => Helpers.axios({ url: '/orders/supplies/get/all' }),
        get_reporte_supplies: () => Helpers.axios({ url: '/orders/supplies/excel' }),
        get_orders_serv: () => Helpers.axios({ url: '/orders/serv/get/all' }),
        get_orders_id: ({ p_id }) => Helpers.axios({ url: '/orders/get/id/' + p_id }),
        get_orders_consolidate_number_consolidate: ({ p_number_resolution }) => Helpers.axios({ url: '/orders/get/number/consolidate/' + p_number_resolution }),
        get_orders_supplies: ({ p_id }) => Helpers.axios({ url: '/orders/supplies/' + p_id }),
        orders_insert_update: (data = {}) => Helpers.axios({ url: '/orders/insert/update', method: 'POST', data }),
        orders_delete: (data = {}) => Helpers.axios({ url: '/orders/delete', method: 'POST', data }),
        get_orders_sub_products: ({ p_id = '' }) => Helpers.axios({ url: '/orders/subproducts/get/all/' + p_id }),
        orders_approve: (data = {}) => Helpers.axios({ url: '/orders/approve', method: 'POST', data }),
        get_orders_consolidate: ({ p_year }) => Helpers.axios({ url: '/orders/get/consolidate/' + p_year }),
        get_orders_consolidate_orders: ({ p_id }) => Helpers.axios({ url: '/orders/get/consolidate/orders/' + p_id }),
        get_orders_consolidate_supplie: ({ p_id }) => Helpers.axios({ url: '/orders/get/consolidate/supplies/' + p_id }),
        orders_send_approval: (data = {}) => Helpers.axios({ url: '/orders/send/approve', method: 'POST', data }),
        orders_disapprove: (data = {}) => Helpers.axios({ url: '/orders/disapprove', method: 'POST', data }),
        purchase_orders_update: (data = {}) => Helpers.axios({ url: '/orders/purchase', method: 'POST', data }),
        get_orders_consolidate_id: ({ p_id }) => Helpers.axios({ url: '/orders/consolidate/' + p_id }),
        orders_compromise: (data = {}) => Helpers.axios({ url: '/orders/compromise', method: 'POST', data }),
        get_orders_invoice: ({ p_id }) => Helpers.axios({ url: '/orders/invoices/' + p_id }),
        orders_devengade: (data = {}) => Helpers.axios({ url: '/orders/devengade', method: 'POST', data }),
        orders_send_daf: (data = {}) => Helpers.axios({ url: '/orders/senddaf', method: 'POST', data }),
        orders_rejection: (data = {}) => Helpers.axios({ url: '/orders/rejection', method: 'POST', data }),
        orders_pay: (data) => Helpers.axios({ url: '/orders/pay', method: 'POST', data, hasFile: true }),
        orders_free: (data = {}) => Helpers.axios({ url: '/orders/free', method: 'POST', data }),
        orders_ban: (data = {}) => Helpers.axios({ url: '/orders/ban', method: 'POST', data }),
        orders_ban_new: (data = {}) => Helpers.axios({ url: '/orders/ban/new', method: 'POST', data }),
        get_reporte_general_oc: ({ p_date_start, p_date_end }) => Helpers.axios({ url: '/orders/report/general/oc/' + p_date_start + '/' + p_date_end }),
        get_reporte_invoice_pending: ({ p_id_unit = '' }) => Helpers.axios({ url: '/orders/report/invoice/pending/' + p_id_unit }),
        get_reporte_deuda_flotante: (data) => Helpers.axios({ url: '/orders/report/deuda/flotante/', data }),
        get_orders_consolidate_number_oc: ({ p_number_purchase }) => Helpers.axios({ url: '/orders/consolidate/numberpurchase/' + p_number_purchase }),
        get_reporte_general: ({ p_date_start, p_date_end }) => Helpers.axios({ url: '/orders/report/general/' + p_date_start + '/' + p_date_end }),
        read_excel: (data) => Helpers.axios({ url: '/orders/excel', method: 'POST', hasFile: true, data }),
        get_reporte_oc_unit_qty: ({ p_list_oc, p_name_provider }) => Helpers.axios({ url: '/orders/get/reporte/excel/' + p_list_oc + '/' + p_name_provider }),
    },
    firms: {
        get_firms: ({ p_id_unit = '' }) => Helpers.axios({ url: '/firms/get/all/' + p_id_unit }),
        get_firms_public: () => Helpers.axios({ url: '/firms/get/public' }),
        get_firms_id: ({ p_id }) => Helpers.axios({ url: '/firms/get/id/' + p_id }),
        firms_insert_update: (data = {}) => Helpers.axios({ url: '/firms/insert/update', method: 'POST', data }),
        firms_delete: (data = {}) => Helpers.axios({ url: '/firms/delete', method: 'POST', data })
    },
    actions: {
        get_actions: () => Helpers.axios({ url: '/actions/get/all' }),
        get_actions_public: () => Helpers.axios({ url: '/actions/get/public' }),
        get_actions_id: ({ p_id }) => Helpers.axios({ url: '/actions/get/id/' + p_id }),
        actions_insert_update: (data = {}) => Helpers.axios({ url: '/actions/insert/update', method: 'POST', data }),
        actions_delete: (data = {}) => Helpers.axios({ url: '/actions/delete', method: 'POST', data })
    },
    tags_items: {
        get_tags_items: () => Helpers.axios({ url: '/tagsitems/get/all' }),
        get_tags_items_id: ({ p_id }) => Helpers.axios({ url: '/tagsitems/get/id/' + p_id }),
        tags_items_insert_update: (data = {}) => Helpers.axios({ url: '/tagsitems/insert/update', method: 'POST', data }),
        get_tags_items_childrens: ({ p_id }) => Helpers.axios({ url: '/tagsitems/childrens/get/all/' + p_id }),
    },
    quarter_annual: {
        get_unit_without_quarter: () => Helpers.axios({ url: '/quarterannual/unit/without/get/all' }),
        get_unit_with_quarter: () => Helpers.axios({ url: '/quarterannual/unit/with/get/all' }),
        get_quarter_annual: () => Helpers.axios({ url: '/quarterannual/get/all' }),
        get_quarter_annual_details: ({ p_id = '' } = {}) => Helpers.axios({ url: '/quarterannual/details/get/all/' + p_id }),
        get_quarter_annual_id: ({ p_id }) => Helpers.axios({ url: '/quarterannual/get/id/' + p_id }),
        quarter_annual_insert_update: (data = {}) => Helpers.axios({ url: '/quarterannual/insert/update', method: 'POST', data }),
        quarter_send_approve: (data = {}) => Helpers.axios({ url: '/quarterannual/send/approve', method: 'POST', data }),
        quarter_approve: (data = {}) => Helpers.axios({ url: '/quarterannual/approve', method: 'POST', data }),
        quarter_refuse: (data = {}) => Helpers.axios({ url: '/quarterannual/refuse', method: 'POST', data }),
        get_quarter_annual_extension: () => Helpers.axios({ url: '/quarterannual/extension/get/all' }),
        get_quarter_unit_balance: ({ p_id_unit, p_year }) => Helpers.axios({ url: '/quarterannual/get/balance/' + p_id_unit + '/' + p_year }),
        get_quarter_extension_details: ({ p_id_unit, p_year, p_id }) => Helpers.axios({ url: '/quarterannual/extension/get/details/' + p_id_unit + '/' + p_year + '/' + p_id }),
        quarter_extension_insert_update: (data) => Helpers.axios({ url: '/quarterannual/extension/insert/update', method: 'POST', data }),
        get_quarter_annual_extension_id: ({ p_id }) => Helpers.axios({ url: '/quarterannual/extension/get/id/' + p_id }),
        get_quarter_extension_details_consolidate: ({ p_id }) => Helpers.axios({ url: '/quarterannual/extension/get/all/consolidate/' + p_id }),
        quarter_extension_send_approve: (data = {}) => Helpers.axios({ url: '/quarter/extension/send/approve', method: 'POST', data }),
        quarter_extension_approve: (data = {}) => Helpers.axios({ url: '/quarter/extension/approve', method: 'POST', data }),
        quarter_extension_refuse: (data = {}) => Helpers.axios({ url: '/quarter/extension/refuse', method: 'POST', data }),
    },
    participant: {
        get_participant: () => Helpers.axios({ url: '/participant/get/all' }),
        get_participant_id: ({ p_id }) => Helpers.axios({ url: '/participant/get/id/' + p_id }),
        participant_insert_update: (data = {}) => Helpers.axios({ url: '/participant/insert/update', method: 'POST', data }),
        participant_delete: (data = {}) => Helpers.axios({ url: '/participant/delete', method: 'POST', data }),
        get_participant_public: _ => Helpers.axios({ url: '/participant/get/public' })
    },
    book: {
        get_book: () => Helpers.axios({ url: '/book/get/all' }),
        get_book_id: ({ p_id }) => Helpers.axios({ url: '/book/get/id/' + p_id }),
        book_insert_update: (data = {}) => Helpers.axios({ url: '/book/insert/update', method: 'POST', data }),
        book_delete: (data = {}) => Helpers.axios({ url: '/book/delete', method: 'POST', data }),
        get_book_public: _ => Helpers.axios({ url: '/book/get/public' })
    },
    address: {
        get_address: ({ p_id_unit = '' } = {}) => Helpers.axios({ url: '/address/get/all/' + p_id_unit }),
        get_address_id: ({ p_id }) => Helpers.axios({ url: '/address/get/id/' + p_id }),
        address_insert_update: (data = {}) => Helpers.axios({ url: '/address/insert/update', method: 'POST', data }),
        address_delete: (data = {}) => Helpers.axios({ url: '/address/delete', method: 'POST', data }),
        get_address_public: ({ p_id_unit = '' } = {}) => Helpers.axios({ url: '/address/get/public/' + p_id_unit })
    },
    unops: {
        get_unops: () => Helpers.axios({ url: '/unops/get/all' }),
        get_unops_consolidate_list: ({ p_id_unit }) => Helpers.axios({ url: '/unops/consolidate/list/' + p_id_unit }),
        get_unops_curdp_list: ({ p_id_unit }) => Helpers.axios({ url: '/unops/curdp/list/' + p_id_unit }),
        get_unops_consolidate: () => Helpers.axios({ url: '/unops/get/consolidate' }),
        get_unops_regularize: () => Helpers.axios({ url: '/unops/get/regularize' }),
        get_unops_consolidate_details: ({ p_id_unops_consolidate }) => Helpers.axios({ url: '/unops/get/consolidate/details/' + p_id_unops_consolidate }),
        get_unops_regularize_details: ({ p_id_unops_regularize }) => Helpers.axios({ url: '/unops/get/regularize/details/' + p_id_unops_regularize }),
        get_unops_id: ({ p_id }) => Helpers.axios({ url: '/unops/get/id/' + p_id }),
        unops_insert_update: (data = {}) => Helpers.axios({ url: '/unops/insert/update', method: 'POST', data }),
        unops_delete: (data = {}) => Helpers.axios({ url: '/unops/delete', method: 'POST', data }),
        unops_consolidate: (data = {}) => Helpers.axios({ url: '/unops/consolidate', method: 'POST', data }),
        unops_consolidate_delete: (data = {}) => Helpers.axios({ url: '/unops/consolidate/delete', method: 'POST', data }),
        unops_regularize: (data = {}) => Helpers.axios({ url: '/unops/regularize', method: 'POST', data, hasFile: true }),
        get_unops_report: () => Helpers.axios({ url: '/unops/get/reporte/excel/' }),
        get_unops_regularize_report: ({ p_id_unit, p_id_firm_realiza, p_id_firm_revisa, p_id_unops_consolidate }) => Helpers.axios({ url: '/unops/regularize/get/reporte/excel/' + p_id_unit + '/' + p_id_firm_realiza + '/' + p_id_firm_revisa + '/' + p_id_unops_consolidate }),
        get_unops_contabilidad_report: ({ p_id_unit, p_id_firm_realiza, p_id_firm_revisa, p_number_curdp }) => Helpers.axios({ url: '/unops/contabilidad/get/reporte/excel/' + p_id_unit + '/' + p_id_firm_realiza + '/' + p_id_firm_revisa + '/' + p_number_curdp }),
        get_unops_general_report: () => Helpers.axios({ url: '/unops/get/reporte/general/excel/' }),
    },
}
export default Controllers;