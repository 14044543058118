import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Controllers from '../Api/Controllers';
import { FormCustom, Input, SwitchToggle, Select, CheckBox } from '../Components/Form/Form';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import ReactPlaceholder from 'react-placeholder';
import {useSelector} from 'react-redux'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


const SuppliesForm = ({ data, onSubmit, viewDisabled }) => {
  const {
    cod_internal = '',
    cod_ppr = '',
    description_ppr = '',
    description_internal = '',
    id = '',
    id_presentation = '',
    id_row = '',
    id_type_supplies = '',
    price = '',
    state = 0,
    has_catalog = 1
  } = data

  const [row, setRow] = useState({
    id,
    description_ppr,
    description_internal,
    cod_internal,
    cod_ppr: id === '' ? cod_ppr : cod_ppr.replace('-'+id_presentation, ''),
    id_type_supplies,
    price,
    state: parseInt(state) === 1,
    has_catalog: parseInt(has_catalog)
  })

  const [presentation, setPresentation] = useState([])
  const [typeSupplies, setTypesSupplies] = useState([])
  const [autoGenerated, setAutoGenerated] = useState(false);
  const [isOk, setIsOk] = useState(false);
  const [group, setGroup] = useState([]);
  const [rows, setRows] = useState([]);
  const [idPresentation, setIdPresentation] = useState(id_presentation)
  const [idRow, setIdRow] = useState(id_row)

  const dataSumbit = useCallback(() => {
    return {
      p_id: row.id,
      p_description_ppr: row.description_ppr,
      p_description_internal: row.description_internal,
      p_cod_internal: row.cod_internal,
      p_cod_ppr: autoGenerated ? row.cod_ppr : row.cod_ppr + '-' + idPresentation,
      p_id_presentation: idPresentation,
      p_id_row: idRow,
      p_id_type_supplies: row.id_type_supplies,
      p_price: row.price,
      p_state: row.state ? 1 : 0,
      p_has_catalog: parseInt(row.has_catalog),
      p_auto_generated: autoGenerated
    }
  }, [row, autoGenerated, idRow, idPresentation])

  useEffect(() => {
    let promises = [
      Controllers.presentation.get_presentation(),
      Controllers.type_supplies.get_type_supplies(),
      Controllers.group.get_group(),
      Controllers.row.get_row_public()
    ]
    Promise.all(promises).then(res => {
      setPresentation(res[0].data)
      setTypesSupplies(res[1].data)
      setGroup(res[2].data)
      setRows(res[3].data)
      if (id !== '') {
        if (res[1].data.some(g => g.id === id_type_supplies)) {
          let gr = res[1].data.find(g => g.id === id_type_supplies)
          if (parseInt(gr.auto_generated) === 1) {
            setAutoGenerated(true);
          }
        }
      }
      setIsOk(true);
    })
  }, [id, id_type_supplies])

  const cboPresentation = useMemo(() => {
    let findP = presentation.find(p => parseInt(p.id) === parseInt(idPresentation))
    return <Select
      defaultValue={(findP ? { id: findP.id, label: findP.id + ' - ' + findP.description } : '')}
      text="Presentación"
      options={presentation.map(p => { return { value: p.id, label: p.id + ' - ' + p.description } })}
      classNameParent={'col-6 mb-2' + (autoGenerated ? ' d-none' : '')}
      required={!autoGenerated}
      icon="fas fa-clipboard-list"
      onChange={e => setIdPresentation(e.value)}
      disabled={viewDisabled}
    />
  }, [presentation, idPresentation, autoGenerated, viewDisabled])

  const cboRows = useMemo(() => {
    let findR = rows.find(p => parseInt(p.id) === parseInt(id_row))
    return <Select
      defaultValue={id === '' ? '' : (findR ? { id: findR.id, label: findR.id + ' - ' + findR.description } : '')}
      text="Renglón"
      options={rows.map(p => { return { value: p.id, label: p.id + ' - ' + p.description } })}
      classNameParent="col-12 mb-2"
      icon="fab fa-rev"
      required
      onChange={e => {
        setIdRow(e.value)
      }}
      disabled={viewDisabled}
    />
  }, [rows, id_row, id, viewDisabled])

  const cboTypeSupplies = useMemo(() => {
    let findTs = typeSupplies.find(ts => parseInt(ts.id) === parseInt(id_type_supplies))
    return <Select
      defaultValue={id === '' ? '' : (findTs ? { value: findTs.id, label: findTs.id + ' - ' + findTs.description } : '')}
      text="Tipo Insumo *"
      options={typeSupplies.map(p => { return { value: p.id, label: p.id + ' - ' + p.description, autoGenerated: p.auto_generated } })}
      classNameParent="col-6 mb-2"
      icon="fab fa-codepen"
      required
      onChange={e => {
        setRow({ ...row, id_type_supplies: e.value })
        setAutoGenerated(parseInt(e.autoGenerated) === 1)
      }}
      disabled={id !== '' || viewDisabled}
    />
  }, [typeSupplies, row, id, id_type_supplies, viewDisabled])

  return <ReactPlaceholder showLoadingAnimation rows={7}  type='text' ready={isOk}>
    <FormCustom dataSubmit={dataSumbit()} onSubmit={onSubmit} viewSubmit={!viewDisabled}>
      {id !== '' && autoGenerated ? <span className="col-12 mb-2">COD PPR: <strong>{cod_ppr}</strong></span> : ''}
      {cboTypeSupplies}
      <Input
        text="Cod PPR Generado"
        classNameParent={'col-6 mb-2' + (autoGenerated ? ' d-none' : '')}
        placeholder="Cod PPR Generado"
        icon="fas fa-code"
        disabled
        value={row.cod_ppr + '-' + idPresentation}
      />
      <Input
        text="Cod PPR *"
        placeholder="Cod PPR"
        classNameParent={'col-6 mb-2' + (autoGenerated ? ' d-none' : '')}
        icon="fas fa-code"
        required={!autoGenerated}
        value={row.cod_ppr}
        onChange={e => setRow({ ...row, cod_ppr: e.currentTarget.value })}
        pattern="[0-9A-Za-z]{1,20}"
        invalid="Formato incorrecto: Ej. 12345A, 12345"
        maxLength={20}
        disabled={viewDisabled}
      />
      {cboPresentation}
      <Input
        text="Cod Interno"
        placeholder="Cod Interno"
        classNameParent={'col-' + (autoGenerated ? 6 : 12) + ' mb-2'}
        icon="fas fa-code-branch"
        defaultValue={cod_internal}
        onChange={e => setRow({ ...row, cod_internal: e.currentTarget.value })}
        disabled={viewDisabled}
      />
      <Input
        text="Descripción PPR"
        placeholder="Descripción PPR"
        classNameParent="col-12 mb-2"
        icon="far fa-comment-alt"
        required
        defaultValue={description_ppr}
        onChange={e => setRow({ ...row, description_ppr: e.currentTarget.value })}
        disabled={viewDisabled}
      />
      <Input
        text="Descripción Interna"
        placeholder="Descripción Interna"
        classNameParent="col-12 mb-2"
        icon="far fa-comment-alt"
        required
        defaultValue={description_internal}
        onChange={e => setRow({ ...row, description_internal: e.currentTarget.value })}
        disabled={viewDisabled}
      />
      {cboRows}
      <Input
        text="Grupo"
        placeholder="Grupo"
        classNameParent="col-12 mb-2"
        icon="fas fa-object-group"
        disabled
        value={idRow !== '' ? (
          rows.some(g => g.id === idRow) ? 
            group.find(g => g.id === rows.find(r => r.id === idRow).id_group).description
          : ''
        ) :  ''}
      />
      <Input
        type="number"
        text="Precio"
        placeholder="Precio"
        classNameParent="col-6 mb-2"
        icon="fa fa-dollar-sign"
        required
        defaultValue={price}
        onChange={e => setRow({ ...row, price: e.currentTarget.value })}
        step="0.0000000001"
        min="0"
        disabled={viewDisabled}
      />
      <CheckBox
        defaultChecked={parseInt(has_catalog) === 1}
        text="¿Tiene Catalogo?"
        classNameParent="col-12 mb-2"
        onChange={e => setRow({...row, has_catalog: e.currentTarget.checked ? 1: 0})}
        id="has-catalog"
        disabled={viewDisabled}
      />
      <SwitchToggle
        id="switch-supplies"
        defaultChecked={parseInt(state) === 1}
        onChange={e => setRow({ ...row, state: e.currentTarget.checked })}
        disabled={viewDisabled}
      />
    </FormCustom>
  </ReactPlaceholder>    
}

const Supplies = ({ isPublic, added, onAdd, hasPrice, newSupplie, dataGet, isPac }) => {
  const nameModulo = 'Insumos'
  const namePage = 'Insumo'
  const nameController = 'supplies'
  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = Helpers.getActions({ permissions, name: 'insumos' })

  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [modalSize, setModalSize] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [form, setForm] = useState('');
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      Controllers[nameController]['get_' + nameController + (isPublic && isPac ? '_pac_public' : (isPublic ? '_public' : ''))](dataGet).then(res => {
        setRows(res.data)
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload, isPublic, isPac, dataGet])

  const handleUpdate = useCallback(e => {
    const { id = '', disabled = 0 } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()
    
    setModalTitle(parseInt(disabled) === 1 ? <><i className={'mr-2 ' + actionsModule.view.icon}></i>{actionsModule.view.description} {namePage}</> : (id ? <><i className={'mr-2 ' + actionsModule.update.icon}></i>{actionsModule.update.description} {namePage}</> : <><i className={'mr-2 ' + actionsModule.create.icon}></i>{actionsModule.create.description} {namePage}</>))
    setModalSize('');
    setModalHeader('');

    Controllers[nameController]['get_' + nameController + '_id']({ p_id: id }).then(res => {
      setForm(<SuppliesForm data={res.data} onSubmit={onSubmit} viewDisabled={parseInt(disabled) === 1} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }, [actionsModule])

  const onSubmit = ({ e, dataSubmit }) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }

      if (dataSubmit.p_id_type_supplies === '') {
        Helpers.toastr.construct({ response: 'warning', message: 'Por favor, seleccionar tipo insumo', toastr })
        return
      }

      if (!dataSubmit.p_auto_generated) {
        if (dataSubmit.p_id_presentation === '') {
          Helpers.toastr.construct({ response: 'warning', message: 'Por favor, seleccionar presentación', toastr })
          return
        }
      }

      if (dataSubmit.p_id_row === '') {
        Helpers.toastr.construct({ response: 'warning', message: 'Por favor, seleccionar renglón', toastr })
        return
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers[nameController][nameController + '_insert_update'](dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        setModalShow(false);
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
      setReload(true)
    });
  }

  const onDelete = e => {
    const { id = '' } = e.currentTarget.dataset;
    setModalTitle(<><i className="fa fa-trash mr-2"></i> Eliminar {namePage}</>);
    setModalHeader('bg-danger');
    setModalSize('sm');
    setForm(<div className="d-flex flex-column align-items-center">
      <p><strong>¿Eliminar {namePage} #{id}?</strong></p>

      <div>        
        <Button variant="danger" className="mr-2" size="sm" onClick={e => {
          let button = e.currentTarget
          let buttonHTML = button.innerHTML;
          button.innerHTML = Helpers.icon.spin()

          Controllers[nameController][nameController + '_delete']({
            p_id: id
          }).then(res => {
            Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
          }).catch(req => {
            Helpers.promise.catch({ req, toastr });
          }).finally(() => {
            button.innerHTML = buttonHTML;
            setReload(true)
            setModalShow(false);
          });
        }}>
          <i className="fa fa-check mr-2"></i>Si
        </Button>
        <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalShow(false)}>
          <i className="fa fa-times mr-2"></i>No
        </Button>
      </div>
    </div>);
    setModalShow(true)
  }

  const dtRows = useMemo(() => {
    const thead = [
      { name: 'Cod PPR', align: 'center' },
      { name: 'Cod Interno', align: 'center' },
      { name: 'Descripción' },
      { name: 'Tipo', align: 'center' },
      { name: 'Precio', align: 'center' },
      { name: 'Presentación', align: 'center' },
      { name: 'Renglón', align: 'center' },
      
    ];

    if (isPublic) {
      if (isPac) {
        thead.unshift({ name: 'SubProd', align: 'center'})
      }
      thead.unshift({ name: 'Acc.', align: 'center'})
      thead.push({ name: 'Catalogo', align: 'center' })
    } else {
      thead.push({ name: 'Catalogo', align: 'center' })
      thead.push({ name: 'Estado', align: 'center' })
      thead.push({ name: 'Acc.', align: 'center' })
    }

    return <DataTable
      isProcessing={isProcessing}
      tbody={rows}
      thead={thead}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;
        if (isPublic) {
          let exists = added.some(a => parseInt(a) === parseInt(r.id))
          let onClick = e => {
            if (!exists) {
              onAdd(e);
            }
          }
          if (hasPrice) {
            if (!isNaN(parseFloat(r.price))) {
              rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
                <Button onClick={onClick} data-has-catalog={r.has_catalog} data-description-ppr={r.description_ppr} data-price={r.price} data-id-row={r.id_row} variant={exists ? 'dark' : 'primary'} size="sm" data-id={r.id} data-description={r.description_internal}data-cod-internal={r.cod_internal} data-type-supplies={r.description_type_supplies}><i className={exists ? 'fa fa-check' : 'fa fa-plus'}></i></Button>
              </td>)
            } else {
              rowRender.push(<td></td>)
            }
          } else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
              <Button onClick={onClick} data-price={r.price} data-has-catalog={r.has_catalog} data-description-ppr={r.description_ppr} data-id-row={r.id_row} variant={exists ? 'dark' : 'primary'} size="sm" data-id={r.id} data-description={r.description_internal}data-cod-internal={r.cod_internal} data-type-supplies={r.description_type_supplies}><i className={exists ? 'fa fa-check' : 'fa fa-plus'}></i></Button>
            </td>)
          }
          i++;
        }

        for (let key in r) {
          if (key === 'id' || key === 'id_row' || key === 'description_ppr') {
            continue;
          }

          if (key === 'state' && isPublic) {
            continue;
          }

          if (key === 'state') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''} className={'text-' + (parseInt(r.state) === 1 ? 'success' : 'danger')}>
              <strong>{parseInt(r.state) === 1 ? 'ACTIVO' : 'INACTIVO'}</strong>
            </td>);
          } else if (key === 'has_catalog') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''} className={'text-' + (parseInt(r.has_catalog) === 1 ? 'success' : 'danger')}>
              <strong>{parseInt(r.has_catalog) === 1 ? 'SI' : 'NO'}</strong>
            </td>);
          }
          else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }

        if (!isPublic) {
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
            {actionsModule.view ?  <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.view.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.view.color} data-disabled={1} size="xs" className="mr-1" data-id={r.id} onClick={handleUpdate}>
                <i className={actionsModule.view.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.update ?  <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.update.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.update.color} size="xs" className="mr-1" data-id={r.id} onClick={handleUpdate}>
                <i className={actionsModule.update.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.delete ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.delete.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.delete.color} size="xs" data-id={r.id} onClick={onDelete}>
                <i className={actionsModule.delete.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
          </td>);
        }
        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, rows, handleUpdate, isPublic, added, onAdd, hasPrice, isPac, actionsModule])

  return <> 
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De {nameModulo}</span>
        {newSupplie ? (actionsModule.create ? <Button onClick={handleUpdate} variant={actionsModule.create.color} size="sm"><i className={ actionsModule.create.icon}></i><span className="ml-2 d-none d-md-inline-block">{actionsModule.create.description} {namePage}</span></Button> : '') : ''}
      </Card.Header>
      <Card.Body>
        {dtRows}
      </Card.Body>
    </Card>
    <Modal show={modalShow} onHide={() => setModalShow(false)} size={modalSize} backdrop="static">
      <Modal.Header closeButton className={modalHeader}>
        <Modal.Title as="div">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
  </>
}

Supplies.defaultProps = {
  isPublic: false,
  added: [],
  onAdd: () => {},
  hasPrice: false,
  newSupplie: true,
  dataGet: {},
  isPac: false
}

export default Supplies;