import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Controllers from '../Api/Controllers';
import { FormCustom, Input, Select, TextArea} from '../Components/Form/Form';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import ReactPlaceholder from 'react-placeholder';
import Supplies from './Supplies'
import Helpers from '../Helpers/Helpers';

const SuppliesPublic = ({showModal, hideModal, added, onAdd, dataGet }) => {
  return <>
    <Modal show={showModal} onHide={hideModal} size={'lg'} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title as="div"><i className="fa fa-list mr-2"></i> Listado De Insumos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Supplies isPublic newSupplie={false} isPac dataGet={dataGet} added={added} onAdd={onAdd} />
      </Modal.Body>
    </Modal>
  </>
}

const OrdersForm = ({ data, onSubmit, view }) => {
  let m = new Date().getMonth();
  let periodo = data.quarter_order ? data.quarter_order : (m <= 3 ? 'PRIMERO' : (m <= 7 ? 'SEGUNDO' : 'TERCERO'));

  const {
    id = '',
    id_unit = '',
    year = '',
    number = '',
    date = '',
    id_supplie = '',
    cod_internal = '',
    description_internal = '',
    type = 'SI',
    id_row = '',
    justification = '',
    justification_state = '',
    user_created = '',
    date_created = '',
    price = 0,
    quarter_order = periodo,
    year_additional = ''
  } = data

  const currentYear = useSelector(store => store.session.year)
  const [subProducts, setSubProducts] = useState([])

  const [row, setRow] = useState({
    id,
    year: id ? year : currentYear,
    number,
    date: id ? date : Helpers.date.get(),
    id_supplie,
    cod_internal,
    description_internal,
    type,
    id_row,
    justification,
    price,
    quarter_order: quarter_order,
    year_additional
  })

  const [unitSelected, setUnitSelected] = useState(id ? id_unit : '')
  const [unit, setUnit] = useState([])
  const [isOk, setIsOk] = useState(false)
  const [modalSupplie, setModalSupplie] = useState(false)

  const totalQty = useCallback(() => {
    let q = 0;
    subProducts.forEach(s => {
      if (!isNaN(parseInt(s.newQty))) {
        q += parseInt(s.newQty)
      }
    })
    return q
  }, [subProducts])

  useEffect(() => {
    Controllers.unit.get_unit_public_with_pack({
      p_year: currentYear
    }).then(res => {
      setUnit(res.data)
      if (!id) {
        if (res.data.length === 1) {
          setUnitSelected(res.data[0].id)
        }
      }
    })

    if (id !== '') {
      let promises = [
        Controllers.unit.get_unit_budget({
          p_id_unit: id_unit,
          p_year: year,
          p_id: id,
          p_id_supplie: id_supplie
        }),
        Controllers.orders.get_orders_sub_products({
          p_id: id
        })
      ]
      Promise.all(promises).then(res => {
        let subs = []
        res[0].data.forEach(s => {
          let sFind = res[1].data.find(s1 => s1.id_sub_products === s.id_sub_products)
          if (sFind) {
            s.newQty = sFind.qty
          }
          if (view) {
            if (sFind) {
              subs.push(s)
            }
          } else {
            subs.push(s)
          }
        })
        setSubProducts(subs)
        setIsOk(true)
      })
    } else {
      setIsOk(true)
    }
  }, [currentYear, id, id_unit, year, id_row, type, id_supplie, view])

  const dataSumbit = useCallback(() => {
    let p_sub_products = []
    subProducts.forEach(s => {
      if (!isNaN(parseInt(s.newQty))) {
        p_sub_products.push({
          p_id_sub_products: s.id_sub_products,
          p_qty: s.newQty
        })
      }
    })

    return {
      p_id: row.id,
      p_id_unit: unitSelected,
      p_year: row.year,
      p_number: row.number,
      p_date: row.date,
      p_id_supplie: row.id_supplie,
      p_cod_internal: row.type === 'SI' ? '' : 'S/C',
      p_description_internal: row.type === 'SI' ? '' : row.description_internal,
      p_type: row.type,
      p_id_row: row.type === 'SI' ? '' : row.id_row,
      p_total_qty: totalQty(),
      p_justification: row.justification,
      p_sub_products,
      p_quarter_order: row.quarter_order,
      p_year_additional: row.year_additional
    }
  }, [row, subProducts, totalQty, unitSelected])

  const cboUnit = useMemo(() => {
    let unitFind = unit.find(u => parseInt(u.id) === parseInt(unitSelected))
    return <Select
      value={unitFind ? { value: unitFind.id, label: unitFind.id + ' - ' + unitFind.description } : ''}
      options={unit.map(s => {
        return {
          value: s.id,
          label: s.id + ' - ' + s.description + ' - '
        }
      })}
      onChange={e => {
        setSubProducts([])
        if (row.id_supplie !== '') {
          Controllers.unit.get_unit_budget({
            p_id_unit: e.value,
            p_year: row.year,
            p_id: row.id,
            p_id_supplie: row.id_supplie
          }).then(res => {
            setSubProducts(res.data)
          })
        }
        setUnitSelected(e.value )
      }}
      text="Centro de costo"
      icon="far fa-building"
      classNameParent="col-12 mb-2"
      disabled={view}
    />
  }, [unit, row, view, unitSelected])

  return <ReactPlaceholder showLoadingAnimation rows={7}  type='text' ready={isOk}>
  {justification_state ? <>
  <div>La orden fue rechazada por:</div>
  <strong>{justification_state}</strong>
  </> : ''}

  <FormCustom dataSubmit={dataSumbit()} onSubmit={onSubmit} viewSubmit={!view}>
    <SuppliesPublic dataGet={{
      p_id_unit: unitSelected ? unitSelected : 0, p_year: row.year
    }} showModal={modalSupplie} hideModal={() => setModalSupplie(false)} added={[row.id_supplie]} onAdd={e => {
      const { id, description, codInternal, descriptionPpr, hasCatalog, price } = e.currentTarget.dataset;

      if (row.type === 'SI') {
        if (parseInt(hasCatalog) === 1) {
          setRow({ ...row, id_supplie: id, description_internal: description, cod_internal: codInternal, price })
        } else {
          setRow({ ...row, id_supplie: id, description_internal: descriptionPpr, cod_internal: 'S/C', price })
        }
      } else {
        setRow({ ...row, id_supplie: id, description_internal: descriptionPpr, cod_internal: 'S/C', price })
      }

      if (unitSelected) {
        setSubProducts([])
        Controllers.unit.get_unit_budget({
          p_id_unit: unitSelected,
          p_year: row.year,
          p_id: row.id,
          p_id_supplie: id
        }).then(res => {
          setSubProducts(res.data)
          setModalSupplie(false)
        })
      }
    }} />
    {cboUnit}
    {/* <Input
      type="number"
      text="Nro"
      placeholder="Nro"
      classNameParent="col-6 mb-2"
      icon="fas fa-code"
      required
      defaultValue={number}
      onChange={e => setRow({ ...row, number: e.currentTarget.value })}
      maxLength={50}
      append={[<span className="input-group-text">/{row.year}</span>]}
      autoFocus
      disabled={view}
    /> */}
    <Input
      type="number"
      text="Año"
      placeholder="Año"
      classNameParent="col-6 mb-2"
      icon="fas fa-code"
      defaultValue={row.year}
      disabled
    />
    <Select
      value={row.quarter_order ? {
        label: row.quarter_order,
        value: row.quarter_order
      } : ''}
      options={[
        { label: 'PRIMERO', value: 'PRIMERO' },
        { label: 'SEGUNDO', value: 'SEGUNDO' },
        { label: 'TERCERO', value: 'TERCERO' },
      ]}
      onChange={e => {
        setRow({ ...row, quarter_order: e.value })
      }}
      text="Cuatrimestre"
      icon="far fa-building"
      classNameParent="col-6 mb-2"
      disabled={view}
    />
    <Input
      type="date"
      text="Fecha"
      placeholder="Fecha"
      classNameParent="col-6 mb-2"
      icon="far fa-calendar"
      required
      value={row.date}
      onChange={e => setRow({ ...row, date: e.currentTarget.value })}
      maxLength={50}
      disabled={view}
    />
    <Input
      type="text"
      text="Año Adicional"
      placeholder="Año Adicional"
      classNameParent="col-6 mb-2"
      icon="far fa-calendar"
      value={row.year_additional}
      pattern="[0-9]{4}"
      onChange={e => setRow({ ...row, year_additional: e.currentTarget.value })}
      maxLength={4}
      disabled={view}
    />
    <TextArea
      defaultValue={justification}
      onChange={e => setRow({ ...row, justification: e.currentTarget.value })}
      icon="fa fa-comment-alt"
      text="Justificación"
      placeholder="Ingresar justificación"
      required
      classNameParent="col-12 mb-2"
      disabled={view}
    />
    <div className="col-12 d-flex mb-2">
      <div className="mr-3">
        <Form.Check
          checked={row.type === 'SI'}
          custom
          type="radio"
          label="Con Catálogo"
          id="has-catalog"
          onChange={() => {
            setRow({ ...row, type: 'SI', id_supplie: '', cod_internal: '', description_internal: '' })
          }}
          name="p_catalog"
          required
          disabled={view}
        />
      </div>
      <Form.Check
        checked={row.type === 'NO'}
        custom
        type="radio"
        label="Sin Catálogo"
        id="no-has-catalog"
        onChange={() => {
          setRow({ ...row, type: 'NO', cod_internal: 'S/C' })
        }}
        name="p_catalog"
        required
        disabled={view}
      />
    </div>
    <div className="col-12 mb-2">
      {view ? '' : <Button size="sm" onClick={() => setModalSupplie(true)}><i className="fa fa-plus mr-2"></i>Seleccionar Insumo</Button>}
    </div>
    <table className="table table-sm table-hover table-striped table-bordered mb-2">
      <thead>
        <tr>
          <th>Código</th>
          <th>Descripción</th>
          <th>Cantidad</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">{row.cod_internal}</td>
          <td>{row.type === 'SI' ? row.description_internal : <TextArea rows="4"
            value={row.description_internal}
            onChange={e => setRow({ ...row, description_internal: e.currentTarget.value })}
            required
          />}</td>
          <td align="center">{totalQty()}</td>
        </tr>
      </tbody>
    </table>
    <div className="col-12 mb-1 mt-4">
      Ingresar Cantidades De SubProductos
    </div>
    <table className="table table-sm table-hover table-striped table-bordered">
      <thead>
        {view ? <tr>
          <th width="50%">Subproducto</th>
          <th width="30%">Cantidad Solicitada</th>
        </tr> : <tr>
          <th width="50%">Subproducto</th>
          <th width="10%">Cantidad Ejec.</th>
          <th width="10%">Cantidad Reservada</th>
          <th width="10%">Cantidad Disponible</th>
          <th width="30%">Cantidad Solicitada</th>
        </tr>}
      </thead>
      <tbody>
        {React.Children.toArray(subProducts.map((s, i) => {
          let qtyBalance = parseFloat(s.qty) - parseFloat(s.qty_order_reservated)
          return view ? <tr>
          <td align="center">{s.id_sub_products} - {s.description_short}</td>
          <td align="center">
          <Input
            type="number"
            min="0.01"
            step="0.01"
            placeholder=""
            defaultValue={s.newQty}
            onChange={e => setSubProducts(subProducts.map((s2, i2) => {
              if (i2 === i) {
                s2.newQty = e.currentTarget.value
              }
              return s2;
            }))}
            max={qtyBalance}
            disabled
            className="text-center"
          />
          </td>
        </tr> : <tr>
            <td align="center">{s.id_sub_products} - {s.description_short}</td>
            <td align="center">{s.qty_order}</td>
            <td align="center">{s.qty_order_reservated}</td>
            <td align="center">{qtyBalance}</td>
            <td align="center">
            <Input
              type="number"
              min="0.01"
              step="0.01"
              placeholder=""
              defaultValue={s.newQty}
              onChange={e => setSubProducts(subProducts.map((s2, i2) => {
                if (i2 === i) {
                  s2.newQty = e.currentTarget.value
                }
                return s2;
              }))}
              max={qtyBalance}
              disabled={parseInt(qtyBalance) <= 0}
              className="text-center"
            />
            </td>
          </tr>
        }))}
        <tr>
          <td align="right" colSpan={view ? 1 : 4}>Total</td>
          <td align="center">{!isNaN(parseFloat(row.price)) ? parseFloat(row.price) * totalQty() : 0}</td>
        </tr>
      </tbody>
    </table>
  </FormCustom>
  {user_created ? <>
    <hr />
    <div className="d-flex flex-column">
      <span>Usuario creación: <strong>{user_created}</strong></span>
      <span>Fecha creación: <strong>{date_created}</strong></span>
    </div>
  </> : ''}
  </ReactPlaceholder>
}

OrdersForm.defaultProps = {
  view: false
}
export default OrdersForm;