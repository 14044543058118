import React, { useState, useEffect, useCallback } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Controllers from '../Api/Controllers';
import { FormCustom, Input, TextArea } from '../Components/Form/Form';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import {useSelector} from 'react-redux'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const RowForm = ({ data, onSubmit, idGroup, viewDisabled }) => {
  const {
    id = '',
    description = '',
    justification = ''
  } = data

  const [row, setRow] = useState({
    id,
    description,
    justification
  })

  const dataSumbit = useCallback(() => {
    return {
      p_id: row.id,
      p_description: row.description,
      p_id_group: idGroup,
      p_justification: row.justification
    }
  }, [row, idGroup])

  return <FormCustom dataSubmit={dataSumbit()} onSubmit={onSubmit} viewSubmit={!viewDisabled}>
    <Input
      text="ID"
      placeholder="ID"
      classNameParent="col-12 mb-2"
      icon="fa fa-code"
      required
      defaultValue={id}
      onChange={e => setRow({ ...row, id: e.currentTarget.value })}
      disabled={id !== ''}
      pattern="[0-9]{1,5}"
      invalid="Solo números. Ej. 0, 10, 100"
      maxLength={5}
    />
    <Input
      text="Descripción"
      placeholder="Descripción"
      classNameParent="col-12 mb-2"
      icon="far fa-comment-alt"
      required
      defaultValue={description}
      onChange={e => setRow({ ...row, description: e.currentTarget.value })}
      maxLength={255}
      disabled={viewDisabled}
    />
    <TextArea
      text="Justificación"
      placeholder="Justificación"
      classNameParent="col-12 mb-2"
      icon="far fa-comment-alt"
      defaultValue={justification}
      onChange={e => setRow({ ...row, justification: e.currentTarget.value })}
      maxLength={255}
      disabled={viewDisabled}
    />
  </FormCustom>
}

const Row = ({ idGroup }) => {
  const nameModulo = 'Renglones'
  const namePage = 'Renglón'
  const nameController = 'row'

  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = Helpers.getActions({ permissions, name: 'renglones' })

  const thead = [
    { name: 'ID', align: 'center' },
    { name: 'Descripción' },
    { name: 'Acc.', align: 'center' }
  ];

  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [modalSize, setModalSize] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [form, setForm] = useState('');
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      Controllers[nameController]['get_' + nameController]({
        p_id_group: idGroup
      }).then(res => {
        setRows(res.data)
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload, idGroup])

  const handleUpdate = e => {
    const { id = '', disabled = 0 } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()
    
    setModalTitle(parseInt(disabled) === 1 ? <><i className={'mr-2 ' + actionsModule.view.icon}></i>{actionsModule.view.description} {namePage}</> : (id ? <><i className={'mr-2 ' + actionsModule.update.icon}></i>{actionsModule.update.description} {namePage}</> : <><i className={'mr-2 ' + actionsModule.create.icon}></i>{actionsModule.create.description} {namePage}</>))
    setModalSize('');
    setModalHeader('');

    Controllers[nameController]['get_' + nameController + '_id']({ p_id: id }).then(res => {
      setForm(<RowForm data={res.data} onSubmit={onSubmit} idGroup={idGroup} viewDisabled={parseInt(disabled) === 1} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }

  const onSubmit = ({ e, dataSubmit }) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers[nameController][nameController + '_insert_update'](dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        setModalShow(false);
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
      setReload(true)
    });
  }

  const onDelete = e => {
    const { id = '' } = e.currentTarget.dataset;
    setModalTitle(<><i className="fa fa-trash mr-2"></i> Eliminar {namePage}</>);
    setModalHeader('bg-danger');
    setModalSize('sm');
    setForm(<div className="d-flex flex-column align-items-center">
      <p><strong>¿Eliminar {namePage} #{id}?</strong></p>

      <div>
        <Button variant="danger"  size="sm" onClick={e => {
          let button = e.currentTarget
          let buttonHTML = button.innerHTML;
          button.innerHTML = Helpers.icon.spin()

          Controllers[nameController][nameController + '_delete']({
            p_id: id
          }).then(res => {
            Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
          }).catch(req => {
            Helpers.promise.catch({ req, toastr });
          }).finally(() => {
            button.innerHTML = buttonHTML;
            setReload(true)
            setModalShow(false);
          });
        }}>
          <i className="fa fa-check mr-2"></i>Si
        </Button>
        <Button variant="dark" className="ml-2" size="sm" onClick={() => setModalShow(false)}>
          <i className="fa fa-times mr-2"></i>No
        </Button>
      </div>
    </div>);
    setModalShow(true)
  }

  return <> 
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De {nameModulo}</span>
        {actionsModule.create ? <Button onClick={handleUpdate} variant={actionsModule.create.color} size="sm"><i className={ actionsModule.create.icon}></i><span className="ml-2 d-none d-md-inline-block">{actionsModule.create.description} {namePage}</span></Button> : ''}
      </Card.Header>
      <Card.Body>
        <DataTable
          isProcessing={isProcessing}
          tbody={rows}
          thead={thead}
          render={tbody => tbody.map(r => {
            let rowRender = [];
            let i = 0;
            for (let key in r) {
              rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
              i++;
            }

            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
              {actionsModule.view ?  <OverlayTrigger
                placement="top"
                overlay={props => <Tooltip {...props}>
                  {actionsModule.view.description}
                </Tooltip>}
              >
                <Button variant={actionsModule.view.color} data-disabled={1} size="xs" className="mr-1" data-id={r.id} onClick={handleUpdate}>
                  <i className={actionsModule.view.icon}></i>
                </Button>
              </OverlayTrigger> : ''}
              {actionsModule.update ?  <OverlayTrigger
                placement="top"
                overlay={props => <Tooltip {...props}>
                  {actionsModule.update.description}
                </Tooltip>}
              >
                <Button variant={actionsModule.update.color} size="xs" className="mr-1" data-id={r.id} onClick={handleUpdate}>
                  <i className={actionsModule.update.icon}></i>
                </Button>
              </OverlayTrigger> : ''}
              {actionsModule.delete ? <OverlayTrigger
                placement="top"
                overlay={props => <Tooltip {...props}>
                  {actionsModule.delete.description}
                </Tooltip>}
              >
                <Button variant={actionsModule.delete.color} size="xs" data-id={r.id} onClick={onDelete}>
                  <i className={actionsModule.delete.icon}></i>
                </Button>
              </OverlayTrigger> : ''}
            </td>);
            return (<tr>
              {React.Children.toArray(rowRender)}
            </tr>);
          })}
        />
      </Card.Body>
    </Card>
    <Modal show={modalShow} onHide={() => setModalShow(false)} size={modalSize} backdrop="static">
      <Modal.Header closeButton className={modalHeader}>
        <Modal.Title as="div">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
  </>
}

export default Row;