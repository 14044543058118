import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Layout from '../Components/Layout/Layout';
import Login from '../Views/Auth/Login';
import NotFound from '../Views/NotFound/NotFound';
import Helpers from '../Helpers/Helpers';
import Users from '../Views/Users';
import Unit from '../Views/Unit';
import Group from '../Views/Group';
import Presentation from '../Views/Presentation';
import TypePurchase from '../Views/TypePurchase';
import TypeSupplies from '../Views/TypeSupplies';
import Supplies from '../Views/Supplies';
import Provider from '../Views/Provider';
import AwardActs from '../Views/AwardActs';
import SubProductsMaster from '../Views/SubProductsMaster';
import PacksAnnual from '../Views/PacksAnnual';
import Nivel from '../Views/Nivel';
import ReProgrammatedSupplie from '../Views/ReProgrammatedSupplie';
import Orders from '../Views/Orders';
import PurchaseOrders from '../Views/PurchaseOrders';
import ReportUnitProvider from '../Views/ReportUnitProvider';
import ReportInvoicePending from '../Views/ReportInvoicePending';
import ReportDeudaFlotante from '../Views/ReportDeudaFlotante';
import SearchOC from '../Views/SearchOC';
import ReportGeneral from '../Views/ReportGeneral';
import ReportOrders from '../Views/ReportOrders';
import ReportPacGeneral from '../Views/ReportPacGeneral';
import MyPersonalInformation from '../Views/MyPersonalInformation';
import Actions from '../Views/Actions';
import TagsItems from '../Views/TagsItems';
import Profiles from '../Views/Profiles';
import { useSelector } from 'react-redux'
import QuarterAnnual from '../Views/QuarterAnnual';
import ReQuarter from '../Views/ReQuarter';
import ServiciosMedicos from '../Views/ServiciosMedicos';
import ReporteSupplies from '../Views/ReporteSupplies';
import Participant from '../Views/Participant';
import Book from '../Views/Book';
import ReportResolution from '../Views/ReportResolution';
import Unops from '../Views/Unops/Unops';
import UnopsReport from '../Views/Unops/UnopsReport';

const Routes = ({ type }) => {
  const permissions = useSelector(store => store.session.permissions)

  const hasPermissions = (name) => {
    let exists = false
    permissions.forEach(d => {
      d.childrens.forEach(p => {
        p.childrens.forEach(ch => {
          if (ch.a_href === name) {
            exists = true;
          }
        })
      })
    })

    return exists
  }
  
  const moduleMain = () => {
    let main = ''
    permissions.forEach(d => {
      d.childrens.forEach(p => {
        p.childrens.forEach(ch => {
          if (parseInt(ch.main) === 1) {
            main = ch.a_href
          }
        })
      })
    })

    return main
  }

  if (type === 'auth') {
    return (<BrowserRouter>
      <Switch>
        <Route exact path={Helpers.config.folder_path + '/login'}>
          <Login />
        </Route>
        <Route exact path={Helpers.config.folder_path + '/'}>
          <Login />
        </Route>
        <Route>
          <Redirect to={Helpers.config.folder_path + '/login'} />
        </Route>
      </Switch>
    </BrowserRouter>);
  } else {
    return (<BrowserRouter>
      <Switch>
        <Route path={Helpers.config.folder_path + '/login'} exact>
          <Redirect to={Helpers.config.folder_path + '/' + (moduleMain() ? moduleMain() : 'notfound')} />
        </Route>
        <Route path={Helpers.config.folder_path + '/'} exact>
          <Redirect to={Helpers.config.folder_path + '/' +  (moduleMain() ? moduleMain() : 'notfound') } />
        </Route>
        {hasPermissions('usuarios') ? <Route path={Helpers.config.folder_path + '/usuarios'} exact>
          <Layout title="Usuarios" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="usuarios">
            <Users />
          </Layout>
        </Route> : ''}
        {hasPermissions('centroscosto') ? <Route path={Helpers.config.folder_path + '/centroscosto'} exact>
        <Layout title="Centros De Costo" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="centroscosto">
            <Unit />
          </Layout>
        </Route> : ''}
        {hasPermissions('productos') ? <Route path={Helpers.config.folder_path + '/productos'} exact>
          <Layout title="Subproductos" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="productos">
            <SubProductsMaster />
          </Layout>
        </Route> : ''}
        {hasPermissions('grupos') ? <Route path={Helpers.config.folder_path + '/grupos'} exact>
          <Layout title="Grupos" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="grupos">
            <Group />
          </Layout>
        </Route> : ''}
        {hasPermissions('presentaciones') ? <Route path={Helpers.config.folder_path + '/presentaciones'} exact>
          <Layout title="Presentaciones" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="presentaciones">
            <Presentation />
          </Layout>
        </Route> : ''}
        {hasPermissions('tiposcompra') ? <Route path={Helpers.config.folder_path + '/tiposcompra'} exact>
          <Layout title="Tipo De Compra" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="tiposcompra">
            <TypePurchase />
          </Layout>
        </Route> : ''}
        {hasPermissions('tiposinsumos') ? <Route path={Helpers.config.folder_path + '/tiposinsumos'} exact>
          <Layout title="Tipo De Insumos" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="tiposinsumos">
            <TypeSupplies />
          </Layout>
        </Route> : ''}
        {hasPermissions('insumos') ? <Route path={Helpers.config.folder_path + '/insumos'} exact>
          <Layout title="Insumos" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="insumos">
            <Supplies />
          </Layout>
        </Route> : ''}
        {hasPermissions('proveedores') ? <Route path={Helpers.config.folder_path + '/proveedores'} exact>
          <Layout title="Proveedores" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="proveedores">
            <Provider />
          </Layout>
        </Route> : ''}
        {hasPermissions('actas') ? <Route path={Helpers.config.folder_path + '/actas'} exact>
          <Layout title="Gestión De Actas" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="actas">
            <AwardActs />
          </Layout>
        </Route> : ''}
        {hasPermissions('packsanuales') ? <Route path={Helpers.config.folder_path + '/packsanuales'} exact>
          <Layout title="PAC Anuales" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="packsanuales">
            <PacksAnnual />
          </Layout>
        </Route> : ''}
        {hasPermissions('tipomodificacion') ? <Route path={Helpers.config.folder_path + '/tipomodificacion'} exact>
          <Layout title="Tipo Modificacion" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="tipomodificacion">
            <Nivel />
          </Layout>
        </Route> : ''}
        {hasPermissions('reprogramacion') ? <Route path={Helpers.config.folder_path + '/reprogramacion'} exact>
          <Layout title="Reprogramación De Insumos" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="reprogramacion">
            <ReProgrammatedSupplie />
          </Layout>
        </Route> : ''}
        {hasPermissions('pedidos') ? <Route path={Helpers.config.folder_path + '/pedidos'} exact>
          <Layout title="Pedidos" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="pedidos">
            <Orders />
          </Layout>
        </Route> : ''}
        {hasPermissions('ordenescompra') ? <Route path={Helpers.config.folder_path + '/ordenescompra'} exact>
          <Layout title="Reporte General De Ordenes De Compra" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="ordenescompra">
            <PurchaseOrders />
          </Layout>
        </Route> : ''}
        {hasPermissions('unidadproveedor') ? <Route path={Helpers.config.folder_path + '/unidadproveedor'} exact>
          <Layout title="Ordenes De Compra" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="unidadproveedor">
            <ReportUnitProvider />
          </Layout>
        </Route> : ''}
        {hasPermissions('recibospendientes') ? <Route path={Helpers.config.folder_path + '/recibospendientes'} exact>
          <Layout title="Reporte Recibos Pendienes" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="recibospendientes">
            <ReportInvoicePending />
          </Layout>
        </Route> : ''}
        {hasPermissions('deudaflotante') ? <Route path={Helpers.config.folder_path + '/deudaflotante'} exact>
          <Layout title="Reporte Deuda Flotante" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="deudaflotante">
            <ReportDeudaFlotante />
          </Layout>
        </Route> : ''}
        {hasPermissions('buscaroc') ? <Route path={Helpers.config.folder_path + '/buscaroc'} exact>
          <Layout title="Reporte Deuda Flotante" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="buscaroc">
            <SearchOC />
          </Layout>
        </Route> : ''}
        {hasPermissions('general') ? <Route path={Helpers.config.folder_path + '/general'} exact>
          <Layout title="Reporte General" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="general">
            <ReportGeneral />
          </Layout>
        </Route> : ''}
        {hasPermissions('pedidosgeneral') ? <Route path={Helpers.config.folder_path + '/pedidosgeneral'} exact>
          <Layout title="Reporte General De Pedidos" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="pedidosgeneral">
            <ReportOrders />
          </Layout>
        </Route> : ''}
        {hasPermissions('pacgeneral') ? <Route path={Helpers.config.folder_path + '/pacgeneral'} exact>
          <Layout title="Reporte Saldo PAC" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="pacgeneral">
            <ReportPacGeneral />
          </Layout>
        </Route> : ''}
        {hasPermissions('acciones') ? <Route path={Helpers.config.folder_path + '/acciones'} exact>
          <Layout title="Acciones" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="acciones">
            <Actions />
          </Layout>
        </Route> : ''}
        {hasPermissions('opcionesmenu') ? <Route path={Helpers.config.folder_path + '/opcionesmenu'} exact>
          <Layout title="Opciones De Menú" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="opcionesmenu">
            <TagsItems />
          </Layout>
        </Route> : ''}
        {hasPermissions('perfiles') ? <Route path={Helpers.config.folder_path + '/perfiles'} exact>
          <Layout title="Perfiles" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="perfiles">
            <Profiles />
          </Layout>
        </Route> : ''}
        {hasPermissions('programacioncuatrimestral') ? <Route path={Helpers.config.folder_path + '/programacioncuatrimestral'} exact>
          <Layout title="Programación Cuatrimestral" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="programacioncuatrimestral">
            <QuarterAnnual />
          </Layout>
        </Route> : ''}
        {hasPermissions('reprogcuatrimestral') ? <Route path={Helpers.config.folder_path + '/reprogcuatrimestral'} exact>
          <Layout title="Reprogramación Cuatrimestral" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="reprogcuatrimestral">
            <ReQuarter />
          </Layout>
        </Route> : ''}
        {hasPermissions('serviciosmedicos') ? <Route path={Helpers.config.folder_path + '/serviciosmedicos'} exact>
          <Layout title="Servicios Médicos" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="serviciosmedicos">
            <ServiciosMedicos />
          </Layout>
        </Route> : ''}
        {hasPermissions('reporteinsumos') ? <Route path={Helpers.config.folder_path + '/reporteinsumos'} exact>
          <Layout title="Reporte De Insumos" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="reporteinsumos">
            <ReporteSupplies />
          </Layout>
        </Route> : ''}
        {hasPermissions('participantes') ? <Route path={Helpers.config.folder_path + '/participantes'} exact>
          <Layout title="Listado de Participantes" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="participantes">
            <Participant />
          </Layout>
        </Route> : ''}
        {hasPermissions('libros') ? <Route path={Helpers.config.folder_path + '/libros'} exact>
          <Layout title="Listado de Libros" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="libros">
            <Book />
          </Layout>
        </Route> : ''}
        {hasPermissions('unops') ? <Route path={Helpers.config.folder_path + '/unops'} exact>
          <Layout title="Listado de Unops" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="unops">
            <Unops />
          </Layout>
        </Route> : ''}
        {hasPermissions('reporte-resoluciones') ? <Route path={Helpers.config.folder_path + '/reporte-resoluciones'} exact>
          <Layout title="Listado de Resoluciones" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="reporte-resoluciones">
            <ReportResolution />
          </Layout>
        </Route> : ''}
        {hasPermissions('reporteunops') ? <Route path={Helpers.config.folder_path + '/reporteunops'} exact>
          <Layout title="Reporte Unops" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="reporteunops">
            <UnopsReport />
          </Layout>
        </Route> : ''}
        <Route path={Helpers.config.folder_path + '/miinformacion'} exact>
          <Layout showBread={false} title="Mi Información Personal" pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }} page="miinformacion">
            <MyPersonalInformation />
          </Layout>
        </Route>
        <Route>
          <Layout title="Página No Existe" showBread={false} pageWrapper={{ classNameContent: 'd-flex align-items-center justify-content-center' }}>
            <NotFound />
          </Layout>
        </Route>
      </Switch>
    </BrowserRouter>);
  }
}
export default Routes;