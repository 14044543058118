import React, { useCallback } from 'react'

const UnopsFormConsolidate = ({ data, details }) => {
    const states = [
        { state: 0, color: 'primary', text: 'SIN CONSOLIDACIÓN' },
        { state: 1, color: 'success', text: 'CONSOLIDADO' },
        { state: 2, color: 'dark', text: 'REGULARIZADO' },
    ]

    const getState = useCallback(() => {
        return states.find(s => s.state === parseInt(data.state))
    }, [states, data])

    const calculateTotal = useCallback(() => {
        let total = 0

        details.forEach(d => total += (parseFloat(d.price) * parseFloat(d.qty)))

        return parseFloat(total).toFixed(2)
    }, [details])

    return <div className="form-row">
        <div className="col-12">Estado: <strong className={'text-' + getState().color}>{getState().text}</strong></div>
        <div className="col-12">Número: <strong>{data.id_unops_consolidate}</strong></div>
        <div className="col-12">Fecha Consolidación: <strong>{data.date_consolidate}</strong></div>
        
        <div className="col-12 mt-2">
            <div className="table-responsive">
                <table className="table table-sm table-hover table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Orden de Compra</th>
                            <th>Unidad</th>
                            <th>Insumo</th>
                            <th>Precio</th>
                            <th>Cantidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        {React.Children.toArray(details.map(u => {
                            return <tr>
                                <td>{u.number_order}</td>
                                <td>{u.description_unit}</td>
                                <td>{u.cod_ppr} - {u.description_ppr}</td>
                                <td>{u.price}</td>
                                <td>{u.qty}</td>
                            </tr>
                        }))}
                    </tbody>
                </table>
            </div>
        </div>

        <div className="col-12 mt-2">Total: <strong>{calculateTotal()}</strong></div>
    </div>
}

export default UnopsFormConsolidate