import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Controllers from '../Api/Controllers';
import { FormCustom, Input, Select, TextArea } from '../Components/Form/Form';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import ReactPlaceholder from 'react-placeholder';
import Form from 'react-bootstrap/Form';
import Supplies from './Supplies';
import Provider from './Provider';
import { useSelector } from 'react-redux';

const GeneratePDF = ({ closeModal, id, requests, autos, closeM, jwt, type }) => {

  const reqSel = requests.find(r => parseInt(r.main) === 1)
  const autoSel = autos.find(r => parseInt(r.main) === 1)

  const [data, setData] = useState({
    req: reqSel ? { value: reqSel.id, label: reqSel.name + ' - (' + reqSel.profession + ')' } : {},
    auto: autoSel ? { value: autoSel.id, label: autoSel.name + ' - (' + autoSel.profession + ')' } : {},
    date: Helpers.date.get()
  })

  return <form className="needs-validation form-row col-12" noValidate onSubmit={e => {
      e.preventDefault();
      let $this = e.currentTarget

      if (!$this.checkValidity()) {
        $this.classList.add('was-validated');
      } else {
        $this.classList.remove('was-validated');
        if (!data.auto.value || !data.req.value) {
          Helpers.toastr.construct({
            response: 'warning',
            message: 'Por favor, ingresar campos completos',
            toastr
          })
          return
        }
        closeModal()
        window.open(Helpers.config.api.url + '/awardacts/get/pdf/'+type+'/' + id + '/' + data.date + '/' + data.req.value + '/' + data.auto.value + '?jwt=' + jwt, '_blank')
      }
    }} autoComplete="off">
      
      <Input
        type="date"
        text="Fecha"
        placeholder="190,191,192"
        classNameParent="col-12 mb-2"
        icon="fas fa-calendar"
        required
        autoFocus
        defaultValue={data.date}
        onChange={e => setData({ ...data, date: e.target.value })}
      />

      <Select
        options={requests.map(r => {
          return {
            value: r.id,
            label: r.name + ' - (' + r.profession + ')'
          }
        })}
        text="Solicitante"
        icon="fa fa-user-tie"
        classNameParent="col-12 mb-2"
        id="txt_id_solicitante"
        defaultValue={data.req}
        onChange={e => setData({ ...data, req: e })}
      />

      <Select
        options={autos.map(r => {
          return {
            value: r.id,
            label: r.name + ' - (' + r.profession + ')'
          }
        })}
        text="Autorizador"
        icon="fa fa-user-tie"
        classNameParent="col-12 mb-2"
        defaultValue={data.auto}
        onChange={e => setData({ ...data, auto: e })}
      />

    <div className="col-12 d-flex justify-content-center">        
      <Button type="submit" variant="danger" className="mr-2" size="sm">
        <i className="fa fa-check mr-2"></i>Generar
      </Button>
      <Button variant="dark" className="mr-2" size="sm" onClick={closeM}>
        <i className="fa fa-times mr-2"></i>Cancelar
      </Button>
    </div>
  </form>
}

GeneratePDF.defaultProps = {
  requests: [],
  autos: []
}

const SuppliesPublic = ({showModal, hideModal, added, onAdd }) => {
  return <>
    <Modal show={showModal} onHide={hideModal} size={'lg'} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title as="div"><i className="fa fa-list mr-2"></i> Listado De Insumos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Supplies isPublic={true} added={added} onAdd={onAdd} />
      </Modal.Body>
    </Modal>
  </>
}

const ProviderPublic = ({showModal, hideModal, added, onAdd }) => {
  return <>
    <Modal show={showModal} onHide={hideModal} size={'lg'} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title as="div"><i className="fa fa-list mr-2"></i> Listado De Proveedores</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Provider isPublic added={added} onAdd={onAdd} />
      </Modal.Body>
    </Modal>
  </>
}

const AwardActsForm = ({ data, closeModal, reload, form, disabledView }) => {
  const year = useSelector(store => store.session.year)
  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = useRef(Helpers.getActions({ permissions, name: 'actas' }))

  const {
    id = '',
    number = '',
    date = '',
    hour = '',
    nog = '',
    id_type_purchase = '',
    qty = '',
    amount = '',
    iva = '',
    id_supplie = '',
    description = '',
    type_supplies = '',
    cod_internal = '',
    justification = '',
    user_created = '',
    date_created = '',
    user_updated = '',
    date_updated = '',
    number_resolution = '',
    id_consolidate = '',
    id_participant = [],
    id_book,
  } = data

  const [participantSelected, setParticipantSelected] = useState('')
  const jwt = useSelector(store => store.session.jwt)

  const [row, setRow] = useState({
    id,
    number,
    date: id ? date : Helpers.date.get(),
    hour: id ? hour.substring(0, 5) : Helpers.date.get({ format: '%H:%i' }),
    nog,
    id_type_purchase,
    qty,
    amount,
    iva: iva === '' ? 1 : parseInt(iva),
    justification,
    id_participant,
    id_book
  })

  const [oc, setOc] = useState({
    number_resolution, id_consolidate
  })

  const [unit, setUnit] = useState([])
  const [typePurchase, setTypePurchase] = useState([])
  const [isOk, setIsOk] = useState(false)
  const [unitSelected, setUnitSelected] = useState([])
  const [modalShowSupplies, setModalShowSupplies] = useState(false);
  const [supplie, setSupplie] = useState(id === '' ? null : {
    id: id_supplie, description, type_supplies, cod_internal
  });
  const [providers, setProviders] = useState([]);
  const [modalShowProvider, setModalShowProvider] = useState(false);
  const [showJustification, setShowJustification] = useState(false);
  const [modalShowJustif, setModalShowJustif] = useState(false)
  const [listBooks, setListBooks] = useState([])
  const [listParticipants, setListParticipants] = useState([])

  const dataSumbit = useCallback(() => {
    let p_unit = []
    unitSelected.forEach(u => {
      p_unit.push({
        id: u.id,
        number_order: u.number_order,
        qty: u.qty
      })
    })
    
    let p_providers = []
    providers.forEach(p => {
      p_providers.push({
        id: p.id,
        amount: p.amount,
        state: parseInt(p.state),
        products_oferts: p.products_oferts
      })
    })


    return {
      p_id: row.id,
      p_number: row.id === '' ? row.number + '/' + year : row.number,
      p_date: row.date,
      p_hour: row.hour,
      p_nog: parseInt(row.id_type_purchase === 2) ? '' : row.nog,
      p_id_type_purchase: row.id_type_purchase,
      p_id_supplie: supplie !== null ? supplie.id : '',
      p_description_supplie: supplie !== null ? supplie.description : '',
      p_amount: row.amount,
      p_qty: row.qty,
      p_iva: row.iva,
      p_unit,
      p_providers,
      p_justification: showJustification || modalShowJustif ? row.justification : '',
      p_id_consolidate: oc.id_consolidate,
      p_id_participant: row.id_participant,
      p_id_book: row.id_book,
    }
  }, [row, supplie, providers, unitSelected, showJustification, modalShowJustif, year, oc.id_consolidate])

  useEffect(() => {
    if (!isOk) {
      let promises = [
        Controllers.unit.get_unit_public_award(),
        Controllers.type_purchase.get_type_purchase(),
        Controllers.award_acts.get_award_acts_provider({
          p_id_award_acts: id
        }),
        Controllers.award_acts.get_award_acts_unit({
          p_id_award_acts: id
        }),
        Controllers.participant.get_participant_public(),
        Controllers.book.get_book_public(),
      ]
      Promise.all(promises).then(res => {
        setUnit(res[0].data)
        setTypePurchase(res[1].data)
        setShowJustification(res[1].data.find(r => parseInt(r.id) === parseInt(id_type_purchase) && parseInt(r.justification) === 1) ? true : false)
  
        let provSelect = []
        res[2].data.forEach(r => {
          provSelect.push({ id: r.id_provider, name: r.name, nit: r.nit, amount: r.amount, state: parseInt(r.state), products_oferts: r.products_oferts })
        })
        setProviders(provSelect)
  
        let unitSelect = []
        res[3].data.forEach(r => {
          unitSelect.push({ id: r.id_unit, description: r.description, number_order: r.number_order, qty: r.qty })
        })
        setUnitSelected(unitSelect)
  
        setListParticipants(res[4].data)
        setListBooks(res[5].data)
        setIsOk(true)
  
        if (!data.id) {
          let findBookMain = false
          let findParticipantMain = []
          let findParticipantNotMain = false
          if (res[5].data?.length > 0) {
            findBookMain = res[5].data.find(b => parseInt(b.main) === 1)
          }

          if (res[4].data?.length > 0) {
            findParticipantMain = res[4].data.filter(b => parseInt(b.main) === 1)
            findParticipantNotMain = res[4].data.find(b => parseInt(b.main) !== 1)
          }
          setRow({ 
            ...row,
            id_book: findBookMain ? findBookMain.id : '',
            id_participant: findParticipantMain.map(f => f.id)
          })
          setParticipantSelected(findParticipantNotMain ? {
            label: findParticipantNotMain.name,
            value: findParticipantNotMain.id
          }: '')
        }
  
      }).catch(req => {
        closeModal()
        Helpers.promise.catch({ req, toastr });
      })
    }
  }, [closeModal, id, id_type_purchase, data.id, isOk, row])
  
  const totalQty = useCallback(() => {
    let qty = 0
    unitSelected.forEach(us2 => {
      if (!isNaN(parseFloat(us2.qty))) {
        qty += parseFloat(us2.qty)
      }
    })
    return qty;
  }, [unitSelected])

  const cboTypePurchase = useMemo(() => {
    let findTp = typePurchase.find(t => parseInt(t.id) === parseInt(row.id_type_purchase));

    let fTp = {}
    if (id === '' && row.id_type_purchase === '') {
      fTp = typePurchase.find(t => {
        return parseInt(t.id) === 1
      })
      if (!fTp) {
        fTp = {}
      } else {
        setRow({ ...row, id_type_purchase: fTp.id })
      }
    }
    return <Select
      value={id === '' && row.id_type_purchase === '' ? { value: fTp.id, label: fTp.id + ' - ' + fTp.description } : (findTp ? { value: findTp.id, label: findTp.id + ' - ' + findTp.description } : '')}
      text="Tipo Compra *"
      options={typePurchase.map(p => { return { value: p.id, label: p.id + ' - ' + p.description, justification: p.justification } })}
      classNameParent="col-12 col-md-6 mb-2"
      icon="fab fa-codepen"
      required
      onChange={e => {
        setRow({ ...row, id_type_purchase: e.value })
        setShowJustification(parseInt(e.justification) === 1)
      }}
      disabled={disabledView}
    />
  }, [typePurchase, row, id, disabledView])

  /* const cboParticipant = useMemo(() => {
    let findTp = listParticipants.find(t => parseInt(t.id) === parseInt(row.id_participant));

    if (!findTp) {
      return ''
    }

    return {
      value: findTp.id,
      label: findTp.name
    }
  }, [listParticipants, row.id_participant]) */

  const cboBook = useMemo(() => {
    let findTp = listBooks.find(t => parseInt(t.id) === parseInt(row.id_book));

    if (!findTp) {
      return ''
    }

    return {
      value: findTp.id,
      label: findTp.name
    }
  }, [listBooks, row.id_book])

  const unitCost = useMemo(() => {
    return <>
      <span className="col-12 mt-2 mb-1">Seleccionar Centros De Costo</span>
      <ul className="col-12 col-md-4 list-group">
        {React.Children.toArray(unit.map(u => {
          let active = unitSelected.some(us => parseInt(us.id) === parseInt(u.id))

          return <div className={'list-group-item d-flex justify-content-between align-items-center'}>
            <span>{u.id} - {u.description}</span>
            <Button onClick={() => {
            if (!disabledView) {
              if (!active) {
                setUnitSelected([...unitSelected, {
                  ...u, number_order: '', qty: ''
                }])
              }
            }
          }} className="align-self-center" variant={(active ? 'success' : '')} size="xs"><i className="far fa-check-circle"></i></Button>
          </div>
        }))}
      </ul>
      <div className="col-12 col-md-8">
        <div className="table-responsive">
        <table className="table table-sm table-hover table-striped table-bordered">
          <thead>
            <tr>
              <th width="50%">Centro Costo</th>
              <th width="30%">Nro. Pedido</th>
              <th width="20%">Cantidad</th>
            </tr>
          </thead>
          <tbody>
            {React.Children.toArray(unitSelected.map((us, i) => {
              return <tr>
                <td>
                  <Button variant="danger" size="xs" className="mr-2" onClick={e => {
                    let usNew = []
                    unitSelected.forEach((us2, j) => {
                      if (j !== i) {
                        usNew.push(us2);
                      }
                    })
                    setUnitSelected(usNew)
                  }}><i className="fa fa-trash"></i></Button>
                  {us.id} - {us.description}
                </td>
                <td>
                  <Input
                    value={us.number_order}
                    type="text"
                    maxLength={50}
                    placeholder="Nro Pedido"
                    className="text-center"
                    required
                    onChange={e => setUnitSelected(unitSelected.map((us2, j) => {
                      if (j === i) {
                        us2.number_order = e.currentTarget.value;
                      }
                      return us2;
                    }))}
                    disabled={disabledView}
                  />
                </td>
                <td>
                  <Input
                    value={us.qty}
                    type="number"
                    step="0.01"
                    min="0.01"
                    maxLength={50}
                    placeholder="0"
                    className="text-center"
                    required
                    onChange={e => setUnitSelected(unitSelected.map((us2, j) => {
                      if (j === i) {
                        us2.qty = e.currentTarget.value;
                      }
                      return us2;
                    }))}
                    disabled={disabledView}
                  />  
                </td>
              </tr>
            }))}
            <tr>
              <td></td>
              <td align="right">Total: </td>
              <td align="center">{totalQty()}</td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </>
  }, [unit, unitSelected, totalQty, disabledView])

  const providersRender = useMemo(() => {
    return <>
      <div className="col-12 d-flex justify-content-between mt-1 mb-1">
        <span className="align-self-center">Seleccionar Proveedor(es)</span>
        {disabledView ? '' : <Button onClick={() => setModalShowProvider(true)} size="sm"><i className="fa fa-search"></i> <span className="ml-2 d-none d-md-inline-block">Buscar Proveedor</span></Button>}
      </div>
      <div className="col-12">
        <div className="table-responsive">
        <table className="table table-sm table-hover table-striped table-bordered">
          <thead>
            <tr>
              <th width="70%">NIT/Razón Social</th>
              <th width="10%">Tipos P.O</th>
              <th width="20%">Total Ofertado</th>
              <th width="1%"></th>
            </tr>
          </thead>
          <tbody>
            {React.Children.toArray(providers.map((p, i) => {
              return <tr>
                <td>
                  {disabledView ? '' : <Button variant="danger" size="xs" className="mr-2" onClick={() => {
                    let newProv = []
                    providers.forEach((p2, j) => {
                      if (j !== i) {
                        newProv.push(p2)
                      }
                    })
                    setProviders(newProv)
                  }}>
                    <i className="fa fa-trash"></i>
                  </Button>}
                  <span>{p.nit} - {p.name}</span>
                </td>
                <td align="center">
                  <Input
                    type="number"
                    className="text-center"
                    value={p.products_oferts}
                    min={1}
                    step="1"
                    required
                    placeholder="0.00"
                    onChange={e => setProviders(providers.map((p2, j) => {
                      if (j === i) {
                        p2.products_oferts = e.currentTarget.value
                      }
                      return p2;
                    }))}
                    disabled={disabledView}
                  />
                </td>
                <td align="center">
                  <Input
                    value={p.amount}
                    type="number"
                    className="text-center"
                    min={1}
                    step="0.01"
                    required
                    placeholder="0.00"
                    onChange={e => setProviders(providers.map((p2, j) => {
                      if (j === i) {
                        p2.amount = e.currentTarget.value
                      }
                      return p2;
                    }))}
                    disabled={disabledView}
                  />
                </td>
                <td>
                  {disabledView ? '' : <Button variant={parseInt(p.state) === 1 ? 'success' : ''} size="xs" onClick={() => {
                    let qtyUnit = 0
                    unitSelected.forEach(us2 => {
                      if (!isNaN(parseFloat(us2.qty))) {
                        qtyUnit += parseFloat(us2.qty)
                      }
                    })

                    setProviders(providers.map((p2, j) => {
                      if (j === i) {
                        p2.state = 1
                        setRow({ ...row, amount: p2.amount, qty: qtyUnit, products_oferts: 1 })
                      } else {
                        p2.state = 0
                      }
                      return p2;
                    }))
                  }}>
                    <i className="far fa-check-circle"></i>
                  </Button>}
                </td>
              </tr>
            }))}
          </tbody>
        </table>
        </div>
      </div>
    </>
  }, [providers, unitSelected, row, disabledView])

  const onSubmit = ({ e, dataSubmit }) => {
    let $this = e.currentTarget

    if (!modalShowJustif) {
      let propsValidateForm = { form: $this, toastr };
      if (!$this.checkValidity()) {
        $this.classList.add('was-validated');
        Helpers.form.isFormCorrect(propsValidateForm);
        return;
      } else {
        if (!Helpers.form.isFormCorrect(propsValidateForm)) {
          return;
        }
        if (document.getElementById('txt_supplie').value === '') {
          Helpers.toastr.construct({ response: 'warning', message: 'Por favor, seleccionar insumo', toastr })
          return;
        }

        if (!unitSelected.length) {
          Helpers.toastr.construct({ response: 'warning', message: 'Por favor, seleccionar centros de costos', toastr })
          return;
        }

        if (!providers.length) {
          Helpers.toastr.construct({ response: 'warning', message: 'Por favor, seleccionar proveedores', toastr })
          return;
        }

        if (!providers.some(p => parseInt(p.state) === 1)) {
          Helpers.toastr.construct({ response: 'warning', message: 'Por favor, seleccionar proveedor ganador', toastr })
          return;
        }

        if (!row.id_book) {
          Helpers.toastr.construct({ response: 'warning', message: 'Por favor, seleccionar Libro', toastr })
          return;
        }

        if (row.id_participant.length === 0) {
          Helpers.toastr.construct({ response: 'warning', message: 'Por favor, seleccionar Representate', toastr })
          return;
        }

        let amountLowProvider = 0
        providers.forEach((pv, i) => {
          if (i === 0) {
            if (!isNaN(parseFloat(pv.amount))) {
              amountLowProvider = parseFloat(pv.amount)
            }
          } else {
            if (!isNaN(parseFloat(pv.amount))) {
              if (parseFloat(pv.amount) < amountLowProvider) {
                amountLowProvider = parseFloat(pv.amount)
              }
            }
          }
        })

        $this.classList.remove('was-validated');
        if (row.amount < providers.find(p => parseInt(p.state) === 1).amount || row.qty < totalQty() || row.amount > amountLowProvider) {
          setModalShowJustif(true);
          return;
        }      
      }
    }
   
    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers.award_acts.award_acts_insert_update(dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        setModalShowJustif(false);
        if (actionsModule.current.printaward) {
          closeModal()
          window.open(Helpers.config.api.url + '/awardacts/get/pdf/award/' + res.data.id + '?jwt=' + jwt, '_blank')
        } else {
          closeModal()
        }
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
      reload()
    });
  }

  return <ReactPlaceholder showLoadingAnimation rows={7}  type='text' ready={isOk}>
    <FormCustom dataSubmit={dataSumbit()} onSubmit={onSubmit} viewSubmit={!disabledView}>
      <Input
        text="Nro Acta"
        placeholder="Nro Acta"
        classNameParent="col-12 col-md-6 mb-2"
        icon="fas fa-code"
        required
        defaultValue={number}
        onChange={e => setRow({ ...row, number: e.currentTarget.value })}
        maxLength={50}
        append={id === '' ? [<span className="input-group-text">/{year}</span>] : []}
        autoFocus
        disabled={disabledView}
      />
      <Input
        type="date"
        text="Fecha"
        placeholder="Fecha"
        classNameParent="col-12 col-md-3 mb-2"
        icon="far fa-calendar"
        required
        value={row.date}
        onChange={e => setRow({ ...row, date: e.currentTarget.value })}
        maxLength={50}
        disabled={disabledView}
      />
      <Input
        type="time"
        text="Hora"
        placeholder="Hora"
        classNameParent="col-12 col-md-3 mb-2"
        icon="far fa-clock"
        required
        value={row.hour}
        onChange={e => setRow({ ...row, hour: e.currentTarget.value })}
        maxLength={50}
        disabled={disabledView}
      />
      {cboTypePurchase}
      <Input
        type="number"
        text="NOG"
        min="1"
        placeholder="NOG"
        classNameParent={'col-12 col-md-6 mb-2' + (parseInt(row.id_type_purchase) === 2 ? ' d-none' : '')}
        icon="fas fa-sort-numeric-up-alt"
        required={parseInt(row.id_type_purchase) !== 2}
        defaultValue={nog}
        onChange={e => setRow({ ...row, nog: e.currentTarget.value })}
        maxLength={50}
        pattern="[0-9]{1,50}"
        disabled={disabledView}
      />
      <Select
        value={cboBook}
        text="Libro *"
        options={listBooks.map(p => { return { value: p.id, label: p.name } })}
        classNameParent="col-12 col-md-6 mb-2"
        icon="fab fa-codepen"
        required
        onChange={e => {
          setRow({ ...row, id_book: e.value })
        }}
        disabled={disabledView}
      />
      <TextArea rows={4} required={showJustification} classNameParent={'col-12 mb-2' + (showJustification ? '' : ' d-none')} icon="far fa-comment-alt" placeholder="Escriba aquí su justificación" text="Justificación" defaultValue={justification} onChange={e => setRow({ ...row, justification: e.currentTarget.value })} />
      <div className="col-12">
        <small><strong>Información de participantes</strong></small>
      </div>
      <Select
        value={participantSelected}
        text="Participante *"
        options={listParticipants.map(p => { return { value: p.id, label: p.name, isDisabled: row.id_participant?.some(pp => parseInt(pp) === parseInt(p.id)) } })}
        classNameParent="col-12 col-md-6 mb-2"
        icon="fab fa-codepen"
        required
        onChange={e => {
          setParticipantSelected(e)
        }}
        disabled={disabledView}
        append={[
          <Button onClick={_ => {
            if (participantSelected) {
              setRow({
                ...row,
                id_participant: [
                  ...row.id_participant,
                  participantSelected.value
                ]
              })
              setParticipantSelected('')
            }
          }}>
            <i className="fa fa-plus"></i>
          </Button>
        ]}
      />
      <div className="col-12">
        <div className="d-flex flex-column">
          {React.Children.toArray(row.id_participant.map(p => {
            let finded = listParticipants.find(b => parseInt(b.id) === parseInt(p))
            return <span>{finded?.name} - {finded?.type} <i className="fa fa-trash ml-2 text-danger" style={{ cursor: 'pointer' }} onClick={_ => {
              setRow({
                ...row,
                id_participant: row.id_participant.filter(pp => parseInt(p) !== parseInt(pp))
              })
            }}></i></span>
          }))}
        </div>
      </div>
      <div className="col-12">
        <hr />
      </div>
      <Input
        text="Buscar OC"
        placeholder="Buscar OC"
        classNameParent="col-12 col-md-4 mb-2"
        icon="fas fa-box-open"
        className="text-center"
        maxLength={250}
        append={[<Button type="button" onClick={e => {
          if (oc.number_resolution) {
            let $this = e.currentTarget
            let buttonHTML = $this.innerHTML
            $this.innerHTML = Helpers.icon.spin()
            Controllers.orders.get_orders_consolidate_number_consolidate({
              p_number_resolution: oc.number_resolution
            }).then(res => {
              if (res.response !== 'success') {
                Helpers.toastr.construct({
                  response: res.response,
                  message: res.message,
                  toastr
                })
              } else {
                setOc({ ...oc, id_consolidate: res.data.id })
                setSupplie(res.data.supplie)
                setUnitSelected(res.data.units)
              }
            }).finally(() => $this.innerHTML = buttonHTML)
          }
        }}>
          <i className="fa fa-search"></i>
        </Button>, <Button onClick={() => {
          setOc({id_consolidate: '', number_resolution: '' })
          setUnitSelected([])
        }} variant="danger" size="xs" className={oc.id_consolidate ? '' : 'd-none'}><i className="fa fa-times mr-1"></i>Quitar OC</Button>]}
        value={oc.number_resolution ? oc.number_resolution : ''}
        onChange={e => setOc({id_consolidate: '', number_resolution: e.currentTarget.value})}
      />
      <span className="col-12 mt-2 mb-1">Seleccionar Insumo De La Compra</span>
      <div className="col-12"></div>
      <Input
        text="Insumo"
        placeholder="Insumo"
        classNameParent="col-12 col-md-4 mb-2"
        icon="fas fa-box-open"
        className="text-center"
        id="txt_supplie"
        required
        disabled
        value={supplie === null ? '' : supplie.cod_internal + ' - ' + supplie.type_supplies}
        maxLength={50}
        append={disabledView || oc.id_consolidate ? [] : [<Button onClick={() => setModalShowSupplies(true)}><i className="fa fa-search"></i></Button>]}
      />
      <Input
        text="Descripción"
        placeholder="Descripción"
        classNameParent="col-12 col-md-8 mb-2"
        icon="far fa-comment-alt"
        required
        value={supplie === null ? '' : supplie.description}
        onChange={e => setSupplie({ ...supplie, description: e.currentTarget.value })}
        disabled={supplie === null || disabledView}
      />
      {unitCost}
      <div className="col-12">
        <hr />
      </div>
      {providersRender}

      <span className="col-12 mt-2 mb-1">Información Adjudicada De Proveedor</span>
      <Input
        type="number"
        className="text-center"
        icon=""
        text="Cantidad Adjudicada"
        value={row.qty}
        min="0.01"
        step="0.01"
        max={totalQty()}
        required
        placeholder="0.00"
        classNameParent="col-6 mb-2"
        onChange={e => setRow({ ...row, qty: e.currentTarget.value })}
        invalid="La cantidad debe ser menor o igual a la total solicitada"
        disabled={disabledView}
      />
      <Input
        type="number"
        className="text-center"
        icon=""
        text="Monto Adjudicado"
        value={row.amount}
        max={providers.some(p => parseInt(p.state) === 1) ? providers.find(p => parseInt(p.state) === 1).amount : 0}
        min="0.01"
        step="0.01"
        required
        placeholder="0.01"
        classNameParent="col-6 mb-2"
        onChange={e => setRow({ ...row, amount: e.currentTarget.value })}
        invalid="El monto debe ser menor o igual que la oferta ganadora"
        disabled={disabledView}
      />
      <div className="col-12 d-flex mb-2">
        <div className="mr-3">
          <Form.Check
            checked={parseInt(row.iva) === 1}
            custom
            type="radio"
            label="Incluye IVA"
            id="has-iva"
            onChange={() => setRow({ ...row, iva: 1 })}
            name="p_iva"
            required
            disabled={disabledView}
          />
        </div>
        <div className="mr-3">
          <Form.Check
            checked={parseInt(row.iva) === 0}
            custom
            type="radio"
            label="No Incluye IVA"
            id="has-no-iva"
            onChange={() => setRow({ ...row, iva: 0 })}
            name="p_iva"
            required
            disabled={disabledView}
          />
        </div>
        <Form.Check
          checked={parseInt(row.iva) === 2}
          custom
          type="radio"
          label="Exento IVA"
          id="exemption-iva"
          onChange={() => setRow({ ...row, iva: 2 })}
          name="p_iva"
          required
          disabled={disabledView}
        />
      </div>
    </FormCustom>
    {id === '' ? '' : <>
      <hr />
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <span className="text-muted">Usuario Creación: <strong>{user_created}</strong></span>
          <span className="text-muted">Fecha Creación: <strong>{date_created}</strong></span>
        </div>
        {user_updated === '' ? '' : <div className="d-flex flex-column">
          <span className="text-muted">Usuario última actualización: <strong>{user_updated}</strong></span>
          <span className="text-muted">Fecha última actualización: <strong>{date_updated}</strong></span>
        </div>}
      </div>
    </>}
    <SuppliesPublic showModal={modalShowSupplies} hideModal={() => setModalShowSupplies(false)} added={supplie !== null ? [supplie.id] : []} onAdd={e => {
      const { id, description, codInternal, typeSupplies } = e.currentTarget.dataset;
      setSupplie({ id, description, cod_internal: codInternal, type_supplies: typeSupplies })
    }} />

    <ProviderPublic showModal={modalShowProvider} hideModal={() => setModalShowProvider(false)} added={providers} onAdd={e => {
      const { id, nit, name } = e.currentTarget.dataset;
      setProviders([...providers, { id, name, nit, amount: '', state: 0, products_oferts: 1}])
    }} />

    <Modal show={modalShowJustif} onHide={() => {
      setModalShowJustif(false)
    }} size="xs" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title as="div"><i className="far fa-comment-alt mr-2"></i>Adicionar Justificación</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormCustom viewSubmit={false} className="d-flex flex-column form-row" onSubmit={({ e })=> {
          let $this = e.currentTarget
          if (!$this.checkValidity()) {
            $this.classList.add('was-validated');
            return;
          } else {
            $this.classList.remove('was-validated');    
          }
          
          if (document.getElementById('txt_justif').value !== '') {
            onSubmit({ e, dataSubmit: dataSumbit()});
          }
        }}>
          <TextArea id="txt_justif" icon="far fa-comment-alt" text="Justificación" placeholder="Justificación" required value={row.justification ? row.justification : ''} onChange={e => setRow({ ...row, justification: e.currentTarget.value })} />
          <div className="mt-2 col-12 d-flex justify-content-center">
            <Button variant="success" className="mr-2" size="sm" type="submit">
              <i className="fa fa-check mr-2"></i>Guardar Información
            </Button>
            <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalShowJustif(false)}>
              <i className="fa fa-times mr-2"></i>Cancelar
            </Button>
          </div>
        </FormCustom>
      </Modal.Body>
    </Modal>
  </ReactPlaceholder>
}

const AwardActs = () => {
  const nameModulo = 'Actas'
  const namePage = 'Acta'
  const nameController = 'award_acts'
  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = useRef(Helpers.getActions({ permissions, name: 'actas' }))

  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [modalSize, setModalSize] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [form, setForm] = useState('');
  const [reload, setReload] = useState(true);
  const year = useSelector(store => store.session.year)
  const [isUpdate, setIsUpdate] = useState(false)

  const jwt = useSelector(store => store.session.jwt)

  useEffect(() => {
    if (reload) {
      setIsProcessing(true)
      Controllers[nameController]['get_' + nameController]().then(res => {
        setRows(res.data)
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload])

  const handleUpdate = useCallback(e => {
    const { id = '', disabled = 0 } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()
    
    setModalTitle(parseInt(disabled) === 1 ? <><i className={'mr-2 ' + actionsModule.current.view.icon}></i>{actionsModule.current.view.description} {namePage}</> : (id ? <><i className={'mr-2 ' + actionsModule.current.update.icon}></i>{actionsModule.current.update.description} {namePage}</> : <><i className={'mr-2 ' + actionsModule.current.create.icon}></i>{actionsModule.current.create.description} {namePage}</>))
    setModalSize('xl');
    setModalHeader('');

    Controllers[nameController]['get_' + nameController + '_id']({ p_id: id }).then(res => {
      if (parseInt(disabled) === 0) {
        setIsUpdate(true)
      }
      setForm(<AwardActsForm disabledView={parseInt(disabled) === 1} data={res.data} form={(f, title, size) => {
        setIsUpdate(false)
        setForm(f)
        setModalTitle(title)
        setModalSize(size)
      }} onSubmit={() => {}} closeModal={() => setModalShow(false)} reload={() => {
        setReload(true)
      }} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }, [actionsModule])

  const onDelete = e => {
    const { id = '' } = e.currentTarget.dataset;
    setModalTitle(<><i className="fa fa-trash mr-2"></i> Eliminar {namePage}</>);
    setModalHeader('bg-danger');
    setModalSize('sm');
    setForm(<div className="d-flex flex-column align-items-center">
      <p><strong>¿Eliminar {namePage} #{id}?</strong></p>

      <div>        
        <Button variant="danger" className="mr-2" size="sm" onClick={e => {
          let button = e.currentTarget
          let buttonHTML = button.innerHTML;
          button.innerHTML = Helpers.icon.spin()

          Controllers[nameController][nameController + '_delete']({
            p_id: id
          }).then(res => {
            Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
          }).catch(req => {
            Helpers.promise.catch({ req, toastr });
          }).finally(() => {
            button.innerHTML = buttonHTML;
            setReload(true)
            setModalShow(false);
          });
        }}>
          <i className="fa fa-check mr-2"></i>Si
        </Button>
        <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalShow(false)}>
          <i className="fa fa-times mr-2"></i>No
        </Button>
      </div>
    </div>);
    setModalShow(true)
  }

  const handleAward = useCallback(e => {
    const { id = '', number } = e.currentTarget.dataset;
    setModalTitle(<><i className="fa fa-check mr-2"></i> Generar Acta De Negociación</>);
    setModalHeader('bg-success');
    setModalSize('');
    setForm(<div className="d-flex flex-column align-items-center">
      <p><strong>¿Generar Acta De Negociación para {number}?</strong></p>

      <form className="needs-validation form-row" noValidate onSubmit={e => {
        e.preventDefault();
        let $this = e.currentTarget

        let button = $this.querySelector('button[type=submit]');
        let buttonHTML = button.innerHTML
        button.innerHTML = Helpers.icon.spin();

        let actaNego = document.getElementById('txt_nro_award_business').value + '/' + year

        if (!$this.checkValidity()) {
          $this.classList.add('was-validated');
        } else {
          Controllers.award_acts.award_acts_business({
            p_id_award_acts: id,
            p_number_business: document.getElementById('txt_nro_award_business').value + '/' + year,
            p_date: document.getElementById('txt_date_business').value,
            p_hour: document.getElementById('txt_hour_business').value,
          }).then(res => {
            Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
            if (res.response === 'success') {
              if (actionsModule.current.printbusiness) {
                setModalTitle('Acta Negociacion #' + actaNego)
                setModalSize('lg')
                setModalHeader('bg-dark')
                setModalShow(false)
                window.open(Helpers.config.api.url + '/awardacts/get/pdf/business/' + id + '?jwt=' + jwt, '_blank')
              } else {
                setModalShow(false)
              }
              setReload(true)
            }
          }).catch(req => {
            setModalShow(false)
            Helpers.promise.catch({ req, toastr });
          }).finally(() => button.innerHTML = buttonHTML)
        }
      }} autoComplete="off">
        
        <Input
          type="number"
          text="Nro Acta Negociación"
          placeholder="Nro Acta Negociación"
          classNameParent="col-12 mb-2"
          icon="fas fa-code"
          required
          maxLength={50}
          append={[<span className="input-group-text">/{year}</span>]}
          autoFocus
          id="txt_nro_award_business"
        />
        <Input
          type="date"
          text="Fecha"
          placeholder="Fecha"
          classNameParent="col-6 mb-2"
          icon="far fa-calendar"
          required
          maxLength={50}
          id="txt_date_business"
          defaultValue={Helpers.date.get()}
        />
        <Input
          type="time"
          text="Hora"
          placeholder="Hora"
          classNameParent="col-6 mb-2"
          icon="far fa-clock"
          required
          maxLength={50}
          id="txt_hour_business"
          defaultValue={Helpers.date.get({ format: '%H:%i' })}
        />

      <div className="col-12 d-flex justify-content-center">        
        <Button type="submit" variant="danger" className="mr-2" size="sm">
          <i className="fa fa-check mr-2"></i>Generar
        </Button>
        <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalShow(false)}>
          <i className="fa fa-times mr-2"></i>Cancelar
        </Button>
      </div>
    </form>
    </div>);
    setModalShow(true)
  }, [year, actionsModule, jwt])

  const onCertification = useCallback(e => {
    const { id = '', number, numberBusiness } = e.currentTarget.dataset;
    setModalTitle(<><i className="fa fa-check mr-2"></i> Generar Acta De Certificación</>);
    setModalHeader('bg-success');
    setModalSize('');
    setForm(<div className="d-flex flex-column align-items-center">
      <p><strong>¿Generar Acta De Negociación para {number} y {numberBusiness}?</strong></p>

      <form className="needs-validation form-row" noValidate onSubmit={e => {
        e.preventDefault();
        let $this = e.currentTarget

        let button = $this.querySelector('button[type=submit]');
        let buttonHTML = button.innerHTML
        button.innerHTML = Helpers.icon.spin();

        if (!$this.checkValidity()) {
          $this.classList.add('was-validated');
        } else {
          Controllers.award_acts.award_acts_certification({
            p_id_award_acts: id,
            p_invoice_award: document.getElementById('txt_nro_invoice_award').value,
            p_invoice_business: document.getElementById('txt_nro_invoice_business').value,
          }).then(res => {
            Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
            setReload(true)
            setModalShow(false);
          }).catch(req => {
            setModalShow(false)
            Helpers.promise.catch({ req, toastr });
          }).finally(() => button.innerHTML = buttonHTML)
        }
      }} autoComplete="off">
        
        <Input
          text="No. Folio Adjudicación"
          placeholder="190,191,192"
          classNameParent="col-12 mb-2"
          icon="fas fa-code"
          required
          maxLength={255}
          autoFocus
          id="txt_nro_invoice_award"
        />
        <Input
          text="No. Folio Negociación"
          placeholder="190,191,192"
          classNameParent="col-12 mb-2"
          icon="fas fa-code"
          required
          maxLength={255}
          autoFocus
          id="txt_nro_invoice_business"
        />

      <div className="col-12 d-flex justify-content-center">        
        <Button type="submit" variant="danger" className="mr-2" size="sm">
          <i className="fa fa-check mr-2"></i>Generar
        </Button>
        <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalShow(false)}>
          <i className="fa fa-times mr-2"></i>Cancelar
        </Button>
      </div>
    </form>
    </div>);
    setModalShow(true)
  }, [])

  const onUpdateTemplates = useCallback(e => {
    const { id = '' } = e.currentTarget.dataset;
    e.preventDefault();
    let $this = e.currentTarget
    let button = $this;
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();
    Controllers.award_acts.award_acts_update_templates({
      p_id: id
    }).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      setReload(true)
    }).catch(req => {
      setModalShow(false)
      Helpers.promise.catch({ req, toastr });
    }).finally(() => button.innerHTML = buttonHTML)
  }, [])

  const dtRows = useMemo(() => {
    const thead = [
      { name: 'No. ADJU', align: 'center' },
      { name: 'No. NEGO', align: 'center' },
      { name: 'Tipo', align: 'center' },
      { name: 'NOG', align: 'center' },
      { name: 'Proveedor' },
      { name: 'Insumo' },
      { name: 'Fecha', align: 'center' },
      { name: 'Monto', align: 'center' },
      { name: 'Cantidad', align: 'center' },
      { name: 'IVA', align: 'center' },
      { name: 'ADJU', align: 'center', sort: false },
      { name: 'ADJU CERT.', align: 'center', sort: false },
      { name: 'NEGO', align: 'center', sort: false },
      { name: 'NEGO CERT.', align: 'center', sort: false },
      { name: 'Acc.', align: 'center', sort: false }
    ];

    return <DataTable
      isProcessing={isProcessing}
      tbody={rows.map(r => {
        let n = r.number.toString().split('/')
        if (n.length) {
          n = n[0]
        }

        let nb = r.number_business
        if (r.number_business) {
          let nb = r.number_business.toString().split('/')
          if (nb.length) {
            nb = nb[0]
          }
        }
    
        return {
          number: !isNaN(parseInt(n)) ? parseInt(n) : n,
          number_business: !isNaN(parseInt(nb)) ? parseInt(nb) : nb,
          description_type_purchase: r.description_type_purchase,
          nog: !isNaN(parseInt(r.nog)) ? parseInt(r.nog) : r.nog,
          name: r.name,
          supplie: r.supplie,
          date: r.date,
          amount: parseFloat(r.amount),
          qty: parseInt(r.qty),
          iva: r.iva,
          date_process: r.date_process,
          id: r.id,
          state: r.state,
          number_string: r.number,
          number_business_string: r.number_business,
        }
      })}
      thead={thead}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;
        for (let key in r) {
          if (key === 'state' || key === 'id' || key === 'date_process' || key === 'number_string' || key === 'number_business_string') {
            continue;
          }

          if (key === 'number') {
            rowRender.push(<td align="center">{r.number_string}</td>)
          }  else if (key === 'number_business') {
            rowRender.push(<td align="center">{r.number_business_string}</td>)
          } else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }

          if (key === 'iva') {
            rowRender.push(<td align="center">
              {actionsModule.current.printaward ? <OverlayTrigger
                  placement="top"
                  overlay={props => <Tooltip {...props}>
                    {actionsModule.current.printaward.description}
                  </Tooltip>}
                >
                <Button data-number={r.number} data-number-business={r.number_business} variant={actionsModule.current.printaward.color} className="mr-1" size="xs" data-id={r.id} onClick={e => {
                  const { id, number } = e.currentTarget.dataset
                  setModalSize('lg')
                  setModalHeader('')
                  setModalTitle('Acta Adjudicación #' + number)
                  setModalShow(false)
                  window.open(Helpers.config.api.url + '/awardacts/get/pdf/award/' + id + '?jwt=' + jwt, '_blank')
                }}><i className={actionsModule.current.printaward.icon}></i></Button>
              </OverlayTrigger> : ''}
            </td>)

            rowRender.push(parseInt(r.state) > 1 && actionsModule.current.printcertify ? <td align="center">
            <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.printcertify.description}
              </Tooltip>}
            >
              <Button data-number={r.number} variant={actionsModule.current.printcertify.color} className="mr-1" size="xs" data-id={r.id} onClick={e => {
              const { id, number } = e.currentTarget.dataset
              setModalSize('')
              setModalHeader('success')
              setModalTitle('Generar Acta Certificación De Adjudicación #' + number)
              

              Controllers.award_acts.get_award_acts_firms({
                p_id: id
              }).then(res => {
                let fReq = res.data.filter(f => f.type === 'SOLICITANTE')
                let fAuto = res.data.filter(f => f.type === 'AUTORIZADOR')
                setForm(<div className="d-flex flex-column align-items-center form-row">
                  <p><strong>¿Generar Acta De Certificación Adjudicación {number}?</strong></p>

                  <GeneratePDF id={id} requests={fReq} autos={fAuto} closeModal={() => {
                    setModalHeader('')
                    setModalTitle('Acta Certificación De Adjudicación #' + number)
                    setModalShow(false)
                  }} closeM={() => setModalShow(false)} jwt={jwt} type="certificationaward" />
                </div>);
                setModalShow(true)
              })
              }}><i className={actionsModule.current.printcertify.icon}></i></Button>
            </OverlayTrigger>
            {/* <Button variant="primary" size="xs" data-id={r.id} onClick={e => {

            }}><i className="fa fa-file-word"></i></Button> */}
            </td> : <td></td>)

            rowRender.push(parseInt(r.state) > 0 && actionsModule.current.printbusiness ? <td align="center">
            <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.printbusiness.description}
            </Tooltip>}
            >
              <Button data-number-business={r.number_business} variant={actionsModule.current.printbusiness.color} className="mr-1" size="xs" data-id={r.id} onClick={e => {
                const { id, numberBusiness } = e.currentTarget.dataset
                setModalSize('lg')
                setModalTitle('Acta Negociación #' + numberBusiness)
                setModalHeader('')
                setModalShow(false)
                window.open(Helpers.config.api.url + '/awardacts/get/pdf/business/' + id + '?jwt=' + jwt, '_blank')
              }}><i className={actionsModule.current.printbusiness.icon}></i></Button>
            </OverlayTrigger>
            {/* <Button variant="primary" size="xs" data-id={r.id} onClick={e => {
              
            }}><i className="fa fa-file-word"></i></Button> */}
            </td> : <td></td>)

            rowRender.push(parseInt(r.state) > 1 && actionsModule.current.printcertify ? <td align="center">
            <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.printcertify.description}
            </Tooltip>}
            >
              <Button data-number-business={r.number_business} variant={actionsModule.current.printcertify.color} className="mr-1" size="xs" data-id={r.id} onClick={e => {
              const { id, numberBusiness } = e.currentTarget.dataset
              setModalSize('')
              setModalHeader('success')
              setModalTitle('Generar Acta Certificación De Negociación #' + numberBusiness)
                              
              Controllers.award_acts.get_award_acts_firms({
                p_id: id
              }).then(res => {
                let fReq = res.data.filter(f => f.type === 'SOLICITANTE')
                let fAuto = res.data.filter(f => f.type === 'AUTORIZADOR')

                setForm(<div className="d-flex flex-column align-items-center">
                  <p><strong>¿Generar Acta De Certificación Negociación {numberBusiness}?</strong></p>

                  <GeneratePDF id={id} requests={fReq} autos={fAuto} closeModal={() => {
                    setModalHeader('')
                    setModalTitle('Acta Certificación De Negociación #' + numberBusiness)
                    setModalShow(false)
                  }} closeM={() => setModalShow(false)} jwt={jwt} type="certificationbusiness" />
                </div>)
                setModalShow(true)
              })
              }}><i className={actionsModule.current.printcertify.icon}></i></Button>
            </OverlayTrigger>
            </td> : <td></td>)
          }

          i++;
        }
        
        rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
          {actionsModule.current.view ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.view.description}
            </Tooltip>}
            >
              <Button variant={actionsModule.current.view.color} size="xs" className="mr-1" data-disabled={1} data-id={r.id} onClick={handleUpdate}>
                <i className={actionsModule.current.view.icon}></i>
              </Button>
            </OverlayTrigger> : ''}

          {parseInt(r.state) === 1 && actionsModule.current.certifyaward ? <OverlayTrigger
          placement="top"
          overlay={props => <Tooltip {...props}>
            {actionsModule.current.certifyaward.description}
          </Tooltip>}
          >
            <Button variant={actionsModule.current.certifyaward.color} className="mr-1" data-number-business={r.number_business} size="xs" data-id={r.id} onClick={onCertification}>
              <i className={actionsModule.current.certifyaward.icon}></i>
            </Button>
          </OverlayTrigger> : ''}
          
          {parseInt(r.state) === 0 ? <>
            {actionsModule.current.generatebusiness ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.generatebusiness.description}
            </Tooltip>}
            >
              <Button variant={actionsModule.current.generatebusiness.color} size="xs" className="mr-1" data-id={r.id} data-number={r.number} onClick={handleAward}>
                <i className={actionsModule.current.generatebusiness.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.current.update ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.update.description}
            </Tooltip>}
            >
              <Button variant={actionsModule.current.update.color} size="xs" className="mr-1" data-id={r.id} onClick={handleUpdate}>
                <i className={actionsModule.current.update.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
          </> : ''}
          {actionsModule.current.delete ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.delete.description}
            </Tooltip>}
            ><Button variant={actionsModule.current.delete.color} size="xs" className="mr-1" data-id={r.id} onClick={onDelete}>
            <i className={actionsModule.current.delete.icon}></i>
          </Button>
          </OverlayTrigger> : ''}
          {actionsModule.current.updatetemplates ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.updatetemplates.description}
            </Tooltip>}
            ><Button variant="info" size="xs" data-id={r.id} onClick={onUpdateTemplates}>
            <i className="fas fa-sync-alt"></i>
          </Button></OverlayTrigger> : ''}
        </td>);
        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, rows, handleUpdate, handleAward, onCertification, onUpdateTemplates, jwt])

  return <> 
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De {nameModulo}</span>
        {actionsModule.current.create ? <Button onClick={handleUpdate} variant={actionsModule.current.create.color} size="sm"><i className={ actionsModule.current.create.icon}></i><span className="ml-2 d-none d-md-inline-block">{actionsModule.current.create.description} {namePage}</span></Button> : ''}
      </Card.Header>
      <Card.Body>
        {dtRows}
      </Card.Body>
    </Card>
    <Modal show={modalShow} onHide={() => {
      if (isUpdate) {
        if (window.confirm('¿Está seguro de cancelar el guardado?')) {
          setModalShow(false)
          setIsUpdate(false)
        }
      } else {
        setModalShow(false)
        setIsUpdate(false)
      }
    }} size={modalSize} backdrop="static">
      <Modal.Header closeButton className={modalHeader}>
        <Modal.Title as="div">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
  </>
}

export default AwardActs;