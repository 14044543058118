import React, { useState, useRef, useEffect, useCallback } from 'react';
import Card from 'react-bootstrap/Card';
import Controllers from '../../Api/Controllers';
import Button from 'react-bootstrap/Button';
import Select from '../../Components/Form/Select';
import {toast as toastr} from 'react-toastify'
import Helpers from '../../Helpers/Helpers';
import ReactPlaceholder from 'react-placeholder/lib';
import { useSelector } from 'react-redux'

const UnopsReport = () => {
  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = useRef(Helpers.getActions({ permissions, name: 'reporteunops' }))

  const [fileReportUnops, setFileReportUnops] = useState('')
  const [filenameReportUnops, setFilenameReportUnops] = useState('')
  const [unit, setUnit] = useState([])
  const [unitRegularize, setUnitRegularize] = useState({
    id_unit: '',
    id_firm_realiza: '',
    id_firm_revisa: '',
    id_unops_consolidate: ''
  })
  const [firmsRegularize, setFirmsRegularize] = useState([])
  const [listConsoRegularize, setListConsoRegularize] = useState([])
  const [listCurdpRegularize, setListCurdpRegularize] = useState([])
  const [ready, setReady] = useState(false)
  const [unitConta, setUnitConta] = useState({
    id_unit: '',
    id_firm_realiza: '',
    id_firm_revisa: '',
    number_curdp: []
  })
  const [firmsConta, setFirmsConta] = useState([])

  const aReportUnops = useRef(null)

  const generateReportUnops = _ => {
    Controllers.unops.get_unops_report().then(res => {
      if (res.data) {
        setFileReportUnops(res.data.file)
        setFilenameReportUnops(res.data.filename)
        aReportUnops.current.click()
      } else {
        setFileReportUnops('')
        setFilenameReportUnops('')
      }
    })
  }

  const generateReportGeneralUnops = _ => {
    Controllers.unops.get_unops_general_report().then(res => {
      if (res.data) {
        setFileReportUnops(res.data.file)
        setFilenameReportUnops(res.data.filename)
        aReportUnops.current.click()
      } else {
        setFileReportUnops('')
        setFilenameReportUnops('')
      }
    })
  }
  
  const generateReportUnopsRegularize = _ => {
    if (!unitRegularize.id_unit) {
      Helpers.toastr.construct({ response: 'warning', message: 'Seleccionar Centro de Costo', toastr })
      return
    }

    if (!unitRegularize.id_unops_consolidate) {
      Helpers.toastr.construct({ response: 'warning', message: 'Seleccionar Consolidación', toastr })
      return
    }

    if (!unitRegularize.id_firm_realiza || !unitRegularize.id_firm_revisa) {
      Helpers.toastr.construct({ response: 'warning', message: 'Seleccionar Firmas', toastr })
      return
    }

    Controllers.unops.get_unops_regularize_report({
      p_id_unit: unitRegularize.id_unit,
      p_id_firm_realiza: unitRegularize.id_firm_realiza,
      p_id_firm_revisa: unitRegularize.id_firm_revisa,
      p_id_unops_consolidate: unitRegularize.id_unops_consolidate
    }).then(res => {
      if (res.data) {
        setFileReportUnops(res.data.file)
        setFilenameReportUnops(res.data.filename)
        aReportUnops.current.click()
      } else {
        setFileReportUnops('')
        setFilenameReportUnops('')
      }
    })
  }

  const generateReportUnopsConta = _ => {
    if (!unitConta.id_unit) {
      Helpers.toastr.construct({ response: 'warning', message: 'Seleccionar Centro de Costo', toastr })
      return
    }
    if (!unitConta.number_curdp) {
      Helpers.toastr.construct({ response: 'warning', message: 'Seleccionar CURDP', toastr })
      return
    }

    if (!unitConta.id_firm_realiza || !unitConta.id_firm_revisa) {
      Helpers.toastr.construct({ response: 'warning', message: 'Seleccionar Firmas', toastr })
      return
    }

    Controllers.unops.get_unops_contabilidad_report({
      p_id_unit: unitConta.id_unit,
      p_id_firm_realiza: unitConta.id_firm_realiza,
      p_id_firm_revisa: unitConta.id_firm_revisa,
      p_number_curdp: unitConta.number_curdp.map(r => r.value).toString()
    }).then(res => {
      if (res.data) {
        setFileReportUnops(res.data.file)
        setFilenameReportUnops(res.data.filename)
        aReportUnops.current.click()
      } else {
        setFileReportUnops('')
        setFilenameReportUnops('')
      }
    })
  }

  useEffect(() => {
    Promise.all([
      Controllers.unit.get_unit_user().then(res => {
        setUnit(res.data)
        setReady(true)
      })
    ])
  }, [])

  const firmRealizaRegularize = useCallback(()  => {
    if (firmsRegularize.length === 0 || !unitRegularize.id_firm_realiza) {
      return ''
    }

    let finded = firmsRegularize.filter(r => r.type_unops  === 'REALIZA').find(r => parseInt(r.id) === parseInt(unitRegularize.id_firm_realiza))

    if (!finded) {
      return ''
    }

    return {
      label: finded.name,
      value: finded.id
    }
  }, [firmsRegularize,unitRegularize])

  const firmRevisaRegularize = useCallback(()  => {
    if (firmsRegularize.length === 0 || !unitRegularize.id_firm_revisa) {
      return ''
    }

    let finded = firmsRegularize.filter(r => r.type_unops  === 'REVISA').find(r => parseInt(r.id) === parseInt(unitRegularize.id_firm_revisa))

    if (!finded) {
      return ''
    }

    return {
      label: finded.name,
      value: finded.id
    }
  }, [firmsRegularize,unitRegularize])

  const consoRegularize = useCallback(()  => {
    if (listConsoRegularize.length === 0 || !unitRegularize.id_unops_consolidate) {
      return ''
    }

    let finded = listConsoRegularize.find(r => parseInt(r.id_unops_consolidate) === parseInt(unitRegularize.id_unops_consolidate))

    if (!finded) {
      return ''
    }

    return {
      label: finded.id_unops_consolidate,
      value: finded.id_unops_consolidate
    }
  }, [listConsoRegularize,unitRegularize])

  const numberCurdp = useCallback(()  => {
    if (listCurdpRegularize.length === 0) {
      return ''
    }

    let finded = []
    listCurdpRegularize.forEach(r => {
      let find = unitConta.number_curdp.find(rr => rr.value === r.number_curdp)
      if (find) {
        finded.push(find)
      }
    })

    if (!finded.length) {
      return ''
    }

    return finded
  }, [listCurdpRegularize,unitConta])

  const firmRealizaConta = useCallback(()  => {
    if (firmsConta.length === 0 || !unitConta.id_firm_realiza) {
      return ''
    }

    let finded = firmsConta.filter(r => r.type_unops  === 'REALIZA').find(r => parseInt(r.id) === parseInt(unitConta.id_firm_realiza))

    if (!finded) {
      return ''
    }

    return {
      label: finded.name,
      value: finded.id
    }
  }, [firmsConta,unitConta])

  const firmRevisaConta = useCallback(()  => {
    if (firmsConta.length === 0 || !unitConta.id_firm_revisa) {
      return ''
    }

    let finded = firmsConta.filter(r => r.type_unops  === 'REVISA').find(r => parseInt(r.id) === parseInt(unitConta.id_firm_revisa))

    if (!finded) {
      return ''
    }

    return {
      label: finded.name,
      value: finded.id
    }
  }, [firmsConta,unitConta])

  const listCurdp = useCallback(()  => {
    let d = []
    listCurdpRegularize.forEach(u => {
      d.push({
        label: u.number_curdp,
        value: u.number_curdp
      })
    })
    return d
  }, [listCurdpRegularize])

  return <> 
    <Card className="mb-2">
      <Card.Header className="justify-content-between">
        <span>Reporte Factura Unops</span>
      </Card.Header>
      <Card.Body>
        <ReactPlaceholder ready={ready} showLoadingAnimation rows={4}>
          <Button size="sm" variant="success" onClick={generateReportUnops}><i className="fa fa-file-excel"></i> Generar Reporte</Button>
        </ReactPlaceholder>
      </Card.Body>
    </Card>
    <Card className="mb-2">
      <Card.Header className="justify-content-between">
        <span>Reporte Unops Regularización</span>
      </Card.Header>
      <Card.Body>
        <ReactPlaceholder ready={ready} showLoadingAnimation rows={7}>
          <div className="form-row">
            <Select
              classNameParent="col-md-6 mb-2"
              text="Centro Costo"
              icon="fa fa-building"
              options={unit.map(u => {
                return {
                  label: u.id + ' - ' + u.description,
                  value: u.id
                }
              })}
              onChange={e => {
                Controllers.firms.get_firms({
                  p_id_unit: e.value
                }).then(res => {
                  setFirmsRegularize(res.data.filter(r => parseInt(r.unops) === 1))

                  let findedRevisa = res.data.filter(r => r.type_unops  === 'REVISA').find(r => parseInt(r.main) === 1)
                  let findedRealiza = res.data.filter(r => r.type_unops  === 'REALIZA').find(r => parseInt(r.main) === 1)

                  setUnitRegularize({
                    id_unit: e.value,
                    id_firm_realiza: findedRealiza ? findedRealiza.id : '',
                    id_firm_revisa: findedRevisa ? findedRevisa.id : '',
                  })
                })

                Controllers.unops.get_unops_consolidate_list({ p_id_unit: e.value }).then(res => {
                  setListConsoRegularize(res.data)
                })
              }}
            />
            <Select
              classNameParent="col-md-6 mb-2"
              text="Consolidaciones"
              icon="fa fa-building"
              options={listConsoRegularize.map(u => {
                return {
                  label: u.id_unops_consolidate,
                  value: u.id_unops_consolidate
                }
              })}
              onChange={e => setUnitRegularize({
                ...unitRegularize,
                id_unops_consolidate: e.value
              })}
              value={consoRegularize()}
            />
            <Select
              classNameParent="col-md-6 mb-2"
              text="Firma Realiza"
              icon="fa fa-building"
              options={firmsRegularize.filter(r => r.type_unops  === 'REALIZA').map(u => {
                return {
                  label: u.name,
                  value: u.id
                }
              })}
              onChange={e => setUnitRegularize({
                ...unitRegularize,
                id_firm_realiza: e.value
              })}
              value={firmRealizaRegularize()}
            />
            <Select
              classNameParent="col-md-6 mb-2"
              text="Firma Revisa"
              icon="fa fa-building"
              options={firmsRegularize.filter(r => r.type_unops  === 'REVISA').map(u => {
                return {
                  label: u.name,
                  value: u.id
                }
              })}
              onChange={e => setUnitRegularize({
                ...unitRegularize,
                id_firm_revisa: e.value
              })}
              value={firmRevisaRegularize()}
            />
          </div>
          <Button size="sm" variant="success" onClick={generateReportUnopsRegularize}><i className="fa fa-file-excel"></i> Generar Reporte</Button>
        </ReactPlaceholder>
      </Card.Body>
    </Card>
    <Card className="mb-2">
      <Card.Header className="justify-content-between">
        <span>Reporte Unops Contabilidad</span>
      </Card.Header>
      <Card.Body>
        <ReactPlaceholder ready={ready} showLoadingAnimation rows={7}>
          <div className="form-row">
            <Select
              classNameParent="col-md-6 mb-2"
              text="Centro Costo"
              icon="fa fa-building"
              options={unit.map(u => {
                return {
                  label: u.id + ' - ' + u.description,
                  value: u.id
                }
              })}
              onChange={e => {
                Controllers.firms.get_firms({
                  p_id_unit: e.value
                }).then(res => {
                  setFirmsConta(res.data.filter(r => parseInt(r.unops) === 1))

                  let findedRevisa = res.data.filter(r => r.type_unops  === 'REVISA').find(r => parseInt(r.main) === 1)
                  let findedRealiza = res.data.filter(r => r.type_unops  === 'REALIZA').find(r => parseInt(r.main) === 1)

                  setUnitConta({
                    id_unit: e.value,
                    id_firm_realiza: findedRealiza ? findedRealiza.id : '',
                    id_firm_revisa: findedRevisa ? findedRevisa.id : '',
                    number_curdp: []
                  })
                })

                Controllers.unops.get_unops_curdp_list({p_id_unit: e.value}).then(res => setListCurdpRegularize(res.data))
              }}
            />
            <Select
              classNameParent="col-md-6 mb-2"
              text="CURDP"
              icon="fa fa-building"
              options={listCurdp()}
              onChange={e => {
                setUnitConta({
                  ...unitConta,
                  number_curdp: e?.length ? e : []
                })
              }}
              value={numberCurdp()}
              isMulti
            />
            <Select
              classNameParent="col-md-6 mb-2"
              text="Firma Realiza"
              icon="fa fa-building"
              options={firmsConta.filter(r => r.type_unops  === 'REALIZA').map(u => {
                return {
                  label: u.name,
                  value: u.id
                }
              })}
              onChange={e => setUnitConta({
                ...unitConta,
                id_firm_realiza: e.value
              })}
              value={firmRealizaConta()}
            />
            <Select
              classNameParent="col-md-6 mb-2"
              text="Firma Revisa"
              icon="fa fa-building"
              options={firmsConta.filter(r => r.type_unops  === 'REVISA').map(u => {
                return {
                  label: u.name,
                  value: u.id
                }
              })}
              onChange={e => setUnitConta({
                ...unitConta,
                id_firm_revisa: e.value
              })}
              value={firmRevisaConta()}
            />
          </div>
          <Button size="sm" variant="success" onClick={generateReportUnopsConta}><i className="fa fa-file-excel"></i> Generar Reporte</Button>
        </ReactPlaceholder>
      </Card.Body>
    </Card>
    {actionsModule.current?.unopsreportegeneral ? <Card className="mb-2">
      <Card.Header className="justify-content-between">
        <span>Reporte General Unops</span>
      </Card.Header>
      <Card.Body>
        <ReactPlaceholder ready={ready} showLoadingAnimation rows={4}>
          <Button size="sm" variant="success" onClick={generateReportGeneralUnops}><i className="fa fa-file-excel"></i> Generar Reporte</Button>
        </ReactPlaceholder>
      </Card.Body>
    </Card> : ''}
    <a className="d-none" download={filenameReportUnops} ref={aReportUnops} href={fileReportUnops}></a>
  </>
}

UnopsReport.defaultProps = {
  isPublic: false,
  added: [],
  onAdd: () => {}
}

export default UnopsReport;