import React, { useState, useCallback } from 'react'
import { Input, FormCustom } from '../../Components/Form/Form'
import Helpers from '../../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import Controllers from '../../Api/Controllers';

const ConsolidateUnops = ({ listConsolidate, hideModal }) => {
    const [row, setRow] = useState({
        date_consolidate: Helpers.date.get()
    })

    const dataSubmit = useCallback(() => {
        return {
            p_date_consolidate: row.date_consolidate,
            p_unops: listConsolidate.map(c => c.id_unops)
        }
    }, [listConsolidate, row])

    return <>
        <FormCustom onSubmit={({ e, dataSubmit }) => {
            let $this = e.currentTarget

            let propsValidateForm = { form: $this, toastr };
            if (!$this.checkValidity()) {
                $this.classList.add('was-validated');
                Helpers.form.isFormCorrect(propsValidateForm);
                return;
            } else {
                $this.classList.remove('was-validated');
                if (!Helpers.form.isFormCorrect(propsValidateForm)) {
                    return;
                }
            }

            let button = $this.querySelector('button[type=submit]');
            let buttonHTML = button.innerHTML
            button.innerHTML = Helpers.icon.spin();

            Controllers.unops.unops_consolidate(dataSubmit).then(res => {
                Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
                if (res.response === 'success') {
                    hideModal();
                }
            }).catch(req => {
                Helpers.promise.catch({ req, toastr });
            }).finally(() => {
                button.innerHTML = buttonHTML;
            });
        }} dataSubmit={dataSubmit()}>
            <div className="col-12">Listado De Unops</div>
            <table className="table table-sm table-hover table-striped table-bordered mb-2">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Centro Costo</th>
                        <th>Order de Compra</th>
                    </tr>
                </thead>
                <tbody>
                    {React.Children.toArray(listConsolidate.map(l =>
                        <tr>
                            <td className="text-center">{l.id_unops}</td>
                            <td>{l.description_unit}</td>
                            <td className="text-center">{l.number_order}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Input
                type="date"
                text="Fecha"
                classNameParent="col-12 mb-2"
                icon="fas fa-calendar"
                required
                autoFocus
                id="txt_date_consolidate"
                value={row.date_consolidate}
                onChange={e => setRow({ ...row, date_consolidate: e.currentTarget.value })}
            />
        </FormCustom>
    </>
}

export default ConsolidateUnops