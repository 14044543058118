import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Controllers from '../Api/Controllers';
import { FormCustom, Input, SwitchToggle, CheckBox } from '../Components/Form/Form';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import ReactPlaceholder from 'react-placeholder'
import Accordion from 'react-bootstrap/Accordion';
import {useSelector} from 'react-redux'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const TypeSuppliesForm = ({ data, onSubmit, viewDisabled }) => {
  const {
    id_tags_items_parent_master = '',
    description = '',
    enabled = 0
  } = data

  const [row, setRow] = useState({
    id_tags_items_parent_master,
    description,
    enabled: parseInt(enabled) === 1
  })

  const [childrens, setChildrens] = useState([])
  const [actions, setActions] = useState([])
  const [isOk, setIsOk] = useState(false)

  useEffect(() => {
    let promises = [
      Controllers.tags_items.get_tags_items_childrens({
        p_id: id_tags_items_parent_master
      }),
      Controllers.actions.get_actions_public()
    ]
    Promise.all(promises).then(res => {
      setChildrens(res[0].data)
      setActions(res[1].data)
      setIsOk(true)
    })
  }, [id_tags_items_parent_master])

  const dataSumbit = useCallback(() => {
    return {
      p_id_tags_items_parent_master: row.id_tags_items_parent_master,
      p_description: row.description,
      p_enabled: row.enabled ? 1 : 0,
      p_childrens: childrens
    }
  }, [row, childrens])

  return <ReactPlaceholder showLoadingAnimation rows={7}  type='text' ready={isOk}><FormCustom dataSubmit={dataSumbit()} onSubmit={onSubmit} viewSubmit={!viewDisabled}>
    <Input
      text="Descripción"
      placeholder="Descripción"
      classNameParent="col-12 mb-2"
      icon="far fa-comment-alt"
      required
      defaultValue={description}
      onChange={e => setRow({ ...row, description: e.currentTarget.value })}
      maxLength={100}
      disabled={viewDisabled}
    />
    <SwitchToggle
      classNameParent="col-12 mb-2"
      defaultChecked={row.enabled}
      onChange={e => setRow({ ...row, enabled: e.currentTarget.checked, prefix: '' })}
      id="chk-enabled-tags-parent-master"
      disabled={viewDisabled}
    />

    <div className="col-12">
      <Card>
        <Card.Header className="justify-content-between">
          <strong>Listado De Submenús</strong>
          {viewDisabled ? '' : <Button 
          onClick={() => {
            setChildrens([...childrens, {
              parent: {
                description: '',
                icon: '',
                enabled: 0,
                id_tags_items_parent: ''
              },
              childrens: []
            }])
          }}
          size="sm" className="btn-circle"><i className="fa fa-plus"></i></Button>}
        </Card.Header>
      </Card>
    </div>
    <Accordion className="col-12 mb-2 mt-2">
      {React.Children.toArray(childrens.map((p, i) => {
        return <Card>
          <Card.Header className="justify-content-between">
            <Accordion.Toggle as={Button} variant="link" eventKey={i}>
              {p.parent.description ? p.parent.description : <span className="text-danger">Sin Nombre</span>}
            </Accordion.Toggle>
            <Button  
              onClick={e => {
                if (!viewDisabled) {
                  setChildrens(childrens.map(((p2, i2) => {
                    if (i === i2) {
                      p2.parent.enabled = parseInt(p2.parent.enabled) === 1 ? 0 : 1 
                    }
                    return p2
                  })))
                }
              }}
            size="sm" className="btn-circle" variant={parseInt(p.parent.enabled) === 1 ? 'success' : 'danger'}><i className="fa fa-power-off"></i></Button>
          </Card.Header>
          <Accordion.Collapse eventKey={i}>
            <Card.Body className="form-row">
              <Input
                text="Descripción"
                placeholder="Descripción"
                classNameParent="col-6 mb-2"
                icon="fas fa-tag"
                required
                value={p.parent.description}
                onChange={e => setChildrens(childrens.map(((p2, i2) => {
                  if (i === i2) {
                    p2.parent.description = e.currentTarget.value
                  }
                  return p2
                })))}
                maxLength={100}
                disabled={viewDisabled}
              />
              <Input
                text="Icono"
                placeholder="Icono"
                classNameParent="col-6 mb-2"
                icon="fa fa-icons"
                required
                defaultValue={p.parent.icon}
                onChange={e => setChildrens(childrens.map(((p2, i2) => {
                  if (i === i2) {
                    p2.parent.icon = e.currentTarget.value
                  }
                  return p2
                })))}
                maxLength={100}
                disabled={viewDisabled}
              />

              <div className="col-12 mt-2">
                <Card>
                  <Card.Header className="justify-content-between">
                    <strong>Listado De Submenús</strong>
                    {viewDisabled ? '' : <Button 
                    onClick={() => setChildrens(childrens.map(((p2, i2) => {
                      if (i === i2) {
                        p2.childrens = [...p2.childrens, {
                          description: '',
                          a_href: '',
                          actions: [],
                          enabled: 0,
                          id_tags_items: '',
                          show: 0
                        }]
                      }
                      return p2
                    })))}
                    size="sm" className="btn-circle"><i className="fa fa-plus"></i></Button>}
                  </Card.Header>
                </Card>
              </div>
              <Accordion className="col-12 mb-2 mt-2">
                {React.Children.toArray(p.childrens.map((ch, j) => {
                  return <Card>
                    <Card.Header className="justify-content-between">
                      <Accordion.Toggle as={Button} variant="link" eventKey={j}>
                        {ch.description ? ch.description : <span className="text-danger">Sin Nombre</span>}
                      </Accordion.Toggle>
                      <div>
                      <Button  
                        onClick={e => {
                          if (!viewDisabled) {
                            setChildrens(childrens.map(((p2, i2) => {
                              if (i === i2) {
                                p2.childrens = p2.childrens.map((ch2, j2) => {
                                  if (j === j2) {
                                    ch.enabled = parseInt(ch.enabled) === 1 ? 0 : 1
                                  }
                                  return ch2
                                })
                              }
                              return p2
                            })))
                          }
                        }}
                      size="sm" className="btn-circle mr-1" variant={parseInt(ch.enabled) === 1 ? 'success' : 'danger'}><i className="fa fa-power-off"></i></Button>
                      <Button  
                        onClick={e => {
                          if (!viewDisabled) {
                            setChildrens(childrens.map(((p2, i2) => {
                              if (i === i2) {
                                p2.childrens = p2.childrens.map((ch2, j2) => {
                                  if (j === j2) {
                                    ch.show = parseInt(ch.show) === 1 ? 0 : 1
                                  }
                                  return ch2
                                })
                              }
                              return p2
                            })))
                          }
                        }}
                      size="sm" className="btn-circle" variant={parseInt(ch.show) === 1 ? 'info' : 'dark'}><i className="fa fa-eye"></i></Button>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={j}>
                      <Card.Body className="form-row">
                        <Input
                          text="Descripción"
                          placeholder="Descripción"
                          classNameParent="col-6 mb-2"
                          icon="fas fa-tag"
                          required
                          value={ch.description}
                          onChange={e => setChildrens(childrens.map(((p2, i2) => {
                            if (i === i2) {
                              p2.childrens = p2.childrens.map((ch2, j2) => {
                                if (j === j2) {
                                  ch.description = e.currentTarget.value
                                }
                                return ch2
                              })
                            }
                            return p2
                          })))}
                          maxLength={100}
                          disabled={viewDisabled}
                        />
                        <Input
                          text="Link"
                          placeholder="Link"
                          classNameParent="col-6 mb-2"
                          icon="fas fa-link"
                          required
                          defaultValue={ch.a_href}
                          onChange={e => setChildrens(childrens.map(((p2, i2) => {
                            if (i === i2) {
                              p2.childrens = p2.childrens.map((ch2, j2) => {
                                if (j === j2) {
                                  ch.a_href = e.currentTarget.value
                                }
                                return ch2
                              })
                            }
                            return p2
                          })))}
                          maxLength={100}
                          disabled={viewDisabled}
                        />

                        <div className="col-12">Listado De Acciones</div>
                        {React.Children.toArray(actions.map((a, k) => {
                          let hasActionActive = ch.actions.some(act => parseInt(act.id_actions) === parseInt(a.id_actions) && parseInt(act.enabled) === 1)

                          if (ch.actions.some(act => parseInt(act.id_actions) === parseInt(a.id_actions))) {
                            return <CheckBox
                              classNameParent="col-12"
                              text={a.description_actions}
                              id={ch.id_tags_items_parent + '-' + i + '-' + ch.id_tags_items + '-' + j + '-' + a.id_actions + '-' + k}
                              checked={hasActionActive}
                              onChange={e => {
                                setChildrens(childrens.map(((p2, i2) => {
                                  if (i === i2) {
                                    p2.childrens = p2.childrens.map((ch2, j2) => {
                                      if (j === j2) {
                                        if (ch2.actions.some(act => parseInt(act.id_actions) === parseInt(a.id_actions))) {
                                          ch2.actions = ch2.actions.map((a2, k2) => {
                                            if (parseInt(a2.id_actions) === parseInt(a.id_actions)) {
                                              a2.enabled = e.currentTarget.checked ? 1 : 0
                                            }
                                            return a2
                                          })
                                        } else {
                                          ch2.actions.push({
                                            id_actions: a.id_actions,
                                            enabled: e.currentTarget.checked ? 1 : 0
                                          })
                                        }
                                      }
                                      return ch2
                                    })
                                  }
                                  return p2
                                })))
                              }}
                              disabled={viewDisabled}
                            />
                          } else {
                            return ''
                          }
                        }))}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                }))}
              </Accordion>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      }))}
    </Accordion>
  </FormCustom>
  </ReactPlaceholder>
}

const TypeSupplies = () => {
  const nameModulo = 'Opciones De Menú'
  const namePage = 'Opción De Menú'
  const nameController = 'tags_items'
  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = Helpers.getActions({ permissions, name: 'opcionesmenu' })

  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [modalSize, setModalSize] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [form, setForm] = useState('');
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      Controllers[nameController]['get_' + nameController]().then(res => {
        setRows(res.data)
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload])

  const handleUpdate = useCallback(e => {
    const { id = '', disabled = 0 } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()
    
    setModalTitle(parseInt(disabled) === 1 ? <><i className={'mr-2 ' + actionsModule.view.icon}></i>{actionsModule.view.description} {namePage}</> : (id ? <><i className={'mr-2 ' + actionsModule.update.icon}></i>{actionsModule.update.description} {namePage}</> : <><i className={'mr-2 ' + actionsModule.create.icon}></i>{actionsModule.create.description} {namePage}</>))
    setModalSize('');
    setModalHeader('');

    Controllers[nameController]['get_' + nameController + '_id']({ p_id: id }).then(res => {
      setForm(<TypeSuppliesForm data={res.data} onSubmit={onSubmit} viewDisabled={parseInt(disabled) === 1} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }, [actionsModule])

  const onSubmit = ({ e, dataSubmit }) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers[nameController][nameController + '_insert_update'](dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        setModalShow(false);
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
      setReload(true)
    });
  }

  /* const onDelete = e => {
    const { id = '' } = e.currentTarget.dataset;
    setModalTitle(<><i className="fa fa-trash mr-2"></i> Eliminar {namePage}</>);
    setModalHeader('bg-danger');
    setModalSize('sm');
    setForm(<div className="d-flex flex-column align-items-center">
      <p><strong>¿Eliminar {namePage} #{id}?</strong></p>

      <div>        
        <Button variant="danger" className="mr-2" size="sm" onClick={e => {
          let button = e.currentTarget
          let buttonHTML = button.innerHTML;
          button.innerHTML = Helpers.icon.spin()

          Controllers[nameController][nameController + '_delete']({
            p_id_actions: id
          }).then(res => {
            Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
          }).catch(req => {
            Helpers.promise.catch({ req, toastr });
          }).finally(() => {
            button.innerHTML = buttonHTML;
            setReload(true)
            setModalShow(false);
          });
        }}>
          <i className="fa fa-check mr-2"></i>Si
        </Button>
        <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalShow(false)}>
          <i className="fa fa-times mr-2"></i>No
        </Button>
      </div>
    </div>);
    setModalShow(true)
  } */

  const dtRows = useMemo(() => {
    const thead = [
      { name: 'Descripción', align: 'center' },
      { name: 'Estado', align: 'center' },
      { name: 'Acc.', align: 'center' }
    ];

    return <DataTable
      isProcessing={isProcessing}
      tbody={rows}
      thead={thead}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;
        for (let key in r) {
          if (key === 'id_tags_items_parent_master') {
            continue
          }
          if (key === 'enabled') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
              <strong className={'text-' + (parseInt(r.enabled) === 1 ? 'success': 'danger')}>
                {parseInt(r.enabled) === 1 ? 'ACTIVO': 'INACTIVO'}
              </strong>
            </td>);
          } else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }

        rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
          {actionsModule.view ?  <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.view.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.view.color} data-disabled={1} size="xs" className="mr-1" data-id={r.id_tags_items_parent_master} onClick={handleUpdate}>
              <i className={actionsModule.view.icon}></i>
            </Button>
          </OverlayTrigger> : ''}
          {actionsModule.update ?  <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.update.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.update.color} size="xs" className="mr-1" data-id={r.id_tags_items_parent_master} onClick={handleUpdate}>
              <i className={actionsModule.update.icon}></i>
            </Button>
          </OverlayTrigger> : ''}
          {/* <Button variant="danger" size="xs" data-id={r.id_tags_items_parent_master} onClick={onDelete}>
            <i className="fa fa-trash"></i>
          </Button> */}
        </td>);
        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, rows, handleUpdate, actionsModule])

  return <> 
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De {nameModulo}</span>
        {actionsModule.create ? <Button onClick={handleUpdate} variant={actionsModule.create.color} size="sm"><i className={ actionsModule.create.icon}></i><span className="ml-2 d-none d-md-inline-block">{actionsModule.create.description} {namePage}</span></Button> : ''}
      </Card.Header>
      <Card.Body>
        {dtRows}
      </Card.Body>
    </Card>
    <Modal show={modalShow} onHide={() => setModalShow(false)} size={modalSize} backdrop="static">
      <Modal.Header closeButton className={modalHeader}>
        <Modal.Title as="div">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
  </>
}

export default TypeSupplies;