import styled from 'styled-components';
import { boxShadow, primary, bgPrimaryLight, primaryGradient, widthFull, widthResize, heightHeader, widthWithPadding, widthResizeWithPadding } from '../Resources/Theme';

const Nav = styled.div`
  position: fixed;
  width: ${props => props.resize && !props.resizeOpen ? widthResize : widthFull};
  height: 100%;
  background: #fff;
  overflow: hidden;
  transition: .5s;
  box-shadow: ${boxShadow};
  transition: .5s;
  z-index: 99;

  &:hover {
    overflow: auto;
    overflow-x: hidden;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    position: fixed;
    margin-top: ${heightHeader};
  }

  &::-webkit-scrollbar {
    width: .3rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #A3A3A3;
    border-radius: 6px;
  }

  @media (max-width: 992px) {
    width: ${widthFull};
    margin-left: -${props => props.mobile ? '0' : '280'}px;
    overflow: auto;
    overflow-x: hidden;
  }
`;

Nav.Bg = styled.div`
  display; none;
  z-index: 99;

  @media (max-width: 992px) {
    display: ${props => props.mobile ? 'block' : 'none'};
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: ${bgPrimaryLight};
  }
`;
Nav.Title = styled.li`
  height: 29px;
  width:  ${props => props.resize && !props.resizeOpen ? widthResizeWithPadding : widthWithPadding};
  font-weight: lighter;
  padding-top: 0px;
  padding-bottom: 10px;
  display: flex;
  justify-content: ${props => props.resize && !props.resizeOpen ? 'center' : ''};
  span {
    display: ${props => props.resize && !props.resizeOpen ? 'none' : 'block'};
    align-self: center;
  }
  i {
    display: ${props => props.resize && !props.resizeOpen ? 'block' : 'none'};
    align-self: center;
  }

  @media (max-width: 992px) {
    display: block;
    justify-content: flex-start;
    width: ${widthWithPadding};

    span {
      display: block;
    }
    i {
      display: none;
    }
  }
`;
Nav.Swipe = styled.div`
  display: none;
  position: fixed;
  width: 30px;
  height: 100%;
  z-index: 1;
  
  @media (max-width: 992px) {
    display: block;
  }
`;

/* Start Brand */
Nav.Brand = styled.div`
  color: ${primary};
  position: fixed;
  width: ${props => props.resize && !props.resizeOpen ? widthResize : widthFull};
  height: ${heightHeader};
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 0 ${props => props.resize && !props.resizeOpen ? '4' : '10'}px;
  transition: .5s;
  box-shadow: ${boxShadow};
  z-index: 1;

  @media (max-width: 992px) {
    padding: 0 20px;
    justify-content: center;
    width: ${widthFull};
  }
`;
Nav.Brand.Link = styled.a`
  align-self: center;
`;
Nav.Brand.Logo = styled.img`
  width: ${props => props.resize && !props.resizeOpen ? '50px' : ''};
  align-self: center;
  transition: .5s;
  max-height: ${heightHeader};

  @media (max-width: 992px) {
    display: none;
  }
`;
Nav.Brand.IconSize = styled.div`
  align-self: center;
  padding: 5px;
  cursor: pointer;
  display: ${props => props.resize && !props.resizeOpen ? 'none' : 'flex'};
  justify-content: center;
  font-size: 1.2rem;

  @media (max-width: 992px) {
    display: none;
  }
`;
Nav.Brand.IconMobile = styled.div`
  align-self: center;
  display: none;
  padding: 5px;
  font-size: 1.2rem;

  @media (max-width: 992px) {
    display: flex;
  }
`;
/* End Brand */

/* Start One Level */
Nav.OneLevel = styled.ul`
  padding: 0 10px;
  padding-top: 80px;
  list-style: none;
`;
Nav.OneLevel.Item = styled.li`
  padding-bottom: 10px;
  width: ${props => props.resize && !props.resizeOpen ? widthResizeWithPadding : widthWithPadding};

  @media (max-width: 992px) {
    width: ${widthWithPadding};
  }
`;
Nav.OneLevel.TitleContent = styled.div`
  display: flex;
  justify-content: ${props => props.resize && !props.resizeOpen ? 'center' : 'space-between'};;
  background: ${props => props.active ? '#EAF0F7' : ''};
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 5px;
  height: 39px;

  &:hover div {
    padding-left: 15px;
  }

  @media (max-width: 992px) {
    justify-content: space-between;

  }
`;
Nav.OneLevel.Title = styled.div`
  align-self: center;
  transition: .5s;
  display: flex;
`;
Nav.OneLevel.IconArrow = styled.span`
  align-self: center;
  display: ${props => props.resize && !props.resizeOpen ? 'none' : 'inline-block'};
  transform: ${props => props.active ? 'rotate(225deg)' : 'rotate(135deg)'};
  transition: .5s;
  content: '';
  width: 4px;
  height: 4px;
  border-width: 1px 0 0 1px;
  border-style: solid;

  @media (max-width: 992px) {
    display: inline-block;
  }
`;
Nav.OneLevel.Icon = styled.span`
  margin-right: ${props => props.resize && !props.resizeOpen ? '' : '1rem'};
  display: flex;
  width: 20px;
  align-self: center;

  @media (max-width: 992px) {
    margin-right: 1rem;
  }
`;
Nav.OneLevel.Description = styled.span`
  display: ${props => props.resize && !props.resizeOpen ? 'none' : 'inline-block'};
  align-self: center;

  @media (max-width: 992px) {
    display: inline-block;
  }
`;
/* End One Level */

/* Start Second Level */
Nav.SecondLevel = styled.ul`
  list-style: none;
  display: ${props => props.resize && !props.resizeOpen ? 'none' : (props.active ? 'block' : 'none')};
  @media (max-width: 992px) {
    display: ${props => props.active ? 'block' : 'none'};
  }
`;
Nav.SecondLevel.Item = styled.li`
  display: flex;
  justify-content: space-between;
  background: ${props => props.active ? primaryGradient : ''};
  color: ${props => props.active ? '#fff' : ''};
  box-shadow: ${props => props.active ? `0 6px 20px 0 ${bgPrimaryLight}` : ''};
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;

  &:hover div {
    padding-left: 15px;
  }
`;
Nav.SecondLevel.Title = styled.div`
  align-self: center;
  transition: .325s;
  display: flex;
  height: 20px;
`;
Nav.SecondLevel.Icon = styled.span`
  margin-right: 1rem;
  align-self: center;
  display: flex;
  width: 8px;
  height: 8px;
  border-radius: 10px;
  border: 1px solid;
`;
Nav.SecondLevel.Description = styled.span`
  display: ${props => props.resize && !props.resizeOpen ? 'none' : 'inline-block'};
  font-weight: lighter;
  width: ${widthWithPadding};
  align-self: center;

  @media (max-width: 992px) {
    display: inline-block !important;
  }
`;
/* End Second Level */

export default Nav;