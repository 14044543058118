import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Controllers from '../Api/Controllers';
import { FormCustom, Input, SwitchToggle } from '../Components/Form/Form';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import {useSelector} from 'react-redux'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ProviderForm = ({ data, onSubmit, viewDisabled}) => {
  const {
    id = '',
    nit = '',
    name = '',
    contact = '',
    phone = '',
    email = '',
    address = '',
    state = 1,
    phone_office = ''
  } = data

  const [row, setRow] = useState({
    id,
    nit,
    name,
    contact,
    phone,
    email,
    address,
    state: parseInt(state) === 1,
    phone_office
  })

  const dataSumbit = useCallback(() => {
    return {
      p_id: row.id,
      p_nit: row.nit,
      p_name: row.name,
      p_contact: row.contact,
      p_phone: row.phone,
      p_email: row.email,
      p_address: row.address,
      p_state: row.state ? 1 : 0,
      p_phone_office: row.phone_office
    }
  }, [row])

  return <FormCustom dataSubmit={dataSumbit()} onSubmit={onSubmit} viewSubmit={!viewDisabled}>
    <Input
      text="NIT"
      placeholder="NIT"
      classNameParent="col-12 mb-2"
      icon="fas fa-code-branch"
      required
      defaultValue={nit}
      onChange={e => setRow({ ...row, nit: e.currentTarget.value })}
      maxLength={20}
      pattern="[A-Za-z0-9]{1,20}"
      invalid="NIT incorrecto. Ej: 1234567891234567"
      disabled={viewDisabled}
    />
    <Input
      text="Razón Social"
      placeholder="Razón Social"
      classNameParent="col-12 mb-2"
      icon="far fa-building"
      required
      defaultValue={name}
      onChange={e => setRow({ ...row, name: e.currentTarget.value })}
      maxLength={255}
      disabled={viewDisabled}
    />
    <Input
      text="Contacto"
      placeholder="Contacto"
      classNameParent="col-12 mb-2"
      icon="far fa-user"
      required
      defaultValue={contact}
      onChange={e => setRow({ ...row, contact: e.currentTarget.value })}
      maxLength={255}
      disabled={viewDisabled}
    />
    <Input
      text="Telefono"
      placeholder="Telefono"
      classNameParent="col-12 mb-2"
      iconText="+(502)"
      required
      defaultValue={phone}
      onChange={e => setRow({ ...row, phone: e.currentTarget.value })}
      pattern="[0-9]{8}"
      maxLength={8}
      invalid="Formato incorrecto: Ej. 12345678"
      disabled={viewDisabled}
    />
    <Input
      text="Telefono Oficina"
      placeholder="Telefono Oficina"
      classNameParent="col-12 mb-2"
      iconText="+(502)"
      required
      defaultValue={phone_office}
      onChange={e => setRow({ ...row, phone_office: e.currentTarget.value })}
      pattern="[0-9]{8}"
      maxLength={8}
      invalid="Formato incorrecto: Ej. 12345678"
      disabled={viewDisabled}
    />
    <Input
      type="email"
      text="Email"
      placeholder="Email"
      pattern="[A-Za-z0-9._-]{1,100}@[A-Za-z0-9._-]{1,100}[.]{1}[A-Za-z0-9._-]{1,5}"
      classNameParent="col-12 mb-2"
      icon="far fa-envelope"
      required
      defaultValue={email}
      onChange={e => setRow({ ...row, email: e.currentTarget.value })}
      disabled={viewDisabled}
    />
    <Input
      text="Dirección"
      placeholder="Dirección"
      classNameParent="col-12 mb-2"
      icon="fas fa-street-view"
      required
      defaultValue={address}
      onChange={e => setRow({ ...row, address: e.currentTarget.value })}
      disabled={viewDisabled}
      maxLength={255}
    />
    <SwitchToggle
      id="switch-provider"
      defaultChecked={parseInt(state) === 1}
      onChange={e => setRow({ ...row, state: e.currentTarget.checked })}
      disabled={viewDisabled}
    />
  </FormCustom>
}

const Provider = ({ isPublic, onAdd, added }) => {
  const nameModulo = 'Proveedores'
  const namePage = 'Proveedor'
  const nameController = 'provider'

  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = Helpers.getActions({ permissions, name: 'proveedores' })

  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [modalSize, setModalSize] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [form, setForm] = useState('');
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      Controllers[nameController]['get_' + nameController + (isPublic ? '_public' : '')]().then(res => {
        setRows(res.data)
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload, isPublic])

  const handleUpdate = useCallback(e => {
    const { id = '', disabled = 0 } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()
    
    setModalTitle(parseInt(disabled) === 1 ? <><i className={'mr-2 ' + actionsModule.view.icon}></i>{actionsModule.view.description} {namePage}</> : (id ? <><i className={'mr-2 ' + actionsModule.update.icon}></i>{actionsModule.update.description} {namePage}</> : <><i className={'mr-2 ' + actionsModule.create.icon}></i>{actionsModule.create.description} {namePage}</>))
    setModalSize('');
    setModalHeader('');

    Controllers[nameController]['get_' + nameController + '_id']({ p_id: id }).then(res => {
      setForm(<ProviderForm data={res.data} onSubmit={onSubmit} viewDisabled={parseInt(disabled) === 1} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }, [actionsModule])

  const onSubmit = ({ e, dataSubmit }) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers[nameController][nameController + '_insert_update'](dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        setModalShow(false);
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
      setReload(true)
    });
  }

  const onDelete = e => {
    const { id = '' } = e.currentTarget.dataset;
    setModalTitle(<><i className="fa fa-trash mr-2"></i> Eliminar {namePage}</>);
    setModalHeader('bg-danger');
    setModalSize('sm');
    setForm(<div className="d-flex flex-column align-items-center">
      <p><strong>¿Eliminar {namePage} #{id}?</strong></p>

      <div>        
        <Button variant="danger" className="mr-2" size="sm" onClick={e => {
          let button = e.currentTarget
          let buttonHTML = button.innerHTML;
          button.innerHTML = Helpers.icon.spin()

          Controllers[nameController][nameController + '_delete']({
            p_id: id
          }).then(res => {
            Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
          }).catch(req => {
            Helpers.promise.catch({ req, toastr });
          }).finally(() => {
            button.innerHTML = buttonHTML;
            setReload(true)
            setModalShow(false);
          });
        }}>
          <i className="fa fa-check mr-2"></i>Si
        </Button>
        <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalShow(false)}>
          <i className="fa fa-times mr-2"></i>No
        </Button>
      </div>
    </div>);
    setModalShow(true)
  }

  const dtRows = useMemo(() => {
    const thead = [
      { name: 'NIT', align: 'center' },
      { name: 'Razón Social' }
      
    ];

    if (isPublic) {
      thead.unshift({ name: 'Acc.', align: 'center'})
      thead.push({ name: 'Telefono', align: 'center' })
      thead.push({ name: 'Email', align: 'center' })
    } else {
      thead.push({ name: 'Contacto' })
      thead.push({ name: 'Telefono', align: 'center' })
      thead.push({ name: 'Email', align: 'center' })
      thead.push({ name: 'Estado', align: 'center' })
      thead.push({ name: 'Acc.', align: 'center' })
    }

    return <DataTable
      isProcessing={isProcessing}
      tbody={rows}
      thead={thead}
      render={tbody => tbody.map(r => {
        let exists = added.some(a => parseInt(a.id) === parseInt(r.id))
        let rowRender = [];
        let i = 0;

        if (isPublic) {
          let onClick = e => {
            if (!exists) {
              onAdd(e);
            }
          }
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
            <Button onClick={onClick} variant={exists ? 'dark' : 'primary'} size="sm" data-id={r.id} data-nit={r.nit} data-name={r.name}><i className={exists ? 'fa fa-check' : 'fa fa-plus'}></i></Button>
          </td>);
          i++;
        }

        for (let key in r) {
          if (key === 'id') {
            continue;
          }

          if (key === 'state' && isPublic) {
            continue;
          }

          if (key === 'state') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''} className={'text-' + (parseInt(r.state) === 1 ? 'success' : 'danger')}>
              <strong>{parseInt(r.state) === 1 ? 'ACTIVO' : 'INACTIVO'}</strong>
            </td>);
          }
          else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }

        if (!isPublic) {
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
            {actionsModule.view ?  <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.view.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.view.color} data-disabled={1} size="xs" className="mr-1" data-id={r.id} onClick={handleUpdate}>
                <i className={actionsModule.view.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.update ?  <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.update.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.update.color} size="xs" className="mr-1" data-id={r.id} onClick={handleUpdate}>
                <i className={actionsModule.update.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.delete ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.delete.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.delete.color} size="xs" data-id={r.id} onClick={onDelete}>
                <i className={actionsModule.delete.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
        </td>);
        }
        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, rows, handleUpdate, isPublic, added, onAdd, actionsModule])

  return <> 
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De {nameModulo}</span>
        {actionsModule.create ? <Button onClick={handleUpdate} variant={actionsModule.create.color} size="sm"><i className={ actionsModule.create.icon}></i><span className="ml-2 d-none d-md-inline-block">{actionsModule.create.description} {namePage}</span></Button> : ''}
      </Card.Header>
      <Card.Body>
        {dtRows}
      </Card.Body>
    </Card>
    <Modal show={modalShow} onHide={() => setModalShow(false)} size={modalSize} backdrop="static">
      <Modal.Header closeButton className={modalHeader}>
        <Modal.Title as="div">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
  </>
}

Provider.defaultProps = {
  isPublic: false,
  added: [],
  onAdd: () => {}
}

export default Provider;