import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Controllers from '../Api/Controllers';
import { FormCustom, Input, CheckBox, TextArea, Select} from '../Components/Form/Form';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import { useSelector } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ServiciosMedicosForm from './ServiciosMedicosForm'
import PresentationForm from './PurchaseOrdersForm'

const FormConsolidate = ({ listConsolidate, hideModal }) => {
  const [row, setRow] = useState({
    number_resolution: '',
    date_resolution: Helpers.date.get(),
    calculate_fee: 1,
    has_award: 1
  })

  const dataSubmit = useCallback(() => {
    let p_list_orders = []
    listConsolidate.forEach(l => {
      p_list_orders.push(l.id)
    })
    return {
      p_number_resolution: row.number_resolution,
      p_date_resolution: row.date_resolution,
      p_list_orders: p_list_orders.toString(),
      p_calculate_fee: row.calculate_fee,
      p_has_award: row.has_award
    }
  }, [listConsolidate, row])

  return <>
    <FormCustom onSubmit={({e, dataSubmit}) => {
      let $this = e.currentTarget

      let propsValidateForm = { form: $this, toastr };
      if (!$this.checkValidity()) {
        $this.classList.add('was-validated');
        Helpers.form.isFormCorrect(propsValidateForm);
        return;
      } else {
        $this.classList.remove('was-validated');
        if (!Helpers.form.isFormCorrect(propsValidateForm)) {
          return;
        }
      }
  
      let button = $this.querySelector('button[type=submit]');
      let buttonHTML = button.innerHTML
      button.innerHTML = Helpers.icon.spin();
  
      Controllers.orders.orders_approve(dataSubmit).then(res => {
        Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
        if (res.response === 'success') {
          hideModal();
        }
      }).catch(req => {
        Helpers.promise.catch({ req, toastr });
      }).finally(() => {
        button.innerHTML = buttonHTML;
      });
    }} dataSubmit={dataSubmit()}>
      <div className="col-12">Listado De Pedidos</div>
      <table className="table table-sm table-hover table-striped table-bordered mb-2">
        <thead>
          <tr>
            <th>ID</th>
            <th>Centro Costo</th>
            <th>Año</th>
            <th>Número</th>
          </tr>
        </thead>
        <tbody>
          {React.Children.toArray(listConsolidate.map(l =>
            <tr>
              <td>{l.id}</td>
              <td>{l.unit}</td>
              <td>{l.year}</td>
              <td>{l.number}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Input
        text="Número Consolidación"
        placeholder="Número Consolidación"
        classNameParent="col-12 mb-2"
        icon="fas fa-sort-numeric-up-alt"
        required
        maxLength={50}
        id="txt_number_resolution"
        onChange={e => setRow({ ...row, number_resolution: e.currentTarget.value })}
      />

      <Input
        type="date"
        text="Fecha"
        classNameParent="col-12 mb-2"
        icon="fas fa-calendar"
        required
        autoFocus
        id="txt_date_resolution"
        value={row.date_resolution}
        onChange={e => setRow({ ...row, date_resolution: e.currentTarget.value })}
      />

      <CheckBox
        id="chk-calculate-fee"
        text="Aplica Impuesto"
        checked={row.calculate_fee === 1}
        onChange={e => setRow({...row, calculate_fee: e.currentTarget.checked ? 1 : 0 })}
      />

      <CheckBox
        id="chk-award-acts"
        text="¿Tiene Acta De Adjudicación?"
        checked={row.has_award === 1}
        onChange={e => setRow({...row, has_award: e.currentTarget.checked ? 1 : 0 })}
      />
    </FormCustom>
  </>
}

const PrintPDF = ({ idOrder, idUnit, showPdf }) => {
  const [row, setRow] = useState({
    p_f_req: '',
    p_f_auto: '',
    p_id_address: ''
  })
  const [firms, setFirms] = useState([])
  const [address, setAddress] = useState([])

  useEffect(() => {
    Promise.all([
      Controllers.firms.get_firms({
        p_id_unit: idUnit
      }),
      Controllers.address.get_address_public({
        p_id_unit: idUnit
      })
    ]).then(res => {
      setFirms(res[0].data.filter(r => parseInt(r.in_acts) === 0))
      let fReq = res[0].data.find(f => parseInt(f.main) === 1 && f.type === 'SOLICITANTE' && parseInt(f.in_acts) === 0)
      let fAuto = res[0].data.find(f => parseInt(f.main) === 1 && f.type === 'AUTORIZADOR' && parseInt(f.in_acts) === 0)
      let fAddress = res[1].data.find(f => f.id === 'main')
      
      setAddress(res[1].data)

      setRow({
        p_f_req: fReq ? fReq.id : '',
        p_f_auto: fAuto ? fAuto.id : '',
        p_id_address: fAddress ? fAddress.id : ''
      })
    })
  }, [idUnit])

  const cboReq = useMemo(() => {
    let fReq = firms.find(f => parseInt(f.id) === parseInt(row.p_f_req))
    return <Select
      value={fReq ? {
        value: fReq.id,
        label: fReq.name + ' - ' + fReq.profession
      } : ''}
      options={firms.filter(f => parseInt(f.state) === 1 && f.type === 'SOLICITANTE').map(f => {
        return {
          value: f.id,
          label: f.name + ' - ' + f.profession
        }
      })}
      onChange={e => setRow({...row, p_f_req: e.value})}
      text="Solicitante"
      icon="fa fa-user"
      classNameParent="col-12 mb-2"
    />
  }, [row, firms])

  const cboAuto = useMemo(() => {
    let fAuto = firms.find(f => parseInt(f.id) === parseInt(row.p_f_auto))
    return <Select
      value={fAuto ? {
        value: fAuto.id,
        label: fAuto.name + ' - ' + fAuto.profession
      } : ''}
      options={firms.filter(f => parseInt(f.state) === 1 && f.type === 'AUTORIZADOR').map(f => {
        return {
          value: f.id,
          label: f.name + ' - ' + f.profession
        }
      })}
      text="Autorizador"
      icon="fa fa-user"
      classNameParent="col-12 mb-2"
      onChange={e => setRow({...row, p_f_auto: e.value})}
    />
  }, [row, firms])

  const cboAddress = useMemo(() => {
    let fAddress = address.find(f => f.id.toString() === row.p_id_address.toString())
    return <Select
      value={fAddress ? {
        value: fAddress.id,
        label: fAddress.description
      } : ''}
      options={address.map(f => {
        return {
          value: f.id,
          label: f.description
        }
      })}
      text="Direcciones"
      icon="fa fa-user"
      classNameParent="col-12 mb-2"
      onChange={e => setRow({...row, p_id_address: e.value})}
    />
  }, [row, address])

  const jwt = useSelector(store => store.session.jwt)

  return <FormCustom textButton="Generar PDF" onSubmit={({e}) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }

      if (row.p_f_req === '' || row.p_f_auto === '') {
        Helpers.toastr.construct({
          response: 'warning',
          message: 'Seleccionar firmas',
          toastr
        })
        return;
      }
    }

    showPdf()
    window.open(Helpers.config.api.url + '/orders/serv/pdf/' + idOrder + '/' + row.p_f_req + '/' + row.p_f_auto + '/' + row.p_id_address + '?jwt=' + jwt, '_blank')
  }}>
    {cboReq}
    {cboAuto}
    {cboAddress}
  </FormCustom>
}

const Nivel = () => {
  const nameModulo = 'Servicios Médicos'
  const namePage = 'Servicio Médico'
  const nameController = 'orders'
  
  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = useRef(Helpers.getActions({ permissions, name: 'serviciosmedicos' }))
  const [unit, setUnit] = useState([])
  const [unitSelected, setUnitSelected] = useState('')

  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [modalSize, setModalSize] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [form, setForm] = useState('');
  const [reload, setReload] = useState(true);
  const [listConsolidate, setListConsolidate] = useState([])
  const [state, setState] = useState('')

  useEffect(() => {
    if (reload) {
      Controllers[nameController]['get_orders_serv']().then(res => {
        setRows(res.data)
        let u = [{ label: 'TODAS', value: '' }]
        res.data.forEach(r => {
          if (!u.some(u => u.value === r.unit)) {
            u.push({
              label: r.unit,
              value: r.unit
            })
          }
        })
        setUnit(u)
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload])

  const handleUpdate = useCallback(e => {
    const { id = '' } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()
    
    setModalTitle(id ? <><i className="fa fa-edit mr-2"></i> Modificar {namePage}</> : <><i className="fa fa-plus mr-2"></i> Nuevo {namePage}</>);
    setModalSize('xl');
    setModalHeader('');

    Controllers[nameController]['get_' + nameController + '_id']({ p_id: id }).then(res => {
      setForm(<ServiciosMedicosForm data={res.data} onSubmit={onSubmit} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }, [])

  const onSubmit = ({ e, dataSubmit }) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }

      if (dataSubmit.p_id_unit === '') {
        Helpers.toastr.construct({ response: 'warning', message: 'No ha seleccionado centro de costo', toastr })
        return;
      }

      if (!dataSubmit.p_supplies.length) {
        Helpers.toastr.construct({ response: 'warning', message: 'Seleccionar insumos', toastr })
        return;
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers[nameController][nameController + '_insert_update'](dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        setModalShow(false);
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
      setReload(true)
    });
  }


  const viewOC = useCallback(e => {
    const { numberPurchase = '', statePurchase, idConsolidate, typeOrder } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()
    
    setModalTitle(<><i className="fas fa-shopping-cart mr-2"></i> Orden De Compra {numberPurchase}</>);
    setModalSize('xl');
    setModalHeader('');

    let prom = [
      Controllers.orders.get_orders_consolidate_id({
        p_id: idConsolidate
      })
    ]

    Promise.all(prom).then(res => {
      setForm(<PresentationForm data={res[0].data} typeOrder={typeOrder} state={parseInt(statePurchase)} disabled idConsolidate={idConsolidate} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }, [])

  const dtRows = useMemo(() => {
    const thead = [
      { name: 'Centro Costo' },
      { name: 'Número', align: 'center' },
      { name: 'Fecha', align: 'center' },
     /*  { name: 'Insumo' }, */
      { name: 'Cant. Total', align: 'center' },
      { name: 'Fecha Compra', align: 'center' },
      { name: 'Número Compra', align: 'center' },
      { name: 'Estado', align: 'center' },
      { name: 'Estado OC', align: 'center' },
      { name: 'Acc.', align: 'center' },
      { name: <i className="fa fa-check"></i>, align: 'center', sort: false },
    ];

    return <DataTable
      isProcessing={isProcessing}
      columnOrder={1}
      tbody={rows.map(r => {
        return {
          unit: r.unit,
          number: parseInt(r.number),
          date_issue: r.date_issue,
          total_qty: r.total_qty,
          date: r.date,
          number_purchase: r.number_purchase,
          state: r.state,
          state_purchase: r.state_purchase,
          id: r.id,
          id_consolidate: r.id_consolidate,
          id_unit: r.id_unit,
          number_year: r.number_year,
          type_order: r.type_order,
          year: r.year,
        }
      })}
      thead={thead}
      render={tbody => tbody.filter(r => {
        if (unitSelected === '') {
          if (state === '') {
            return r;
          } else {
            if (parseInt(r.state) === parseInt(state)) {
              return r;
            }
            return false;
          }
        } else {
          if (unitSelected === r.unit) {
            if (state === '') {
              return r;
            } else {
              if (parseInt(r.state) === parseInt(state)) {
                return r;
              }
              return false;
            }
          }
          return false
        }
      }).map(r => {
        let rowRender = [];
        let i = 0;
        for (let key in r) {
          if (key === 'id' || key === 'year' || key === 'id_consolidate' || key === 'id_unit' || key === 'type_order' || key === 'number_year') {
            continue;
          }
          
          if (key === 'number') {
            rowRender.push(<td>
              {r.number_year}
            </td>)
          } else if (key === 'state') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''} className={'text-' + (parseInt(r.state) === 0 ? 'warning' : (parseInt(r.state) === 1 ? 'info' : (parseInt(r.state) === 2 ) ? 'danger' : (parseInt(r.state) === 3 ? 'success' : 'danger')))}>
                <strong>{parseInt(r.state) === 0 ? 'PENDIENTE' : (parseInt(r.state) === 1 ? 'PENDIENTE DE CONSOLIDACIÓN' : (parseInt(r.state) === 2 ) ? 'RECHAZADO' : (parseInt(r.state) === 3 ? 'CONSOLIDADO' : 'ANULADO'))}</strong>
              </td>);
          } else if (key === 'state_purchase') {
            let color = ''
            let texto = ''
            switch (parseInt(r.state_purchase)) {
              case 0:
                color = 'warning'
                texto = 'SIN ORDEN DE COMPRA'
                break;
              case 1:
                color = 'info'
                texto = 'CON ORDEN DE COMPRA'
                break;
              case 2:
                color = 'primary'
                texto = 'COMPROMISO'
                break;
              case 3:
                color = 'dark'
                texto = 'COMPLETADO'
                break;
              case 4:
                color = 'info'
                texto = 'DEVENGADO'
                break;
              case 5:
                color = 'primary'
                texto = 'ENVIADO DAF'
                break;
              case 6:
                color = 'danger'
                texto = 'RECHAZADO'
                break;
              case 7:
                color = 'success'
                texto = 'PAGADO'
                break;
              case 8:
                color = 'danger'
                texto = 'ANULADO'
                break;
              case 9:
                color = 'primary'
                texto = 'CON DOCUMENTACIÓN';
                break;
              default:
                break;
            }
                
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
            <strong className={'text-' + color}>{texto}</strong></td>)
          }
          else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }

        rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
          {parseInt(r.state) < 4 ? <>
            {actionsModule.current.view ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.view.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.view.color} data-disabled={1} size="xs" data-number={r.number} data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
              const { id = '', number, year} = e.currentTarget.dataset;
              let button = e.currentTarget
              let buttonHTML = button.innerHTML;
              button.innerHTML = Helpers.icon.spin()
              
              setModalTitle(<><i className="fa fa-edit mr-2"></i> Detalle Pedido {number}/{year}</>);
              setModalSize('xl');
              setModalHeader('');
          
              Controllers[nameController]['get_' + nameController + '_id']({ p_id: id }).then(res => {
                setForm(<ServiciosMedicosForm data={res.data} view />);
                setModalShow(true);
              }).catch(req => {
                Helpers.promise.catch({ req, toastr });
                setModalShow(false);
              }).finally(() => button.innerHTML = buttonHTML)
            }}>
              <i className={actionsModule.current.view.icon}></i>
            </Button>
          </OverlayTrigger> :''}
          </> : ''}
          {parseInt(r.state_purchase) > 0 && parseInt(r.state) < 4 && actionsModule.current.viewpurchaseorder ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.viewpurchaseorder.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.viewpurchaseorder.color} data-type-order={r.type_order} data-state-purchase={r.state_purchase} data-id-consolidate={r.id_consolidate} data-disabled={1} data-number-purchase={r.number_purchase} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={viewOC}>
              <i className={actionsModule.current.viewpurchaseorder.icon}></i>
            </Button>
          </OverlayTrigger> :''}
          {parseInt(r.state) === 0 || parseInt(r.state) === 2 ? <>
            {actionsModule.current.update ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.update.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.update.color} size="xs" data-extension-budget={r.extension_budget} data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={handleUpdate}>
                <i className={actionsModule.current.update.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.current.sendrequest ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.sendrequest.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.sendrequest.color} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
                let button = e.currentTarget;
                button.innerHTML = Helpers.icon.spin();
                const { id } = e.currentTarget.dataset;
                Controllers.orders.orders_send_approval({
                  p_id: id
                }).then(res => {
                  Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
                }).finally(() => {
                  setReload(true)
                })
              }}>
                <i className={actionsModule.current.sendrequest.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.current.ban ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.ban.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.ban.color} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
                let button = e.currentTarget;
                button.innerHTML = Helpers.icon.spin();
                const { id } = e.currentTarget.dataset;
                Controllers.orders.orders_ban_new({
                  p_id_order: id
                }).then(res => {
                  Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
                }).finally(() => {
                  setIsProcessing(true)
                  setReload(true)
                })
              }}>
                <i className={actionsModule.current.ban.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
          </> : ''}

          {parseInt(r.state) === 1 && actionsModule.current.refuse ? <>
            <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.refuse.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.refuse.color} size="xs" data-description={r.description} data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
                const id = e.currentTarget.dataset.id
                setModalTitle('Rechazar Solicitud')
                setModalHeader('bg-danger')
                setModalSize('sm')
                setForm(<form className="form-row needs-validation" onSubmit={e => {
                  e.preventDefault();
                  let $this = e.currentTarget

                  let button = $this.querySelector('button[type=submit]');
                  let buttonHTML = button.innerHTML
                  button.innerHTML = Helpers.icon.spin();

                  if (!$this.checkValidity()) {
                    $this.classList.add('was-validated');
                  } else {
                    Controllers.orders.orders_disapprove({
                      p_id: id,
                      p_justification: document.getElementById('txt_justify_disapprove').value,
                    }).then(res => {
                      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
                    }).catch(req => {
                      setModalShow(false)
                      Helpers.promise.catch({ req, toastr });
                    }).finally(() =>{ 
                      button.innerHTML = buttonHTML
                      setReload(true)
                      setModalShow(false);
                    })
                  }
                }}>
                  <p className="col-12 text-center">Rechazar Solicitud <strong>{r.number + '/' + r.year}</strong></p>

                  <TextArea
                    text="Justificación"
                    placeholder="Ingresar..."
                    classNameParent="col-12 mb-2"
                    icon="fas fa-comment-alt"
                    required
                    autoFocus
                    id="txt_justify_disapprove"
                    rows={4}
                  />

                <div className="col-12 d-flex justify-content-center">        
                  <Button type="submit" variant="danger" className="mr-2" size="sm">
                    <i className="fa fa-check mr-2"></i>Rechazar
                  </Button>
                  <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalShow(false)}>
                    <i className="fa fa-times mr-2"></i>Cancelar
                  </Button>
                </div>
                </form>)
                setModalShow(true)
              }}>
                <i className={actionsModule.current.refuse.icon}></i>
              </Button>
            </OverlayTrigger>
          </> : ''}

          {parseInt(r.state) < 4 && actionsModule.current.printorder ?
          <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.printorder.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.printorder.color} size="xs" data-number-purchase={r.number} data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
              setModalHeader('')
              setModalSize('')
              setModalTitle('Genera PDF ' + e.currentTarget.dataset.numberPurchase)
              setForm(<PrintPDF idOrder={r.id} idUnit={e.currentTarget.dataset.idUnit} modalHide={() => setModalShow(false)} showPdf={pdf => {
                setModalHeader('')
                setModalTitle('Imprimir Documento PDF')
                setModalSize('lg')
                setModalShow(false)
              }} />)
              setModalShow(true)
            }}>
              <i className={actionsModule.current.printorder.icon}></i>
            </Button>
          </OverlayTrigger>
          : ''}
        </td>);


        rowRender.push(<td aling="center">
          {parseInt(r.state) === 1 && actionsModule.current.consolidateorder ? <CheckBox
            id={'chk-consolidate' + r.id}
            data-id={r.id}
            data-unit={r.unit} data-year={r.year}
            data-number={r.number}
            checked={listConsolidate.some(l => parseInt(r.id) === l.id)}
            onChange={e => {
              const { unit, year, number, id } = e.currentTarget.dataset
              if (e.currentTarget.checked) {
                setListConsolidate([...listConsolidate, {
                  id: parseInt(id),
                  number,
                  year,
                  unit
                }])
              } else {
                setListConsolidate(listConsolidate.filter(l => {
                  if (l.id !== parseInt(id)) {
                    return l;
                  }

                  return false;
                }))
              }
            }}
          /> : ''}
        </td>)

        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, rows, handleUpdate, listConsolidate, viewOC, actionsModule, unitSelected, state])

  const cboUnit = useMemo(() => {
    let uSelected = unit.find(u => u.value === unitSelected)
    return <Select
      classNameParent="col-12 col-md-6 mb-2"
      icon="far fa-building"
      text="Centro De Costo"
      options={unit}
      onChange={e => setUnitSelected(e.value)}
      value={uSelected}
    />
  }, [unit, unitSelected])

  const cboState = useMemo(() => {
    let lisState = [
      { label: 'TODAS', value: '' },
      { label: 'PENDIENTE', value: 0 },
      { label: 'PENDIENTE DE CONSOLIDACIÓN', value: 1 },
      { label: 'RECHAZADO', value: 2 },
      { label: 'CONSOLIDADO', value: 3 },
      { label: 'ANULADO', value: 4 },
    ]
    let sSelected = lisState.find(u => u.value === state)
    return <Select
      classNameParent="col-12 col-md-6 mb-2"
      icon="far fa-building"
      text="Estado"
      options={lisState}
      onChange={e => setState(e.value)}
      value={sSelected}
    />
  }, [state])

  return <> 
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De {nameModulo}</span>
        <div>
          <a href={Helpers.config.api.url + '/PLANTILLA_SERVICIOS_MEDICOS.xlsx'} download><Button variant="success" className="mr-1" size="sm"><i className="fa fa-file-excel"></i><span className="ml-2 d-none d-md-inline-block">Descargar Plantilla</span></Button></a>  
          {actionsModule.current.consolidateorder ? <Button onClick={e => {
            setModalTitle('Consolidar Pedidos')
            setModalHeader('bg-success')
            setModalSize('')
            setForm(<FormConsolidate listConsolidate={listConsolidate} hideModal={() => {
              setModalShow(false)
              setReload(true)
              setListConsolidate([])

            }} />)
            setModalShow(true)
          }} disabled={listConsolidate.length === 0} variant={actionsModule.current.consolidateorder.color} size="sm"><i className={actionsModule.current.consolidateorder.icon}></i><span className="ml-2 d-none d-md-inline-block">{actionsModule.current.consolidateorder.description}</span></Button> : ''}
          {actionsModule.current.create ? <Button onClick={handleUpdate} variant={actionsModule.current.create.color} size="sm" className="ml-1"><i className={actionsModule.current.create.icon}></i><span className="ml-2 d-none d-md-inline-block">{actionsModule.current.create.description} {namePage}</span></Button> : ''}
        </div>
      </Card.Header>
      <Card.Body>
        <div className="form-row">
          {cboUnit}
          {cboState}
        </div>
        {dtRows}
      </Card.Body>
    </Card>
    <Modal show={modalShow} onHide={() => setModalShow(false)} size={modalSize} backdrop="static">
      <Modal.Header closeButton className={modalHeader}>
        <Modal.Title as="div">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
  </>
}

export default Nivel;