import styled from 'styled-components';
import { primary, boxShadow } from '../Resources/Theme';

const DropDown = styled.div`
  position: relative;

  &:hover div:nth-child(2) {
    visibility: visible;
    transform: scaleY(1);
    opacity: 1;
  }
`;

DropDown.Body = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  position: absolute;
  transition: .5s;
  visibility: hidden;
  transform: scaleY(0);
  opacity: 0;
  transition: all 0.325s ease-in-out;
  transform-origin: 0 0;
  box-shadow: ${boxShadow};
  ${props => props.position === 'left' ? 'right: 0' : (props.position === 'right' ? 'left: 0' : '')};
  min-width: 200px;
  border-radius: 3px;
`;

DropDown.Item = styled.div`
  padding: 5px 10px;
  border-radius: 3px;
  display: flex;

  * {
    align-self: center;
  }
  i {
    margin-right: 1rem;
  }

  &:hover {
    background-color: ${primary};
    color: #fff;
    cursor: pointer;
  }
`;

export default DropDown;