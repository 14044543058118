import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Card from 'react-bootstrap/Card';
import Controllers from '../Api/Controllers';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import { FormCustom, Input, SwitchToggle, CheckBox, Select } from '../Components/Form/Form';
import ReactPlaceholder from 'react-placeholder';

const ChangePass = () => {
  const [row, setRow] = useState({
    password: '',
    password_2: ''
  }) 

  return <FormCustom viewSubmit={row.password !== '' ? (row.password === row.password_2) : false} onSubmit={({e}) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers.user.users_change_password({
      p_password: row.password
    }).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      setRow({
        password: '',
        password_2: ''
      })
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
    });
  }}>
    <Input
      type="password"
      text="Nueva Contraseña"
      placeholder="Nueva Contraseña"
      classNameParent="col-12 mb-2"
      icon="fas fa-shield-alt"
      required
      value={row.password}
      onChange={e => setRow({ ...row, password: e.currentTarget.value })}
    />
    <Input
      type="password"
      text="Repetir Nueva Contraseña"
      placeholder="Repetir Nueva Contraseña"
      classNameParent="col-12 mb-2"
      icon="fas fa-shield-alt"
      required
      value={row.password_2}
      onChange={e => setRow({ ...row, password_2: e.currentTarget.value })}
    />
  </FormCustom>
}

const UsersForm = ({ data, onSubmit }) => {
  const {
    id = '',
    full_name = '',
    email = '',
    phone = '',
    username = '',
    state = 0,
    id_profile = ''
  } = data

  const [user, setUser] = useState({
    id,
    full_name,
    email,
    phone,
    username,
    password: '',
    state: parseInt(state) === 1,
    id_profile
  })

  const [unit, setUnit] = useState([])
  const dataSumbit = useCallback(() => {
    let p_unit = []
    unit.forEach(u => {
      if (parseInt(u.state) === 1 || parseInt(u.exists_unit) === 1) {
        p_unit.push({
          p_id_unit: u.id,
          p_state: u.state
        })
      }
    })
    return {
      p_id: user.id,
      p_full_name: user.full_name,
      p_email: user.email,
      p_phone: user.phone,
      p_username: user.username,
      p_password: user.password,
      p_state: user.state ? 1 : 0,
      p_id_profile: user.id_profile,
      p_unit
    }
  }, [user, unit])

  const [profile, setProfile] = useState([])
  const [isOk, setIsOk] = useState(false)
  

  useEffect(() => {
    let promises = [
      Controllers.profile.get_profile(),
      Controllers.user.get_user_unit({
        p_id: id
      })
    ]
    Promise.all(promises).then(res => {
      setProfile(res[0].data)
      setUnit(res[1].data)
      setIsOk(true)
    })
  }, [id])

  const cboProfile = useMemo(() => {
    let findP = profile.find(p => parseInt(p.id) === parseInt(id_profile))
    return <Select
      defaultValue={id === '' ? '' : (findP ? { id: findP.id, label: findP.id + ' - ' + findP.description } : '')}
      text="Perfil"
      options={profile.map(p => { return { value: p.id, label: p.id + ' - ' + p.description } })}
      classNameParent="col-12 mb-2"
      required
      icon="fas fa-clipboard-list"
      onChange={e => setUser({ ...user, id_profile: e.value })}
      disabled
    />
  }, [profile, user, id_profile, id])

  return <ReactPlaceholder showLoadingAnimation rows={7}  type='text' ready={isOk}>
    <FormCustom dataSubmit={dataSumbit()} viewSubmit={false}>
      <Input
        text="Nombres"
        placeholder="Nombres"
        classNameParent="col-12 mb-2"
        icon="fas fa-user-tie"
        required
        defaultValue={full_name}
        onChange={e => setUser({ ...user, full_name: e.currentTarget.value })}
        disabled
      />
      <Input
        type="email"
        text="Email"
        placeholder="Email"
        pattern="[A-Za-z0-9._-]{1,20}@[A-Za-z0-9._-]{1,20}[.]{1}[A-Za-z0-9._-]{1,5}"
        classNameParent="col-12 mb-2"
        icon="far fa-envelope"
        required
        defaultValue={email}
        onChange={e => setUser({ ...user, email: e.currentTarget.value })}
        disabled
      />
      <Input
        text="Telefono"
        placeholder="Telefono"
        classNameParent="col-12 mb-2"
        iconText="+(502)"
        required
        defaultValue={phone}
        onChange={e => setUser({ ...user, phone: e.currentTarget.value })}
        pattern="[0-9]{8}"
        maxLength={8}
        invalid="Formato incorrecto: Ej. 12345678"
        disabled
      />
      {cboProfile}
      <Input
        text="Nombre de usuario"
        placeholder="Nombre de usuario"
        classNameParent="col-12 mb-2"
        icon="far fa-user"
        required
        defaultValue={username}
        onChange={e => setUser({ ...user, username: e.currentTarget.value })}
        disabled
      />
      <SwitchToggle
        id="switch-user"
        defaultChecked={parseInt(state) === 1}
        onChange={e => setUser({ ...user, state: e.currentTarget.checked })}
        disabled
      />
      <div className="col-12 mt-2 mb-1">Seleccionar Centro De Costo</div>
      {React.Children.toArray(unit.map((u, i) => {
        return <CheckBox
          id={'chk-' + u.id}
          text={u.id + ' - ' + u.description}
          checked={parseInt(u.state) === 1}
          onChange={e => {
            setUnit(unit.map((u2, i2) => {
              if (i === i2) {
                u2.state = e.currentTarget.checked ? 1 : 0
              }
              return u2;
            }))
          }}
          disabled
        />
      }))}
    </FormCustom>
  </ReactPlaceholder>
}

const Nivel = () => {
  const [form, setForm] = useState('');

  useEffect(() => {
    Controllers.user.get_user_information().then(res => {
      setForm(<UsersForm data={res.data} />)
    })
  }, [])


  return <div className="form-row justify-content-between"> 
    <Card className="col-7">
      <Card.Header className="justify-content-between">
        <span>Mi Información</span>
      </Card.Header>
      <Card.Body>
        {form}
      </Card.Body>
    </Card>
    <Card className="col-4 align-self-start">
      <Card.Header className="justify-content-between">
        <span>Cambiar Contraseña</span>
      </Card.Header>
      <Card.Body>
        <ChangePass />
      </Card.Body>
    </Card>
  </div>
}

export default Nivel;