import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Controllers from '../Api/Controllers';
import { FormCustom, Input, Select, TextArea } from '../Components/Form/Form';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import { useSelector } from 'react-redux';
import ReactPlaceholder from 'react-placeholder/lib';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ReQuarterForm = ({ data, onSubmit, disabled }) => {
  const yearCurrent = useSelector(store => store.session.year)

  let m = new Date().getMonth();
  let periodo = m <= 3 ? 'PRIMERO' : (m <= 7 ? 'SEGUNDO' : 'TERCERO');

  const {
    id = '',
    id_unit = '',
    year = yearCurrent,
    user_created = '',
    date_created = '',
    justification = '',
    state = 0
  } = data

  const [isModify, setIsModify] = useState(id ? true : false)
  const [isOk, setIsOk] = useState(false)
  const [unit, setUnit] = useState([])
  const [unitSelected, setUnitSelected] = useState(id ? id_unit : '')
  const [details, setDetails] = useState([])
  const [detailsLeft, setDetailsLeft] = useState([])
  const [total, setTotal] = useState(0)
  const [modalSize, setModalSize] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [nivel, setNivel] = useState([])

  const dataSumbit = useCallback(() => {
    let p_details = []
    detailsLeft.forEach(d => {
      p_details.push({
        p_id_details: d.id_details,
        p_id_nivel: d.id_nivel,
        p_justification: d.justification,
        p_newBudget: d.newBudget,
        p_type: parseFloat(d.budget) > parseFloat(d.newBudget) ? 'DOWN' : 'UP',
        p_budget: parseFloat(d.budget) > parseFloat(d.newBudget) ? (parseFloat(d.budget) - parseFloat(d.newBudget)) : (parseFloat(d.newBudget) - parseFloat(d.budget))
      })
    })

    return {
      p_id: id,
      p_id_unit: unitSelected,
      p_year: year,
      p_details
    }
  }, [detailsLeft, unitSelected,id, year])

  useEffect(() => {
    if (!isModify) {
      setDetailsLeft([])
      setDetails([])
    }
  }, [isModify])

  useEffect(() => {
    let promises = [
      Controllers.quarter_annual.get_unit_with_quarter({
        p_year: yearCurrent
      }),
      Controllers.nivel.get_nivel()
    ]

    if (id) {
      promises.push(Controllers.quarter_annual.get_quarter_unit_balance({
        p_id_unit: id_unit,
        p_year: year
      }))
      promises.push(Controllers.quarter_annual.get_quarter_extension_details({
        p_id_unit: id_unit,
        p_year: year,
        p_id: id
      }))
      promises.push(Controllers.quarter_annual.get_quarter_extension_details_consolidate({
        p_id: id
      }))
    }

    Promise.all(promises).then(res => {
      setUnit(res[0].data)

      if (res[0].data.length === 1) {
        if (id === '') {
          setUnitSelected(res[0].data[0].id)
        }
      }
      setNivel(res[1].data.filter(r => r.id !== 'VCU' && r.id !== 'VC' && r.id !== 'E' && r.id !== 'RA'))
      if (id) {
        setTotal(res[2].data.balance)
        setDetails(res[3].data)
        
        let dLeft = []
        res[4].data.forEach(dl => {
          let f = res[3].data.find(d => parseInt(d.id) === parseInt(dl.id_details))
          let currentBalance = parseInt(state === 3) ? 0 : (parseFloat(f.budget) + parseFloat(f.extension_budget) - parseFloat(f.reduction_budget) - parseFloat(f.order_reservated) - parseFloat(f.order_ejec) - parseFloat(f.budget_reservated))
          dLeft.push({
            id_group: f.id_group,
            description: f.description,
            quarter: f.quarter,
            budget: parseInt(state) === 3 ? dl.budget_start : currentBalance,
            newBudget: parseInt(state) === 3 ? dl.budget_end : (dl.type === 'UP' ? currentBalance + parseFloat(dl.budget) : currentBalance - parseFloat(dl.budget)),
            justification: dl.justification,
            id_details: f.id,
            id_nivel: dl.id_nivel
          })
        })
        setDetailsLeft(dLeft)
      }
      setIsOk(true)      
    })
  }, [year, id_unit, yearCurrent, id, state])

  return <ReactPlaceholder showLoadingAnimation rows={7}  type='text' ready={isOk}>
    {justification ? <p className="text-muted">La Reprogramación fue rechazada por el siguiente motivo: <br/><strong>{justification}</strong></p> : ''}

    <FormCustom dataSubmit={dataSumbit()} onSubmit={onSubmit} viewSubmit={!disabled}>
      {<Select
        options={unit.map(s => {
          return {
            value: s.id,
            label: s.id + ' - ' + s.description
          }
        })}
        value={unit.some(u => parseInt(u.id) === parseInt(unitSelected)) ? { value: unitSelected, label: unit.find(u => parseInt(u.id) === parseInt(unitSelected)).id + ' - ' +unit.find(u => parseInt(u.id) === parseInt(unitSelected)).description } : ''}
        onChange={e => setUnitSelected(e.value)}
        text="Centro de costo"
        icon="far fa-building"
        disabled={id_unit !== '' || isModify}
        classNameParent="col-12 col-md-8 mb-2"
        append={id ? [] : [!isModify ? <Button onClick={e => {
          if (unitSelected) {
            let promises = [
              Controllers.quarter_annual.get_quarter_unit_balance({
                p_id_unit: unitSelected,
                p_year: year
              }),
              Controllers.quarter_annual.get_quarter_extension_details({
                p_id_unit: unitSelected,
                p_year: year,
                p_id: ''
              })
            ]
            Promise.all(promises).then(res => {
              setTotal(res[0].data.balance)
              setIsModify(true)
              setDetails(res[1].data)
            })
          }
        }}><i className="fa fa-search mr-1"></i> Generar</Button> : <Button onClick={() => {
          setIsModify(false)
        }} variant="danger"><i className="fa fa-times mr-1"></i> Cancelar</Button>]}
      />}
      <Input
        defaultValue={yearCurrent}
        className="text-center"
        disabled={false}
        classNameParent="col-12 col-md-4 mb-2"
        readOnly
        text="Año PAC"
        icon="far fa-calendar"
      />

      <div className="col-12 mt-2 mb-2 d-flex justify-content-between">
        <span>Presupuesto Total: <strong>{Helpers.number.float.format({ number: parseFloat(total) })}</strong></span>
        {disabled ? '' : isModify ? <Button size="xs" className="align-self-center" onClick={() => {
          setModalSize('xl')
          setModalShow(true)
          setModalTitle('Listado De Programación')
          setModalHeader('')
        }}><i className="fa fa-plus mr-1"></i>Agregar Grupo</Button> : ''}
      </div>

      <div className="col-12 mb-2 mt-2">
        <table>
          <thead>
            <tr>
              <th>Grupo</th>
              <th>Cuatir.</th>
              <th>Pres.</th>
              <th>Tipo Modif.</th>
              <th>Nuevo Pres.</th>
              <th>Justificación</th>
            </tr>
          </thead>
          <tbody>
            {React.Children.toArray(detailsLeft.map((d, i) => {
              let nivelSelected = nivel.find(n => n.id === d.id_nivel)
              nivelSelected = {
                value: nivelSelected.id,
                label: nivelSelected.id + ' - ' + nivelSelected.description
              }

              return <tr>
                <td>{d.id_group} - {d.description}</td>
                <td align="center">{d.quarter}</td>
                <td align="center">{Helpers.number.float.format({ number: parseFloat(d.budget) })}</td>
                <td align="center">
                 <Select
                    options={nivel.map(s => {
                      return {
                        value: s.id,
                        label: s.id + ' - ' + s.description
                      }
                    })}
                    value={nivelSelected}
                    disabled
                  />
                </td>
                <td align="center">
                  <Input
                    value={d.newBudget}
                    type="number"
                    min="0"
                    step="0.01"
                    required
                    onChange={e => setDetailsLeft(detailsLeft.map((d2, i2) => {
                      if (i === i2) {
                        d2.newBudget = e.currentTarget.value
                      }
                      return d2
                    }))}
                    className="text-center"
                    disabled={disabled}
                  />
                </td>
                <td>
                  <TextArea 
                    value={d.justification}
                    required
                    onChange={e => setDetailsLeft(detailsLeft.map((d2, i2) => {
                      if (i === i2) {
                        d2.justification = e.currentTarget.value
                      }
                      return d2
                    }))}
                    disabled={disabled}
                  />
                </td>
                  <td>{!disabled ? <Button size="xs" variant="danger" onClick={() => setDetailsLeft(detailsLeft.filter((d2,i2) => i !== i2))}>
                    <i className="fa fa-minus"></i>
                </Button> : ''}</td>
              </tr>
            }))}
          </tbody>
        </table>
      </div>

    </FormCustom>
    
    
    {id === '' ? '' : <>
      <hr />
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <span className="text-muted">Usuario Creación: <strong>{user_created}</strong></span>
          <span className="text-muted">Fecha Creación: <strong>{date_created}</strong></span>
        </div>
      </div>
    </>}

    <Modal show={modalShow} onHide={() => setModalShow(false)} size={modalSize} backdrop="static">
      <Modal.Header closeButton className={modalHeader}>
        <Modal.Title as="div">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table>
          <thead>
            <tr>
              <th>Acc.</th>
              <th>Grupo</th>
              <th>Cuatri.</th>
              <th>Pres.</th>
              <th>+</th>
              <th>-</th>
              <th>Rep Res</th>
              <th>Total</th>
              <th>OC R</th>
              <th>OC E</th>
              <th>Saldo</th>
            </tr>
          </thead>
          <tbody>
            {React.Children.toArray(details.map(d => {
              let isAdd = detailsLeft.some(dl => parseInt(dl.id_group) === parseInt(d.id_group) && dl.quarter === d.quarter)

              let canAdd = true
              
              if (periodo === 'SEGUNDO') {
                if (d.quarter === 'PRIMERO') {
                  canAdd = false
                }
              } else if (periodo === 'TERCERO') {
                if (d.quarter === 'PRIMERO' || d.quarter === 'SEGUNDO') {
                  canAdd = false
                }
              }

              return <tr>
                <td>
                  {canAdd ? <Button size="xs" variant={isAdd ? 'dark' : 'primary'} onClick={() => {
                    if (!isAdd) {
                      setDetailsLeft([...detailsLeft, {
                        id_group: d.id_group,
                        description: d.description,
                        quarter: d.quarter,
                        budget: parseFloat(d.budget) + parseFloat(d.extension_budget) - parseFloat(d.reduction_budget) - parseFloat(d.order_reservated) - parseFloat(d.order_ejec) - parseFloat(d.budget_reservated),
                        newBudget: '',
                        justification: '',
                        id_details: d.id,
                        id_nivel: 'VP'
                      }])
                    }
                  }}>
                    <i className={isAdd ? 'fa fa-check' : 'fa fa-plus'}></i>
                  </Button> : ''}
                </td>
                <td>{d.id_group} - {d.description}</td>
                <td align="center">{d.quarter}</td>
                <td align="center">{d.budget}</td>
                <td align="center">{d.extension_budget}</td>
                <td align="center">{d.reduction_budget}</td>
                <td align="center">{d.budget_reservated}</td>
                <td align="center">{parseFloat(d.budget) + parseFloat(d.extension_budget) - parseFloat(d.reduction_budget) - parseFloat(d.budget_reservated)}</td>
                <td align="center">{parseFloat(d.order_reservated)}</td>
                <td align="center">{parseFloat(d.order_ejec)}</td>
                <td align="center">{parseFloat(d.balance)}</td>
              </tr>
            }))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  </ReactPlaceholder>
}

ReQuarterForm.defaultProps = {
  disabled: false
}

const ReQuarter = () => {
  const nameModulo = 'Reprogramación Cuatrimestral'
  const namePage = 'Reprogramación'
  const nameController = 'quarter_annual'

  const p_year = useSelector(store => store.session.year)
  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = Helpers.getActions({ permissions, name: 'programacioncuatrimestral' })

  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [modalSize, setModalSize] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [form, setForm] = useState('');
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      Controllers[nameController]['get_quarter_annual_extension']({p_year}).then(res => {
        setRows(res.data)
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload, p_year])

  const handleUpdate = useCallback(e => {
    const { id = '', disabled = 0 } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()
    
    setModalTitle(parseInt(disabled) === 1 ? <><i className={'mr-2 ' + actionsModule.view.icon}></i>{actionsModule.view.description} {namePage}</> : (id ? <><i className={'mr-2 ' + actionsModule.update.icon}></i>{actionsModule.update.description} {namePage}</> : <><i className={'mr-2 ' + actionsModule.create.icon}></i>{actionsModule.create.description} {namePage}</>))
    setModalSize('xl');
    setModalHeader('');

    Controllers[nameController]['get_quarter_annual_extension_id']({ p_id: id }).then(res => {
      setForm(<ReQuarterForm disabled={parseInt(disabled) === 1} data={res.data} onSubmit={onSubmit} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }, [actionsModule])

  const onSubmit = ({ e, dataSubmit }) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }

      if (dataSubmit.p_id_unit === '') {
        Helpers.toastr.construct({
          response: 'warning',
          message: 'Seleccionar centro de costo',
          toastr
        })
        return
      }

      if (!dataSubmit.p_details.length) {
        Helpers.toastr.construct({
          response: 'warning',
          message: 'Ingresar los cambios a realizar',
          toastr
        })
        return
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers[nameController]['quarter_extension_insert_update'](dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        setModalShow(false);
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
      setReload(true)
    });
  }

  const dtRows = useMemo(() => {
    const thead = [
      { name: 'Centro De Costo' },
      { name: 'Año', align: 'center' },
      { name: 'Fecha Registro', align: 'center' },
      { name: 'Nro Resolución', align: 'center' },
      { name: 'Fecha Resolución', align: 'center' },
      { name: 'Estado', align: 'center' },
      { name: 'Acc.', align: 'center' }
    ];

    return <DataTable
      isProcessing={isProcessing}
      tbody={rows}
      thead={thead}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;
        for (let key in r) {
          if (key === 'id') {
            continue
          }
          if (key === 'state') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''} className={'text-' + (parseInt(r.state) === 0 ? 'warning' : (parseInt(r.state) === 1 ? 'info' : (parseInt(r.state) === 2 ) ? 'danger' : 'success'))}>
              <strong>{parseInt(r.state) === 0 ? 'PENDIENTE' : (parseInt(r.state) === 1 ? 'PENDIENTE DE APROBACIÓN' : (parseInt(r.state) === 2 ) ? 'RECHAZADO' : 'APROBADO')}</strong>
            </td>);
          } else if (key === 'budget') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{Helpers.number.float.format({
              number: parseFloat(r[key])
            })}</td>);
          } else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }

        rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
          {actionsModule.view ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.view.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.view.color} data-disabled={1} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={handleUpdate}>
              <i className={actionsModule.view.icon}></i>
            </Button>
          </OverlayTrigger> :''}

          {parseInt(r.state) === 0 || parseInt(r.state) === 2 ? <>
            {actionsModule.update ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.update.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.update.color} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={handleUpdate}>
                <i className={actionsModule.update.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.sendrequest ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.sendrequest.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.sendrequest.color} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
                let button = e.currentTarget;
                button.innerHTML = Helpers.icon.spin();
                const { id } = e.currentTarget.dataset;
                Controllers.quarter_annual.quarter_extension_send_approve({
                  p_id: id
                }).then(res => {
                  Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
                }).finally(() => {
                  setReload(true)
                })
              }}>
                <i className={actionsModule.sendrequest.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
          </> : ''}

          {parseInt(r.state) === 1 ? <>
            {actionsModule.approve ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.approve.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.approve.color} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
                let button = e.currentTarget;
                button.innerHTML = Helpers.icon.spin();
                Controllers.quarter_annual.quarter_extension_approve({
                  p_id: r.id
                }).then(res => {
                  Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
                }).finally(() => {
                  setReload(true)
                })
              }}>
                <i className={actionsModule.approve.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.refuse ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.refuse.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.refuse.color} size="xs" data-description={r.description} data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
                setModalTitle(<><i className="fa fa-times mr-2"></i>Rechazar Programación</>)
                setModalHeader('bg-danger')
                setModalShow(true)
                setModalSize('sm')
                setForm(<form className="form-row needs-validation" onSubmit={e => {
                  e.preventDefault();
                  let $this = e.currentTarget

                  let button = $this.querySelector('button[type=submit]');
                  let buttonHTML = button.innerHTML
                  button.innerHTML = Helpers.icon.spin();

                  if (!$this.checkValidity()) {
                    $this.classList.add('was-validated');
                  } else {
                    Controllers.quarter_annual.quarter_extension_refuse({
                      p_id: r.id,
                      p_justification: document.getElementById('txt_justify_disapprove').value,
                    }).then(res => {
                      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
                      setReload(true)
                      setModalShow(false);
                    }).catch(req => {
                      setModalShow(false)
                      Helpers.promise.catch({ req, toastr });
                    }).finally(() => button.innerHTML = buttonHTML)
                  }
                }}>
                  <p className="col-12 text-center">Rechazar Programación <strong>{e.currentTarget.dataset.description}/{e.currentTarget.dataset.year}</strong></p>

                  <TextArea
                    text="Justificación"
                    placeholder="Ingresar..."
                    classNameParent="col-12 mb-2"
                    icon="fas fa-comment-alt"
                    required
                    autoFocus
                    id="txt_justify_disapprove"
                    rows={4}
                  />

                <div className="col-12 d-flex justify-content-center">        
                  <Button type="submit" variant="danger" className="mr-2" size="sm">
                    <i className="fa fa-check mr-2"></i>Rechazar
                  </Button>
                  <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalShow(false)}>
                    <i className="fa fa-times mr-2"></i>Cancelar
                  </Button>
                </div>
                </form>)
              }}>
                <i className={actionsModule.refuse.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
          </> : ''}
        </td>);
        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, rows, handleUpdate, actionsModule])

  return <>
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De {nameModulo}</span>
        <div>
        {actionsModule.create ? <Button onClick={handleUpdate} variant={actionsModule.create.color} size="sm"><i className={ actionsModule.create.icon}></i><span className="ml-2 d-none d-md-inline-block">{actionsModule.create.description} {namePage}</span></Button> : ''}
        </div>
      </Card.Header>
      <Card.Body>
        {dtRows}
      </Card.Body>
    </Card>
    <Modal show={modalShow} onHide={() => setModalShow(false)} size={modalSize} backdrop="static">
      <Modal.Header closeButton className={modalHeader}>
        <Modal.Title as="div">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
  </>
}

export default ReQuarter;