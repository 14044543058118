import React, { useState, useEffect, useMemo, useCallback } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Controllers from '../Api/Controllers';
import { Input, Select, FormCustom } from '../Components/Form/Form';
import Button from 'react-bootstrap/Button';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import PresentationForm from './PurchaseOrdersForm'

const Provider = () => {
  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [reload, setReload] = useState(true);
  let p_date_end = Helpers.date.get()
  let p_date_start = Helpers.date.get({
    addDays: -7
  })
  
  const [dates, setDates] = useState({
    p_date_start: '',
    p_date_end: ''
  })
  const [state, setState] = useState(-1)

  const [modalSize, setModalSize] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [form, setForm] = useState('');

  const [users, setUsers] = useState([])
  const [user, setUser] = useState('')
  const [filename, setFilename] = useState('')

  const optionsState = [
    { value: -1, label: 'TODAS' },
    { value: 0, label: 'SIN ORDEN DE COMPRA' },
    { value: 1, label: 'CON ORDEN DE COMPRA' },
    { value: 2, label: 'COMPROMISO' },
    { value: 3, label: 'COMPLETADO' },
    { value: 4, label: 'DEVENGADO' },
    { value: 5, label: 'ENVIADO DAF' },
    { value: 6, label: 'RECHAZADO' },
    { value: 7, label: 'PAGADO' },
    { value: 8, label: 'ANULADO' },
    { value: 9, label: 'CON DOCUMENTACIÓN' },
  ]

  useEffect(() => {
    if (reload) {
      setIsProcessing(true)
      Controllers.orders.get_reporte_general_oc(dates).then(res => {
        setRows(res.data.data)
        setFilename({
          file: res.data.file,
          name: res.data.filename
        })

        let u = [{
          value: '', label: 'TODOS'
        }]
        res.data.data.forEach(r => {
          if (!u.some(us => us.value.toLowerCase() === r.user_created.toLowerCase())) {
            u.push({
              value: r.user_created.toLowerCase(),
              label: r.user_created.toLowerCase()
            })
          }
        })
        setUsers(u)
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload, dates])

  const viewOC = useCallback(e => {
    const { numberPurchase = '', statePurchase, idConsolidate, typeOrder } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()
    
    setModalTitle(<><i className="fas fa-shopping-cart mr-2"></i> Orden De Compra {numberPurchase}</>);
    setModalSize('xl');
    setModalHeader('');

    let prom = [
      Controllers.orders.get_orders_consolidate_id({
        p_id: idConsolidate
      })
    ]

    Promise.all(prom).then(res => {
      setForm(<PresentationForm data={res[0].data} typeOrder={typeOrder} state={parseInt(statePurchase)} disabled idConsolidate={idConsolidate} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }, [])


  const dtRows = useMemo(() => {
    const thead = [
      { name: 'Fecha Compra', align: 'center' },
      { name: 'Nro OC' }
      
    ];
    thead.push({ name: 'Fecha Compromiso' })
    thead.push({ name: 'Nro Compromiso', align: 'center' })
    thead.push({ name: 'Nro Devengado', align: 'center' })
    thead.push({ name: 'Proveedor', align: 'center' })
    thead.push({ name: 'Insumo', align: 'center' })
    thead.push({ name: 'Estado', align: 'center' })
    thead.push({ name: 'Total', align: 'center' })
    thead.push({ name: 'Usuario', align: 'center' })
    thead.push({ name: 'Acc.', align: 'center' })

    return <DataTable
      isProcessing={isProcessing}
      tbody={rows.map(r => {
        return {
          date_issue: r.date_issue,
          number_purchase: parseInt(r.number_purchase),
          date_compromise: r.date_compromise,
          number_compromise: !isNaN(parseInt(r.number_compromise)) ? parseInt(r.number_compromise) : r.number_compromise,
          number_devengade: !isNaN(parseInt(r.number_devengade)) ? parseInt(r.number_devengade) : r.number_devengade,
          provider: r.provider,
          supplie: r.supplie,
          state: parseInt(r.state),
          total: parseFloat(r.total),
          user_created: r.user_created,
          id_consolidate: r.id_consolidate,
          type_order: r.type_order
        }
      }).filter(r => {
        if (state === -1)
          return r

        return parseInt(r.state) === state
      }).filter(r => {
        if (user === '') {
          return r
        }

        return r.user_created.toLowerCase() === user.toLowerCase();
      })}
      thead={thead}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;

        for (let key in r) {
          if (key === 'id' || key === 'id_consolidate' || key === 'type_order') {
            continue;
          }

          let color = ''
          let texto = ''
          switch (parseInt(r.state)) {
            case 0:
              color = 'warning'
              texto = 'SIN ORDEN DE COMPRA'
              break;
            case 1:
              color = 'info'
              texto = 'CON ORDEN DE COMPRA'
              break;
            case 2:
              color = 'primary'
              texto = 'COMPROMISO'
              break;
            case 3:
              color = 'dark'
              texto = 'COMPLETADO'
              break;
            case 4:
              color = 'info'
              texto = 'DEVENGADO'
              break;
            case 5:
              color = 'primary'
              texto = 'ENVIADO DAF'
              break;
            case 6:
              color = 'danger'
              texto = 'RECHAZADO'
              break;
            case 7:
              color = 'success'
              texto = 'PAGADO'
              break;
            case 8:
              color = 'danger'
              texto = 'ANULADO'
              break;
            case 9:
              color = 'primary'
              texto = 'CON DOCUMENTACIÓN';
              break;
            default:
              break;
          }

          if (key === 'state') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
            <strong className={'text-' + color}>{texto}</strong>
          </td>);
          }
          else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }
        rowRender.push(<td>
          <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              Ver Orden De Compra
            </Tooltip>}
          >
            <Button variant="success" data-type-order={r.type_order} data-state-purchase={r.state} data-id-consolidate={r.id_consolidate} data-disabled={1} data-number-purchase={r.number_purchase} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={viewOC}>
              <i className="fas fa-shopping-cart"></i>
            </Button>
          </OverlayTrigger>
        </td>)

        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, rows, state, viewOC, user])

  return <> 
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De Ordenes De Compra</span>
      </Card.Header>
      <Card.Body >
        
        <div className="d-flex justify-content-between mb-2 form-row">
          <div className="col-12 mb-2">
            {filename ? <a href={filename.file} download={filename.name}><Button variant="success" size="sm" className="align-self-center">
              <i className="fa fa-file-excel mr-2"></i> Descargar Reporte
            </Button></a> : ''}
          </div>
          <FormCustom className="form-row col-12" viewSubmit={false} onSubmit={({e}) => {
            let $this = e.currentTarget

            let propsValidateForm = { form: $this, toastr };
            if (!$this.checkValidity()) {
              $this.classList.add('was-validated');
              Helpers.form.isFormCorrect(propsValidateForm);
              return;
            } else {
              $this.classList.remove('was-validated');
              if (!Helpers.form.isFormCorrect(propsValidateForm)) {
                return;
              }
            }
            setDates({
              p_date_start: document.getElementById('p_date_start').value,
              p_date_end: document.getElementById('p_date_end').value
            })
            setReload(true)
          }}>
            <Input
              type="date"
              defaultValue={p_date_start}
              icon="fa fa-calendar"
              text="Fecha Inicio"
              classNameParent="col-12 col-md-6"
              required
              id="p_date_start"
            />
            <Input
              type="date"
              defaultValue={p_date_end}
              value=""
              icon="fa fa-calendar"
              text="Fecha Fin"
              classNameParent="col-12 col-md-6"
              required
              id="p_date_end"
              append={[<Button type="submit"><i className="fa fa-search"></i></Button>]}
            />
          </FormCustom>
          <Select
            defaultValue={optionsState.find(o => o.value === state)}
            classNameParent="col-12 col-md-6"
            icon="fa fa-list"
            text="Estado"
            options={optionsState}
            onChange={e => setState(e.value)}
          />
          <Select
            classNameParent="col-12 col-md-6"
            icon="fa fa-list"
            text="Usuario"
            options={users}
            onChange={e => setUser(e.value)}
          />
        </div>
        
        {dtRows}
      </Card.Body>
    </Card>
    <Modal show={modalShow} onHide={() => setModalShow(false)} size={modalSize} backdrop="static">
      <Modal.Header closeButton className={modalHeader}>
        <Modal.Title as="div">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
  </>
}

Provider.defaultProps = {
  isPublic: false,
  added: [],
  onAdd: () => {}
}

export default Provider;