import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input } from '../../Components/Form/Form';

const SubProductsListAdd = ({ added, callbackAdd }) => {
    const [listSubProducts, setSubListProducts] = useState([])
    const [ready, setReady] = useState(false)

    useEffect(() => {
        Controllers.sub_products.get_sub_products_public().then(res => {
            res.data = res.data.map(s => {
                let find = added.find(ss => ss.id === s.id)

                s.qty = ''
                if (find) {
                    s.qty = find.qty
                }

                
                return s
            })
            setSubListProducts(res.data)
            setReady(true)
        })
    }, [added])

    const addSubProduct = _ => {
        callbackAdd({ items: listSubProducts.filter(s => !isNaN(parseInt(s.qty))) })
    }

    return <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
        <div className="table table-responsive">
            <table className="table table-sm table-hover table-striped table-bordered">
                <thead>
                    <tr>
                        <th width="40%">SubProducto</th>
                        <th width="40%">Cantidad</th>
                    </tr>
                </thead>
                <tbody>
                    {React.Children.toArray(listSubProducts.map((s, i) => {
                        return <tr>
                            <td align="center">{s.id} - {s.description_short}</td>
                            <td>
                                <Input
                                    className="text-center"
                                    type="number"
                                    min="1"
                                    step="1"
                                    required
                                    value={s.qty}
                                    onChange={k => setSubListProducts(listSubProducts.map((ss, ii) => {
                                        if (ii === i) {
                                            ss.qty = k.currentTarget.value
                                        }
                                        return ss
                                    }))}
                                    onKeyUp={k => {
                                        if (k.keyCode === 13) {
                                            addSubProduct()
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                    }))}
                </tbody>
            </table>

            <Button block size="sm" onClick={_ => addSubProduct()}>
                <i className="fa fa-check mr-2"></i>Agregar Subproductos
        </Button>
        </div>
    </ReactPlaceholder>
}

SubProductsListAdd.defaultProps = {
    added: [],
    callbackAdd: _ => {}
}

export default SubProductsListAdd;