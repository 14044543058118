import React from 'react';
import { Link } from 'react-router-dom';
import Helpers from '../../Helpers/Helpers';
import Card from 'react-bootstrap/Card';

const NotFound = () => {
  return <Card className="d-flex flex-column justify-content-center align-items-center p-5">
    <img src={Helpers.config.api.images + '/dashboard/not-found.png'} alt="Página No Existe" style={{
      maxHeight: '200px'
    }} />

    <h3 className="text-primary font-weight-bold mt-2">No Existe La Página</h3>
    <Link to={Helpers.config.folder_path + '/'} className="btn btn-outline-primary">
      <i className="fa fa-arrow-left mr-2"></i>Regresar
    </Link>
  </Card>
}

export default NotFound;