import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Controllers from '../Api/Controllers';
import { FormCustom, Input, TextArea, CheckBox } from '../Components/Form/Form';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import { useSelector } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PresentationForm from './PurchaseOrdersForm'

const Compromiso = ({ idConsolidate, modalHide }) => {
  const [row, setRow] = useState({
    number_compromise: '',
    date_compromise: Helpers.date.get(),
    date_send_provider: Helpers.date.get()
  })

  const dataSumbit = useCallback(() => {
    return {
      p_id: idConsolidate,
      p_number_compromise: row.number_compromise,
      p_date_compromise: row.date_compromise,
      p_date_send_provider: row.date_send_provider,
    }
  }, [row, idConsolidate])

  return <FormCustom dataSubmit={dataSumbit()} onSubmit={({e, dataSubmit}) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers.orders.orders_compromise(dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        modalHide()
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
    });
  }}>
    <Input
      type="number"
      text="Número Compromiso"
      placeholder="Número Compromiso"
      icon="fas fa-code"
      classNameParent="col-12 mb-2"
      required
      onChange={e => setRow({...row, number_compromise: e.currentTarget.value})}
    />
    <Input
      type="date"
      text="Fecha Compromiso"
      placeholder="Fecha Compromiso"
      icon="far fa-calendar"
      classNameParent="col-12 mb-2"
      required
      onChange={e => setRow({...row, date_compromise: e.currentTarget.value})}
      value={row.date_compromise}
    />
    <Input
      type="date"
      text="Fecha Envío Proveedor"
      placeholder="Fecha Envío Proveedor"
      icon="far fa-calendar"
      classNameParent="col-12 mb-2"
      required
      onChange={e => setRow({...row, date_send_provider: e.currentTarget.value})}
      value={row.date_send_provider}
    />
  </FormCustom>
}

const Devengado = ({ idConsolidate, modalHide }) => {
  const [row, setRow] = useState({
    number_devengade: '',
    date_devengade: Helpers.date.get()
  })

  const dataSumbit = useCallback(() => {
    return {
      p_id: idConsolidate,
      p_number_devengade: row.number_devengade,
      p_date_devengade: row.date_devengade
    }
  }, [row, idConsolidate])

  return <FormCustom dataSubmit={dataSumbit()} onSubmit={({e, dataSubmit}) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers.orders.orders_devengade(dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        modalHide()
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
    });
  }}>
    <Input
      type="number"
      text="Número Devengado"
      placeholder="Número Devengado"
      icon="fas fa-code"
      classNameParent="col-12 mb-2"
      required
      onChange={e => setRow({...row, number_devengade: e.currentTarget.value})}
    />
    <Input
      type="date"
      text="Fecha Devengado"
      placeholder="Fecha Devengado"
      icon="fas fa-code"
      classNameParent="col-12 mb-2"
      required
      onChange={e => setRow({...row, date_devengade: e.currentTarget.value})}
      value={row.date_devengade}
    />
  </FormCustom>
}

const EnviadoDAF = ({ idConsolidate, modalHide, isReenvied }) => {
  const [row, setRow] = useState({
    number_table: '',
    date_send_daf: Helpers.date.get()
  })

  const dataSumbit = useCallback(() => {
    return {
      p_id: idConsolidate,
      p_number_table: isReenvied ? '' : row.number_table,
      p_date_send_daf: row.date_send_daf
    }
  }, [row, idConsolidate, isReenvied])

  return <FormCustom dataSubmit={dataSumbit()} onSubmit={({e, dataSubmit}) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers.orders.orders_send_daf(dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        modalHide()
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
    });
  }}>
    {isReenvied ? '' : <Input
      type="number"
      text="Número Mesa Entrada"
      placeholder="Número Mesa Entrada"
      icon="fas fa-code"
      classNameParent="col-12 mb-2"
      required
      onChange={e => setRow({...row, number_table: e.currentTarget.value})}
    />}
    <Input
      type="date"
      text="Fecha Envío"
      placeholder="Fecha Envío"
      icon="far fa-calendar"
      classNameParent="col-12 mb-2"
      required
      onChange={e => setRow({...row, date_send_daf: e.currentTarget.value})}
      value={row.date_send_daf}
    />
  </FormCustom>
}

const Rechazado = ({ idConsolidate, modalHide }) => {
  const [row, setRow] = useState({
    date_rejection: Helpers.date.get(),
    justification_rejection:''
  })

  const dataSumbit = useCallback(() => {
    return {
      p_id: idConsolidate,
      p_date_rejection: row.date_rejection,
      p_justification_rejection: row.justification_rejection
    }
  }, [row, idConsolidate])

  return <FormCustom dataSubmit={dataSumbit()} onSubmit={({e, dataSubmit}) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers.orders.orders_rejection(dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        modalHide()
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
    });
  }}>
    <Input
      type="date"
      text="Fecha Rechazo"
      placeholder="Fecha Rechazo"
      icon="fas fa-code"
      classNameParent="col-12 mb-2"
      required
      onChange={e => setRow({...row, date_rejection: e.currentTarget.value})}
      value={row.date_rejection}
    />
    <TextArea
      text="Justificación"
      placeholder="Justificación"
      icon="far fa-calendar"
      classNameParent="col-12 mb-2"
      rows="5"
      required
      onChange={e => setRow({...row, justification_rejection: e.currentTarget.value})}
    />
  </FormCustom>
}

const Pagado = ({ idConsolidate, modalHide, numberPurchase }) => {
  const [row, setRow] = useState({
    date_pay: Helpers.date.get(),
    number_box: ''
  })

  const dataSumbit = useCallback(() => {
    let f = new FormData()
    f.append('p_id', idConsolidate)
    f.append('p_date_pay', row.date_pay)
    f.append('p_number', numberPurchase)
    f.append('p_number_box', row.number_box)
    f.append('p_pdf_document', row.pdf_document)
    return f
  }, [row, idConsolidate, numberPurchase])

  return <FormCustom dataSubmit={dataSumbit()} onSubmit={({e, dataSubmit}) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers.orders.orders_pay(dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        modalHide()
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
    });
  }}>
    <Input
      type="date"
      text="Fecha Pago"
      placeholder="Fecha Pago"
      icon="fas fa-code"
      classNameParent="col-12 mb-2"
      required
      onChange={e => setRow({...row, date_pay: e.currentTarget.value})}
      value={row.date_pay}
    />
    <Input
      text="Número Caja"
      placeholder="Número Caja"
      icon="fas fa-code"
      classNameParent="col-12 mb-2"
      required
      maxLength={20}
      onChange={e => setRow({...row, number_box: e.currentTarget.value})}
      value={row.number_box}
    />
    <div className="col-12 mb-2">
      <Form.File id="formcheck-api-regular">
        <Form.File.Label>Selecionar PDF</Form.File.Label>
        <Form.File.Input required accept="application/pdf" onChange={e => {
          setRow({ ...row, pdf_document: e.currentTarget.files.length > 0 ? e.currentTarget.files[0] : '' })
        }} />
      </Form.File>
    </div>
  </FormCustom>
}

const Anulado = ({ idConsolidate, modalHide }) => {
  const [row, setRow] = useState({
    date_ban: Helpers.date.get(),
    justification_ban: ''
  })

  const dataSumbit = useCallback(() => {
    return {
      p_id: idConsolidate,
      p_date_ban: row.date_ban,
      p_justification_ban: row.justification_ban
    }
  }, [row, idConsolidate])

  return <FormCustom dataSubmit={dataSumbit()} onSubmit={({e, dataSubmit}) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers.orders.orders_ban(dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        modalHide()
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
    });
  }}>
    <Input
      type="date"
      text="Fecha"
      placeholder="Fecha"
      icon="fas fa-code"
      classNameParent="col-12 mb-2"
      required
      onChange={e => setRow({...row, date_ban: e.currentTarget.value})}
      value={row.date_ban}
    />
    <TextArea
      text="Justificación"
      placeholder="Justificación"
      icon="fas fa-code"
      classNameParent="col-12 mb-2"
      required
      onChange={e => setRow({...row, justification_ban: e.currentTarget.value})}
    />
  </FormCustom>
}



const Presentation = () => {
  const currentYear = useSelector(store => store.session.year)

  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [modalSize, setModalSize] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [form, setForm] = useState('');
  const [reload, setReload] = useState(true);
  const [state, setState] = useState(0)
  const [listGenerate, setListGenerate] = useState([])

  const jwt = useSelector(store => store.session.jwt)

  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = useRef(Helpers.getActions({ permissions, name: 'ordenescompra' }))

  const [states, setStates] = useState([
    { id: 0, icon: 'fa fa-boxes mr-1', text: 'Consolidados', qty: 0},
    { id: 1, icon: 'fas fa-shopping-cart mr-1', text: 'Con Orden De Compra', qty: 0},
    { id: 2, icon: 'fas fa-certificate mr-1', text: 'Compromiso', qty: 0},
    { id: 3, icon: 'fa fa-check mr-1', text: 'Completado', qty: 0},
    { id: 4, icon: 'far fa-handshake mr-1', text: 'Devengado', qty: 0},
    { id: 5, icon: 'far fa-paper-plane mr-1', text: 'Enviado DAF', qty: 0},
    { id: 6, icon: 'fa fa-times mr-1', text: 'Rechazado', qty: 0},
    { id: 7, icon: 'fa fa-dollar-sign mr-1', text: 'Pagado', qty: 0},
    { id: 8, icon: 'fa fa-ban mr-1', text: 'Anulado', qty: 0},
  ])

  useEffect(() => {
    if (reload) {
      Controllers.orders.get_orders_consolidate({
        p_year: currentYear
      }).then(res => {
        setRows(res.data)
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload, currentYear])

  const handleUpdate = useCallback(e => {
    const { number = '', id, state, typeOrder, disabled = 0 } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()
    
    setModalTitle(<><i className="fa fa-plus mr-2"></i> Generar Orden De Compra {number}</>);
    setModalSize('xl');
    setModalHeader('');

    let prom = [
    ]
    if (parseInt(state) !== 0) {
      prom.push(Controllers.orders.get_orders_consolidate_id({
        p_id: id
      }))
    }

    Promise.all(prom).then(res => {
      setForm(<PresentationForm typeOrder={typeOrder} disabled={parseInt(disabled) === 1} data={parseInt(state) !== 0 ? res[0].data : {} } state={parseInt(state)} idConsolidate={id} onSubmit={onSubmit} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }, [])

  const onSubmit = ({ e, dataSubmit }) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }

      if (dataSubmit.p_quarter === '' || dataSubmit.p_id_type_purchase === '' || dataSubmit.p_id_provider === '') {
        Helpers.toastr.construct({
          message: 'Por favor, ingresar los campos obligatorios',
          response: 'warning',
          toastr
        })
        return
      }

      let hasRecibo = dataSubmit.p_unit.some(u => u.p_number_invoice)
      if (hasRecibo) {
        if (!dataSubmit.p_invoices.length) {
          Helpers.toastr.construct({
            message: 'Existe una unidad con recibo. Por favor, ingrese la factura',
            response: 'warning',
            toastr
          })
          return
        }
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers.orders.purchase_orders_update(dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        setModalShow(false);
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
      setReload(true)
    });
  }

  useEffect(() => {
    setListGenerate([])
  }, [rows])

  const dtRows = useMemo(() => {
    const thead = [
      { name: 'Nro Consolidación', align: 'center' },
      { name: 'Proveedor' },
      { name: 'Cuatri.', align: 'center' },
      { name: 'Nro Orden', align: 'center' },
      { name: 'Fecha Orden', align: 'center' },
      { name: 'Insumo' },
      { name: 'Cantidad', align: 'center' },
      { name: 'Total', align: 'center' },
      { name: 'Tipo Compra', align: 'center' },
      { name: 'Estado', align: 'center' },
      { name: 'Acc.', align: 'center', sort: false },
      { name: <i className="fa fa-check"></i>, align: 'center', sort: false }
    ];

    return <DataTable
    columnOrder={1}
      isProcessing={isProcessing}
      tbody={rows.map(r => {
        return {
          number_resolution: !isNaN(parseInt(r.number_resolution)) ? parseInt(r.number_resolution) : r.number_resolution,
          provider: r.provider,
          quarter: r.quarter,
          number_purchase: !isNaN(parseInt(r.number_purchase)) ? parseInt(r.number_purchase) : r.number_purchase,
          date_issue: r.date_issue,
          supplie: r.supplie,
          qty: !isNaN(parseInt(r.qty)) ? parseInt(r.qty) : r.qty,
          total: !isNaN(parseFloat(r.total)) ? parseFloat(r.total) : r.total,
          type_purchase: r.type_purchase,
          state: r.state,
          type_order: r.type_order,
          id: r.id        
        }
      }).filter(r => {
        if (parseInt(r.state) === state) {
          return r;
        }
        return false;
      })}
      thead={thead}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;
        for (let key in r) {
          if (key === 'id' || key === 'type_order') {
            continue
          }
          let color = ''
          let texto = ''
          switch (parseInt(r.state)) {
            case 0:
              color = 'warning'
              texto = 'SIN ORDEN DE COMPRA'
              break;
            case 1:
              color = 'info'
              texto = 'CON ORDEN DE COMPRA'
              break;
            case 2:
              color = 'primary'
              texto = 'COMPROMISO'
              break;
            case 3:
              color = 'dark'
              texto = 'COMPLETADO'
              break;
            case 4:
              color = 'info'
              texto = 'DEVENGADO'
              break;
            case 5:
              color = 'primary'
              texto = 'ENVIADO DAF'
              break;
            case 6:
              color = 'danger'
              texto = 'RECHAZADO'
              break;
            case 7:
              color = 'success'
              texto = 'PAGADO'
              break;
            case 8:
              color = 'danger'
              texto = 'ANULADO'
              break;
            case 9:
              color = 'primary'
              texto = 'CON DOCUMENTACIÓN'
              break;
            default:
              break;
          }
          

          if (key === 'state') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
            <strong className={'text-' + color}>{texto}</strong>
          </td>);
          } else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }

        rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
          {parseInt(r.state) > 0 && actionsModule.current.viewpurchaseorder ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.viewpurchaseorder.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.viewpurchaseorder.color} data-disabled={1} data-type-order={r.type_order} size="xs" data-number={r.number_purchase} className="mr-1" data-id={r.id} data-state={r.state} onClick={handleUpdate}>
              <i className={actionsModule.current.viewpurchaseorder.icon}></i>
            </Button>
          </OverlayTrigger> :''}


          {parseInt(r.state) === 0 && actionsModule.current.purchaseorder ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.purchaseorder.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.purchaseorder.color} data-type-order={r.type_order} size="xs" data-number={r.number_purchase} className="mr-1" data-id={r.id} data-state={r.state} onClick={handleUpdate}>
              <i className={actionsModule.current.purchaseorder.icon}></i>
            </Button>
          </OverlayTrigger> : ''}
          {parseInt(r.state) === 0 && actionsModule.current.freeorder ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.freeorder.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.freeorder.color} data-type-order={r.type_order} size="xs" data-number={r.number_purchase} className="mr-1" data-id={r.id} data-state={r.state} onClick={e => {
              const { id = '', number } = e.currentTarget.dataset;
              setModalTitle(<><i className="fa fa-trash mr-2"></i> Liberar Pedidos</>);
              setModalHeader('bg-danger');
              setModalSize('sm');
              setForm(<div className="d-flex flex-column align-items-center">
                <p><strong>¿Libear Pedidos #{number}?</strong></p>
          
                <div>        
                  <Button variant="danger" className="mr-2" size="sm" onClick={e => {
                    let button = e.currentTarget
                    let buttonHTML = button.innerHTML;
                    button.innerHTML = Helpers.icon.spin()
          
                    Controllers.orders.orders_free({
                      p_id: id
                    }).then(res => {
                      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
                    }).catch(req => {
                      Helpers.promise.catch({ req, toastr });
                    }).finally(() => {
                      button.innerHTML = buttonHTML;
                      setReload(true)
                      setModalShow(false);
                    });
                  }}>
                    <i className="fa fa-check mr-2"></i>Si
                  </Button>
                  <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalShow(false)}>
                    <i className="fa fa-times mr-2"></i>No
                  </Button>
                </div>
              </div>);
              setModalShow(true)
            }}>
              <i className={actionsModule.current.freeorder.icon}></i>
            </Button>
          </OverlayTrigger> : ''}
          {(parseInt(r.state) === 1 || parseInt(r.state) === 2 || parseInt(r.state) === 9 || parseInt(r.state) === 3 || parseInt(r.state) === 4) && actionsModule.current.update ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.update.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.update.color} data-type-order={r.type_order} size="xs" data-number={r.number_purchase} className="mr-1" data-id={r.id} data-state={r.state} onClick={handleUpdate}>
              <i className={actionsModule.current.update.icon}></i>
            </Button>
          </OverlayTrigger> : ''}
          
          {parseInt(r.state) === 1 && actionsModule.current.commitment ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.commitment.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.commitment.color} data-type-order={r.type_order} size="xs" data-number={r.number_purchase} className="mr-1" data-id={r.id} data-state={r.state} onClick={() => {
              setModalHeader('bg-success')
              setModalSize('sm')
              setModalTitle('Generar Compromiso De Orden De Compra ' + r.number_purchase)
              setForm(<Compromiso idConsolidate={r.id} modalHide={() => {
                setReload(true)
                setModalShow(false)
              }} />)
              setModalShow(true)
            }}>
              <i className={actionsModule.current.commitment.icon}></i>
            </Button>
          </OverlayTrigger> : ''}

          {parseInt(r.state) === 3 && actionsModule.current.accrued ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.accrued.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.accrued.color} data-type-order={r.type_order} size="xs" data-number={r.number_purchase} className="mr-1" data-id={r.id} data-state={r.state} onClick={() => {
              setModalHeader('bg-success')
              setModalSize('sm')
              setModalTitle('Generar Devengado De Orden De Compra ' + r.number_purchase)
              setForm(<Devengado idConsolidate={r.id} modalHide={() => {
                setReload(true)
                setModalShow(false)
              }} />)
              setModalShow(true)
            }}>
              <i className={actionsModule.current.accrued.icon}></i>
            </Button>
          </OverlayTrigger> : ''}

          {(parseInt(r.state) === 4 || parseInt(r.state) === 6) && actionsModule.current.senddaf ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.senddaf.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.senddaf.color} data-type-order={r.type_order} size="xs" data-number={r.number_purchase} className="mr-1" data-id={r.id} data-state={r.state} onClick={() => {
              setModalHeader('bg-success')
              setModalSize('sm')
              setModalTitle('Generar Enviado DAF De Orden De Compra ' + r.number_purchase)
              setForm(<EnviadoDAF idConsolidate={r.id} isReenvied={parseInt(r.state) === 6} modalHide={() => {
                setReload(true)
                setModalShow(false)
              }} />)
              setModalShow(true)
            }}>
              <i className={actionsModule.current.senddaf.icon}></i>
            </Button>
          </OverlayTrigger> : ''}

          {parseInt(r.state) === 5 && actionsModule.current.refuse ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.refuse.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.refuse.color} data-type-order={r.type_order} size="xs" data-number={r.number_purchase} className="mr-1" data-id={r.id} data-state={r.state} onClick={() => {
              setModalHeader('bg-danger')
              setModalSize('sm')
              setModalTitle('Rechazar De Orden De Compra ' + r.number_purchase)
              setForm(<Rechazado idConsolidate={r.id} modalHide={() => {
                setReload(true)
                setModalShow(false)
              }} />)
              setModalShow(true)
            }}>
              <i className={actionsModule.current.refuse.icon}></i>
            </Button>
          </OverlayTrigger> : ''}

          {parseInt(r.state) === 5 && actionsModule.current.pay ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.pay.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.pay.color} data-type-order={r.type_order} size="xs" data-number={r.number_purchase} className="mr-1" data-id={r.id} data-state={r.state} onClick={() => {
              setModalHeader('bg-success')
              setModalSize('lg')
              setModalTitle('Pago De Orden De Compra ' + r.number_purchase)
              setForm(<Pagado numberPurchase={r.number_purchase} idConsolidate={r.id} modalHide={() => {
                setReload(true)
                setModalShow(false)
              }} />)
              setModalShow(true)
            }}>
              <i className={actionsModule.current.pay.icon}></i>
            </Button>
          </OverlayTrigger> : ''}
          
          {parseInt(r.state) > 1 && parseInt(r.state) !== 7 && parseInt(r.state) !== 8 && actionsModule.current.ban ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.ban.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.ban.color} data-type-order={r.type_order} size="xs" data-number={r.number_purchase} className="mr-1" data-id={r.id} data-state={r.state} onClick={() => {
              setModalHeader('bg-danger')
              setModalSize('sm')
              setModalTitle('Anular Orden De Compra ' + r.number_purchase)
              setForm(<Anulado idConsolidate={r.id} modalHide={() => {
                setReload(true)
                setModalShow(false)
              }} />)
              setModalShow(true)
            }}>
              <i className={actionsModule.current.ban.icon}></i>
            </Button>
          </OverlayTrigger> : ''}

          {parseInt(r.state) === 7 && actionsModule.current.print ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.print.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.print.color} data-type-order={r.type_order} size="xs" data-number={r.number_purchase} className="mr-1" data-id={r.id} data-state={r.state} onClick={() => {
               window.open(Helpers.config.api.url + '/orders/consolidate/get/pdf/' + r.id + '?jwt=' + jwt, '_blank')
            }}>
              <i className={actionsModule.current.print.icon}></i>
            </Button>
          </OverlayTrigger> : ''}
        </td>);

        let showCheck = true

        if (listGenerate.length) {
          showCheck = listGenerate.some(l => l.provider === r.provider)
        }

        rowRender.push(<td>
          {parseInt(r.state) !== 0 && parseInt(r.state) !== 8 && showCheck ? <CheckBox
            id={'chk-generate-' + r.id}
            onChange={e => {
              if (e.target.checked) {
                setListGenerate([
                  ...listGenerate,
                  {
                    id: r.id,
                    provider: r.provider
                  }
                ])
              } else {
                setListGenerate(listGenerate.filter(l => parseInt(l.id) !== parseInt(r.id)))
              }
            }}
            checked={listGenerate.some(l => parseInt(r.id) === parseInt(l.id))}
          /> : ''}
        </td>)

        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, rows, handleUpdate, state, jwt, listGenerate])

  const changeList = e => {
    setState(parseInt(e.currentTarget.dataset.id))
  }

  useEffect(() => {
    let qtys = {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
    }
    rows.forEach(r => {
      switch (parseInt(r.state)) {
        case 0:
          qtys[0] = qtys[0] + 1
          break;
        case 1:
          qtys[1] = qtys[1] + 1
          break;
        case 2:
          qtys[2] = qtys[2] + 1
          break;
        case 3:
          qtys[3] = qtys[3] + 1
          break;
        case 4:
          qtys[4] = qtys[4] + 1
          break;
        case 5:
          qtys[5] = qtys[5] + 1
          break;
        case 6:
          qtys[6] = qtys[6] + 1
          break;
        case 7:
          qtys[7] = qtys[7] + 1
          break;
        case 8:
          qtys[8] = qtys[8] + 1
          break;
        case 9:
          qtys[9] = qtys[9] + 1
          break;
        default:
          break;
      }
    })
    setStates([
      { id: 0, icon: 'fa fa-boxes mr-1', text: 'Consolidados', qty: qtys[0]},
      { id: 1, icon: 'fas fa-shopping-cart mr-1', text: 'Con Orden De Compra', qty: qtys[1]},
      { id: 2, icon: 'fas fa-certificate mr-1', text: 'Compromiso', qty: qtys[2]},
      { id: 9, icon: 'fas fa-certificate mr-1', text: 'Con Documentación', qty: qtys[9]},
      { id: 3, icon: 'fa fa-check mr-1', text: 'Completado', qty: qtys[3]},
      { id: 4, icon: 'far fa-handshake mr-1', text: 'Devengado', qty: qtys[4]},
      { id: 5, icon: 'far fa-paper-plane mr-1', text: 'Enviado DAF', qty: qtys[5]},
      { id: 6, icon: 'fa fa-times mr-1', text: 'Rechazado', qty: qtys[6]},
      { id: 7, icon: 'fa fa-dollar-sign mr-1', text: 'Pagado', qty: qtys[7]},
      { id: 8, icon: 'fa fa-ban mr-1', text: 'Anulado', qty: qtys[8]},
    ])
  }, [rows,]);

  return <> 
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De Ordenes De Compra</span>
        <div className="d-flex">
        <Button onClick={() => {
          let p_list_oc = listGenerate.map(r => r.id)

          Controllers.orders.get_reporte_oc_unit_qty({
            p_list_oc,
            p_name_provider: listGenerate[0].provider.replace(' - ', '_')
          }).then(res => {
            let a = document.createElement('a')
            a.download = res.data.filename
            a.href = res.data.file
            a.click()
            setListGenerate([])
          })
        }} disabled={!listGenerate.length} className="align-self-center" variant="success" size="sm">
          <i className="fa fa-file-excel"></i>
          <span className="ml-2 d-none d-md-inline-block">Descargar Reporte</span>
          <span className="ml-2">({listGenerate.length})</span>
        </Button>
        <Button size="sm" onClick={() => setListGenerate([])} variant="danger">
          <i className="fa fa-times"></i><span className="ml-2 d-none d-md-inline-block">Cancelar</span>
        </Button>
        </div>
      </Card.Header>
      <Card.Body>
        {React.Children.toArray(states.map(st => {
          return <Button size="sm" data-id={st.id} onClick={changeList} variant={state === st.id ? 'success' : 'primary'} className="mr-2"><i className={'mr-1 ' + st.icon}></i>{st.text} ({st.qty})</Button>
        }))}
        <div className="mb-2"></div>
        {dtRows}
      </Card.Body>
    </Card>
    <Modal show={modalShow} onHide={() => setModalShow(false)} size={modalSize} backdrop="static">
      <Modal.Header closeButton className={modalHeader}>
        <Modal.Title as="div">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
  </>
}

export default Presentation;