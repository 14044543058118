import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactPlaceholder from 'react-placeholder/lib';
import Controllers from '../../Api/Controllers'
import { FormCustom, Input, Select } from '../../Components/Form/Form';
import Helpers from '../../Helpers/Helpers';
import SubProductsListAdd from '../Products/SubProductsListAdd';
import { toast } from 'react-toastify'

const UnopsForm = ({ data, listSubProducts, onSubmit, viewDisabled, listInvoices, listReceipts }) => {
    const states = [
        { state: 0, color: 'primary', text: 'SIN CONSOLIDACIÓN' },
        { state: 1, color: 'success', text: 'CONSOLIDADO' },
        { state: 2, color: 'dark', text: 'REGULARIZADO' },
    ]
    
    const [row, setRow] = useState({
        p_id_unit: data.id_unit,
        p_id_unops: data.id_unops,
        p_number_order: data.number_order,
        p_number_dispatch: data.number_dispatch,
        p_number_act: data.number_act,
        p_date_act: data.date_act,
        p_year_reception: data.year_reception,
        p_number_bidding: data.number_bidding,
        p_number_itb: data.number_itb,
        p_id_provider: data.id_provider,
        p_id_supplie: data.id_supplie,
        p_state: data.state,
        p_price: data.price,
        p_qty: data.qty,
    })

    const getState = useCallback(() => {
        return states.find(s => s.state === parseInt(row.p_state))
    }, [states, row.p_state])

    const [provider, setProvider] = useState([])
    const [unit, setUnit] = useState([])
    const [supplies, setSupplies] = useState([])
    const [subProducts, setSubProducts] = useState(listSubProducts)
    const [ready, setReady] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const [invoices, setInvoices] = useState(listInvoices)
    const [receipts, setReceipts] = useState(listReceipts)

    useEffect(() => {
        Promise.all([
            Controllers.supplies.get_supplies_public(),
            Controllers.provider.get_provider_public(),
            Controllers.unit.get_unit()
        ]).then(res => {
            setSupplies(res[0].data)
            setProvider(res[1].data)
            setUnit(res[2].data)
            setReady(true)
        })
    }, [])

    const cboProvider = useMemo(() => {
        if (!row.p_id_provider) {
            return ''
        }

        let finded = provider.find(u => parseInt(u.id) === parseInt(row.p_id_provider))

        if (!finded) {
            return ''
        }

        return {
            value: finded.name,
            label: finded.id + ' - ' + finded.name
        }
    }, [provider, row.p_id_provider])

    const cboSupplie = useMemo(() => {
        if (!row.p_id_supplie) {
            return ''
        }

        let finded = supplies.find(u => parseInt(u.id) === parseInt(row.p_id_supplie))

        if (!finded) {
            return ''
        }

        return {
            value: finded.id,
            label: finded.cod_ppr + ' - ' + finded.description_ppr
        }
    }, [supplies, row.p_id_supplie])

    const cboUnit = useMemo(() => {
        if (!row.p_id_unit) {
            return ''
        }

        let finded = unit.find(u => parseInt(u.id) === parseInt(row.p_id_unit))

        if (!finded) {
            return ''
        }

        return {
            value: finded.id,
            label: finded.id + ' - ' + finded.description
        }
    }, [unit, row.p_id_unit])

    const dataSubmit = useCallback(() => {
        let d = { ...row }
        d.p_sub_products = subProducts.map(s => {
            return {
                id: s.id,
                qty: s.qty
            }
        })

        d.p_invoices = invoices
        d.p_receipts = receipts

        return d
    }, [ row, subProducts, invoices, receipts ])

    const calculateTotal = useCallback(() => {
        let total = 0
        
        if (!isNaN(parseInt(row.p_qty)) && !isNaN(parseFloat(row.p_price))) {
            total = parseInt(row.p_qty) * parseFloat(row.p_price)
        }

        return parseFloat(total).toFixed(2)
    }, [row])

    return <ReactPlaceholder showLoadingAnimation ready={ready} rows={10}>
        <FormCustom dataSubmit={dataSubmit()} onSubmit={({ e, dataSubmit }) => {
            let $this = e.currentTarget

            let propsValidateForm = { form: $this, toast };
            if (!$this.checkValidity()) {
              $this.classList.add('was-validated');
              Helpers.form.isFormCorrect(propsValidateForm);
              return;
            } else {
              $this.classList.remove('was-validated');
              if (!Helpers.form.isFormCorrect(propsValidateForm)) {
                return;
              }
            }
            if (!row.p_id_unit) {
                Helpers.toastr.construct({ response: 'warning', message: 'Seleccionar Unidad', toastr: toast })
                return
            }
            if (!row.p_id_supplie) {
                Helpers.toastr.construct({ response: 'warning', message: 'Seleccionar Insumo', toastr: toast })
                return
            }
            if (!row.p_id_provider) {
                Helpers.toastr.construct({ response: 'warning', message: 'Seleccionar Proveedor', toastr: toast })
                return
            }
            let qtyTotal = 0
            subProducts.forEach(a => qtyTotal += parseInt(a.qty))

            if (subProducts.length > 0) {
                if (qtyTotal !== parseInt(row.p_qty)) {
                    Helpers.toastr.construct({ response: 'warning', message: 'La Cantidad Total debe de coincidir con la suma de las cantidades de los SubProductos.', toastr: toast })
                    return
                }
            }

            if (invoices.length === 0) {
                Helpers.toastr.construct({ response: 'warning', message: 'Debe ingresar Factura(s)', toastr: toast })
                return
            }
        
            let totalInvoices = 0
            invoices.forEach(a => totalInvoices += parseFloat(a.value_invoice))
            if (isNaN(totalInvoices) || totalInvoices.toFixed(2) !== calculateTotal()) {
                Helpers.toastr.construct({ response: 'warning', message: 'El total debe de coincidir con la suma de los valores de las Facturas.', toastr: toast })
                return
            }

            if (receipts.length === 0) {
                Helpers.toastr.construct({ response: 'warning', message: 'Debe ingresar Recibo(s)', toastr: toast })
                return
            }
        
            let totalReceipts = 0
            receipts.forEach(a => totalReceipts += parseFloat(a.value_receipt))
            if (isNaN(totalReceipts) || totalReceipts.toFixed(2) !== calculateTotal()) {
                Helpers.toastr.construct({ response: 'warning', message: 'El total debe de coincidir con la suma de los valores de los Recibos.', toastr: toast })
                return
            }

            onSubmit({ e, dataSubmit })
        }} viewSubmit={!viewDisabled}>
            <div className="col-12">Estado: <strong className={'text-' + getState().color}>{getState().text}</strong></div>
            <Select
                value={cboUnit}
                options={unit.map(s => {
                    return {
                        value: s.id,
                        label: s.id + ' - ' + s.description
                    }
                })}
                onChange={e => setRow({ ...row, p_id_unit: e.value })}
                text="Unidad"
                icon="far fa-building"
                classNameParent="col-12 mb-2"
                disabled={viewDisabled}
            />
            <Input
                text="Numero Order de Compra"
                placeholder="Numero Order de Compra"
                classNameParent="col-6 mb-2"
                icon="far fa-comment-alt"
                required
                defaultValue={data.number_order}
                onChange={e => setRow({ ...row, p_number_order: e.currentTarget.value })}
                maxLength={100}
                disabled={viewDisabled}
            />
            <Input
                text="Numero Entrega"
                placeholder="Numero Entrega"
                classNameParent="col-6 mb-2"
                icon="far fa-comment-alt"
                required
                defaultValue={data.number_dispatch}
                onChange={e => setRow({ ...row, p_number_dispatch: e.currentTarget.value })}
                maxLength={100}
                disabled={viewDisabled}
            />
            <Input
                text="Nro Acta Recepción"
                placeholder="Nro Acta Recepción"
                classNameParent="col-6 mb-2"
                icon="far fa-comment-alt"
                required
                defaultValue={data.number_act}
                onChange={e => setRow({ ...row, p_number_act: e.currentTarget.value })}
                maxLength={100}
                disabled={viewDisabled}
            />
            <Input
                text="Fecha Acta Recepción"
                type="date"
                classNameParent="col-6 mb-2"
                icon="far fa-comment-alt"
                required
                defaultValue={data.date_act}
                onChange={e => setRow({ ...row, p_date_act: e.currentTarget.value })}
                maxLength={100}
                disabled={viewDisabled}
            />
            <Input
                text="Año Recepción"
                placeholder="Año Recepción"
                classNameParent="col-6 mb-2"
                icon="far fa-comment-alt"
                required
                defaultValue={data.year_reception}
                onChange={e => setRow({ ...row, p_year_reception: e.currentTarget.value })}
                maxLength={100}
                disabled={viewDisabled}
            />
            <Input
                text="Número Licitación"
                placeholder="Número Licitación"
                classNameParent="col-6 mb-2"
                icon="far fa-comment-alt"
                required
                defaultValue={data.number_bidding}
                onChange={e => setRow({ ...row, p_number_bidding: e.currentTarget.value })}
                maxLength={100}
                disabled={viewDisabled}
            />
            <Input
                text="Número ITB"
                placeholder="Número ITB"
                classNameParent="col-6 mb-2"
                icon="far fa-comment-alt"
                required
                defaultValue={data.number_itb}
                onChange={e => setRow({ ...row, p_number_itb: e.currentTarget.value })}
                maxLength={100}
                disabled={viewDisabled}
            />
            <Select
                value={cboProvider}
                options={provider.map(s => {
                    return {
                        value: s.id,
                        label: s.id + ' - ' + s.name
                    }
                })}
                onChange={e => setRow({ ...row, p_id_provider: e.value })}
                text="Proveedor"
                icon="far fa-building"
                classNameParent="col-12 mb-2"
                disabled={viewDisabled}
            />
            <Select
                value={cboSupplie}
                options={supplies.map(s => {
                    return {
                        value: s.id,
                        label: s.cod_ppr + ' - ' + s.description_ppr
                    }
                })}
                onChange={e => setRow({ ...row, p_id_supplie: e.value })}
                text="Insumo"
                icon="far fa-building"
                classNameParent="col-12 mb-2"
                disabled={viewDisabled}
            />
            <Input
                type="number"
                text="Precio"
                placeholder="Precio"
                classNameParent="col-6 mb-2"
                min="0.01"
                step="0.01"
                defaultValue={data.price}
                onChange={e => setRow({ ...row, p_price: e.currentTarget.value })}
                className="text-center"
                required
                disabled={viewDisabled}
            />
            <Input
                type="number"
                text="Cantidad Total"
                placeholder="Cantidad Total"
                classNameParent="col-6 mb-2"
                min="1"
                step="1"
                defaultValue={data.qty ? parseInt(data.qty) : ''}
                onChange={e => setRow({ ...row, p_qty: e.currentTarget.value })}
                className="text-center"
                required
                disabled={!isNaN(parseInt(row.p_id_unops))}
            />
            <Input
                type="number"
                text="Total"
                placeholder="Total"
                classNameParent="col-6 mb-2"
                value={calculateTotal()}
                className="text-center"
                disabled
            />
            <div className="col-12">
                <hr />
            </div>
            <div className="col-12 d-flex mb-2">
                <span className="align-self-center mr-2">Subproductos</span>
                {viewDisabled ? '' : <Button size="sm" type="button" variant="primary" onClick={_ => setModalShow(true)}>
                    <i className="fa fa-plus"></i> Agregar
                </Button>}
            </div>
            <div className="col-12">
                <div className="table-responsive">
                    <table className="table table-sm table-hover table-striped table-bordered">
                        <thead>
                            <tr>
                                <th width="70%">SubProducto</th>
                                <th width="25%">Cantidad</th>
                                <th width="5%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {React.Children.toArray(subProducts.map((s, i) => {
                                return <tr>
                                    <td align="center">{s.id} - {s.description_short}</td>
                                    <td>
                                        <Input
                                            className="text-center"
                                            type="number"
                                            min="1"
                                            step="1"
                                            required
                                            value={s.qty ? parseInt(s.qty) : ''}
                                            onChange={k => {
                                                setSubProducts(subProducts.map((ss, ii) => {
                                                    if (ii === i) {
                                                        ss.qty = k.currentTarget.value
                                                    }
                                                    return ss
                                                }))
                                            }}
                                            disabled={viewDisabled}
                                        />
                                    </td>
                                    <td>
                                        {viewDisabled ? '' : <Button type="button" variant="danger" size="sm" onClick={_ => {
                                            setSubProducts(subProducts.filter(ss => ss.id !== s.id))
                                        }}>
                                            <i className="fa fa-times"></i>
                                        </Button>}
                                    </td>
                                </tr>
                            }))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-12 d-flex mb-2">
                <span className="align-self-center mr-2">Facturas</span>
                {viewDisabled ? '' : <Button size="sm" type="button" variant="primary" onClick={_ => setInvoices([
                    ...invoices,
                    {
                        serie_invoice: '',
                        number_invoice: '',
                        date_invoice: '',
                        value_invoice: '',
                    }
                ])}>
                    <i className="fa fa-plus"></i> Agregar
                </Button>}
            </div>
            <div className="col-12">
                <div className="table-responsive">
                    <table className="table table-sm table-hover table-striped table-bordered">
                        <thead>
                            <tr>
                                <th width="30%">Serie</th>
                                <th width="30%">Número</th>
                                <th width="30%">Fecha</th>
                                <th style={{ minWidth: 150 }}>Valor</th>
                                <th width="1%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {React.Children.toArray(invoices.map((s, i) => {
                                return <tr>
                                    <td align="center">
                                        <Input
                                            className="text-center"
                                            type="text"
                                            maxLength={100}
                                            required
                                            value={s.serie_invoice}
                                            onChange={k => {
                                                setInvoices(invoices.map((ss, ii) => {
                                                    if (ii === i) {
                                                        ss.serie_invoice = k.currentTarget.value
                                                    }
                                                    return ss
                                                }))
                                            }}
                                            disabled={viewDisabled}
                                        />
                                    </td>
                                    <td align="center">
                                        <Input
                                            className="text-center"
                                            type="number"
                                            required
                                            value={s.number_invoice}
                                            onChange={k => {
                                                setInvoices(invoices.map((ss, ii) => {
                                                    if (ii === i) {
                                                        ss.number_invoice = k.currentTarget.value
                                                    }
                                                    return ss
                                                }))
                                            }}
                                            disabled={viewDisabled}
                                            min="1"
                                            step="1"
                                        />
                                    </td>
                                    <td>
                                        <Input
                                            className="text-center"
                                            type="date"
                                            required
                                            value={s.date_invoice}
                                            onChange={k => {
                                                setInvoices(invoices.map((ss, ii) => {
                                                    if (ii === i) {
                                                        ss.date_invoice = k.currentTarget.value
                                                    }
                                                    return ss
                                                }))
                                            }}
                                            disabled={viewDisabled}
                                        />
                                    </td>
                                    <td>
                                        <Input
                                            className="text-center"
                                            type="number"
                                            min="0.01"
                                            step="0.01"
                                            required
                                            value={s.value_invoice}
                                            onChange={k => {
                                                setInvoices(invoices.map((ss, ii) => {
                                                    if (ii === i) {
                                                        ss.value_invoice = k.currentTarget.value
                                                    }
                                                    return ss
                                                }))
                                            }}
                                            disabled={viewDisabled}
                                        />
                                    </td>
                                    <td>
                                        {viewDisabled ? '' : <Button type="button" variant="danger" size="sm" onClick={_ => {
                                            setInvoices(invoices.filter((ss, ii) => i !== ii))
                                        }}>
                                            <i className="fa fa-times"></i>
                                        </Button>}
                                    </td>
                                </tr>
                            }))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-12 d-flex mb-2">
                <span className="align-self-center mr-2">Recibos</span>
                {viewDisabled ? '' : <Button size="sm" type="button" variant="primary" onClick={_ => setReceipts([
                    ...receipts,
                    {
                        number_receipt: '',
                        date_receipt: '',
                        value_receipt: '',
                    }
                ])}>
                    <i className="fa fa-plus"></i> Agregar
                </Button>}
            </div>
            <div className="col-12">
                <div className="table-responsive">
                    <table className="table table-sm table-hover table-striped table-bordered">
                        <thead>
                            <tr>
                                <th width="30%">Número</th>
                                <th width="30%">Fecha</th>
                                <th width="39%">Valor</th>
                                <th width="1%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {React.Children.toArray(receipts.map((s, i) => {
                                return <tr>
                                    <td align="center">
                                        <Input
                                            className="text-center"
                                            type="text"
                                            maxLength={100}
                                            required
                                            value={s.number_receipt}
                                            onChange={k => {
                                                setReceipts(receipts.map((ss, ii) => {
                                                    if (ii === i) {
                                                        ss.number_receipt = k.currentTarget.value
                                                    }
                                                    return ss
                                                }))
                                            }}
                                            disabled={viewDisabled}
                                        />
                                    </td>
                                    <td>
                                        <Input
                                            className="text-center"
                                            type="date"
                                            required
                                            value={s.date_receipt}
                                            onChange={k => {
                                                setReceipts(receipts.map((ss, ii) => {
                                                    if (ii === i) {
                                                        ss.date_receipt = k.currentTarget.value
                                                    }
                                                    return ss
                                                }))
                                            }}
                                            disabled={viewDisabled}
                                        />
                                    </td>
                                    <td>
                                        <Input
                                            className="text-center"
                                            type="number"
                                            min="0.01"
                                            step="0.01"
                                            required
                                            value={s.value_receipt}
                                            onChange={k => {
                                                setReceipts(receipts.map((ss, ii) => {
                                                    if (ii === i) {
                                                        ss.value_receipt = k.currentTarget.value
                                                    }
                                                    return ss
                                                }))
                                            }}
                                            disabled={viewDisabled}
                                        />
                                    </td>
                                    {viewDisabled ? '' : <td>
                                        <Button type="button" variant="danger" size="sm" onClick={_ => {
                                            setReceipts(receipts.filter((ss, ii) => i !== ii))
                                        }}>
                                            <i className="fa fa-times"></i>
                                        </Button>
                                    </td>}
                                </tr>
                            }))}
                        </tbody>
                    </table>
                </div>
            </div>
        </FormCustom>
        <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg" backdrop="static">
            <Modal.Header closeButton className={'bg-dark'}>
                <Modal.Title as="div">Seleccionar Subproductos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SubProductsListAdd added={subProducts} callbackAdd={({ items }) => {
                    let newSub = []
                    subProducts.forEach(s => {
                        let find = items.find(ss => ss.id === s.id)
                        let newQty = s.qty
                        if (find) {
                            newQty = find.qty
                        }
                        newSub.push({
                            ...s,
                            qty: newQty
                        })
                    })

                    items.forEach(s => {
                        let find = subProducts.find(ss => ss.id === s.id)
                        if (!find) {
                            newSub.push(s)
                        }
                    })

                    setSubProducts(newSub)
                    setModalShow(false)
                }} />
            </Modal.Body>
        </Modal>
    </ReactPlaceholder>
}

UnopsForm.defaultProps = {
    data: {
        id_unops: '',
        id_provider: '',
        id_supplie: '',
        number_act: '',
        date_act: '',
        year_reception: '',
        number_bidding: '',
        number_order: '',
        number_dispatch: '',
        number_itb: '',
        date_created: '',
        user_created: '',
        date_updated: '',
        user_updated: '',
        state: 0,
        price: '',
        qty: '',
        id_unit: ''
    },
    listSubProducts: [],
    listInvoices: [],
    listReceipts: []
}

export default UnopsForm