import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Controllers from '../Api/Controllers';
import { FormCustom, Input, Select, TextArea, CheckBox } from '../Components/Form/Form';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import { useSelector } from 'react-redux';
import Supplies from './Supplies'
import DataTable from '../Components/DataTable/DataTable';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const SuppliesPublic = ({showModal, hideModal, added, onAdd }) => {
  return <>
    <Modal show={showModal} onHide={hideModal} size={'lg'} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title as="div"><i className="fa fa-list mr-2"></i> Listado De Insumos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Supplies isPublic={true} added={added} onAdd={onAdd} hasPrice newSupplie={false} />
      </Modal.Body>
    </Modal>
  </>
}

const ReProgrammatedSupplie = () => {
  const [modalShow, setModalShow] = useState(false);
  const [unit, setUnit] = useState([]);
  const [currentUnit, setCurrentUnit] = useState(null)
  const [unitSelected, setUnitSelected] = useState(null)
  const [details, setDetails] = useState([]);
  const [detailsLeft, setDetailsLeft] = useState([])
  const [nivel, setNivel] = useState([]);
  const [budgetAdd, setBudgetAdd] = useState(false)
  const currentYear = useSelector(store => store.session.year);
  const [reload, setReload] = useState(true)
  const [modalShowSupplies, setModalShowSupplies] = useState(false);
  const [modalShowSubProductos, setModalShowSubProductos] = useState(false);
  const [form, setForm] = useState('')
  const [subProducts, setSubProducts] = useState([])
  const [modalPDF, setModalPDF] = useState(false)
  const [formPDF] = useState('')
  const [state, setState] = useState('')

  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = useRef(Helpers.getActions({ permissions, name: 'reprogramacion' }))

  const [modalDisapproveTitle, setModalDisapproveTitle] = useState('')
  const [modalDisapproveHeader, setModalDisapproveHeader] = useState('')  

  const [isCreated, setIsCreated] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [isView, setIsView] = useState(false)

  const jwt = useSelector(store => store.session.jwt)
  
  useEffect(() => {
    if (reload) {
      Controllers.unit.get_unit_public_with_pack({
        p_year: currentYear
      }).then(res => {
        setUnit(res.data)
        if (res.data.length === 1) {
          setCurrentUnit({
            value: res.data[0].id,
            label: res.data[0].id + ' - ' + res.data[0].description + ' - ' + currentYear,
            budget: res.data[0].budget,
            qty: res.data[0].qty
          })
        }
        setReload(false)
      })
      Controllers.nivel.get_nivel().then(res => {
        setNivel(res.data.filter(r => r.id !== 'VP'))
      })

      Controllers.sub_products.get_sub_products_public().then(res => {
        setSubProducts(res.data)
      })
    }
  }, [currentYear, reload])

  const totalAdded = useCallback(() => {
    let b = 0;
    detailsLeft.forEach(d => {
      if (d.exists) {
        b += (parseFloat(d.price) * parseInt(d.qty_start))
      }
    })
    return b;
  }, [detailsLeft])

  const totalAddedNew = useCallback(() => {
    let b = 0;
    detailsLeft.forEach(d => {
      if (!isNaN(parseInt(d.newQtyStart)) && !isNaN(parseFloat(d.newPrice))) {
        b += parseFloat(d.newQtyStart * d.newPrice)
      } 
    })
    return b;
  }, [detailsLeft])

  const dataSubmit = useCallback(() => {
    let p_details = []
    detailsLeft.forEach(d => {
      if (d.id_nivel !== 'A' && d.id_nivel !== 'RA') {
        p_details.push({
          p_id: d.id,
          p_type: d.id_nivel === 'E' ? 'DOWN' : (d.id_nivel === 'VCU' ? (parseFloat(d.price) >= parseFloat(d.newPrice) ? 'DOWN' : 'UP') :
            parseInt(d.qty_start) >= parseInt(d.newQtyStart) ? 'DOWN' : 'UP'
          ),
          p_id_nivel: d.id_nivel,
          p_qty: d.id_nivel === 'E' ? d.qty_start : (d.id_nivel === 'VCU' ? d.qty_start : d.newQtyStart),
          p_price: d.id_nivel === 'E' ? d.price : (d.id_nivel === 'VC' ? d.price : d.newPrice),
          p_change: d.changeBudget,
          p_justification: d.justification,
          p_id_supplie: d.id_supplie,
          p_modify_budget: d.changeBudget,
          p_id_sub_products: d.id_sub_products
        })
      } 
      else if (d.id_nivel === 'RA') {
        p_details.push({
          p_id: d.id,
          p_type: 'UP',
          p_id_nivel: d.id_nivel,
          p_qty: d.newQtyStart,
          p_price: d.newPrice,
          p_change: d.changeBudget,
          p_justification: d.justification,
          p_id_supplie: d.id_supplie,
          p_modify_budget: d.changeBudget,
          p_id_sub_products: d.id_sub_products
        })
      }
      else {
        p_details.push({
          p_id: '',
          p_type: 'UP',
          p_id_nivel: d.id_nivel,
          p_qty: d.newQtyStart,
          p_price: d.newPrice,
          p_change: d.changeBudget,
          p_justification: d.justification,
          p_id_supplie: d.id_supplie,
          p_modify_budget: d.changeBudget,
          p_id_sub_products: d.id_sub_products
        })
      }
    })
    if (currentUnit == null) {
      return {}
    }
    return {
      p_id: isUpdated ? currentUnit.id_header : '',
      p_extension_budget: budgetAdd ? 1 : 0,
      p_id_unit: currentUnit.value,
      p_year: currentYear,
      p_details
    }
  }, [detailsLeft, currentUnit, currentYear, budgetAdd, isUpdated])

  const onSubmit = ({ e, dataSubmit }) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }

      if (dataSubmit.p_details.some(d => d.p_id_nivel === '')) {
        Helpers.toastr.construct({ response: 'warning', message: 'Seleccionar tipo modificación', toastr })
        return
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers.packs_annual.packs_annual_details_extension_header_insert(dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        setReload(true)
        setDetails([])
        setUnitSelected(null)
        setDetailsLeft([])
        setCurrentUnit(null);
        setIsCreated(false)
        setIsUpdated(false)
        setBudgetAdd(false)
        setIsView(false)
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });      
    }).finally(() => {
      button.innerHTML = buttonHTML;
    });
  }

  const cboUnit = useMemo(() => {
    let unitFind = currentUnit === null ? '' : unit.find(u => parseInt(u.id) === parseInt(currentUnit.value))

    return <Select
      value={unitFind ? currentUnit : ''}
      options={unit.map(s => {
        return {
          value: s.id,
          label: s.id + ' - ' + s.description + ' - ' + currentYear,
          budget: s.budget,
          qty: s.qty
        }
      })}
      onChange={e => {
        setUnitSelected(null)
        setDetails([])
        setCurrentUnit(e)
        setDetailsLeft([])
        setIsUpdated(false)
        setIsCreated(false)
        setBudgetAdd(false)
        setIsView(false)
      }}
      text="Centro de costo"
      icon="far fa-building"
      classNameParent="col-12 mb-2"
      append={[<Button onClick={e => {
        if (currentUnit === null) {
          return;
        }

        let button = e.currentTarget
        let buttonHTML = button.innerHTML;
        button.innerHTML = Helpers.icon.spin()
        
        Controllers.packs_annual.get_packs_annual_details_extension({
          p_year: currentYear,
          p_id_unit: currentUnit.value,
          p_id: ''
        }).then(res => {
          setUnitSelected(currentUnit)
          setDetails(res.data)
          setDetailsLeft([])
          setIsCreated(true)
          setIsUpdated(false)
          setBudgetAdd(false)
          setIsView(false)
        }).catch(req => {
          Helpers.promise.catch({ req, toastr })
          setDetails([])
          setUnitSelected(null)
          setDetailsLeft([])
        }).finally(() => button.innerHTML = buttonHTML)
      }}>
        <i className="fa fa-plus"></i> Generar Nueva Solicitud
      </Button>]}
    />
  }, [unit, currentYear, currentUnit])

  const cboState = useMemo(() => {
    return <Select
      value={{ value: '', label: 'TODOS' }}
      options={[
        { value: '', label: 'TODOS' },
        { value: 0, label: 'PENDIENTE' },
        { value: 1, label: 'PENDIENTE DE APROBACIÓN' },
        { value: 2, label: 'RECHAZADO' },
        { value: 3, label: 'APROBADO' },
      ]}
      onChange={e => {
        setState(e.value)
      }}
      text="Estado"
      icon="far fa-building"
      classNameParent="col-12 mb-2"
    />
  }, [])

  const isCorrectBudget = useCallback(() => {
    if (unitSelected === null) {
      return false
    } else {
      if (budgetAdd) {
        return parseFloat(totalAdded()) < parseFloat(totalAddedNew())
      } else {
        return parseFloat(totalAdded()).toFixed(2) === parseFloat(totalAddedNew()).toFixed(2)
      }
    }
  }, [unitSelected, totalAdded, totalAddedNew, budgetAdd])

  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [reloadHeader, setReloadHeader] = useState(true);
  const [modalDisapprove, setModalDisapprove] = useState(false)
  const [formDisapprove, setFormDisapprove] = useState('')

  useEffect(() => {
    if (reload) {
      setIsProcessing(true);
      Controllers.packs_annual.get_packs_annual_details_extension_header({
        p_year: currentYear
      }).then(res => {
        setRows(res.data)
      })
      .finally(() => {
        setIsProcessing(false)
        setReloadHeader(false);
      });
    }
  }, [reloadHeader, currentYear, reload])

  const thead = [
    { name: 'ID', align: 'center' },
    { name: 'Centro Costo.', align: 'center' },
    { name: 'Año', align: 'center' },
    { name: 'Fecha Registro', align: 'center' },
    { name: 'Amplicación Pres.', align: 'center' },
    { name: 'Nro Resolución.', align: 'center' },
    { name: 'Fecha Resolución', align: 'center' },
    { name: 'Estado', align: 'center' },
    { name: 'Acc.', align: 'center' },
  ];

  const dtListPac = useMemo(() => {
    return <DataTable
      isProcessing={isProcessing}
      tbody={rows.filter(r => {
        if (state === '') {
          return r
        }

        return parseInt(state) === parseInt(r.state)
      }).map(r => {
        return {
          number: parseInt(r.number),
          description: r.description,
          year: r.year,
          date_created: r.date_created,
          extension_budget: r.extension_budget,
          number_resolution: !isNaN(parseInt(r.number_resolution)) ? parseInt(r.number_resolution) : r.number_resolution,
          date_resolution: r.date_resolution,
          state: r.state,
          id_unit: r.id_unit,
          id: r.id,
          number_year: r.number_year,
          justification: r.justification
        }
      })}
      thead={thead}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;
        for (let key in r) {
          if (key === 'id_unit' || key === 'id' || key === 'number_year' || key === 'justification') {
            continue;
          }

          if (key === 'state') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''} className={'text-' + (parseInt(r.state) === 0 ? 'warning' : (parseInt(r.state) === 1 ? 'info' : (parseInt(r.state) === 2 ) ? 'danger' : 'success'))}>
            <strong>{parseInt(r.state) === 0 ? 'PENDIENTE' : (parseInt(r.state) === 1 ? 'PENDIENTE DE APROBACIÓN' : (parseInt(r.state) === 2 ) ? 'RECHAZADO' : 'APROBADO')}</strong>
          </td>);
          } else if (key === 'extension_budget') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''} className={'text-' + (parseInt(r.extension_budget) === 0 ? 'success' : 'danger')}>
            <strong>{parseInt(r.extension_budget) === 0 ? 'NO' : 'SI'}</strong>
          </td>);
          } else if (key === 'number') {
            rowRender.push(<td align="center">{r.number_year}</td>);
          } else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }

        rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
          {actionsModule.current.view ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.view.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.view.color} data-disabled={1} size="xs" data-extension-budget={r.extension_budget} data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
              let isBudget = parseInt(e.currentTarget.dataset.extensionBudget) === 1
              let button = e.currentTarget
              let buttonHTML = button.innerHTML;
              button.innerHTML = Helpers.icon.spin()
              let findUnit = unit.find(u => parseInt(u.id) === parseInt(e.currentTarget.dataset.idUnit))
              let id = e.currentTarget.dataset.id

              let promises = [
                Controllers.packs_annual[parseInt(r.state) === 3 ? 'get_packs_annual_consolidate' : 'get_pack_details_extension']({
                  p_id: e.currentTarget.dataset.id
                }),
              ]

              if (parseInt(r.state) !== 3) {
                promises.push(Controllers.packs_annual.get_packs_annual_details_extension({
                  p_year: e.currentTarget.dataset.year,
                  p_id_unit: e.currentTarget.dataset.idUnit,
                  p_id: e.currentTarget.dataset.id
                }))
              }
              
              Promise.all(promises).then(res => {
                setIsView(true)
                setCurrentUnit({
                  number_header: r.number + '/' + r.year,
                  id_header: id,
                  value: findUnit.id,
                  label: findUnit.id + ' - ' + findUnit.description,
                  budget: findUnit.budget,
                  qty: findUnit.qty
                })
                setUnitSelected({
                  number_header: r.number + '/' + r.year,
                  id_header: id,
                  value: findUnit.id,
                  label: findUnit.id + ' - ' + findUnit.description,
                  budget: findUnit.budget,
                  qty: findUnit.qty
                })
                setIsCreated(false)
                setIsUpdated(true)
                setBudgetAdd(isBudget)

                let dt = []

                if (parseInt(r.state) === 3) {
                  res[0].data.forEach(r => {
                    dt.push({
                      id_nivel: r.id_nivel, 
                      changeBudget: r.modify_budget, 
                      newQtyStart: parseInt(r.qty), 
                      newPrice: parseFloat(r.price), 
                      justification: r.justification, 
                      exists: true, 
                      qty_start: r.qty_start,
                      price: r.price_start,
                      cod_internal: r.cod_internal,
                      description_internal: r.description_internal,
                      id_row: r.id_row,
                      id_sub_products: r.id_sub_products,
                      id_supplie: r.id_supplie
                    })
                  })
                  setDetailsLeft(dt)
                  document.getElementById('form').scrollIntoView()
                } else {
                  res[0].data.forEach(r => {
                    let findDetLeft = res[1].data.find(fl => parseInt(fl.id) === parseInt(r.id_packs_details))

                    if (r.id_nivel === 'A') {
                      dt.push({ ...findDetLeft, 
                        id_nivel: r.id_nivel, 
                        changeBudget: r.modify_budget, 
                        newQtyStart: parseInt(r.qty), 
                        newPrice: parseFloat(r.price), 
                        justification: r.justification, 
                        exists: false, 
                        qty_start: '',
                        price: '',
                        cod_internal: r.cod_internal,
                        description_internal: r.description_internal,
                        id_row: r.id_row,
                        id_sub_products: r.id_sub_products,
                        id_supplie: r.id_supplie
                      })
                    } else {
                      let q = parseInt(findDetLeft.qty_start) + parseInt(findDetLeft.extension_qty) - parseInt(findDetLeft.reduction_qty) - parseInt(findDetLeft.reservated_qty) - parseInt(findDetLeft.qty_order_reservated) - parseInt(findDetLeft.qty_order)
                      let p = parseFloat(findDetLeft.price) + parseFloat(findDetLeft.extension_price) - parseFloat(findDetLeft.reduction_price)
                      
                      let numberDecimals = 0
                      if (!isNaN(parseFloat(r.price))) {
                        if (r.price.includes('.')) {
                          let splitPrice = parseFloat(r.price).toString().split('.')
                          if (splitPrice.length > 1) {
                            numberDecimals = splitPrice[1].toString().length
                          }
                        }
                      }

                      let pShow = (r.type === 'DOWN' ? parseFloat(p - parseFloat(r.price)).toFixed(numberDecimals) : parseFloat(p + parseFloat(r.price)).toFixed(numberDecimals))

                      dt.push({ ...findDetLeft, 
                        id_nivel: r.id_nivel, 
                        changeBudget: r.modify_budget, 
                        newQtyStart: r.qty === null ? q : (r.type === 'DOWN' ? q - parseInt(r.qty) : q + parseInt(r.qty)), 
                        newPrice: r.price === null ? p : pShow, 
                        justification: r.justification, exists: true, 
                        qty_start: q,
                        price: p,
                      })
                    }
                  })
                }
                setDetailsLeft(dt)
                document.getElementById('form').scrollIntoView()
              }).catch(req => {
                Helpers.promise.catch({ req, toastr })
                setUnitSelected({})
                setDetails([])
                setDetailsLeft([])
                setIsCreated(false)
                setIsUpdated(false)
                setBudgetAdd(false)
                setIsView(false)
              }).finally(() => button.innerHTML = buttonHTML)

            }}>
              <i className={actionsModule.current.view.icon}></i>
            </Button>
          </OverlayTrigger> :''}
          {parseInt(r.state) === 0 || parseInt(r.state) === 2 ? <>
            {actionsModule.current.update ?
              <OverlayTrigger
                placement="top"
                overlay={props => <Tooltip {...props}>
                  {actionsModule.current.update.description}
                </Tooltip>}
              >
                <Button variant={actionsModule.current.update.color} size="xs" data-justification={r.justification} data-extension-budget={r.extension_budget} data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
                  let isBudget = parseInt(e.currentTarget.dataset.extensionBudget) === 1
                  let button = e.currentTarget
                  let buttonHTML = button.innerHTML;
                  button.innerHTML = Helpers.icon.spin()
                  let findUnit = unit.find(u => parseInt(u.id) === parseInt(e.currentTarget.dataset.idUnit))
                  let id = e.currentTarget.dataset.id
                  let justification = e.currentTarget.dataset.justification
                  

                  let promises = [
                    Controllers.packs_annual.get_pack_details_extension({
                      p_id: e.currentTarget.dataset.id
                    }),
                    Controllers.packs_annual.get_packs_annual_details_extension({
                      p_year: e.currentTarget.dataset.year,
                      p_id_unit: e.currentTarget.dataset.idUnit,
                      p_id: e.currentTarget.dataset.id
                    })
                  ]
                  
                  Promise.all(promises).then(res => {
                    setCurrentUnit({
                      number_header: r.number + '/' + r.year,
                      id_header: id,
                      value: findUnit.id,
                      label: findUnit.id + ' - ' + findUnit.description,
                      budget: findUnit.budget,
                      qty: findUnit.qty
                    })
                    setUnitSelected({
                      number_header: r.number + '/' + r.year,
                      id_header: id,
                      value: findUnit.id,
                      label: findUnit.id + ' - ' + findUnit.description,
                      budget: findUnit.budget,
                      qty: findUnit.qty,
                      justification: parseInt(r.state) === 2 ? justification : ''
                    })
                    setDetails(res[1].data)
                  
                    setIsCreated(false)
                    setIsUpdated(true)
                    setBudgetAdd(isBudget)
                    setIsView(false)

                    let dt = []
                    res[0].data.forEach(r => {
                      let findDetLeft = res[1].data.find(fl => parseInt(fl.id) === parseInt(r.id_packs_details))

                      if (r.id_nivel === 'A') {
                        dt.push({ ...findDetLeft, 
                          id_nivel: r.id_nivel, 
                          changeBudget: r.modify_budget, 
                          newQtyStart: parseInt(r.qty), 
                          newPrice: parseFloat(r.price), 
                          justification: r.justification, 
                          exists: false, 
                          qty_start: '',
                          price: '',
                          cod_internal: r.cod_internal,
                          description_internal: r.description_internal,
                          id_row: r.id_row,
                          id_sub_products: r.id_sub_products,
                          id_supplie: r.id_supplie
                        })
                      } else {
                        let q = parseInt(findDetLeft.qty_start) + parseInt(findDetLeft.extension_qty) - parseInt(findDetLeft.reduction_qty) - parseInt(findDetLeft.reservated_qty) - parseInt(findDetLeft.qty_order) - parseInt(findDetLeft.qty_order_reservated)
                        let p = parseFloat(findDetLeft.price) + parseFloat(findDetLeft.extension_price) - parseFloat(findDetLeft.reduction_price)

                        let numberDecimals = 0
                        if (!isNaN(parseFloat(r.price))) {
                          if (r.price.includes('.')) {
                            let splitPrice = parseFloat(r.price).toString().split('.')
                            if (splitPrice.length > 1) {
                              numberDecimals = splitPrice[1].toString().length
                            }
                          }
                        }

                        let pShow = (r.type === 'DOWN' ? parseFloat(p - parseFloat(r.price)).toFixed(numberDecimals) : parseFloat(p + parseFloat(r.price)).toFixed(numberDecimals))

                        dt.push({ ...findDetLeft, 
                          id_nivel: r.id_nivel, 
                          changeBudget: r.modify_budget, 
                          newQtyStart: r.qty === null ? q : (r.type === 'DOWN' ? q - parseInt(r.qty) : q + parseInt(r.qty)), 
                          newPrice: r.price === null ? p : pShow, 
                          justification: r.justification, exists: true, 
                          qty_start: q,
                          price: p,
                        })
                      }
                    })
                    setDetailsLeft(dt)
                    document.getElementById('form').scrollIntoView()
                  }).catch(req => {
                    Helpers.promise.catch({ req, toastr })
                    setUnitSelected({})
                    setDetails([])
                    setDetailsLeft([])
                    setIsCreated(false)
                    setIsUpdated(false)
                    setBudgetAdd(false)
                    setIsView(false)
                  }).finally(() => button.innerHTML = buttonHTML)

                }}>
                  <i className={actionsModule.current.update.icon}></i>
                </Button>
              </OverlayTrigger>
            : ''}
            {actionsModule.current.sendrequest ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.sendrequest.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.sendrequest.color} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
                let button = e.currentTarget;
                button.innerHTML = Helpers.icon.spin();
                const { id } = e.currentTarget.dataset;
                Controllers.packs_annual.packs_annual_header_send_approval({
                  p_id: id
                }).then(res => {
                  Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
                }).finally(() => {
                  setReload(true)
                })
              }}>
                <i className={actionsModule.current.sendrequest.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
          </> : ''}

          {parseInt(r.state) === 1 ? <>
            {actionsModule.current.approve ?
              <OverlayTrigger
                placement="top"
                overlay={props => <Tooltip {...props}>
                  {actionsModule.current.approve.description}
                </Tooltip>}
              >
                <Button variant={actionsModule.current.approve.color} size="xs" data-description={r.description} data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
                  const { id } = e.currentTarget.dataset;
                  setModalDisapproveTitle('Aprobar Solicitud')
                  setModalDisapproveHeader('bg-success')
                  setFormDisapprove(<form className="form-row needs-validation" onSubmit={e => {
                    e.preventDefault();
                    let $this = e.currentTarget
                    let button = $this.querySelector('button[type=submit]');
                    let buttonHTML = button.innerHTML
                    button.innerHTML = Helpers.icon.spin();
                    
                    Controllers.packs_annual.packs_annual_header_approve({
                      p_id: id,
                      p_date_resolution: document.getElementById('txt_date_resolution').value,
                      //p_number_resolution: document.getElementById('txt_number_resolution').value,
                    }).then(res => {
                      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
                      if (res.response === 'success') {
                        setModalDisapprove(false);
                      }
                    }).finally(() => {
                      button.innerHTML = buttonHTML
                      setReload(true)
                      setDetails([])
                      setUnitSelected(null)
                      setDetailsLeft([])
                      setCurrentUnit(null);
                      setIsCreated(false)
                      setIsUpdated(false)
                      setBudgetAdd(false)
                      setIsView(false)
                    })
                  }}>
                    <p className="col-12 text-center">Aprobar Solicitud <strong>{r.number + '/' + r.year}</strong></p>
                    {/* <Input
                      text="Número Resolucion"
                      placeholder="Número Resolucion"
                      classNameParent="col-12 mb-2"
                      icon="fas fa-sort-numeric-up-alt"
                      required
                      maxLength={50}
                      id="txt_number_resolution"
                    /> */}

                    <Input
                      type="date"
                      text="Fecha"
                      classNameParent="col-12 mb-2"
                      icon="fas fa-calendar"
                      required
                      autoFocus
                      id="txt_date_resolution"
                      defaultValue={Helpers.date.get()}
                    />

                  <div className="col-12 d-flex justify-content-center">        
                    <Button type="submit" variant="success" className="mr-2" size="sm">
                      <i className="fa fa-check mr-2"></i>Aprobar
                    </Button>
                    <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalDisapprove(false)}>
                      <i className="fa fa-times mr-2"></i>Cancelar
                    </Button>
                  </div>
                  </form>)
                  setModalDisapprove(true)                      
                }}>
                  <i className={actionsModule.current.approve.icon}></i>
                </Button>
              </OverlayTrigger>
            : ''}
            {actionsModule.current.refuse? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.refuse.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.refuse.color} size="xs" data-description={r.description} data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
                const id = e.currentTarget.dataset.id
                setModalDisapproveTitle('Rechazar Solicitud')
                setModalDisapproveHeader('bg-danger')
                setFormDisapprove(<form className="form-row needs-validation" onSubmit={e => {
                  e.preventDefault();
                  let $this = e.currentTarget

                  let button = $this.querySelector('button[type=submit]');
                  let buttonHTML = button.innerHTML
                  button.innerHTML = Helpers.icon.spin();

                  if (!$this.checkValidity()) {
                    $this.classList.add('was-validated');
                  } else {
                    Controllers.packs_annual.packs_annual_header_disapprove({
                      p_id: id,
                      p_justification: document.getElementById('txt_justify_disapprove').value,
                    }).then(res => {
                      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
                      setReload(true)
                      setModalDisapprove(false);
                    }).catch(req => {
                      setModalDisapprove(false)
                      Helpers.promise.catch({ req, toastr });
                    }).finally(() =>{ 
                      button.innerHTML = buttonHTML
                      setReload(true)
                      setDetails([])
                      setUnitSelected(null)
                      setDetailsLeft([])
                      setCurrentUnit(null);
                      setIsCreated(false)
                      setIsUpdated(false)
                      setBudgetAdd(false)
                      setIsView(false)
                    })
                  }
                }}>
                  <p className="col-12 text-center">Rechazar Solicitud <strong>{r.number + '/' + r.year}</strong></p>

                  <TextArea
                    text="Justificación"
                    placeholder="Ingresar..."
                    classNameParent="col-12 mb-2"
                    icon="fas fa-comment-alt"
                    required
                    autoFocus
                    id="txt_justify_disapprove"
                    rows={4}
                  />

                <div className="col-12 d-flex justify-content-center">        
                  <Button type="submit" variant="danger" className="mr-2" size="sm">
                    <i className="fa fa-check mr-2"></i>Rechazar
                  </Button>
                  <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalDisapprove(false)}>
                    <i className="fa fa-times mr-2"></i>Cancelar
                  </Button>
                </div>
                </form>)
                setModalDisapprove(true)
              }}>
                <i className={actionsModule.current.refuse.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
          </> : ''}

          {parseInt(r.state) > 0 && actionsModule.current.printrepac ?
          <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.printrepac.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.printrepac.color} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
              setModalPDF(false)
              window.open(Helpers.config.api.url + '/packsannual/consolidate/pdf/' + e.currentTarget.dataset.id + '?jwt=' + jwt, '_blank')
            }}>
              <i className={actionsModule.current.printrepac.icon}></i>
            </Button>
          </OverlayTrigger>
          : ''}
        </td>);
        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, jwt, thead, unit, rows, state])
 
  return <>
    <Card className="mb-2">
      <Card.Body className="form-row">
        {cboState}
        <div className="col-12 mt-2">
        {dtListPac}
        </div>
      </Card.Body>
    </Card>
    <Card>
      <Card.Body>
        <div id="form"></div>
        <FormCustom viewSubmit={isCorrectBudget() && detailsLeft.length > 0 && !isView} dataSubmit={dataSubmit()} onSubmit={onSubmit}>
          {!isCreated && !isUpdated ? (actionsModule.current.create ? cboUnit : '') : <Button onClick={() => {
            setDetails([])
            setUnitSelected(null)
            setDetailsLeft([])
            setCurrentUnit(null);
            setIsCreated(false)
            setIsUpdated(false)
            setBudgetAdd(false)
            setReload(true)
            setIsView(false)
          }} variant="danger" size="sm"><i className="fa fa-times mr-2"></i>Cancelar {isCreated ? 'registro' :( isView ? 'vista previa' : 'modificación')} de solicitud</Button>}
          {unitSelected ? <div className="col-12 d-flex justify-content-between mb-2 mt-2">
            <div className="text-muted d-flex flex-column">
              {isUpdated ? <span>ID: <strong>{unitSelected.number_header}</strong></span> : ''}
              <span>Centro De Costo: <strong>{unitSelected.label}</strong></span>
              <span>Cantidad Total: <strong>{unitSelected.qty}</strong></span>
              <span>Presupuesto: <strong>{Helpers.number.float.format({
                number: parseFloat(unitSelected.budget)
              })}</strong></span>
              <CheckBox
                checked={budgetAdd}
                text="¿Amplicación Presupuestaria?"
                onChange={e => {
                  if (e.currentTarget.checked) {
                    setDetailsLeft(detailsLeft.map(dl => {
                      if (dl.id_nivel === 'E') {
                        dl.newQtyStart = dl.qty_start
                        dl.newPrice = dl.price
                        dl.id_nivel = ''
                      }
                      return dl;
                    }))
                  }
                  setBudgetAdd(e.currentTarget.checked)
                }}
                classNameParent=""
                id="chk-budget-add"
                disabled={isView}
              />
              {unitSelected.justification ? <div>
                <strong className="text-danger">Justificación Rechazo:</strong> {unitSelected.justification}
              </div> : ''}
            </div>
            {isView ? '' : <Button onClick={() => setModalShow(true)} variant="primary" size="sm" className="align-self-end"><i className="fa fa-plus"></i><span className="ml-2 d-none d-md-inline-block">Asociar Insumos</span></Button>}
          </div> : ''}
          <div className="col-12 table-responsive d-flex justify-content-between">
            <table className="table table-sm table-hover table-striped table-bordered">
              <thead>
                <tr>
                  <th colSpan="6" className="alert alert-success">PLAN ANUAL DE COMPRAS AUTORIZADO PARA EL AÑO</th>
                  <th colSpan="6" className="alert alert-danger">PLAN ANUAL DE COMPRAS, MODIFICADO SOLICITADO</th>
                </tr>
                <tr>
                  <th className="alert alert-success p-1" width="1%">Renglón</th>
                  <th className="alert alert-success p-1" width="1%">Sub Prod.</th>
                  <th className="alert alert-success p-1" width="15%">Insumo</th>
                  <th className="alert alert-success p-1" width="1%">Cant.</th>
                  <th className="alert alert-success p-1" width="1%">Precio</th>
                  <th className="alert alert-success p-1" width="1%">Sub.</th>
                  <th className="alert alert-danger p-1" width="15%">Tipo Mod.</th>
                  <th className="alert alert-danger p-1" width="10%">Mod. Pres.</th>
                  <th className="alert alert-danger p-1" width="10%">Cant.</th>
                  <th className="alert alert-danger p-1" width="10%">Precio</th>
                  <th className="alert alert-danger p-1" width="5%">Sub.</th>
                  <th className="alert alert-danger p-1" width="15%">Justif.</th>
                  <th className="p-1" width="1%"></th>
                </tr>
              </thead>
              <tbody>
                {React.Children.toArray(detailsLeft.map((d, i) => {
                  let selectedNivel = nivel.find(n => n.id === d.id_nivel)

                  return <tr>
                    <td align="center">{d.id_row}</td>
                    <td align="center">{d.id_sub_products}</td>
                    <td>{d.cod_internal} - {d.description_internal}</td>
                    <td align="center">{d.qty_start}</td>
                    <td align="center">{d.price}</td>
                    <td align="center">{d.id_nivel === 'A' ? '' : (parseFloat(d.price) * parseInt(d.qty_start)).toFixed(2)}</td>
                    <td>
                      <Select
                        options={(d.exists ? nivel.filter(n => budgetAdd ? n.id !== 'A' && n.id !== 'RA' && n.id !== 'E' : n.id !== 'A' && n.id !== 'RA') : nivel).map(s => {
                          return {
                            value: s.id,
                            label: s.id + ' - ' + s.description
                          }
                        })}
                        onChange={e => {
                          setDetailsLeft(detailsLeft.map((d2, i2) => {
                            if (i2 === i) {
                              if (e.value === 'E') {
                                d2.newPrice = 0;
                                d2.newQtyStart = 0
                              } else {
                                d2.newPrice = d2.price;
                                d2.newQtyStart = d2.qty_start
                              }
                              d2.id_nivel = e.value
                            }
                            return d2;
                          }))
                        }}
                        value={selectedNivel ? { value: selectedNivel.id, label: selectedNivel.id + ' - ' + selectedNivel.description } : ''}
                        disabled={!d.exists || isView || d.id_nivel === 'RA'}
                      />
                    </td>
                    <td>
                    <Select
                      options={[{ value: 'SI', label: 'SI' }, { value: 'NO', label: 'NO' }]}
                      onChange={e => {
                        setDetailsLeft(detailsLeft.map((d2, i2) => {
                          if (i2 === i) {
                            d2.changeBudget = e.value
                          }
                          return d2;
                        }))
                      }}
                      value={{ id: d.changeBudget, label: d.changeBudget }}
                      disabled={isView}
                    />
                    </td>
                    <td align="center">
                    <Input
                      value={d.newQtyStart}
                      className="text-center"
                      type="number"
                      min={budgetAdd ? d.qty_start + 1 : '1'}
                      step="1"
                      required
                      onChange={e => {
                        setDetailsLeft(detailsLeft.map((d2, i2) => {
                          if (i2 === i) {
                            d2.newQtyStart = e.currentTarget.value
                          }
                          return d2;
                        }))
                      }}
                      disabled={d.id_nivel === '' || d.id_nivel === 'E' || d.id_nivel === 'VCU' || isView}
                    />
                    </td>
                    <td align="center">
                      <Input
                        value={d.newPrice}
                        className="text-center"
                        type="number"
                        min={budgetAdd ? d.price + .1 : '0.0000000001'}
                        step="0.0000000001"
                        required
                        onChange={e => {
                          setDetailsLeft(detailsLeft.map((d2, i2) => {
                            if (i2 === i) {
                              d2.newPrice = e.currentTarget.value
                            }
                            return d2;
                          }))
                        }}
                        disabled={d.id_nivel === '' || d.id_nivel === 'E' || d.id_nivel === 'VC' || isView}
                      />
                    </td>
                    <td align="center">
                      {!isNaN(parseInt(d.newQtyStart)) && !isNaN(parseFloat(d.newPrice)) ? parseFloat(d.newQtyStart * d.newPrice).toFixed(2) : '0.00'}
                    </td>
                    <td>
                      <TextArea
                        value={d.justification}
                        required
                        onChange={e => {
                          setDetailsLeft(detailsLeft.map((d2, i2) => {
                            if (i2 === i) {
                              d2.justification = e.currentTarget.value
                            }
                            return d2;
                          }))
                        }}
                        rows="5"
                        disabled={isView}
                      />
                    </td>
                    <td>
                      {isView ? '' : <Button variant="danger" size="xs" onClick={() => {
                        setDetailsLeft(detailsLeft.filter((d2, i2) => {
                          if (i2 === i) {
                            return false;
                          }
                          return d2;
                        }))
                      }}><i className="fa fa-minus"></i></Button>}
                    </td>
                  </tr>
                }))}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{totalAdded().toFixed(2)}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{totalAddedNew().toFixed(2)}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>

          {isCorrectBudget() || unitSelected === null ? '' : <div className="alert alert-danger d-flex flex-column col-12">
            <span>{budgetAdd ? 'El nuevo prespuesto debe de ser mayor al presupuesto actual' : 'El presupuesto actual no coincide con el nuevo presupuesto:'}</span>
            <span>Presupuesto Actual: <strong>{currentUnit === null ? '' : parseFloat(currentUnit.budget).toFixed(2)}</strong></span>
            <span>Presupuesto Nuevo: <strong>{currentUnit === null ? '' : parseFloat(parseFloat(currentUnit.budget) - totalAdded() + totalAddedNew()).toFixed(2)}</strong></span>
          </div>}
        </FormCustom>
      </Card.Body>
    </Card>
    <Modal show={modalPDF} onHide={() => setModalPDF(false)} size={'lg'} backdrop="static">
      <Modal.Header closeButton >
        <Modal.Title as="div"><i className="fa fa-list mr-2"></i>Programación De Insumos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {formPDF}
      </Modal.Body>
    </Modal>
    <Modal show={modalShowSubProductos} onHide={() => setModalShowSubProductos(false)} size={''} backdrop="static">
      <Modal.Header closeButton >
        <Modal.Title as="div"><i className="fa fa-list mr-2"></i>Listado De Insumos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
    <Modal show={modalDisapprove} onHide={() => setModalDisapprove(false)} size={'sm'} backdrop="static">
      <Modal.Header closeButton className={modalDisapproveHeader}>
        <Modal.Title as="div"><><i className="fa fa-check mr-2"></i>{modalDisapproveTitle}</></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {formDisapprove}
      </Modal.Body>
    </Modal>
    <Modal show={modalShow} onHide={() => setModalShow(false)} className="modal-custom-max" backdrop="static">
      <Modal.Header closeButton >
        <Modal.Title as="div"><i className="fa fa-list mr-2"></i>Listado De Insumos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Button size="sm" onClick={() => setModalShowSupplies(true)}>
        <i className="fa fa-plus mr-2"></i>Asociar Nuevo Insumo
      </Button>
      <SuppliesPublic showModal={modalShowSupplies} hideModal={() => setModalShowSupplies(false)} added={[]} onAdd={e => {
        const { id, description, codInternal, idRow, price } = e.currentTarget.dataset;

        setForm(<div className="table table-responsive">
          <div className="mb-2"><strong>Insumo: </strong>{codInternal} - {description}</div>
          <table className="table table-sm table-hover table-striped table-bordered" id="tbl-subproducts">
            <thead>
              <tr>
                <th width="25%">SubProducto</th>
                <th width="25%">Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {React.Children.toArray(subProducts.map(s => {
                return <tr>
                  <td align="center">{s.id} - {s.description_short}</td>
                  <td>
                  <Input
                    data-sub-product={s.id}
                    defaultValue={''}
                    className="text-center qty"
                    type="number"
                    min="1"
                    step="1"
                    required
                    onKeyUp={e => {
                      if  (e.keyCode === 13) {
                        let tbl = document.getElementById('tbl-subproducts')
                        let qt = tbl.querySelectorAll('.qty')
                        
                        let addDet = []
                        for (let i = 0; i < qt.length; i++) {
                          if (!isNaN(parseInt(qt[i].value))) {
                            if (!details.some(d2 => {
                              return parseInt(d2.id_supplie) === parseInt(id) && qt[i].dataset.subProduct === d2.id_sub_products
                            })) {
                              addDet.push({
                                cod_internal: codInternal,
                                description_internal: description,
                                id: '',
                                id_row: idRow,
                                id_sub_products: qt[i].dataset.subProduct,
                                id_supplie: id,
                                price: '',
                                qty_start: '',
                                id_nivel: 'A',
                                changeBudget: 'NO',
                                newQtyStart: qt[i].value,
                                newPrice: parseFloat(price).toFixed(2),
                                justification: '',
                                exists: false
                              })
                            } 
                          }
                        }
            
                        if (addDet.length) {
                          setDetailsLeft([...detailsLeft, ...addDet])
                        }
                        setModalShowSubProductos(false)
                      }
                    }}
                  />
                  </td>
                </tr>
              }))}
            </tbody>
          </table>

          <Button block size="sm" onClick={e => {
            let tbl = document.getElementById('tbl-subproducts')
            let qt = tbl.querySelectorAll('.qty')
            
            let addDet = []
            for (let i = 0; i < qt.length; i++) {
              if (!isNaN(parseInt(qt[i].value))) {
                if (!details.some(d2 => {
                  return parseInt(d2.id_supplie) === parseInt(id) && qt[i].dataset.subProduct === d2.id_sub_products
                })) {
                  addDet.push({
                    cod_internal: codInternal,
                    description_internal: description,
                    id: '',
                    id_row: idRow,
                    id_sub_products: qt[i].dataset.subProduct,
                    id_supplie: id,
                    price: '',
                    qty_start: '',
                    id_nivel: 'A',
                    changeBudget: 'NO',
                    newQtyStart: qt[i].value,
                    newPrice: parseFloat(price).toFixed(2),
                    justification: '',
                    exists: false
                  })
                } 
              }
            }

            if (addDet.length) {
              setDetailsLeft([...detailsLeft, ...addDet])
            }
            setModalShowSubProductos(false)
          }}>
              <i className="fa fa-check mr-2"></i>Agregar Insumo
          </Button>
        </div>)
        setModalShowSubProductos(true)
      }} />
      <div className="mb-2"></div>
      <DataTable
        tableScroll
        thead={[
          { name: 'Acc', align: 'center', width: '36px' },
          { name: 'SubProd', align: 'center', width: '96px' },
          { name: 'Reng', align: 'center', width: '65px' },
          { name: 'Ins', align: 'center', width: '144px' },
          { name: 'Cant Ini', align: 'center', width: '70px' },
          { name: '+', align: 'center', width: '70px' },
          { name: '-', align: 'center', width: '70px' },
          { name: 'Reserv', align: 'center', width: '70px' },
          { name: 'Ord Ejec', align: 'center', width: '70px' },
          { name: 'Ord Reserv', align: 'center', width: '70px' },
          { name: 'Cant Saldo', align: 'center', width: '70px' },
          { name: 'Precio', align: 'center', width: '70px' },
          { name: '+', align: 'center', width: '70px' },
          { name: '-', align: 'center', width: '70px' },
          { name: 'Saldo', align: 'center', width: '70px' },
          { name: 'Ord Tot R', align: 'center', width: '78px' },
          { name: 'Tot Ejec.', align: 'center', width: '79px' },
          { name: 'Tot', align: 'center', width: '79px' },
        ]}
        tbody={details}
        columnOrder={3}
        render={tbody => tbody.map((d) => {
          let notExits = !detailsLeft.some(d2 => parseInt(d2.id_supplie) === parseInt(d.id_supplie) && d2.id_sub_products === d.id_sub_products)
          return <tr style={{
            fontSize: '10px'
          }}>
            <td width="36px">
              <Button size="sm" variant={notExits ? 'dark' : 'primary'} onClick={e => {
                if (notExits) {
                  setDetailsLeft([...detailsLeft, { 
                    ...d, 
                    id_nivel: (parseFloat(d.price) + parseFloat(d.extension_price) - parseFloat(d.reduction_price)) === 0 ? 'RA' : '', changeBudget: 'NO',
                    newQtyStart: parseInt(d.qty_start) + parseInt(d.extension_qty) - parseInt(d.reduction_qty) - parseInt(d.reservated_qty) - parseInt(d.qty_order) - parseInt(d.qty_order_reservated), 
                    newPrice: parseFloat(d.price) + parseFloat(d.extension_price) - parseFloat(d.reduction_price), 
                    justification: '', exists: true, 
                    qty_start: parseInt(d.qty_start) + parseInt(d.extension_qty) - parseInt(d.reduction_qty) - parseInt(d.reservated_qty) - parseInt(d.qty_order) - parseInt(d.qty_order_reservated), 
                    price: parseFloat(d.price) + parseFloat(d.extension_price) - parseFloat(d.reduction_price) }])
                }
              }}>
                <i className={notExits ? 'fa fa-plus' : 'fa fa-check'}></i>
              </Button>
            </td>
            <td align="center" width="96px">
              <div className="d-flex flex-column">
                <span>{d.id_sub_products}</span>
                <div className={parseInt(d.qty_start) + parseInt(d.extension_qty) - parseInt(d.reduction_qty) - parseInt(d.reservated_qty) - parseInt(d.qty_order) - parseInt(d.qty_order_reservated) > 0 ? 'badge badge-success' : 'badge badge-danger'}>
                  {parseInt(d.qty_start) + parseInt(d.extension_qty) - parseInt(d.reduction_qty) - parseInt(d.reservated_qty) - parseInt(d.qty_order) - parseInt(d.qty_order_reservated) > 0 ? 'Con Saldo' : 'Sin Saldo'}
                </div>
              </div>
            </td>
            <td align="center" width="66px">
              {d.id_row}
            </td>
            <td width="143px">
              {d.cod_internal} - {d.description_internal}
            </td>
            <td width="70px" align="center" className="alert alert-info">{d.qty_start}</td>
            <td width="70px" align="center" className="alert alert-info">{d.extension_qty}</td>
            <td width="70px" align="center" className="alert alert-info">{d.reduction_qty}</td>
            <td width="70px" align="center" className="alert alert-info">{d.reservated_qty}</td>
            <td width="70px" align="center" className="alert alert-info">{d.qty_order}</td>
            <td width="70px" align="center" className="alert alert-info">{d.qty_order_reservated}</td>
            <td width="70px" align="center" className="alert alert-info">{parseFloat(d.qty_start) + parseFloat(d.extension_qty) - parseFloat(d.reduction_qty) - parseFloat(d.reservated_qty) - parseFloat(d.qty_order) - parseFloat(d.qty_order_reservated)}</td>
            <td width="70px" align="center" className="alert alert-danger">{d.price}</td>
            <td width="70px" align="center" className="alert alert-danger">{parseFloat(d.extension_price).toFixed(2)}</td>
            <td width="70px" align="center" className="alert alert-danger">{parseFloat(d.reduction_price).toFixed(2)}</td>
            <td width="70px" align="center" className="alert alert-danger">{parseFloat(d.price) + parseFloat(d.extension_price) - parseFloat(d.reduction_price)}</td>
            <td width="78px" align="center">
              {parseFloat(d.total_order).toFixed(2)}
            </td>
            <td width="78px" align="center">
              {parseFloat(d.total_order_ejec).toFixed(2)}
            </td>
            <td width="80px" align="center">
            {parseFloat(parseFloat(d.total) - parseFloat(d.total_order)).toFixed(2)}
            </td>
          </tr>
        })}
      />
      </Modal.Body>
    </Modal>
  </>
}

export default ReProgrammatedSupplie;