import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Nav from './Styled/Nav';
import Header from './Styled/Header';
import PageWrapper from './Styled/PageWrapper';
import Footer from './Styled/Footer';
import { Link } from 'react-router-dom';
import Helpers from '../../Helpers/Helpers';
import DropDown from './Styled/DropDown';

const Layout = ({ page, type, children, title, showBread }) => {
  const [resize, setResize] = useState(true);
  const [resizeOpen, setResizeOpen] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [navigation, setNavigation] = useState([]);
  const [idxActive, setIdxActive] = useState(-1);
  const [touchStartX, setTouchStartX] = useState(0);
  const session = useSelector(store => store.session);
  const permissions = useSelector(store => store.session.permissions);

  useEffect(() => {
    window.document.title = title + ' - ' + Helpers.config.company.name;
  }, [title]);

  useEffect(() => {
    let nav = [];
    let i = 0;

    permissions.forEach(d => {
      nav.push(<Nav.Title resizeOpen={resizeOpen} resize={resize}>
        <span>{d.parent.description}</span>
        <i className="fas fa-ellipsis-h"></i>
      </Nav.Title>);

      d.childrens.forEach(p => {
        let childs = [];
        let itemActive = false;

        p.childrens.forEach(ch => {
          if (!itemActive) {
            if (idxActive === -1) {
              if (ch.a_href === page) {
                setIdxActive(i);
              }
            } else {
              if (idxActive === i) {
                itemActive = true;
              }
            }
          }

          if (parseInt(ch.show) === 1) {
            childs.push(<Link to={ch.a_href} style={{
              textDecoration: 'none',
              color: 'inherit'
            }}>
              <Nav.SecondLevel.Item active={page === ch.a_href}>
                <Nav.SecondLevel.Title>
                  <Nav.SecondLevel.Icon />
                  <Nav.SecondLevel.Description resizeOpen={resizeOpen} resize={resize}>{ch.description}</Nav.SecondLevel.Description>
                </Nav.SecondLevel.Title>
              </Nav.SecondLevel.Item>
            </Link>);
          }
        });

        nav.push(<Nav.OneLevel.Item resizeOpen={resizeOpen} resize={resize}>
          <Nav.OneLevel.TitleContent resizeOpen={resizeOpen} resize={resize} data-idx={i} active={itemActive} onClick={e => setIdxActive(idxActive === parseInt(e.currentTarget.dataset.idx) ? -2 : parseInt(e.currentTarget.dataset.idx))}>
            <Nav.OneLevel.Title>
              <Nav.OneLevel.Icon resizeOpen={resizeOpen} resize={resize}>
                {type === 'FontAwesome' ? <i className={p.parent.icon}></i> : ''}
              </Nav.OneLevel.Icon>
              <Nav.OneLevel.Description resizeOpen={resizeOpen} resize={resize}>{p.parent.description}</Nav.OneLevel.Description>
            </Nav.OneLevel.Title>
            <Nav.OneLevel.IconArrow resizeOpen={resizeOpen} resize={resize} active={itemActive} />
          </Nav.OneLevel.TitleContent>

          <Nav.SecondLevel active={itemActive} resizeOpen={resizeOpen} resize={resize}>
            {React.Children.toArray(childs)}
          </Nav.SecondLevel>
        </Nav.OneLevel.Item>);

        i++;
      });
    });

    setNavigation(nav);
  }, [permissions, type, resize, resizeOpen, idxActive, page]);

  const breadCrumb = useMemo(() => {
    let bread = {}

    permissions.forEach(d => {
      d.childrens.forEach(p => {
        p.childrens.forEach(ch => {
          if (ch.a_href === page) {
            bread = {
              parent: d.parent.description,
              child: p.parent.description,
              current: ch.description
            }
          }
        })
      });
    });

    return <>
      <PageWrapper.BreadCrumb.Title>{bread.parent}</PageWrapper.BreadCrumb.Title>
      <PageWrapper.BreadCrumb.Icon />
      <PageWrapper.BreadCrumb.Title>{bread.child}</PageWrapper.BreadCrumb.Title>
      <PageWrapper.BreadCrumb.Icon />
      <PageWrapper.BreadCrumb.Title active>{bread.current}</PageWrapper.BreadCrumb.Title>
    </>
  }, [page, permissions]);

  return (<>
    <Nav.Swipe
    onTouchStart={e => setTouchStartX(e.changedTouches[0].screenX)}
    onTouchEnd={e => {
      if (touchStartX < e.changedTouches[0].screenX) {
        setMobile(true);
      }
    }} />
    <Nav.Bg mobile={mobile} onClick={() => setMobile(false)}
      onTouchStart={e => setTouchStartX(e.changedTouches[0].screenX)}
      onTouchEnd={e => {
        if (touchStartX > e.changedTouches[0].screenX) {
          setMobile(false);
        }
      }}
    />
    <Nav resizeOpen={resizeOpen} resize={resize} mobile={mobile} onMouseEnter={() => setResizeOpen(true)} onMouseLeave={() => setResizeOpen(false)}>
      <Nav.Brand resize={resize} resizeOpen={resizeOpen}>
        <Nav.Brand.Link href={Helpers.config.folder_path + '/'}>
          <Nav.Brand.Logo src={Helpers.config.api.images + '/logos/isotipo_logotipo.png'} alt="" resize={resize} resizeOpen={resizeOpen} />
        </Nav.Brand.Link>
        <Nav.Brand.IconSize resize={resize} resizeOpen={resizeOpen} onClick={() => setResize(!resize)}>
          {resize ? <i className="far fa-circle" /> : <i className="far fa-dot-circle"></i>}
        </Nav.Brand.IconSize>
        
        <Nav.Brand.IconMobile onClick={() => setMobile(!mobile)}>
          <i className="fa fa-times" />
        </Nav.Brand.IconMobile>
      </Nav.Brand>

      <Nav.OneLevel>
        {React.Children.toArray(navigation)}
      </Nav.OneLevel>
    </Nav>

    <Header resize={resize}>
      <Header.Left>
        <i className="fa fa-bars align-self-center" onClick={() => setMobile(true)}></i>
      </Header.Left>
      <Header.Brand>
        <Nav.Brand.Link href={Helpers.config.folder_path + '/'}>
          <Header.Brand.Logo src={Helpers.config.api.images + '/logos/isotipo_logotipo.png'} alt="" />
        </Nav.Brand.Link>
      </Header.Brand>
      <Header.Right>
        {/* <DropDown>
          <Header.Right.Icon>
            <i className="far fa-bell"></i>
          </Header.Right.Icon>
          <DropDown.Body position="left">
            
          </DropDown.Body>
        </DropDown> */}
        <Header.Right.Icon className="d-none d-md-flex">
          <strong style={{
            fontSize: '12px'
          }}>{session.user}</strong>    
        </Header.Right.Icon>
        <DropDown>
          <Header.Right.Icon>
            <Header.Right.Image src={Helpers.config.api.images + '/user/user.png'} alt="" />
          </Header.Right.Icon>
          <DropDown.Body position="left">
            <Header.Right.Profile>
              <Header.Right.Image maxHeight="70px" src={Helpers.config.api.images + '/user/user.png'} alt="" />
              <strong>{session.name}</strong>
              <span>{session.description_profession}</span>
            </Header.Right.Profile>
            <DropDown.Item onClick={() => {
              localStorage.clear();
              window.location.href = Helpers.config.folder_path + '/';
            }}>
              <i className="fas fa-sign-out-alt"></i>
              <span>Salir</span>
            </DropDown.Item>
            <DropDown.Item onClick={() => {
              window.location.href = Helpers.config.folder_path + '/miinformacion';
            }}>
              <i className="fas fa-user"></i>
              <span>Mi Información</span>
            </DropDown.Item>
          </DropDown.Body>
        </DropDown>
      </Header.Right>
    </Header>

    <PageWrapper resize={resize}>
      <PageWrapper.BgTop>
        <PageWrapper.Title>{title}</PageWrapper.Title>
        {showBread ? 
        <PageWrapper.BreadCrumb>
          {breadCrumb}
        </PageWrapper.BreadCrumb>
        : ''}
      </PageWrapper.BgTop>
      <PageWrapper.Body>{children}</PageWrapper.Body>
    </PageWrapper>

    <Footer resize={resize}>
      Derechos Reservados Por {Helpers.config.company.name}
    </Footer>
  </>
  );
}

Layout.defaultProps = {
  type: 'FontAwesome',
  showBread: true
}

Layout.propTypes = {
  page: PropTypes.string,
  logo: PropTypes.string,
  type: PropTypes.oneOf(['FontAwesome']),
  title: PropTypes.string,
  showBread: PropTypes.bool
}
export default Layout;