import axios from 'axios';
import Store from '../Redux/Store';
import { setSession } from '../Redux/Actions';

const Helpers = {}

Helpers.config = {
  systemType: process.env.NODE_ENV === 'production' ? window.systemType : 'CUSTOMERS',
  isDeveloperWeb: _ => {
    return Helpers.config.systemType === 'DEVELOPERPERU'
  },
  isCustomers: _ => {
    return Helpers.config.systemType === 'CUSTOMERS'
  },
  isDev: _ => {
    return Helpers.config.systemType === 'DEV'
  },
  isKey: _ => false,
  showConsole: false,
  folder_path: process.env.NODE_ENV === 'production' ? window.pathFolder : process.env.REACT_APP_FOLDER_PATH,
  isProduction: process.env.NODE_ENV === 'production',
  api: {
    url: process.env.NODE_ENV === 'production' ? window.pathUrl : process.env.REACT_APP_API_URL,
    images: process.env.NODE_ENV === 'production' ? window.pathUrlAssets : process.env.REACT_APP_IMAGES_URL
  },
  company: {
    name: process.env.NODE_ENV === 'production' ? window.pathCompanyName : process.env.REACT_APP_COMPANY_NAME
  }
}

/**
 * Realizar peticiones por a la API usando axios
 * 
 * @param {String} method Método de la petición
 * @param {String} url URL de la petición
 * @param {Object} body Parámetros a envíar
 * 
 * @returns {Promise} Promesa con la petición
 */
Helpers.axios = ({ method = 'GET', url = '', data = {}, hasFile = false }) => {
  let urlReq = method === 'GET' ? url + '?jwt=' + Helpers.localStorage.getJWT() : url;
  let bodyReq;
  if (method === 'POST') {
    if (hasFile) {
      bodyReq = data;
      bodyReq.append('jwt', Helpers.localStorage.getJWT());
    } else {
      bodyReq = { ...data };
      bodyReq.jwt = Helpers.localStorage.getJWT();
    }
  } else {
    if (data) {
      for (const key in data) {
        urlReq += '&' + key + '=' + data[key]
      }
    }
  }
  let config = {
    method,
    url: Helpers.config.api.url + urlReq,
    data: bodyReq
  }

  if (hasFile) {
    config.headers = { 'content-type': 'multipart/form-data' }
  }

  axios({
    url: Helpers.config.api.url + '/users/session',
    method: 'POST',
    data: {
      jwt: Helpers.localStorage.getJWT()
    }
  }).then(res => {
    Store.dispatch(setSession(res.data.data));
    Helpers.localStorage.setSession({
      jwt: res.data.data.jwt
    });
  })

  return new Promise((resolve, reject) => {
    axios(config).then(res => {
      return res.data;
    }).then(res => {
      if (!Helpers.config.isProduction && Helpers.config.showConsole) {
        console.log(res);
      }
      if (res.message === 'error_session_api') {
        localStorage.clear();
        window.location.href = Helpers.config.folder_path + '/';
        return;
      }
      if (res.hasOwnProperty('data')) {
        resolve(res);
      } else {
        reject({
          response: 'danger',
          message: 'No se ha podido realizar la conexión al servidor. Contacte con el área de IT.'
        });
      }
    }).catch(req => {
      if (!Helpers.config.isProduction) {
        console.log(req);
      }
      reject({
        response: 'danger',
        message: 'No se ha podido realizar la conexión al servidor. Contacte con el área de IT.'
      });
    })
  })
}

Helpers.promise = {}
Helpers.promise.catch = ({ req = {}, toastr = {} }) => {
  if (req.response) {
    Helpers.toastr.construct({ response: req.response, message: req.message, toastr });
  } else {
    Helpers.toastr.construct({ response: 'error', message: 'No se ha podido realizar la conexión al servidor. Contacte con el área de IT.', toastr });
  }
}

Helpers.toastr = {
  options: ({ toastr }) => {
    return {
      autoClose: 5000,
      hideProgressBar: false,
      pauseOnHover: true,
      progress: 0.2
    }
  },
  construct: ({ response = '', message = '', toastr = {} }) => {
    if (response === 'success') {
      toastr.success(message, '', Helpers.toastr.options({ toastr }));
    } else if (response === 'info') {
      toastr.info(message, '', Helpers.toastr.options({ toastr }));
    } else if (response === 'warning') {
      toastr.warning(message, '', Helpers.toastr.options({ toastr }));
    } else {
      toastr.error(message, '', Helpers.toastr.options({ toastr }));
    }
  }
}

Helpers.form = {
  isFormCorrect: ({ form, toastr }) => {
    let hasError = false;
    let f = form.elements;
    for (let i = 0; i < f.length; i++) {
      let ele = f[i];
      if (ele.tagName === 'INPUT') {
        if (!Helpers.form.isCorrectInput({ ele, toastr })) {
          hasError = true;
          break;
        }
      }
      else if (ele.tagName === 'TEXTAREA') {
        if (!Helpers.form.isCorrectTextarea({ ele, toastr })) {
          hasError = true;
          break;
        }
      }
      else if (ele.tagName === 'SELECT') {
        if (!Helpers.form.isCorrectSelect({ ele })) {
          hasError = true;
          break;
        }
      }
    }

    return !hasError;
  },
  isEmpty: ({ ele }) => {
    if (ele.hasAttribute('required') && !ele.hasAttribute('disabled') && ele.value === '') {
      ele.focus();
      return true;
    }
    return false;;
  },
  existsTrim: ({ ele, toastr }) => {
    if (ele.value !== ele.value.trim()) {
      Helpers.toastr.construct({ response: 'warning', message: 'Por favor, eliminar espacio en blanco a los costados', toastr });
      ele.focus();
      return true;
    }
    return false;
  },
  isEmptyOrExistsTrim: ({ ele, toastr }) => {
    if (Helpers.form.isEmpty({ ele }) || Helpers.form.existsTrim({ ele, toastr })) {
      return true;
    }
    return false;;
  },
  /* isCorrectPattern: ({ ele, toastr }) => {
    if (ele.hasAttribute('pattern')) {
      let data = ele.dataset;
      if (!new RegExp('^' + ele.getAttribute('pattern') + '$').exec(ele.value)) {
        Helpers.toastr.construct({ response: 'warning', message: data.patternMessage, toastr });
        ele.focus();
        return false;
      }
    }
    return true;
  }, */
  isCorrectEmail: (/* { ele, toastr } */) => {
    return true;
  },
  isCorrectDate: (/* { ele, toastr } */) => {
    return true;
  },
  isCorrectInput: ({ ele, toastr }) => {
    if (Helpers.form.isEmptyOrExistsTrim({ ele, toastr })) {
      return false;
    } /* else if (!Helpers.form.isCorrectPattern({ ele, toastr })) {
      return false;
    } */

    if (ele.type === 'email') {
      if (!Helpers.form.isCorrectEmail({ ele, toastr })) {
        return false
      }
    }

    if (ele.type === 'date') {
      if (!Helpers.form.isCorrectDate({ ele, toastr })) {
        return false
      }
    }

    /* if (ele.type === 'number') {
      if (ele.hasAttribute('required') && ele.hasAttribute('min')) {
        if (parseFloat(ele.value) < parseFloat(ele.getAttribute('min'))) {
          ele.focus()
          return false;
        }
      }

      if (ele.hasAttribute('required') && ele.hasAttribute('max')) {
        if (parseFloat(ele.value) > parseFloat(ele.getAttribute('max'))) {
          ele.focus()
          return false;
        }
      }
    } */

    return true;
  },
  isCorrectTextarea: ({ ele, toastr }) => {
    if (Helpers.form.isEmpty({ ele, toastr })) {
      return false;
    }

    return true;
  },
  isCorrectSelect: ({ ele }) => {
    if (Helpers.form.isEmpty({ ele })) {
      ele.focus();
      return false;
    }
    return true;
  }
}

Helpers.icon = {
  spin: () => {
    return ('<i class="fa fa-circle-notch fa-spin"></i>');
  }
}

Helpers.views = {
  gridMaintenance: () => {
    return {
      thead: [
        { name: 'ID', align: 'center' },
        { name: 'Descripción' },
        { name: 'Estado', align: 'center' }
      ]
    }
  }
}

Helpers.localStorage = {
  name: 'session_react',
  getSession: () => JSON.parse(localStorage.getItem(Helpers.localStorage.name)),
  exists: () => localStorage.getItem(Helpers.localStorage.name),
  existsJWT: () => {
    if (Helpers.localStorage.exists()) {
      if (Helpers.localStorage.getSession().jwt) {
        return true;
      }
    }
    return false;
  },
  setSession: (session) => localStorage.setItem(Helpers.localStorage.name, JSON.stringify(session)),
  getJWT: () => Helpers.localStorage.existsJWT() ? Helpers.localStorage.getSession().jwt : ''
}

Helpers.getActions = ({ permissions, name }) => {
  let actions = {}
  permissions.forEach(d => {
    d.childrens.forEach(p => {
      p.childrens.forEach(ch => {
        if (ch.a_href === name) {
          ch.actions_profile.forEach(act => {
            if (parseInt(act.enabled) === 1) {
              actions[act.field] = {
                color: act.color,
                icon: act.icon,
                description: act.description
              }
            }
          })
        }
      })
    })
  })

  return actions
}

Helpers.date = {
  get: ({ format = '%Y-%m-%d', addDays = 0 } = {}) => {
    let date = new Date()
    if (addDays !== 0) {
      date.setDate(date.getDate() + addDays)
    }

    let d = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
      hours: date.getHours(),
      minutes: date.getMinutes()
    }
    let dateTex = format.replace('%Y', d.year)
      .replace('%m', (d.month + 1 < 10 ? '0' : '') + (d.month + 1))
      .replace('%d', (d.day < 10 ? '0' : '') + d.day)
      .replace('%H', (d.hours < 10 ? '0' : '') + d.hours)
      .replace('%i', (d.minutes < 10 ? '0' : '') + d.minutes)

    return dateTex
  }
}

Helpers.number = {
  float: {
    format: ({ number } = {}) => {
      return parseFloat(number).toLocaleString('es-MX', { minimumFractionDigits: 2 })
    }
  }
}

export default Helpers;