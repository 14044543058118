import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Controllers from '../Api/Controllers';
import { FormCustom, Input, CheckBox } from '../Components/Form/Form';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import ReactPlaceholder from 'react-placeholder/lib';
import Accordion from 'react-bootstrap/Accordion';
import {useSelector} from 'react-redux'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ProfileForm = ({ data, onSubmit, viewDisabled }) => {
  const {
    id = '',
    description = '',
  } = data

  const [row, setRow] = useState({
    id,
    description,
  })

  const [tagsItems, setTagsItems] = useState([])
  const [actions, setActions] = useState([])
  const [isOk, setIsOk] = useState(false)

  const dataSumbit = useCallback(() => {
    return {
      p_id: row.id,
      p_description: row.description,
      p_tags_items: tagsItems
    }
  }, [row, tagsItems])

  useEffect(() => {
    let promises = [
      Controllers.profile.get_tags_items_profile({
        p_id: id
      }),
      Controllers.actions.get_actions_public()
    ]
    Promise.all(promises).then(res => {
      setTagsItems(res[0].data)
      setActions(res[1].data)
      setIsOk(true)
    })
  }, [id])

  return <ReactPlaceholder showLoadingAnimation rows={7}  type='text' ready={isOk}>
    <FormCustom dataSubmit={dataSumbit()} onSubmit={onSubmit} viewSubmit={!viewDisabled}>
      <Input
        text="Descripción"
        placeholder="Descripción"
        classNameParent="col-12 mb-2"
        icon="far fa-comment-alt"
        required
        defaultValue={description}
        onChange={e => setRow({ ...row, description: e.currentTarget.value })}
        maxLength={100}
        disabled={viewDisabled}
      />

      <Accordion className="col-12 mb-2 mt-2">
      {React.Children.toArray(tagsItems.map((t, i) => {
        return <Card>
          <Card.Header className="justify-content-between">
            <Accordion.Toggle as={Button} variant="link" eventKey={i}>
              {t.parent.description}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={i}>
            <Card.Body className="form-row">
              <div className="col-12">
                <Card>
                  <Card.Header className="justify-content-between">
                    <strong>Listado De Submenús</strong>
                  </Card.Header>
                </Card>
              </div>
              <Accordion className="col-12">
                {React.Children.toArray(t.childrens.map((p, j) => {
                  return <Card>
                    <Card.Header className="justify-content-between">
                      <Accordion.Toggle as={Button} variant="link" eventKey={j}>
                        {p.parent.description}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={j}>
                      <Card.Body className="form-row">
                        <div className="col-12">
                          <Card>
                            <Card.Header className="justify-content-between">
                              <strong>Seleccionar Privilegios</strong>
                            </Card.Header>
                          </Card>
                        </div>
                        <Accordion className="col-12">
                          {React.Children.toArray(p.childrens.map((ch, k) => {
                            return <Card>
                              <Card.Header>
                                <CheckBox
                                  id={'chk-' + ch.id_tags_items_parent_master + '-' + ch.id_tags_items_parent + '-' + ch.id_tags_items}
                                  classNameParent=""
                                  checked={parseInt(ch.enabled) === 1}
                                  onChange={e => setTagsItems(tagsItems.map((t2, i2) => {
                                    if (i === i2) {
                                      t2.childrens = t2.childrens.map((p2, j2) => {
                                        if (j === j2) {
                                          p2.childrens = p2.childrens.map((ch2, k2) => {
                                            if (k === k2) {
                                              ch2.enabled = e.currentTarget.checked ? 1 : 0
                                              ch2.main = 0
                                              ch2.actions_profile = ch2.actions_profile.map(prof_act => {
                                                prof_act.enabled = 0
                                                return prof_act
                                              })
                                            }
                                            return ch2;
                                          })
                                        }   
                                        return p2;
                                      })
                                    }
                                    return t2;
                                  }))}
                                  disabled={viewDisabled}
                                />
                                <Accordion.Toggle as={Button} variant="link" eventKey={k}>
                                  {ch.description}                             
                                </Accordion.Toggle>
                                {parseInt(ch.show) === 1 ? <Button disabled={parseInt(ch.enabled) === 0 || viewDisabled} variant="" size="xs" className="ml-2" onClick={e => setTagsItems(tagsItems.map((t2, i2) => {
                                    if (i === i2) {
                                      t2.childrens = t2.childrens.map((p2, j2) => {
                                        if (j === j2) {
                                          p2.childrens = p2.childrens.map((ch2, k2) => {
                                            if (k === k2) {
                                              ch2.main = parseInt(ch2.main) === 0 ? 1 : 0
                                            } else {
                                              ch2.main = 0
                                            }
                                            return ch2;
                                          })
                                        } else {
                                          p2.childrens = p2.childrens.map((ch2) => {
                                            ch2.main = 0
                                            return ch2;
                                          })
                                        }
                                        return p2;
                                      })
                                    }
                                    return t2;
                                  }))}>
                                  <i className={parseInt(ch.main) === 1 ? 'fa fa-star text-success' : 'far fa-star'}></i>
                                </Button> : ''}
                              </Card.Header>
                              <Accordion.Collapse eventKey={k}>
                                <Card.Body className="form-row">
                                  {React.Children.toArray(actions.map((a, l) => {
                                    return ch.tags_actions.some(tg_act => parseInt(tg_act.id_actions) === parseInt(a.id_actions)) ? <CheckBox
                                      id={ch.id_tags_items_parent_master + '-' + ch.id_tags_items_parent + '-' + ch.id_tags_items + '-' + l}
                                      text={a.description_actions}
                                      disabled={parseInt(ch.enabled) === 0 || viewDisabled}
                                      checked={ch.actions_profile.some(prof_act => parseInt(prof_act.id_actions) === parseInt(a.id_actions) && parseInt(prof_act.enabled) === 1)}
                                      onChange={e => setTagsItems(tagsItems.map((t2, i2) => {
                                        if (i === i2) {
                                          t2.childrens = t2.childrens.map((p2, j2) => {
                                            if (j === j2) {
                                              p2.childrens = p2.childrens.map((ch2, k2) => {
                                                if (k === k2) {
                                                  if (!ch2.actions_profile.some(prof_act => parseInt(prof_act.id_actions) === parseInt(a.id_actions))) {
                                                    ch2.actions_profile.push({
                                                      id_actions: a.id_actions,
                                                      enabled: e.currentTarget.checked ? 1 : 0
                                                    })
                                                  } else {
                                                    ch2.actions_profile = ch2.actions_profile.map(prof_act => {
                                                      if (parseInt(prof_act.id_actions) === parseInt(a.id_actions)) {
                                                        prof_act.enabled = e.currentTarget.checked ? 1 : 0
                                                      }
                                                      return prof_act
                                                    })
                                                  }
                                                }
                                                return ch2;
                                              })
                                            }   
                                            return p2;
                                          })
                                        }
                                        return t2;
                                      }))}
                                    /> : ''
                                  }))}
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          }))}
                        </Accordion>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                }))}
              </Accordion>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      }))}
      </Accordion>
    </FormCustom>
  </ReactPlaceholder>
}

const Profile = () => {
  const nameModulo = 'Perfiles'
  const namePage = 'Perfil'
  const nameController = 'profile'
  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = Helpers.getActions({ permissions, name: 'perfiles' })

  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [modalSize, setModalSize] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [form, setForm] = useState('');
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      Controllers[nameController]['get_' + nameController]().then(res => {
        setRows(res.data)
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload])

  const handleUpdate = useCallback(e => {
    const { id = '', disabled = 0 } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()
    
    setModalTitle(parseInt(disabled) === 1 ? <><i className={'mr-2 ' + actionsModule.view.icon}></i>{actionsModule.view.description} {namePage}</> : (id ? <><i className={'mr-2 ' + actionsModule.update.icon}></i>{actionsModule.update.description} {namePage}</> : <><i className={'mr-2 ' + actionsModule.create.icon}></i>{actionsModule.create.description} {namePage}</>))
    setModalSize('xl');
    setModalHeader('');

    Controllers[nameController]['get_' + nameController + '_id']({ p_id: id }).then(res => {
      setForm(<ProfileForm data={res.data} onSubmit={onSubmit} viewDisabled={parseInt(disabled) === 1} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }, [actionsModule])

  const onSubmit = ({ e, dataSubmit }) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }

      let hasMain = false
      dataSubmit.p_tags_items.forEach(d => {
        d.childrens.forEach(p => {
          p.childrens.forEach(ch => {
            if (parseInt(ch.main) === 1) {
              hasMain = true
            }
          })
        })
      })

      if (!hasMain) {
        Helpers.toastr.construct({ response: 'warning', message: 'Por favor, seleccione el módulo inicial', toastr })
        return
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers[nameController][nameController + '_insert_update'](dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        setModalShow(false);
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
      setReload(true)
    });
  }

  const onDelete = e => {
    const { id = '' } = e.currentTarget.dataset;
    setModalTitle(<><i className="fa fa-trash mr-2"></i> Eliminar {namePage}</>);
    setModalHeader('bg-danger');
    setModalSize('sm');
    setForm(<div className="d-flex flex-column align-items-center">
      <p><strong>¿Eliminar {namePage} #{id}?</strong></p>

      <div>        
        <Button variant="danger" className="mr-2" size="sm" onClick={e => {
          let button = e.currentTarget
          let buttonHTML = button.innerHTML;
          button.innerHTML = Helpers.icon.spin()

          Controllers[nameController][nameController + '_delete']({
            p_id: id
          }).then(res => {
            Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
          }).catch(req => {
            Helpers.promise.catch({ req, toastr });
          }).finally(() => {
            button.innerHTML = buttonHTML;
            setReload(true)
            setModalShow(false);
          });
        }}>
          <i className="fa fa-check mr-2"></i>Si
        </Button>
        <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalShow(false)}>
          <i className="fa fa-times mr-2"></i>No
        </Button>
      </div>
    </div>);
    setModalShow(true)
  }

  const dtRows = useMemo(() => {
    const thead = [
      { name: 'ID', align: 'center' },
      { name: 'Descripción' },
      { name: 'Acc.', align: 'center' }
    ];

    return <DataTable
      isProcessing={isProcessing}
      tbody={rows}
      thead={thead}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;
        for (let key in r) {
          if (key === 'auto_generated') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
              <strong className={'text-' + (parseInt(r.auto_generated) === 1 ? 'success': 'danger')}>
                {parseInt(r.auto_generated) === 1 ? 'SI': 'NO'}
              </strong>
            </td>);
          } else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }

        if (parseInt(r.id) === 1 && Helpers.config.isDeveloperWeb()) {
          rowRender.push(<td></td>)
        } else {
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
          {actionsModule.view ?  <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.view.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.view.color} data-disabled={1} size="xs" className="mr-1" data-id={r.id} onClick={handleUpdate}>
              <i className={actionsModule.view.icon}></i>
            </Button>
          </OverlayTrigger> : ''}
          {actionsModule.update ?  <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.update.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.update.color} size="xs" className="mr-1" data-id={r.id} onClick={handleUpdate}>
              <i className={actionsModule.update.icon}></i>
            </Button>
          </OverlayTrigger> : ''}
          {actionsModule.delete ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.delete.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.delete.color} size="xs" data-id={r.id} onClick={onDelete}>
              <i className={actionsModule.delete.icon}></i>
            </Button>
          </OverlayTrigger> : ''}
        </td>);
        }
        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, rows, handleUpdate, actionsModule])

  return <> 
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De {nameModulo}</span>
        {actionsModule.create ? <Button onClick={handleUpdate} variant={actionsModule.create.color} size="sm"><i className={ actionsModule.create.icon}></i><span className="ml-2 d-none d-md-inline-block">{actionsModule.create.description} {namePage}</span></Button> : ''}
      </Card.Header>
      <Card.Body>
        {dtRows}
      </Card.Body>
    </Card>
    <Modal show={modalShow} onHide={() => setModalShow(false)} size={modalSize} backdrop="static">
      <Modal.Header closeButton className={modalHeader}>
        <Modal.Title as="div">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
  </>
}

export default Profile;