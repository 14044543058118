import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Controllers from '../Api/Controllers';
import { FormCustom, Input, Select, TextArea} from '../Components/Form/Form';
import { useSelector } from 'react-redux';
import ReactPlaceholder from 'react-placeholder';
import Helpers from '../Helpers/Helpers';
import DataTable from '../Components/DataTable/DataTable';
import { toast as toastr } from 'react-toastify';
import Alert from 'react-bootstrap/Alert'

const SuppliesPublic = ({ showModal, hideModal, added, onAdd, supplies }) => {
  const dtRows = useMemo(() => {
    const thead = [
      { name: 'Acc.', align: 'center'},
      { name: 'SubProd', align: 'center'},
      { name: 'Cod PPR', align: 'center' },
      { name: 'Cod Interno', align: 'center' },
      { name: 'Descripción' },
      { name: 'Tipo', align: 'center' },
      { name: 'Precio', align: 'center' },
      { name: 'Presentación', align: 'center' },
      { name: 'Renglón', align: 'center' },
      { name: 'Catalogo', align: 'center' },
    ];

    return <DataTable
      tbody={supplies}
      thead={thead}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;
        let exists = added.some(a => parseInt(a.id) === parseInt(r.id) && a.idSubProducts === r.id_sub_products)
        let onClick = e => {
          if (!exists) {
            onAdd(e);
          }
        }
        rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
          <Button onClick={onClick} data-has-catalog={r.has_catalog} data-description-ppr={r.description_ppr} data-price={r.price} data-id-row={r.id_row} variant={exists ? 'dark' : 'primary'} size="sm" data-id={r.id} data-description={r.description_internal}data-cod-internal={r.cod_internal} data-id-sub-products={r.id_sub_products} data-qty={r.qty} data-qty-order-reservated={r.qty_order_reservated} data-qty-order={r.qty_order} data-type-supplies={r.description_type_supplies}><i className={exists ? 'fa fa-check' : 'fa fa-plus'}></i></Button>
        </td>)
        i++;

        for (let key in r) {
          if (key === 'id' || key === 'id_row' || key === 'description_ppr' || key === 'qty' || key === 'qty_order_reservated' || key === 'qty_order') {
            continue;
          }

          if (key === 'state') {
            continue;
          }

          if (key === 'state') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''} className={'text-' + (parseInt(r.state) === 1 ? 'success' : 'danger')}>
              <strong>{parseInt(r.state) === 1 ? 'ACTIVO' : 'INACTIVO'}</strong>
            </td>);
          } else if (key === 'has_catalog') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''} className={'text-' + (parseInt(r.has_catalog) === 1 ? 'success' : 'danger')}>
              <strong>{parseInt(r.has_catalog) === 1 ? 'SI' : 'NO'}</strong>
            </td>);
          }
          else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }

        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
      columnOrder={1}
    />
  }, [supplies, added, onAdd])

  return <>
    <Modal show={showModal} onHide={hideModal} size={'lg'} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title as="div"><i className="fa fa-list mr-2"></i> Listado De Insumos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       {dtRows}
      </Modal.Body>
    </Modal>
  </>
}

const OrdersForm = ({ data, onSubmit, view }) => {
  let m = new Date().getMonth();
  let periodo = m <= 3 ? 'PRIMERO' : (m <= 7 ? 'SEGUNDO' : 'TERCERO');
  const {
    id = '',
    id_unit = '',
    year = '',
    number = '',
    date = '',
    justification = '',
    justification_state = '',
    user_created = '',
    date_created = '',
    quarter_order = periodo,
    year_additional = ''
  } = data

  const [isUpdated, setIsUpdated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const currentYear = useSelector(store => store.session.year)
  const [showAlert, setShowAlert] = useState(false);
  const [resumen, setResumen] = useState('')

  const [row, setRow] = useState({
    id,
    year: id ? year : currentYear,
    number,
    date: id ? date : Helpers.date.get(),
    justification,
    quarter_order: quarter_order,
    year_additional
  })

  const [unitSelected, setUnitSelected] = useState(id ? id_unit : '')
  const [unit, setUnit] = useState([])
  const [isOk, setIsOk] = useState(false)
  const [modalSupplie, setModalSupplie] = useState(false)
  const [allSupplies, setAllSupplies] = useState([])
  const [supplies, setSupplies] = useState([])


  useEffect(() => {
    let promises = [
      Controllers.unit.get_unit_public_with_pack({
        p_year: currentYear
      }),
      Controllers.orders.get_orders_supplies({
        p_id: id
      }),
    ]

    if (id && !view) {
      promises.push(Controllers.supplies.get_supplies_pac_public_serv({
        p_year: year,
        p_id_unit: id_unit,
        p_id: ''
      }))
    }

    Promise.all(promises).then(res => {
      if (res[0].data.length === 1 && !id) {
        setAllSupplies([])
        setSupplies([])
        setUnitSelected(res[0].data[0].id)
        setIsLoading(true)
        setIsUpdated(false)
        Controllers.supplies.get_supplies_pac_public_serv({
          p_year: year ? year : currentYear,
          p_id_unit: res[0].data[0].id,
          p_id: ''
        }).then(res => {
          if (!res.data.length) {
            Helpers.toastr.construct({ response: 'warning', message: 'No existen insumos del renglón 182 en este centro de costo', toastr })
          } else {
            setAllSupplies(res.data)
            setIsUpdated(true)
          }
          setIsLoading(false)
        })
      }
      setUnit(res[0].data)
      if (id) {
        let sup = []
        res[1].data.forEach(r => {
          if (view) {
            sup.push({
              id: r.id_supplie,
              description: r.description_internal,
              codInternal: r.cod_internal,
              newQty: r.qty,
              idSubProducts: r.id_sub_products
            })
          } else {
            let fSup = res[2].data.find(s => parseInt(s.id) === parseInt(r.id_supplie) && s.id_sub_products === r.id_sub_products)
            sup.push({
              id: r.id_supplie,
              description: fSup.description_internal,
              codInternal: fSup.cod_internal,
              qty: fSup.qty,
              qtyOrder: fSup.qty_order,
              qtyOrderReservated: fSup.qty_order_reservated,
              newQty: r.qty,
              idSubProducts: r.id_sub_products
            })
            setAllSupplies(res[2].data)
          }
        })
        setSupplies(sup)
      }

      setIsOk(true)
    })
  }, [currentYear, id, year, id_unit, view])

  const totalQty = useCallback(() => {
    let qty = 0
    supplies.forEach(s => {
      if (!isNaN(parseFloat(s.newQty))) {
        qty += parseFloat(s.newQty)
      }
    })

    return qty
  }, [supplies])

  const dataSumbit = useCallback(() => {
    let p_supplies = []
    supplies.forEach(s => {
      p_supplies.push({
        p_id: s.id,
        p_id_sub_products: s.idSubProducts,
        p_qty: s.newQty
      })
    })

    return {
      p_id: row.id,
      p_id_unit: unitSelected,
      p_year: row.year,
      p_number: row.number,
      p_date: row.date,
      p_serv: 2,
      p_total_qty: totalQty(),
      p_justification: row.justification,
      p_supplies,
      p_quarter_order: row.quarter_order,
      p_year_additional: row.year_additional
    }
  }, [row, unitSelected, supplies, totalQty])


  const cboUnit = useMemo(() => {
    let unitFind = unit.find(u => parseInt(u.id) === parseInt(unitSelected))
    return <Select
      value={unitFind ? { value: unitFind.id, label: unitFind.id + ' - ' + unitFind.description } : ''}
      options={unit.map(s => {
        return {
          value: s.id,
          label: s.id + ' - ' + s.description + ' - '
        }
      })}
      onChange={e => {
        setUnitSelected(e.value)
        setAllSupplies([])
        setSupplies([])
        setIsUpdated(false)
      }}
      text="Centro de costo"
      icon="far fa-building"
      classNameParent="col-12 mb-2"
      disabled={id !== ''}
      append={id ? [] : [<Button variant={isUpdated ? 'danger' : 'primary'} onClick={() => {
        setAllSupplies([])
        setSupplies([])
        if (unitSelected) {
          if (isUpdated) {
            setIsUpdated(false)
          } else {
            setIsLoading(true)
            setIsUpdated(false)
            Controllers.supplies.get_supplies_pac_public_serv({
              p_year: year ? year : currentYear,
              p_id_unit: unitSelected,
              p_id: ''
            }).then(res => {
              if (!res.data.length) {
                Helpers.toastr.construct({ response: 'warning', message: 'No existen insumos del renglón 182 en este centro de costo', toastr })
              } else {
                setAllSupplies(res.data)
                setIsUpdated(true)
              }
              setIsLoading(false)
            })
          }
        }
      }}><i className={(isLoading ? 'fa fa-circle-notch fa-spin' : (isUpdated ? 'fa fa-times mr-1' : 'fa fa-plus mr-1'))}></i>{(isLoading ? '' : (isUpdated ? 'Cancelar' : 'Generar'))}</Button>]}
    />
  }, [unit, unitSelected, isLoading, isUpdated, year, currentYear, id])

  return <ReactPlaceholder showLoadingAnimation rows={7}  type='text' ready={isOk}>
  {justification_state ? <>
  <div>La orden fue rechazada por:</div>
  <strong>{justification_state}</strong>
  </> : ''}

  <FormCustom dataSubmit={dataSumbit()} onSubmit={onSubmit} viewSubmit={!view}>
    <SuppliesPublic showModal={modalSupplie} supplies={allSupplies} hideModal={() => setModalSupplie(false)} added={supplies} onAdd={e => {
      let { id, description, codInternal, qty, qtyOrder, qtyOrderReservated, idSubProducts } = e.currentTarget.dataset
      setSupplies([...supplies, {
        id, description, codInternal, qty, qtyOrder, qtyOrderReservated, newQty: '', idSubProducts
      }])
    }} />
    {cboUnit}
    {/* <Input
      type="number"
      text="Nro"
      placeholder="Nro"
      classNameParent="col-6 mb-2"
      icon="fas fa-code"
      required
      defaultValue={number}
      onChange={e => setRow({ ...row, number: e.currentTarget.value })}
      maxLength={50}
      append={[<span className="input-group-text">/{row.year}</span>]}
      autoFocus
      disabled={view}
    /> */}
    <Input
      type="number"
      text="Año"
      placeholder="Año"
      classNameParent="col-6 mb-2"
      icon="fas fa-code"
      defaultValue={row.year}
      disabled
    />
    <Select
      value={row.quarter_order ? {
        label: row.quarter_order,
        value: row.quarter_order
      } : ''}
      options={[
        { label: 'PRIMERO', value: 'PRIMERO' },
        { label: 'SEGUNDO', value: 'SEGUNDO' },
        { label: 'TERCERO', value: 'TERCERO' },
      ]}
      onChange={e => {
        setRow({ ...row, quarter_order: e.value })
      }}
      text="Cuatrimestre"
      icon="far fa-building"
      classNameParent="col-6 mb-2"
      disabled={view}
    />
    <Input
      type="date"
      text="Fecha"
      placeholder="Fecha"
      classNameParent="col-6 mb-2"
      icon="far fa-calendar"
      required
      value={row.date}
      onChange={e => setRow({ ...row, date: e.currentTarget.value })}
      maxLength={50}
      disabled={view}
    />
    <Input
      type="text"
      text="Año Adicional"
      placeholder="Año Adicional"
      classNameParent="col-6 mb-2"
      icon="far fa-calendar"
      value={row.year_additional}
      pattern="[0-9]{4}"
      onChange={e => setRow({ ...row, year_additional: e.currentTarget.value })}
      maxLength={4}
      disabled={view}
    />
    <TextArea
      defaultValue={justification}
      onChange={e => setRow({ ...row, justification: e.currentTarget.value })}
      icon="fa fa-comment-alt"
      text="Justificación"
      placeholder="Ingresar justificación"
      required
      classNameParent="col-12 mb-2"
      disabled={view}
    />
    <div className="col-12 mb-2">
      {!allSupplies.length ? '' : <><Button size="sm" onClick={() => setModalSupplie(true)}><i className="fa fa-plus mr-2"></i>Seleccionar Insumo</Button> <Button size="sm" id="btn-process-excel" onClick={() => {
        document.getElementById('file-excel-serv').click()
      }} variant="success"><i className="fa fa-file-excel mr-2"></i> Carga Masiva</Button></>}
    </div>
    <input onChange={e => {
      if (e.currentTarget.files.length === 1) {
        let button = document.getElementById('btn-process-excel')
        let buttonHTML = button.innerHTML
        button.innerHTML = Helpers.icon.spin()

        let formData = new FormData()
        formData.append('file_excel_serv', e.currentTarget.files[0])
        Controllers.orders.read_excel(formData).then(res => {
          let suppliesFinded = []
          let bodyResumen = []
          res.data.forEach(r => {
            let fSup = allSupplies.find(s => s.cod_ppr.includes(r[1] + '-') && s.id_sub_products === r[0])

            if (fSup) {
              if (!supplies.some(s => parseInt(s.id) === parseInt(fSup.id) && s.idSubProducts === r[0])) {
                suppliesFinded.push({
                  id: fSup.id,
                  description: fSup.description_internal,
                  codInternal: fSup.cod_internal,
                  qty: fSup.qty,
                  qtyOrder: fSup.qty_order,
                  qtyOrderReservated: fSup.qty_order_reservated,
                  newQty: r[3],
                  idSubProducts: fSup.id_sub_products,
                })
              } else {
                bodyResumen.push(<span>
                  <i className="fa fa-times mr-1"></i> Ya está agregado el insumo <strong>{r[1]}</strong> y subproducto <strong>{r[0]}</strong>
                </span>)
              }
            } else {
              bodyResumen.push(<span>
                <i className="fa fa-times mr-1"></i> No se ha encontrado la información de insumo <strong>{r[1]}</strong> y subproducto <strong>{r[0]}</strong>
              </span>)
            }
          })

          setSupplies([...supplies, ...suppliesFinded])
          if (bodyResumen.length) {
            setResumen(bodyResumen)
            setShowAlert(true)
          }
        }).catch(req => {
          Helpers.promise.catch({ req, toastr });
        }).finally(() => {
          button.innerHTML = buttonHTML
          document.getElementById('file-excel-serv').value = ''
        })
       }
    }} type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" id="file-excel-serv" className="d-none" />

    {showAlert ? <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
      <Alert.Heading>Resumen de información procesada</Alert.Heading>
      <div className="d-flex flex-column">
        {React.Children.toArray(resumen)}
      </div>
    </Alert> : ''}

    <div className="col-12 mb-1 mt-2">
      Ingresar cantidad de productos seleccionados
    </div>
    <div className="table-responsive">
    <table className="table table-sm table-hover table-striped table-bordered">
      <thead>
        {view ? <tr>
          <th>Código</th>
          <th width="50%">Descripción</th>
          <th>Subproducto</th>
          <th>Cantidad Solicitada</th>
        </tr> : <tr>
          <th>Código</th>
          <th width="50%">Descripción</th>
          <th>Subproducto</th>
          <th width="10%">Cantidad Ejec.</th>
          <th width="10%">Cantidad Reservada</th>
          <th width="10%">Cantidad Disponible</th>
          <th width="30%">Cantidad Solicitada</th>
          <th></th>
        </tr>}
      </thead>
      <tbody>
        {React.Children.toArray(supplies.map((s, i) => {
          let qtyBalance = parseFloat(s.qty) - parseFloat(s.qtyOrderReservated)
          return view ? <tr>
          <td align="center">{s.codInternal}</td>
          <td align="center">{s.description}</td>
          <td align="center">{s.idSubProducts}</td>
          <td align="center">
          <Input
            type="number"
            min="0.01"
            step="0.01"
            placeholder=""
            defaultValue={s.newQty}
            disabled
            className="text-center"
          />
          </td>
        </tr> : <tr>
            <td align="center">{s.codInternal}</td>
            <td align="center">{s.description}</td>
            <td align="center">{s.idSubProducts}</td>
            <td align="center">{s.qtyOrder}</td>
            <td align="center">{s.qtyOrderReservated}</td>
            <td align="center">{qtyBalance}</td>
            <td align="center">
            <Input
              type="number"
              min="0.01"
              step="0.01"
              placeholder=""
              defaultValue={s.newQty}
              onChange={e => setSupplies(supplies.map((s2, i2) => {
                if (i2 === i) {
                  s2.newQty = e.currentTarget.value
                }
                return s2;
              }))}
              max={qtyBalance}
             
              className="text-center"
              required
            />
            </td>
            <td><Button variant="danger" size="xs" onClick={() => setSupplies(supplies.filter((s2, i2) => {
              if (i !== i2) {
                return s2
              }
              return false
            }))}><i className="fa fa-minus"></i></Button></td>
          </tr>
        }))}

        <tr>
          <td colSpan={view ? 3 : 6} align="right">Total</td>
          <td align="center"><strong>{parseFloat(totalQty()).toFixed(2)}</strong></td>
        </tr>
      </tbody>
    </table>
    </div>
  </FormCustom>
  {user_created ? <>
    <hr />
    <div className="d-flex flex-column">
      <span>Usuario creación: <strong>{user_created}</strong></span>
      <span>Fecha creación: <strong>{date_created}</strong></span>
    </div>
  </> : ''}
  </ReactPlaceholder>
}

OrdersForm.defaultProps = {
  view: false
}
export default OrdersForm;