import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const Input = ({ defaultValue, value, onChange, classNameParent, type, className, text, size, icon, append, invalid, iconText, ...props }) => {
  const propInput = useCallback(() => {
    return defaultValue ? { defaultValue } : { value }
  }, [defaultValue, value]);

  if (type === 'hidden') 
    return (<input {...props} {...propInput()} type="hidden" className={'form-control ' + className} />);
  else 
    return (<div className={classNameParent}>
      <small className="text-muted">{text}</small>
      <div className={'input-group input-group-' + size}>
        {icon || iconText ? <div className="input-group-prepend">
          <div className="input-group-text">{icon ? <i className={icon}></i> : iconText}</div>
        </div> : ''}
        <input {...props} {...propInput()} onChange={onChange} type={type} className={'form-control ' + className} />
        <div className="input-group-append">
          {React.Children.toArray(append)}
        </div>
        <div className="invalid-feedback">
          {invalid}
        </div>
      </div>
      
    </div>);
}
Input.defaultProps = {
  size: 'sm',
  classNameParent: 'col-12',
  type: 'text',
  required: false,
  append: [],
  className: '',
  onChange: () => {}
}
Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['hidden', 'text', 'date', 'time', 'password', 'email', 'number', 'datetime-local']),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  classNameParent: PropTypes.string,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  pattern: PropTypes.string,
  'data-pattern-message': PropTypes.string,
  append: PropTypes.array,
  maxLength: PropTypes.number,
  onChange: PropTypes.func
}
export default Input;