import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import DataTable from '../../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import { useSelector } from 'react-redux'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import UnopsForm from './UnopsForm'
import { CheckBox } from '../../Components/Form/Form'
import ConsolidateUnops from './ConsolidateUnops';
import UnopsFormConsolidate from './UnopsFormConsolidate';
import UnopsFormRegularize from './UnopsFormRegularize';

const Unops = () => {
  const nameModulo = 'Unops'
  const namePage = 'Unops'
  const nameController = 'unops'

  const [states, setStates] = useState([])

  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = useRef(Helpers.getActions({ permissions, name: 'unops' }))

  const [rows, setRows] = useState([]);
  const [rowsConsolidate, setRowsConsolidate] = useState([]);
  const [rowsRegularize, setRowsRegularize] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [modalSize, setModalSize] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [form, setForm] = useState('');
  const [reload, setReload] = useState(true);
  const [listConsolidate, setListConsolidate] = useState([])
  const [stateSelected, setStateSelected] = useState(0)

  useEffect(() => {
    if (reload) {
      Promise.all([
        Controllers[nameController]['get_' + nameController](),
        Controllers[nameController].get_unops_consolidate(),
        Controllers[nameController].get_unops_regularize()
      ]).then(res => {
        let stateZero = res[0].data.filter(d => parseInt(d.state) === 0).length
        let stateOne = res[1].data.filter(d => parseInt(d.state) === 1).length
        let stateTwo = res[2].data.filter(d => parseInt(d.state) === 2).length
        setStates([
          { state: 0, color: 'primary', text: 'SIN CONSOLIDACIÓN', qty: stateZero },
          { state: 1, color: 'success', text: 'CONSOLIDADO', qty: stateOne },
          { state: 2, color: 'dark', text: 'REGULARIZADO', qty: stateTwo },
        ])
        setRows(res[0].data)
        setRowsConsolidate(res[1].data)
        setRowsRegularize(res[2].data)
      })
        .finally(() => {
          setIsProcessing(false)
          setReload(false);
        });
    }
  }, [reload])

  const handleUpdate = useCallback(e => {
    const { id = '', disabled = 0 } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()

    setModalTitle(parseInt(disabled) === 1 ? <><i className={'mr-2 ' + actionsModule.current.view.icon}></i>{actionsModule.current.view.description} {namePage}</> : (id ? <><i className={'mr-2 ' + actionsModule.current.update.icon}></i>{actionsModule.current.update.description} {namePage}</> : <><i className={'mr-2 ' + actionsModule.current.create.icon}></i>{actionsModule.current.create.description} {namePage}</>))
    setModalSize('lg');
    setModalHeader('');

    Controllers[nameController]['get_' + nameController + '_id']({ p_id: id }).then(res => {
      let formData = {}
      if (id) {
        formData.data = res.data.unops
        formData.listSubProducts = res.data.sub_products
        formData.listInvoices = res.data.invoices
        formData.listReceipts = res.data.receipts
      }
      setForm(<UnopsForm {...formData} onSubmit={onSubmit} viewDisabled={parseInt(disabled) === 1} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }, [actionsModule])

  const handleViewConsolidate = useCallback(e => {
    const { idUnopsConsolidate } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()

    setModalTitle(<><i className={'mr-2 ' + actionsModule.current.viewconsolidateunops.icon}></i>{actionsModule.current.viewconsolidateunops.description}</>)
    setModalSize('lg');
    setModalHeader('');

    Controllers[nameController].get_unops_consolidate_details({ p_id_unops_consolidate: idUnopsConsolidate }).then(res => {
      setForm(<UnopsFormConsolidate data={res.data.unops_consolidate} details={res.data.unops} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }, [actionsModule])

  const handleViewRegularize = useCallback(e => {
    const { idUnopsRegularize } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()

    setModalTitle(<><i className={'mr-2 ' + actionsModule.current.viewregularizeunops.icon}></i>{actionsModule.current.viewregularizeunops.description}</>)
    setModalSize('lg');
    setModalHeader('');

    Controllers[nameController].get_unops_regularize_details({ p_id_unops_regularize: idUnopsRegularize }).then(res => {
      setForm(<>
        <UnopsFormRegularize listConsolidate={res.data.unops} unopsRegularize={res.data.unops_regularize} hideModal={_ => {}} />
      </>);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }, [actionsModule])

  const onSubmit = ({ e, dataSubmit }) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers[nameController][nameController + '_insert_update'](dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        setModalShow(false);
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
      setReload(true)
      setListConsolidate([])
    });
  }

  const onDelete = e => {
    const { id = '' } = e.currentTarget.dataset;
    setModalTitle(<><i className="fa fa-trash mr-2"></i> Eliminar {namePage}</>);
    setModalHeader('bg-danger');
    setModalSize('sm');
    setForm(<div className="d-flex flex-column align-items-center">
      <p><strong>¿Eliminar {namePage} #{id}?</strong></p>

      <div>
        <Button variant="danger" className="mr-2" size="sm" onClick={e => {
          let button = e.currentTarget
          let buttonHTML = button.innerHTML;
          button.innerHTML = Helpers.icon.spin()

          Controllers[nameController][nameController + '_delete']({
            p_id_unops: id
          }).then(res => {
            Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
          }).catch(req => {
            Helpers.promise.catch({ req, toastr });
          }).finally(() => {
            button.innerHTML = buttonHTML;
            setReload(true)
            setModalShow(false);
          });
        }}>
          <i className="fa fa-check mr-2"></i>Si
        </Button>
        <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalShow(false)}>
          <i className="fa fa-times mr-2"></i>No
        </Button>
      </div>
    </div>);
    setModalShow(true)
  }

  const [modalShowFreeConsolidate, setModalShowFreeConsolidate] = useState(false)
  const [formConsolidate, setFormConsolidate] = useState('')

  const handleFreeConsolidation = e => {
    const { idUnopsConsolidate } = e.currentTarget.dataset;
    setFormConsolidate(<div className="d-flex flex-column align-items-center">
      <p><strong>¿Liberar Consolidación?</strong></p>

      <div>        
        <Button variant="danger" className="mr-2" size="sm" onClick={e => {
          let button = e.currentTarget
          let buttonHTML = button.innerHTML;
          button.innerHTML = Helpers.icon.spin()

          Controllers.unops.unops_consolidate_delete({
            p_id_unops_consolidate: idUnopsConsolidate
          }).then(res => {
            Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
          }).catch(req => {
            Helpers.promise.catch({ req, toastr });
          }).finally(() => {
            button.innerHTML = buttonHTML;
            setReload(true)
            setModalShowFreeConsolidate(false);
          });
        }}>
          <i className="fa fa-check mr-2"></i>Si
        </Button>
        <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalShowFreeConsolidate(false)}>
          <i className="fa fa-times mr-2"></i>No
        </Button>
      </div>
    </div>);
    setModalShowFreeConsolidate(true)
  }

  const dtRows = useMemo(() => {
    let thead = [
      { name: 'ID', align: 'center' },
      { name: 'Unidad', align: 'center' },
      { name: 'Order de Compra', align: 'center' },
      { name: 'Proveedor', align: 'center' },
      { name: 'Insumo', align: 'center' },
      { name: 'Fecha Acta', align: 'center' },
      { name: 'Número Acta', align: 'center' },
      { name: 'Estado', align: 'center' },
      { name: 'Acc.', align: 'center' },
      { name: <i className="fa fa-check"></i>, align: 'center' },
    ];

    if (stateSelected === 1) {
      thead = [
        { name: 'Numero Consolidación', align: 'center' },
        { name: 'Fecha Consolidación', align: 'center' },
        { name: 'Usuario', align: 'center' },
        { name: 'Estado', align: 'center' },
        { name: 'Acc.', align: 'center' },
        { name: <i className="fa fa-check"></i>, align: 'center' },
      ];
    }

    if (stateSelected === 2) {
      thead = [
        { name: 'CURDP', align: 'center' },
        { name: 'Fecha Pago', align: 'center' },
        { name: 'Usuario', align: 'center' },
        { name: 'Estado', align: 'center' },
        { name: 'Acc.', align: 'center' },
      ];
    }

    return <DataTable
      isProcessing={isProcessing}
      tbody={stateSelected === 0 ? rows.filter(s => parseInt(s.state) === stateSelected) : (stateSelected === 1 ? rowsConsolidate.filter(s => parseInt(s.state) === stateSelected) : rowsRegularize)}
      thead={thead}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;

        if (stateSelected === 0) {
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r.id_unops}</td>);
          i++;
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r.description_unit}</td>);
          i++;
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r.number_order}</td>);
          i++;
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r.name_provider}</td>);
          i++;
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r.description_ppr}</td>);
          i++;
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r.date_act}</td>);
          i++;
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r.number_act}</td>);
          i++;
          let findState = states.find(s => s.state === parseInt(r.state))
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
            {findState ? <strong className={'text-' + findState.color}>{findState.text}</strong> : ''}
          </td>);
          i++;
        } else if (stateSelected === 1) {
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r.id_unops_consolidate}</td>);
          i++;
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r.date_consolidate}</td>);
          i++;
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r.user_created}</td>);
          i++;
          let findState = states.find(s => s.state === parseInt(r.state))
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
            {findState ? <strong className={'text-' + findState.color}>{findState.text}</strong> : ''}
          </td>);
          i++;
        } else if (stateSelected === 2) {
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r.number_curdp}</td>);
          i++;
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r.date_pay}</td>);
          i++;
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r.user_created}</td>);
          i++;
          let findState = states.find(s => s.state === parseInt(r.state))
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
            {findState ? <strong className={'text-' + findState.color}>{findState.text}</strong> : ''}
          </td>);
          i++;
        }


        if (stateSelected === 0) {
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
            {actionsModule.current.view ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.view.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.view.color} data-disabled={1} size="xs" className="mr-1" data-id={r.id_unops} onClick={handleUpdate}>
                <i className={actionsModule.current.view.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.current.update && parseInt(r.state) === 0 ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.update.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.update.color} size="xs" className="mr-1" data-id={r.id_unops} onClick={handleUpdate}>
                <i className={actionsModule.current.update.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.current.delete && parseInt(r.state) === 0 ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.delete.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.delete.color} size="xs" data-id={r.id_unops} onClick={onDelete}>
                <i className={actionsModule.current.delete.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
          </td>);
          rowRender.push(<td align="center">
            {parseInt(r.receipts_completed) === 1 && actionsModule.current.consolidateunops && parseInt(r.state) === 0 ? <CheckBox
              id={'chk-consolidate' + r.id_unops}
              data-id-unops={r.id_unops}
              data-description-unit={r.description_unit}
              data-number-order={r.number_order}
              checked={listConsolidate.some(l => parseInt(r.id_unops) === parseInt(l.id_unops))}
              onChange={e => {
                const { idUnops, descriptionUnit, numberOrder } = e.currentTarget.dataset
                if (e.currentTarget.checked) {
                  setListConsolidate([...listConsolidate, {
                    id_unops: idUnops,
                    description_unit: descriptionUnit,
                    number_order: numberOrder
                  }])
                } else {
                  setListConsolidate(listConsolidate.filter(l => {
                    if (parseInt(l.id_unops) !== parseInt(idUnops)) {
                      return l;
                    }
                    return false;
                  }))
                }
              }}
            /> : ''}
          </td>)
        } else if (stateSelected === 1) {
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
            {actionsModule.current.viewconsolidateunops ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.viewconsolidateunops.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.viewconsolidateunops.color} data-disabled={1} size="xs" className="mr-1" data-id-unops-consolidate={r.id_unops_consolidate} onClick={handleViewConsolidate}>
                <i className={actionsModule.current.viewconsolidateunops.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.current.freeunopsconsolidate ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.freeunopsconsolidate.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.freeunopsconsolidate.color} data-disabled={1} size="xs" className="mr-1" data-id-unops-consolidate={r.id_unops_consolidate} onClick={handleFreeConsolidation}>
                <i className={actionsModule.current.freeunopsconsolidate.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
          </td>);
          rowRender.push(<td align="center">
            {parseInt(r.state) === 1 && actionsModule.current.consolidateunops ? <CheckBox
              id={'chk-regularize_' + r.id_unops_consolidate}
              data-id-unops-consolidate={r.id_unops_consolidate}
              checked={listConsolidate.some(l => parseInt(r.id_unops_consolidate) === parseInt(l.id_unops_consolidate))}
              onChange={e => {
                const { idUnopsConsolidate } = e.currentTarget.dataset
                if (e.currentTarget.checked) {
                  setListConsolidate([...listConsolidate, {
                    id_unops_consolidate: idUnopsConsolidate,
                  }])
                } else {
                  setListConsolidate(listConsolidate.filter(l => {
                    if (parseInt(l.id_unops_consolidate) !== parseInt(idUnopsConsolidate)) {
                      return l;
                    }
                    return false;
                  }))
                }
              }}
            /> : ''}
          </td>)
        } else if (stateSelected === 2) {
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
            {actionsModule.current.viewregularizeunops ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.viewregularizeunops.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.viewregularizeunops.color} data-disabled={1} size="xs" className="mr-1" data-id-unops-regularize={r.id_unops_regularize} onClick={handleViewRegularize}>
                <i className={actionsModule.current.viewregularizeunops.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.current.printregularize ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.printregularize.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.printregularize.color} data-disabled={1} size="xs" className="mr-1" data-id-unops-regularize={r.id_unops_regularize} onClick={_ => {
                  window.open(r.file_regularize, '_blank')
              }}>
                <i className={actionsModule.current.printregularize.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
          </td>);
        }

        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, rows, handleUpdate, actionsModule, states, stateSelected, listConsolidate, rowsConsolidate, handleViewConsolidate, handleViewRegularize, rowsRegularize])

  return <>
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De {nameModulo}</span>
        <div>
          {actionsModule.current.consolidateunops && stateSelected === 0 ? <Button className="mr-2" onClick={e => {
            setModalTitle('Consolidar')
            setModalHeader('bg-success')
            setModalSize('md')
            setForm(<ConsolidateUnops listConsolidate={listConsolidate} hideModal={() => {
              setModalShow(false)
              setReload(true)
              setListConsolidate([])
            }} />)
            setModalShow(true)
          }} disabled={listConsolidate.length === 0} variant={actionsModule.current.consolidateunops.color} size="sm"><i className={actionsModule.current.consolidateunops.icon}></i><span className="ml-2 d-none d-md-inline-block">{actionsModule.current.consolidateunops.description}</span></Button> : ''}
          {actionsModule.current.regularizeunops && stateSelected === 1 ? <Button className="mr-2" onClick={e => {
            setModalTitle('Regularizar')
            setModalHeader('bg-dark')
            setModalSize('lg')
            setForm(<UnopsFormRegularize listConsolidate={listConsolidate} hideModal={() => {
              setModalShow(false)
              setReload(true)
              setListConsolidate([])
            }} />)
            setModalShow(true)
          }} disabled={listConsolidate.length === 0} variant={actionsModule.current.regularizeunops.color} size="sm"><i className={actionsModule.current.regularizeunops.icon}></i><span className="ml-2 d-none d-md-inline-block">{actionsModule.current.regularizeunops.description}</span></Button> : ''}
          {actionsModule.current.create ? <Button onClick={handleUpdate} variant={actionsModule.current.create.color} size="sm"><i className={actionsModule.current.create.icon}></i><span className="ml-2 d-none d-md-inline-block">{actionsModule.current.create.description} {namePage}</span></Button> : ''}
        </div>
      </Card.Header>
      <Card.Body>
        <div className="d-flex mb-2">
          {React.Children.toArray(states.map(s => <Button className="mr-2" size="sm" variant={stateSelected === s.state ? 'success' : 'primary'} onClick={_ => {
            setListConsolidate([])
            setStateSelected(s.state)
          }}>
            {s.text} ({s.qty})
              </Button>))}
        </div>
        {dtRows}
      </Card.Body>
    </Card>
    <Modal show={modalShow} onHide={() => setModalShow(false)} size={modalSize} backdrop="static">
      <Modal.Header closeButton className={modalHeader}>
        <Modal.Title as="div">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
    <Modal show={modalShowFreeConsolidate} onHide={() => setModalShowFreeConsolidate(false)} size={"sm"} backdrop="static">
      <Modal.Header closeButton className={'bg-danger'}>
        <Modal.Title as="div">Librer Consolidación</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {formConsolidate}
      </Modal.Body>
    </Modal>
  </>
}

export default Unops;