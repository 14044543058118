import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Controllers from '../Api/Controllers';
import { CheckBox, FormCustom, Input, SwitchToggle } from '../Components/Form/Form';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import {useSelector} from 'react-redux'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ParticipantForm = ({ data, onSubmit, viewDisabled }) => {
  const {
    id = '',
    name = '',
    enabled = 0,
    main = 0,
    type = '',
  } = data

  const [row, setRow] = useState({
    id,
    name,
    enabled,
    main,
    type
  })

  const dataSumbit = useCallback(() => {
    return {
      p_id: row.id,
      p_name: row.name,
      p_enabled: row.enabled,
      p_main: row.main,
      p_type: row.type
    }
  }, [row])

  return <FormCustom dataSubmit={dataSumbit()} onSubmit={onSubmit} viewSubmit={!viewDisabled}>
    <Input
      text="Nombres"
      placeholder="Nombres"
      classNameParent="col-12 mb-2"
      icon="far fa-comment-alt"
      required
      defaultValue={name}
      onChange={e => setRow({ ...row, name: e.currentTarget.value })}
      maxLength={255}
      disabled={viewDisabled}
    />
    {React.Children.toArray(['COMISION', 'SECRETARIA'].map(t => {
      return <CheckBox
        id={'chk-' + t}
        text={t}
        onChange={_ => setRow({ ...row, type: t })}
        checked={t === row.type}
        required={row.type === ''}
      />
    }))}
    <SwitchToggle
      classNameParent="mt-2 col-12"
      id="swtich-estado-participante"
      defaultChecked={parseInt(row.enabled) === 1}
      disabled={viewDisabled}
      onChange={e => setRow({ ...row, enabled: e.currentTarget.checked ? 1 : 0 })}
    />
    <SwitchToggle
      id="swtich-main-participante"
      defaultChecked={parseInt(row.main) === 1}
      disabled={viewDisabled}
      onChange={e => setRow({ ...row, main: e.currentTarget.checked ? 1 : 0 })}
      textCheck="Predeterminado"
      textUnChecked="Predeterminado"
    />
  </FormCustom>
}

const Participant = () => {
  const nameModulo = 'Participantes'
  const namePage = 'Participantes'
  const nameController = 'participant'
  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = Helpers.getActions({ permissions, name: 'participantes' })

  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [modalSize, setModalSize] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [form, setForm] = useState('');
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      Controllers[nameController]['get_' + nameController]().then(res => {
        setRows(res.data)
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload])

  const handleUpdate = useCallback(e => {
    const { id = '', disabled = 0 } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()
    
    setModalTitle(parseInt(disabled) === 1 ? <><i className={'mr-2 ' + actionsModule.view.icon}></i>{actionsModule.view.description} {namePage}</> : (id ? <><i className={'mr-2 ' + actionsModule.update.icon}></i>{actionsModule.update.description} {namePage}</> : <><i className={'mr-2 ' + actionsModule.create.icon}></i>{actionsModule.create.description} {namePage}</>))
    setModalSize('');
    setModalHeader('');

    Controllers[nameController]['get_' + nameController + '_id']({ p_id: id }).then(res => {
      setForm(<ParticipantForm data={res.data} onSubmit={onSubmit} viewDisabled={parseInt(disabled) === 1} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }, [actionsModule])

  const onSubmit = ({ e, dataSubmit }) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers[nameController][nameController + '_insert_update'](dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        setModalShow(false);
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
      setReload(true)
    });
  }

  const onDelete = e => {
    const { id = '' } = e.currentTarget.dataset;
    setModalTitle(<><i className="fa fa-trash mr-2"></i> Eliminar {namePage}</>);
    setModalHeader('bg-danger');
    setModalSize('sm');
    setForm(<div className="d-flex flex-column align-items-center">
      <p><strong>¿Eliminar {namePage} #{id}?</strong></p>

      <div>        
        <Button variant="danger" className="mr-2" size="sm" onClick={e => {
          let button = e.currentTarget
          let buttonHTML = button.innerHTML;
          button.innerHTML = Helpers.icon.spin()

          Controllers[nameController][nameController + '_delete']({
            p_id: id
          }).then(res => {
            Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
          }).catch(req => {
            Helpers.promise.catch({ req, toastr });
          }).finally(() => {
            button.innerHTML = buttonHTML;
            setReload(true)
            setModalShow(false);
          });
        }}>
          <i className="fa fa-check mr-2"></i>Si
        </Button>
        <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalShow(false)}>
          <i className="fa fa-times mr-2"></i>No
        </Button>
      </div>
    </div>);
    setModalShow(true)
  }

  const dtRows = useMemo(() => {
    const thead = [
      { name: 'ID', align: 'center' },
      { name: 'Descripción' },
      { name: 'Estado', align: 'center' },
      { name: 'Predeterminado', align: 'center' },
      { name: 'Tipo', align: 'center' },
      { name: 'Acc.', align: 'center' }
    ];

    return <DataTable
      isProcessing={isProcessing}
      tbody={rows}
      thead={thead}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;
        for (let key in r) {
          if (key === 'enabled') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
              <div className={'badge badge-' + (parseInt(r.enabled) === 1 ? 'success' : 'danger')}>
                {parseInt(r.enabled) === 1 ? 'Activo' : 'Inactivo'}
              </div>
            </td>);
          } else if (key === 'main') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
              <div className={'badge badge-' + (parseInt(r.main) === 1 ? 'success' : 'danger')}>
                {parseInt(r.main) === 1 ? 'Si' : 'No'}
              </div>
            </td>);
          }  else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }

        rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
          {actionsModule.view ?  <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.view.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.view.color} data-disabled={1} size="xs" className="mr-1" data-id={r.id} onClick={handleUpdate}>
              <i className={actionsModule.view.icon}></i>
            </Button>
          </OverlayTrigger> : ''}
          {actionsModule.update ?  <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.update.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.update.color} size="xs" className="mr-1" data-id={r.id} onClick={handleUpdate}>
              <i className={actionsModule.update.icon}></i>
            </Button>
          </OverlayTrigger> : ''}
          {actionsModule.delete ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.delete.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.delete.color} size="xs" data-id={r.id} onClick={onDelete}>
              <i className={actionsModule.delete.icon}></i>
            </Button>
          </OverlayTrigger> : ''}
        </td>);
        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, rows, handleUpdate, actionsModule])

  return <> 
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De {nameModulo}</span>
        {actionsModule.create ? <Button onClick={handleUpdate} variant={actionsModule.create.color} size="sm"><i className={ actionsModule.create.icon}></i><span className="ml-2 d-none d-md-inline-block">{actionsModule.create.description} {namePage}</span></Button> : ''}
      </Card.Header>
      <Card.Body>
        {dtRows}
      </Card.Body>
    </Card>
    <Modal show={modalShow} onHide={() => setModalShow(false)} size={modalSize} backdrop="static">
      <Modal.Header closeButton className={modalHeader}>
        <Modal.Title as="div">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
  </>
}

export default Participant;