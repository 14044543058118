const boxShadow = '0 1px 5px 0 rgba(0,0,0,.2)';
const primary = '#376A87';
const bgPrimaryLight = `rgba(55,106,135, .5)`;
const primaryGradient = `linear-gradient(118deg, ${primary}, #6f99c3)`;
const widthFull = '260px';
const widthResize = '60px';
const widthWithPadding = '240px';
const widthResizeWithPadding = '40px';
const heightHeader = '60px';
const heightFooter = '20px'

export {
  boxShadow, primary, bgPrimaryLight, primaryGradient, widthFull, widthResize, heightHeader, heightFooter, widthWithPadding, widthResizeWithPadding
}