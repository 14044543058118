import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import Controllers from '../Api/Controllers';
import { FormCustom, Input, Select, TextArea } from '../Components/Form/Form';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import ReactPlaceholder from 'react-placeholder';

const PresentationForm = ({ data, onSubmit, idConsolidate, state, disabled, typeOrder }) => {
  let color = ''
  let texto = ''
  switch (parseInt(state)) {
    case 0:
      color = 'warning'
      texto = 'SIN ORDEN DE COMPRA'
      break;
    case 1:
      color = 'info'
      texto = 'CON ORDEN DE COMPRA'
      break;
    case 2:
      color = 'primary'
      texto = 'COMPROMISO'
      break;
    case 3:
      color = 'dark'
      texto = 'COMPLETADO'
      break;
    case 4:
      color = 'info'
      texto = 'DEVENGADO'
      break;
    case 5:
      color = 'primary'
      texto = 'ENVIADO DAF'
      break;
    case 6:
      color = 'danger'
      texto = 'RECHAZADO'
      break;
    case 7:
      color = 'success'
      texto = 'PAGADO'
      break;
    case 8:
      color = 'danger'
      texto = 'ANULADO'
      break;
    case 9:
      color = 'primary'
      texto = 'CON DOCUMENTACIÓN'
      break;
    default:
      break;
  }

  let m = new Date().getMonth();
  let periodo = m <= 3 ? 'PRIMERO' : (m <= 7 ? 'SEGUNDO' : 'TERCERO');

  const {
    iva = 1,
    number_purchase = '',
    date_issue = '',
    quarter = periodo,
    id_type_purchase = '',
    npg_nog = '',
    date_npg_nog = '',
    number_award = '',
    id_provider = '',
    number_compromise = '',
    date_compromise = '',
    date_send_provider = '',
    number_devengade = '',
    date_send_daf = '',
    number_table = '',
    date_rejection = '',
    justification_rejection = '',
    date_send_daf_last = '',
    date_devengade = '',
    date_ban = '',
    justification_ban = '',
    exemption_iva = '',
    user_created = '',
    date_created = '',
    justification_purchase_order = '',
    date_pay_format,
    number_box
  } = data
  
  let isCompromise = state === 2 || state === 9
  let isCompleted = state === 3
  let isDevengade = state === 4
  let isSendDaf = state === 5 || state === 7 || state === 8
  let isRejection = state === 6
  let isPay = state === 7

  const [row, setRow] = useState({
    iva,
    number_purchase,
    date_issue: date_issue ? date_issue : Helpers.date.get(),
    quarter,
    id_type_purchase,
    npg_nog,
    date_npg_nog,
    number_award,
    id_provider,
    exemption_iva,
    justification_purchase_order
  })
  const [isOk, setIsOk] = useState(false)
  
  const [orders, setOrders] = useState([])
  const [supplies, setSupplies] = useState([])
  const [typePurchase, setTypePurchase] = useState([])
  const [provider, setProvider] = useState([])
  const [invoices, setInvoices] = useState([])
  const [orderSupplies, setOrderSupplies] = useState([])
  const qtyTotal = useCallback(() => {
    let qty = 0
    orders.forEach(o => {
      qty += parseFloat(o.qty)
    })

    return qty
  }, [orders])

  const total = useCallback(() => {
    let total = 0
    if (parseInt(typeOrder) === 1) {
      supplies.forEach(s => {
        if (!isNaN(parseFloat(s.price))) {
          if (s.description_internal) {
            orders.filter(o => parseInt(s.id_supplie) === parseInt(o.id_supplie) && s.description_internal === o.description_internal).forEach(o => {
              total += parseFloat(o.qty) * parseFloat(s.price)
            })
          } else {
            orders.filter(o => parseInt(s.id_supplie) === parseInt(o.id_supplie)).forEach(o => {
              total += parseFloat(o.qty) * parseFloat(s.price)
            })
          }
        }
      })
    } else {
      supplies.forEach(s => {
        if (!isNaN(parseFloat(s.price))) {
          orderSupplies.filter(o => parseInt(s.id_supplie) === parseInt(o.id_supplie)).forEach(o => {
            total += parseFloat(o.qty) * parseFloat(s.price)
          })
        }
      })
    }

    return total
  }, [supplies, orders, orderSupplies, typeOrder])

  const dataSumbit = useCallback(() => {
    let p_unit = []

    supplies.forEach(s => {
      orders.filter(o => parseInt(s.id_supplie) === parseInt(o.id_supplie)).forEach(o => {
        p_unit.push({
          p_id: o.id,
          p_price: s.price,
          p_number_invoice: o.number_invoice,
          p_date_issue: o.date_issue,
          p_date_reception: o.date_reception,
        })
      })
    });

    let p_supplies = []
    if (parseInt(typeOrder) === 2) {
      supplies.forEach(s => {
        p_supplies.push({
          p_id_supplie: s.id_supplie,
          p_price: s.price
        })
      })
    }

    let p_invoices = []
    invoices.forEach(i => {
      p_invoices.push({
        p_id: idConsolidate,
        p_serie_invoice: i.serie_invoice,
        p_number_invoice: i.number_invoice,
        p_date_invoice: i.date_invoice,
      })
    })

    return {
      p_id: idConsolidate,
      p_iva: row.iva,
      p_number_purchase: row.number_purchase,
      p_date_issue: row.date_issue,
      p_quarter: row.quarter,
      p_id_type_purchase: row.id_type_purchase,
      p_npg_nog: row.npg_nog,
      p_date_npg_nog: row.date_npg_nog,
      p_number_award: row.number_award,
      p_id_provider: row.id_provider,
      p_total: total(),
      p_subtotal: (total() - (parseInt(row.iva) === 1 ? (total() / 1.12) * 0.12 : 0)).toFixed(2),
      p_unit,
      p_invoices,
      p_exemption_iva: row.exemption_iva,
      p_justification_purchase_order: row.justification_purchase_order,
      p_type_order: typeOrder,
      p_supplies
    }
  }, [row, idConsolidate, total, orders, supplies, invoices, typeOrder])

  useEffect(() => {
    let prom = [
      Controllers.orders.get_orders_consolidate_orders({
        p_id: idConsolidate
      }),
      Controllers.orders.get_orders_consolidate_supplie({
        p_id: idConsolidate
      }),
      Controllers.type_purchase.get_type_purchase(),
      Controllers.provider.get_provider_public(),
      Controllers.orders.get_orders_invoice({
        p_id: idConsolidate
      })
    ]

    Promise.all(prom).then(res => {
      if (parseInt(typeOrder) === 2) {
        let ord = []
        res[0].data.forEach(r => {
          if (ord.some(o => parseInt(o.id) === parseInt(r.id))) {
            ord = ord.map(o1 => {
              if (parseInt(r.id) === parseInt(o1.id)) {
                o1.qty += parseFloat(r.qty)
              }
              
              return o1
            })
          } else {
            ord.push({
              id: r.id, unit: r.unit, qty: parseFloat(r.qty), id_supplie: r.id_supplie,
              number_invoice: r.number_invoice, date_issue: r.date_issue, date_reception: r.date_reception
            })
          }
        })
        setOrders(ord)
      } else {
        setOrders(res[0].data)
      }
      
      if (parseInt(typeOrder) === 2) {
        let supp = res[1].data
        supp = []
        res[1].data.forEach(r => {
          if (!supp.some(o => parseInt(o.id_supplie) === parseInt(r.id_supplie))) {
            supp.push(r)
          }
        })
        setSupplies(supp)
        setOrderSupplies(res[0].data)
      } else {
       setSupplies(res[1].data) 
      }
      setTypePurchase(res[2].data)
      setProvider(res[3].data)
      setInvoices(res[4].data)
      setIsOk(true)
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    })
  }, [idConsolidate, typeOrder])

  const cboTypePurchase = useMemo(() => {
    let f = typePurchase.find(t => parseInt(t.id) === parseInt(row.id_type_purchase))

    return <Select
      value={f ? { value: f.id, label: f.id + ' - ' + f.description } : ''}
      options={typePurchase.map(t => {
        return {
          value: t.id, label: t.id + ' - ' + t.description
        }
      })}
      classNameParent="col-12 col-md-6 mb-2"
      icon="fa fa-list"
      text="Tipo Compra"
      onChange={e => setRow({...row, id_type_purchase: e.value})}
      disabled={isCompromise || isCompleted || isDevengade || isSendDaf || isRejection || disabled}
    />
  }, [typePurchase, row, isCompromise, isCompleted, isDevengade, isSendDaf, isRejection, disabled])

  const cboQuarter = useMemo(() => {
    let opt = [
      { value: 'PRIMERO', label: 'PRIMERO' },
      { value: 'SEGUNDO', label: 'SEGUNDO' },
      { value: 'TERCERO', label: 'TERCERO' },
    ]
    let f = opt.find(t => t.value === row.quarter)

    return <Select
      value={f ? f : ''}
      options={opt}
      classNameParent="col-12 col-md-6 mb-2"
      icon="fa fa-list"
      text="Cuatrimestre"
      onChange={e => setRow({...row, quarter: e.value})}
      disabled={isCompromise || isCompleted || isDevengade || isSendDaf || isRejection || disabled}
    />
  }, [row, isCompromise, isCompleted, isDevengade, isSendDaf, isRejection, disabled])

  const cboProviders = useMemo(() => {
    let f = provider.find(t => parseInt(t.id) === parseInt(row.id_provider))

    return <Select
      value={f ? { value: f.id, label: f.nit + ' - ' + f.name } : ''}
      options={provider.map(t => {
        return {
          value: t.id, label: t.nit + ' - ' + t.name
        }
      })}
      classNameParent="col-12 col-md-6 mb-2"
      icon="fa fa-list"
      text="Proveedores"
      onChange={e => setRow({...row, id_provider: e.value})}
      disabled={isCompromise || isCompleted || isDevengade || isSendDaf || isRejection || disabled}
    />
  }, [provider, row, isCompromise, isCompleted, isDevengade, isSendDaf, isRejection, disabled])

  return <ReactPlaceholder showLoadingAnimation rows={7}  type='text' ready={isOk}>
  <div>Estado: <strong className={'text-' + color}>{texto}</strong></div>
  <FormCustom dataSubmit={dataSumbit()} onSubmit={onSubmit} viewSubmit={!isSendDaf && !disabled}>
    <Input
      defaultValue={number_purchase}
      text="Nro Compra"
      placeholder="Nro Compra"
      icon="fas fa-code"
      classNameParent="col-12 col-md-6 mb-2"
      required
      onChange={e => setRow({...row, number_purchase: e.currentTarget.value})}
      disabled={isCompromise || isCompleted || isDevengade || isSendDaf || isRejection || disabled}
    />
    <Input
      value={row.date_issue}
      type="date"
      text="Fecha Emisión"
      placeholder="Fecha Emisión"
      icon="fas fa-code"
      classNameParent="col-12 col-md-6 mb-2"
      required
      onChange={e => setRow({...row, date_issue: e.currentTarget.value})}
      disabled={isCompromise || isCompleted || isDevengade || isSendDaf || isRejection || disabled}
    />
    {cboQuarter}
    {cboTypePurchase}
    <Input
      text="NOG/NPG"
      placeholder="NOG/NPG"
      icon="fas fa-code"
      classNameParent="col-12 col-md-6 mb-2"
      defaultValue={npg_nog}
      onChange={e => setRow({...row, npg_nog: e.currentTarget.value})}
      disabled={isDevengade || isSendDaf || isRejection || disabled}
    />
    <Input
      type="date"
      text="Fecha NOG/NPG"
      placeholder="Fecha NOG/NPG"
      icon="fas fa-code"
      classNameParent="col-12 col-md-6 mb-2"
      defaultValue={date_npg_nog}
      onChange={e => setRow({...row, date_npg_nog: e.currentTarget.value})}
      disabled={isDevengade || isSendDaf || isRejection || disabled}
    />
    <Input
      text="Nro Adjudicación"
      placeholder="Nro Adjudicación"
      icon="fas fa-code"
      classNameParent="col-12 col-md-6 mb-3"
      required
      defaultValue={number_award}
      onChange={e => setRow({...row, number_award: e.currentTarget.value})}
      disabled={isCompromise || isCompleted || isDevengade || isSendDaf || isRejection || disabled}
    />
    {cboProviders}
    <TextArea
      defaultValue={justification_purchase_order}
      icon="far fa-comment-alt"
      text="Observación"
      placeholder="Observación"
      classNameParent="col-12 mb-2"
      rows="5"
      onChange={e => setRow({
        ...row, justification_purchase_order: e.currentTarget.value
      })}
      disabled={isCompromise || isCompleted || isDevengade || isSendDaf || isRejection || disabled}
    />
    {isCompromise || isCompleted || isDevengade || isSendDaf || isRejection ? <>
      <div className="col-12 mt-2 mb-1">Información Compromiso</div>
      <Input
        text="Número Compromiso"
        placeholder="Número Compromiso"
        value={number_compromise}
        disabled
        classNameParent="col-12 col-md-4 mb-2"
        icon="fa fa-code"
      />
      <Input
        text="Fecha Compromiso"
        placeholder="Fecha Compromiso"
        value={date_compromise}
        disabled
        classNameParent="col-12 col-md-4 mb-2"
        icon="fas fa-calendar"
      />
      <Input
        text="Fecha Envío Proveedor"
        placeholder="Fecha Envío Proveedor"
        value={date_send_provider}
        disabled
        classNameParent="col-12 col-md-4 mb-2"
        icon="fas fa-calendar"
      />
    </> : ''}

    {(isDevengade || isSendDaf || isRejection) && number_devengade ? <>
      <div className="col-12 mt-2 mb-1">Información Devengado</div>
      <Input
        text="Número Devengado"
        placeholder="Número Devengado"
        value={number_devengade}
        disabled
        classNameParent="col-6 col-md-4 mb-2"
        icon="fa fa-code"
      />
      <Input
        text="Fecha Devengado"
        placeholder="Fecha Devengado"
        value={date_devengade}
        disabled
        classNameParent="col-6 col-md-4 mb-2"
        icon="far fa-calendar"
      />
    </> : ''}

    {(isSendDaf || isRejection) && number_table ? <>
      <div className="col-12 mt-2 mb-1">Información Enviado DAF</div>
      <Input
        text="Número Mesa Entrada"
        placeholder="Número Mesa Entrada"
        value={number_table}
        disabled
        classNameParent="col-6 col-md-4 mb-2"
        icon="fa fa-code"
      />
      <Input
        text="Fecha Envío DAF"
        placeholder="Fecha Envío DAF"
        value={date_send_daf}
        disabled
        classNameParent="col-6 col-md-4 mb-2"
        icon="fas fa-comment-alt"
      />
      {date_send_daf_last ? <Input
        text="Fecha Envío DAF Reingreso"
        placeholder="Fecha Envío DAF Reingreso"
        value={date_send_daf_last}
        disabled
        classNameParent="col-4 mb-2"
        icon="fas fa-comment-alt"
      /> : ''}
    </> : ''}

    
    {isPay ? <>
      <div className="col-12 mt-2 mb-1">Información de Pago</div>
      <Input
        text="Fecha pago"
        placeholder="Fecha pago"
        value={date_pay_format}
        disabled
        classNameParent="col-6 col-md-4 mb-2"
        icon="fas fa-comment-alt"
      />
      <Input
        text="Número Caja"
        placeholder="Número Caja"
        value={number_box}
        disabled
        classNameParent="col-6 col-md-4 mb-2"
        icon="fa fa-code"
      />
    </> : ''}

    {isRejection ? <>
      <div className="col-12 mt-2 mb-1 text-danger">Información Rechazo</div>
      <Input
        text="Fecha Rechazo"
        placeholder="Fecha Rechazo"
        value={date_rejection}
        disabled
        classNameParent="col-12 col-md-4 mb-2"
        icon="fa fa-code"
      />
      <TextArea
        text="Justificación Rechazo"
        placeholder="Justificación Rechazo"
        value={justification_rejection}
        disabled
        classNameParent="col-12 mb-2"
        icon="fas fa-calendar"
        rows="5"
      />
    </> : ''}

    {date_ban ? <>
      <div className="col-12 mt-2 mb-1 text-danger">Información Anulado</div>
      <Input
        text="Fecha Anulado"
        placeholder="Fecha Anulado"
        value={date_ban}
        disabled
        classNameParent="col-12 col-md-4mb-2"
        icon="fa fa-code"
      />
      <TextArea
        text="Justificación Anulado"
        placeholder="Justificación Anulado"
        value={justification_ban}
        disabled
        classNameParent="col-12 mb-2"
        icon="fas fa-calendar"
        rows="5"
      />
    </> : ''}

    <span className="col-12 mt-2">Centros De Costo</span>
    <div className="table-responsive">
    <table className="table table-sm table-hover table-striped table-bordered mb-4">
      <thead>
        <tr>
          {isCompromise || isSendDaf || isCompleted ? <>
            <th>Centro De Costo</th> 
            <th>Cantidad A Adquirir</th>
            <th>Número Recibo</th>
            <th>Fecha Emisión</th>
            <th>Fecha Recepción</th>
          </> : <>
            <th>Centro De Costo</th>
            <th>Cantidad A Adquirir</th>
          </>}
        </tr>
      </thead>
      <tbody>
        {isCompromise || isCompleted || isDevengade || isSendDaf || isRejection? <>
          {React.Children.toArray(orders.map((s, i) => {
            return <tr>
              <td>{s.unit}</td>
              <td align="center">{s.qty}</td>
              <td><Input
                value={s.number_invoice ? s.number_invoice : ''}
                onChange={e => {
                  setOrders(orders.map((s2, i2) => {
                    if (i2 === i) {
                      s2.number_invoice = e.currentTarget.value
                    }
                    return s2;
                  }))
                }}
                disabled={isDevengade || isSendDaf || isRejection || disabled}
              /></td>
              <td><Input
                type="date"
                value={s.date_issue ? s.date_issue : ''}
                onChange={e => {
                  setOrders(orders.map((s2, i2) => {
                    if (i2 === i) {
                      s2.date_issue = e.currentTarget.value
                    }
                    return s2;
                  }))
                }}
                disabled={isDevengade || isSendDaf || isRejection || disabled}
              /></td>
              <td><Input
                type="date"
                value={s.date_reception ? s.date_reception : ''}
                onChange={e => {
                  setOrders(orders.map((s2, i2) => {
                    if (i2 === i) {
                      s2.date_reception = e.currentTarget.value
                    }
                    return s2;
                  }))
                }}
                disabled={isDevengade || isSendDaf || isRejection || disabled}
              /></td>
            </tr>
          }))}
          <tr>
            <td align="center">Cantidad Total</td>
            <td align="center"><strong>{qtyTotal()}</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </> : <>
          {React.Children.toArray(orders.map(s => {
            return <tr>
              <td>{s.unit}</td>
              <td align="center">{s.qty}</td>
            </tr>
          }))}
          <tr>
            <td align="center">Cantidad Total</td>
            <td align="center"><strong>{qtyTotal()}</strong></td>
          </tr>
        </>}
      </tbody>
    </table>
    </div>
    <span>Insumo De La Consolidación</span>

    <div className="table-responsive">
    <table className="table table-sm table-hover table-striped table-bordered mb-3">
      <thead>
        <tr>
          <th width="60%">
            Insumo
          </th>
          <th width="40%">Precio</th>
        </tr>
      </thead>
      <tbody>
        {React.Children.toArray(supplies.map((s, i) => {
          return <tr>
            <td>{s.supplie}</td>
            <td><Input
              type="number"
              min="0.0000000001"
              step="0.0000000001"
              value={s.price}
              onChange={e => {
                setSupplies(supplies.map((s2, i2) => {
                  if (i2 === i) {
                    s2.price = e.currentTarget.value
                  }
                  return s2;
                }))
              }}
              className="text-center"
              required
              disabled={isCompromise || isCompleted || isDevengade || isSendDaf || isRejection || disabled}
            /></td>
          </tr>
        }))}
      </tbody>
    </table>
    </div>
    <div className="col-12 form-row mb-2">
      <div className="mr-3 col-12 col-md-2">
        <Form.Check
          checked={parseInt(row.iva) === 1}
          custom
          type="radio"
          label="Incluye IVA"
          id="has-iva"
          onChange={() => setRow({ ...row, iva: 1, exemption_iva: '' })}
          name="p_iva"
          required
          disabled={isCompromise || isCompleted || isDevengade || isSendDaf || isRejection || disabled}
        />
      </div>
      <div className="mr-3 col-12 col-md-2">
        <Form.Check
          checked={parseInt(row.iva) === 0}
          custom
          type="radio"
          label="No Incluye IVA"
          id="has-no-iva"
          onChange={() => setRow({ ...row, iva: 0, exemption_iva: '' })}
          name="p_iva"
          required
          disabled={isCompromise || isCompleted || isDevengade || isSendDaf || isRejection || disabled}
        />
      </div>
      <div className="col-12 col-md-2">
        <Form.Check
          checked={parseInt(row.iva) === 2}
          custom
          type="radio"
          label="Exento IVA"
          id="exemption-iva"
          onChange={() => setRow({ ...row, iva: 2, exemption_iva: '' })}
          name="p_iva"
          required
          disabled={isCompromise || isCompleted || isDevengade || isSendDaf || isRejection || disabled}
        />
      </div>
    </div>
    {parseInt(row.iva) === 1 ? <Input
      value={row.exemption_iva ? row.exemption_iva : ''}
      className="text-center font-weight-bold"
      icon="fa fa-code"
      text="Exención de IVA"
      placeholder="Exención de IVA"
      classNameParent="col-12 col-md-4 mb-2"
      required={invoices.length > 0 || orders.some(o => o.number_invoice)}
      disabled={isCompleted || isDevengade || isSendDaf || isRejection || disabled}
      onChange={e => setRow({ ...row, exemption_iva: e.currentTarget.value })}
    /> : ''}
    <Input
      value={(total() - (parseInt(row.iva) === 1 ? (total() / 1.12) * 0.12 : 0)).toFixed(2) + ' Q.'}
      className="text-center font-weight-bold"
      icon="fa fa-dollar-sign"
      text="Subtotal"
      placeholder="0.00"
      classNameParent="col-12 col-md-4 mb-2"
      disabled
    />
    <Input
      value={(parseInt(row.iva) === 1 ?((total() / 1.12) * 0.12).toFixed(2) : 0) + ' Q.'}
      className="text-center font-weight-bold"
      icon="fa fa-dollar-sign"
      text="IVA"
      placeholder="0.00"
      classNameParent="col-12 col-md-4 mb-2"
      disabled
    />
    <Input
      value={parseFloat(total()).toFixed(2) + ' Q.'}
      className="text-center font-weight-bold"
      icon="fa fa-dollar-sign"
      text="Total De La Compra"
      placeholder="0.00"
      classNameParent="col-12 col-md-4 mb-2"
      disabled
    />
    {isCompromise || isCompleted || isDevengade || isSendDaf || isRejection ? <>
      <div className="col-12 d-flex justify-content-between">
        <div className="align-self-center">Listado De Facturas</div>
        {isSendDaf || isRejection || disabled ? '' : <Button size="xs" onClick={() => {
          setInvoices([...invoices, {
            serie_invoice: '',
            number_invoice: '',
            date_invoice: '',
          }])
        }}><i className="fa fa-plus mr-1"></i>Factura</Button>}
      </div>
      <div className="col-12 mb-3">
        <div className="table-responsive">
        <table className="table table-sm table-hover table-striped table-bordered mb-4">
          <thead>
            <tr>
              <th>Serie</th>
              <th>Número</th>
              <th>Fecha</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {React.Children.toArray(invoices.map((i, j) => {
            return <tr>
              <td><Input
                value={i.serie_invoice}
                required
                onChange={e => {
                  setInvoices(invoices.map((i2, j2) => {
                    if (j === j2) {
                      i2.serie_invoice = e.currentTarget.value
                    }
                    return i2;
                  }))
                }}
                disabled={isSendDaf || disabled}
              /></td>
              <td><Input
                value={i.number_invoice}
                required
                onChange={e => {
                  setInvoices(invoices.map((i2, j2) => {
                    if (j === j2) {
                      i2.number_invoice = e.currentTarget.value
                    }
                    return i2;
                  }))
                }}
                disabled={isSendDaf || disabled}
              /></td>
              <td><Input
                type="date"
                value={i.date_invoice}
                required
                onChange={e => {
                  setInvoices(invoices.map((i2, j2) => {
                    if (j === j2) {
                      i2.date_invoice = e.currentTarget.value
                    }
                    return i2;
                  }))
                }}
                disabled={isSendDaf || disabled}
              /></td>
              <td>{isDevengade || isSendDaf || isRejection || disabled ? '' : <Button size="xs" variant="danger" onClick={() => {
                setInvoices(invoices.filter((i2, j2) => {
                  if (j !== j2) {
                    return i2
                  }
                  return false;
                }))
              }}><i className="fa fa-minus"></i></Button>}</td>
            </tr>
          }))}
          </tbody>
        </table>
        </div>
      </div>
    </> :''}
  </FormCustom>
  {user_created ? <>
    <hr />
    <div className="d-flex flex-column">
      <span>Usuario creación: <strong>{user_created}</strong></span>
      <span>Fecha creación: <strong>{date_created}</strong></span>
    </div>
  </> : ''}
  </ReactPlaceholder>
}

PresentationForm.defaultProps = {
  disabled: false
}

export default PresentationForm;