import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Controllers from '../Api/Controllers';
import { FormCustom, Input } from '../Components/Form/Form';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import PurchaseOrdersForm from './PurchaseOrdersForm'

const Row = () => {
  const [form, setForm] = useState('');

  useEffect(() => {
    /* Controllers[nameController]['get_' + nameController]({
      p_id_group: idGroup
    }).then(res => {
      setRows(res.data)
    })
    .finally(() => {
      setIsProcessing(false)
      setReload(false);
    }); */
  }, [])


  return <> 
    <Card>
      <Card.Header className="justify-content-between">
        <span>Buscar Orden De Compra</span>
      </Card.Header>
      <Card.Body>
        <FormCustom onSubmit={({e}) => {
          let $this = e.currentTarget

          let propsValidateForm = { form: $this, toastr };
          if (!$this.checkValidity()) {
            $this.classList.add('was-validated');
            Helpers.form.isFormCorrect(propsValidateForm);
            return;
          } else {
            $this.classList.remove('was-validated');
            if (!Helpers.form.isFormCorrect(propsValidateForm)) {
              return;
            }
          }

          let button = $this.querySelector('button[type=submit]');
          let buttonHTML = button.innerHTML
          button.innerHTML = Helpers.icon.spin();

          Controllers.orders.get_orders_consolidate_number_oc({
            p_number_purchase: document.getElementById('txt_number_purchase').value
          }).then(res => {
            if (res.data.number_purchase) {
              setForm(<PurchaseOrdersForm data={res.data} typeOrder={res.data.type_order} disabled state={parseInt(res.data.state)} idConsolidate={res.data.id} />);
            } else {
              setForm(<>No existe información de la OC: <strong>{document.getElementById('txt_number_purchase').value}</strong></>)
            }
          })
          .finally(() => {
            button.innerHTML = buttonHTML
          });
        }} viewSubmit={false}>
          <Input 
            defaultValue=""
            text="Buscar OC"
            placeholder="Buscar OC"
            icon="fa fa-code"
            id="txt_number_purchase"
            append={[<Button type="submit"><i className="fa fa-search"></i></Button>]}
            onChange={() => setForm('')}
            required
            classNameParent="col-12"
          />
        </FormCustom>
      </Card.Body>
    </Card>
    {form ? <Card className="mt-3">
      <Card.Body>{form}</Card.Body>
    </Card> : ''}
  </>
}

export default Row;