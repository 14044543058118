import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Controllers from '../Api/Controllers';
import { FormCustom, Input, Select, TextArea } from '../Components/Form/Form';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import { useSelector } from 'react-redux';
import ReactPlaceholder from 'react-placeholder/lib';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'


const QuarterAnnualForm = ({ data, onSubmit, disabled }) => {
  const yearCurrent = useSelector(store => store.session.year)

  const {
    id = '',
    id_unit = '',
    year = yearCurrent,
    description = '',
    user_created = '',
    date_created = '',
    user_updated = '',
    date_updated = '',
    justification = '',
    quarter_approve = 0,
    budget_pac = ''
  } = data

  const [isOk, setIsOk] = useState(false)
  const [unit, setUnit] = useState([])
  const [unitSelected, setUnitSelected] = useState(id ? id_unit : '')
  const [budget, setBudget] = useState(id ? budget_pac : 0)
  const [details, setDetails] = useState([])

  const total = useCallback(() => {
    let t = 0

    details.forEach(d => {
      d.groups.forEach(g => {
        if (!isNaN(parseFloat(g.budget))) {
          t += parseFloat(g.budget)
        }
      })
    })
    return t
  }, [details])

  const dataSumbit = useCallback(() => {
    let p_details = []
    
    details.forEach(d => {
      d.groups.forEach(g => {
        if (!isNaN(parseFloat(g.budget))) {
          p_details.push({
            p_id_group: g.id_group,
            p_quarter: d.name,
            p_budget: g.budget
          })
        }
      })
    })

    return {
      p_id: id,
      p_id_unit: unitSelected,
      p_year: year ? year : yearCurrent,
      p_budget: parseFloat(total()).toFixed(2),
      p_budget_pac: budget,
      p_quarter_approve: quarter_approve,
      p_details,
    }
  }, [year, yearCurrent, details, id, total, unitSelected, quarter_approve, budget])

  useEffect(() => {
    let promises = [
      Controllers.quarter_annual.get_unit_without_quarter({
        p_year: yearCurrent
      }),
      Controllers.group.get_group(),
      Controllers.quarter_annual.get_quarter_annual_details({
        p_id: id
      })
    ]
    Promise.all(promises).then(res => {
      setUnit(res[0].data)
      if (id) {
        setDetails([
          {
            name: 'PRIMERO',
            groups: res[2].data.filter(r => r.quarter === 'PRIMERO').map(r => ({
              id_group: r.id,
              description: r.description,
              budget: r.budget ? r.budget : ''
            }))
          },
          {
            name: 'SEGUNDO',
            groups: res[2].data.filter(r => r.quarter === 'SEGUNDO').map(r => ({
              id_group: r.id,
              description: r.description,
              budget: r.budget ? r.budget : ''
            }))
          },
          {
            name: 'TERCERO',
            groups: res[2].data.filter(r => r.quarter === 'TERCERO').map(r => ({
              id_group: r.id,
              description: r.description,
              budget: r.budget ? r.budget : ''
            }))
          }
        ])
      } else {
        if (res[0].data.length === 1) {
          if (id === '') {
            setUnitSelected(res[0].data[0].id)
            setBudget(res[0].data[0].budget)
          }
        }
        setDetails([
          {
            name: 'PRIMERO',
            groups: res[1].data.map(r => ({
              id_group: r.id,
              description: r.description,
              budget: ''
            }))
          },
          {
            name: 'SEGUNDO',
            groups: res[1].data.map(r => ({
              id_group: r.id,
              description: r.description,
              budget: ''
            }))
          },
          {
            name: 'TERCERO',
            groups: res[1].data.map(r => ({
              id_group: r.id,
              description: r.description,
              budget: ''
            }))
          }
        ])
      }
      
      setIsOk(true)
    })
  }, [year, id_unit, yearCurrent, id])

  return <ReactPlaceholder showLoadingAnimation rows={7}  type='text' ready={isOk}>
    {justification ? <p className="text-muted">El PAC fue rechazado por el siguiente motivo: <br/><strong>{justification}</strong></p> : ''}

    <FormCustom dataSubmit={dataSumbit()} onSubmit={onSubmit} viewSubmit={disabled ? false : parseFloat(total()) <= parseFloat(budget)}>
      {description !== '' || disabled ? <Input
        text="Centro de costo"
        icon="far fa-building"
        disabled
        classNameParent="col-12 col-md-8 mb-2"
        defaultValue={id_unit + ' - ' + description}
      /> : <Select
        options={unit.map(s => {
          return {
            value: s.id,
            label: s.id + ' - ' + s.description,
            budget: s.budget
          }
        })}
        value={unit.some(u => parseInt(u.id) === parseInt(unitSelected)) ? { value: unitSelected, label: unit.find(u => parseInt(u.id) === parseInt(unitSelected)).id + ' - ' +unit.find(u => parseInt(u.id) === parseInt(unitSelected)).description } : ''}
        onChange={e => {
          setUnitSelected(e.value)
          setBudget(e.budget)
        }}
        text="Centro de costo"
        icon="far fa-building"
        disabled={id_unit !== ''}
        classNameParent="col-12 col-md-8 mb-2"
      />}
      <Input
        defaultValue={yearCurrent}
        className="text-center"
        disabled={false}
        classNameParent="col-12 col-md-4 mb-2"
        readOnly
        text="Año PAC"
        icon="far fa-calendar"
      />

      <div className="col-12 mt-2 mb-2 d-flex flex-column">
        <span>Presupuesto Total: <strong>{Helpers.number.float.format({ number: parseFloat(total()) })}</strong></span>
        <span>Presupuesto PAC: <strong>{Helpers.number.float.format({ number: parseFloat(budget) })}</strong></span>
      </div>

      <div className="col-12 mb-2 mt-2">
        <Tabs defaultActiveKey="PRIMERO" id="uncontrolled-tab-example">
          {React.Children.toArray(details.map((d, i) => {
            let subtotal = 0
            return <Tab eventKey={d.name} title={d.name}>
              <table>
                <thead>
                  <tr>
                    <th>Grupo</th>
                    <th>Presupuesto</th>
                  </tr>
                </thead>
                <tbody>
                  {React.Children.toArray(d.groups.map((g, j) => {
                    if (!isNaN(parseFloat(g.budget))) {
                      subtotal += parseFloat(g.budget)
                    }

                    return <tr>
                      <td>{g.id_group + ' - ' + g.description}</td>
                      <td><Input
                        type="number"
                        step="0.01"
                        min="0.01"
                        value={g.budget}
                        className="text-center"
                        onChange={e => setDetails(details.map((d2, i2) => {
                          if (i === i2) {
                            d2.groups = d2.groups.map((g2, j2) => {
                              if (j === j2) {
                                g2.budget = e.currentTarget.value
                              }
                              return g2
                            })
                          }
                          return d2
                        }))}
                        disabled={disabled || (d.name === 'PRIMERO' && parseInt(quarter_approve) > 0) || (d.name === 'SEGUNDO' && parseInt(quarter_approve) > 1) || (d.name === 'TERCERO' && parseInt(quarter_approve) > 2)}
                      /></td>
                    </tr>
                  }))}
                  <tr>
                    <td>Subtotal</td>
                    <th>{parseFloat(subtotal).toFixed(2)}</th>
                  </tr>
                </tbody>
              </table>
            </Tab>
          }))}
        </Tabs>
      </div>

    </FormCustom>
    
    
    {id === '' ? '' : <>
      <hr />
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <span className="text-muted">Usuario Creación: <strong>{user_created}</strong></span>
          <span className="text-muted">Fecha Creación: <strong>{date_created}</strong></span>
        </div>
        {user_updated === '' ? '' : <div className="d-flex flex-column">
          <span className="text-muted">Usuario última actualización: <strong>{user_updated}</strong></span>
          <span className="text-muted">Fecha última actualización: <strong>{date_updated}</strong></span>
        </div>}
      </div>
    </>}
  </ReactPlaceholder>
}

QuarterAnnualForm.defaultProps = {
  disabled: false
}

const QuarterAnnual = () => {
  const nameModulo = 'Programación Cuatrimestral'
  const namePage = 'Programación'
  const nameController = 'quarter_annual'

  const p_year = useSelector(store => store.session.year)
  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = Helpers.getActions({ permissions, name: 'programacioncuatrimestral' })

  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [modalSize, setModalSize] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [form, setForm] = useState('');
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      Controllers[nameController]['get_' + nameController]({p_year}).then(res => {
        setRows(res.data)
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload, p_year])

  const handleUpdate = useCallback(e => {
    const { id = '', disabled = 0 } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()
    
    setModalTitle(parseInt(disabled) === 1 ? <><i className={'mr-2 ' + actionsModule.view.icon}></i>{actionsModule.view.description} {namePage}</> : (id ? <><i className={'mr-2 ' + actionsModule.update.icon}></i>{actionsModule.update.description} {namePage}</> : <><i className={'mr-2 ' + actionsModule.create.icon}></i>{actionsModule.create.description} {namePage}</>))
    setModalSize('xl');
    setModalHeader('');

    Controllers[nameController]['get_' + nameController + '_id']({ p_id: id }).then(res => {
      setForm(<QuarterAnnualForm disabled={parseInt(disabled) === 1} data={res.data} onSubmit={onSubmit} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }, [actionsModule])

  const onSubmit = ({ e, dataSubmit }) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }

      if (dataSubmit.p_id_unit === '') {
        Helpers.toastr.construct({
          response: 'warning',
          message: 'Seleccionar centro de costo',
          toastr
        })
        return
      }

      let hasPrimero = false
      let hasSegundo = false
      let hasTercero = false

      dataSubmit.p_details.forEach(d => {
        if (d.p_quarter === 'PRIMERO') {
          hasPrimero = true
        }
        if (d.p_quarter === 'SEGUNDO') {
          hasSegundo = true
        }
        if (d.p_quarter === 'TERCERO') {
          hasTercero = true
        }
      })

      if (parseInt(dataSubmit.p_quarter_approve) === 0 && !hasPrimero) {
        Helpers.toastr.construct({
          response: 'warning',
          message: 'Ingresar presupuestos en el cuatrimestre PRIMERO',
          toastr
        })
        return
      }

      if (parseInt(dataSubmit.p_quarter_approve) === 1 && !hasSegundo) {
        Helpers.toastr.construct({
          response: 'warning',
          message: 'Ingresar presupuestos en el cuatrimestre SEGUNDO',
          toastr
        })
        return
      }

      if (parseInt(dataSubmit.p_quarter_approve) === 2 && !hasTercero) {
        Helpers.toastr.construct({
          response: 'warning',
          message: 'Ingresar presupuestos en el cuatrimestre TERCERO',
          toastr
        })
        return
      }

      if (parseInt(dataSubmit.p_quarter_approve) === 2 && parseFloat(dataSubmit.p_budget).toFixed(2) !== parseFloat(dataSubmit.p_budget_pac).toFixed(2)) {
        Helpers.toastr.construct({
          response: 'warning',
          message: 'El presupuesto total debe de coincider con el presupuesto del PAC.',
          toastr
        })
        return
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers[nameController][nameController + '_insert_update'](dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        setModalShow(false);
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
      setReload(true)
    });
  }

  const dtRows = useMemo(() => {
    const thead = [
      { name: 'Centro De Costo' },
      { name: 'Año', align: 'center' },
      { name: 'Presupuesto', align: 'center' },
      { name: 'Estado', align: 'center' },
      { name: 'Acc.', align: 'center' }
    ];

    return <DataTable
      isProcessing={isProcessing}
      tbody={rows}
      thead={thead}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;
        for (let key in r) {
          if (key === 'id') {
            continue
          }
          if (key === 'state') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''} className={'text-' + (parseInt(r.state) === 0 ? 'warning' : (parseInt(r.state) === 1 ? 'info' : (parseInt(r.state) === 2 ) ? 'danger' : 'success'))}>
              <strong>{parseInt(r.state) === 0 ? 'PENDIENTE' : (parseInt(r.state) === 1 ? 'PENDIENTE DE APROBACIÓN' : (parseInt(r.state) === 2 ) ? 'RECHAZADO' : 'APROBADO')}</strong>
            </td>);
          } else if (key === 'budget') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{Helpers.number.float.format({
              number: parseFloat(r[key])
            })}</td>);
          } else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }

        rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
          {actionsModule.view ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.view.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.view.color} data-disabled={1} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={handleUpdate}>
              <i className={actionsModule.view.icon}></i>
            </Button>
          </OverlayTrigger> :''}

          {parseInt(r.state) === 0 || parseInt(r.state) === 2 ? <>
            {actionsModule.update ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.update.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.update.color} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={handleUpdate}>
                <i className={actionsModule.update.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.sendrequest ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.sendrequest.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.sendrequest.color} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
                let button = e.currentTarget;
                button.innerHTML = Helpers.icon.spin();
                const { id } = e.currentTarget.dataset;
                Controllers.quarter_annual.quarter_send_approve({
                  p_id: id
                }).then(res => {
                  Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
                }).finally(() => {
                  setReload(true)
                })
              }}>
                <i className={actionsModule.sendrequest.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
          </> : ''}

          {parseInt(r.state) === 1 ? <>
            {actionsModule.approve ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.approve.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.approve.color} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
                let button = e.currentTarget;
                button.innerHTML = Helpers.icon.spin();
                Controllers.quarter_annual.quarter_approve({
                  p_id: r.id
                }).then(res => {
                  Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
                }).finally(() => {
                  setReload(true)
                })
              }}>
                <i className={actionsModule.approve.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.refuse ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.refuse.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.refuse.color} size="xs" data-description={r.description} data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
                setModalTitle(<><i className="fa fa-times mr-2"></i>Rechazar Programación</>)
                setModalHeader('bg-danger')
                setModalShow(true)
                setModalSize('sm')
                setForm(<form className="form-row needs-validation" onSubmit={e => {
                  e.preventDefault();
                  let $this = e.currentTarget

                  let button = $this.querySelector('button[type=submit]');
                  let buttonHTML = button.innerHTML
                  button.innerHTML = Helpers.icon.spin();

                  if (!$this.checkValidity()) {
                    $this.classList.add('was-validated');
                  } else {
                    Controllers.quarter_annual.quarter_refuse({
                      p_id: r.id,
                      p_justification: document.getElementById('txt_justify_disapprove').value,
                    }).then(res => {
                      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
                      setReload(true)
                      setModalShow(false);
                    }).catch(req => {
                      setModalShow(false)
                      Helpers.promise.catch({ req, toastr });
                    }).finally(() => button.innerHTML = buttonHTML)
                  }
                }}>
                  <p className="col-12 text-center">Rechazar Programación <strong>{e.currentTarget.dataset.description}/{e.currentTarget.dataset.year}</strong></p>

                  <TextArea
                    text="Justificación"
                    placeholder="Ingresar..."
                    classNameParent="col-12 mb-2"
                    icon="fas fa-comment-alt"
                    required
                    autoFocus
                    id="txt_justify_disapprove"
                    rows={4}
                  />

                <div className="col-12 d-flex justify-content-center">        
                  <Button type="submit" variant="danger" className="mr-2" size="sm">
                    <i className="fa fa-check mr-2"></i>Rechazar
                  </Button>
                  <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalShow(false)}>
                    <i className="fa fa-times mr-2"></i>Cancelar
                  </Button>
                </div>
                </form>)
              }}>
                <i className={actionsModule.refuse.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
          </> : ''}
        </td>);
        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, rows, handleUpdate, actionsModule])

  return <>
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De {nameModulo}</span>
        <div>
        {actionsModule.create ? <Button onClick={handleUpdate} variant={actionsModule.create.color} size="sm"><i className={ actionsModule.create.icon}></i><span className="ml-2 d-none d-md-inline-block">{actionsModule.create.description} {namePage}</span></Button> : ''}
        </div>
      </Card.Header>
      <Card.Body>
        {dtRows}
      </Card.Body>
    </Card>
    <Modal show={modalShow} onHide={() => setModalShow(false)} size={modalSize} backdrop="static">
      <Modal.Header closeButton className={modalHeader}>
        <Modal.Title as="div">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
  </>
}

export default QuarterAnnual;