import React, { useState, useEffect, useCallback } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Controllers from '../Api/Controllers';
import { FormCustom, Input, CheckBox } from '../Components/Form/Form';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import {useSelector} from 'react-redux'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const RowForm = ({ data, onSubmit, idUnit, viewDisabled }) => {
  const {
    id = '',
    name = '',
    profession = '',
    state = 0,
    main = 0,
    type = 'SOLICITANTE',
    in_acts = 0,
    type_unops = 'REALIZA',
    unops = 0
  } = data

  const [row, setRow] = useState({
    id,
    name,
    profession,
    state: parseInt(state),
    main: parseInt(main),
    type,
    in_acts,
    type_unops,
    unops
  })

  const dataSumbit = useCallback(() => {
    return {
      p_id: row.id,
      p_name: row.name,
      p_id_unit: idUnit,
      p_profession: row.profession,
      p_state: row.state,
      p_main: row.main,
      p_type: row.type,
      p_in_acts: row.in_acts,
      p_type_unops: row.type_unops,
      p_unops: row.unops,
    }
  }, [row, idUnit])

  return <FormCustom dataSubmit={dataSumbit()} onSubmit={onSubmit} viewSubmit={!viewDisabled}>
    <Input
      text="Nombres"
      placeholder="Nombres"
      classNameParent="col-12 mb-2"
      icon="far fa-comment-alt"
      required
      defaultValue={name}
      onChange={e => setRow({ ...row, name: e.currentTarget.value })}
      maxLength={255}
      disabled={viewDisabled}
    />
    <Input
      text="Cargo"
      placeholder="Cargo"
      classNameParent="col-12 mb-2"
      icon="far fa-comment-alt"
      required
      defaultValue={profession}
      onChange={e => setRow({ ...row, profession: e.currentTarget.value })}
      maxLength={255}
      disabled={viewDisabled}
    />
    <CheckBox
      text="Activo"
      classNameParent="col-12 mb-2"
      defaultChecked={parseInt(state) === 1}
      onChange={e => setRow({ ...row, state: e.currentTarget.checked ? 1 : 0 })}
      id="state-type-purchase"
      disabled={viewDisabled}
    />
    <CheckBox
      text="Predeterminado"
      classNameParent="col-12 mb-2"
      defaultChecked={parseInt(main) === 1}
      onChange={e => setRow({ ...row, main: e.currentTarget.checked ? 1 : 0 })}
      id="main-type-purchase"
      disabled={viewDisabled}
    />
    <CheckBox
      text="¿En Actas?"
      classNameParent="col-12 mb-2"
      defaultChecked={parseInt(in_acts) === 1}
      onChange={e => setRow({ ...row, in_acts: e.currentTarget.checked ? 1 : 0 })}
      id="in-acts"
      disabled={viewDisabled}
    />
    <div className="col-12 d-flex mb-2">
      <div className="mr-3">
        <Form.Check
          checked={row.type === 'SOLICITANTE'}
          custom
          type="radio"
          label="SOLICITANTE"
          id="rdb-request"
          onChange={() => setRow({ ...row, type: 'SOLICITANTE' })}
          name="p_iva"
          required
          disabled={viewDisabled}
        />
      </div>
      <div className="mr-3">
        <Form.Check
          checked={row.type === 'AUTORIZADOR'}
          custom
          type="radio"
          label="AUTORIZADOR"
          id="rbd-autorizador"
          onChange={() => setRow({ ...row, type: 'AUTORIZADOR' })}
          name="p_iva"
          required
          disabled={viewDisabled}
        />
      </div>
    </div>
    <div className="col-12 mt-2">
      <p className="mb-2">Información para Unops</p> 
    </div>
    <CheckBox
      text="¿En Unops?"
      classNameParent="col-12 mb-2"
      defaultChecked={parseInt(unops) === 1}
      onChange={e => setRow({ ...row, unops: e.currentTarget.checked ? 1 : 0 })}
      id="in-unops"
      disabled={viewDisabled}
    />
    <div className="col-12 d-flex mb-2">
      <div className="mr-3">
        <Form.Check
          checked={row.type_unops === 'REALIZA'}
          custom
          type="radio"
          label="REALIZA AUTORIZACIÓN"
          id="rdb-realiza"
          onChange={() => setRow({ ...row, type_unops: 'REALIZA' })}
          name="p_unops"
          required
          disabled={viewDisabled}
        />
      </div>
      <div className="mr-3">
        <Form.Check
          checked={row.type_unops === 'REVISA'}
          custom
          type="radio"
          label="REVISA AUTORIZACIÓN"
          id="rbd-autoriza"
          onChange={() => setRow({ ...row, type_unops: 'REVISA' })}
          name="p_unops"
          required
          disabled={viewDisabled}
        />
      </div>
    </div>
  </FormCustom>
}

const Row = ({ idUnit }) => {
  const nameModulo = 'Firmas'
  const namePage = 'Firma'
  const nameController = 'firms'

  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = Helpers.getActions({ permissions, name: 'firmas' })

  const thead = [
    { name: 'ID', align: 'center' },
    { name: 'Nombres' },
    { name: 'Cargo', align: 'center' },
    { name: 'Tipo', align: 'center' },
    { name: 'Estado', align: 'center' },
    { name: 'Predeterminado', align: 'center' },
    { name: 'En Actas', align: 'center' },
    { name: 'En Unops', align: 'center' },
    { name: 'Tipo Unops', align: 'center' },
    { name: 'Acc.', align: 'center' }
  ];

  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [modalSize, setModalSize] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [form, setForm] = useState('');
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      Controllers[nameController]['get_' + nameController]({
        p_id_unit: idUnit
      }).then(res => {
        setRows(res.data)
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload, idUnit])

  const handleUpdate = e => {
    const { id = '', disabled = 0 } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()
    
    setModalTitle(parseInt(disabled) === 1 ? <><i className={'mr-2 ' + actionsModule.view.icon}></i>{actionsModule.view.description} {namePage}</> : (id ? <><i className={'mr-2 ' + actionsModule.update.icon}></i>{actionsModule.update.description} {namePage}</> : <><i className={'mr-2 ' + actionsModule.create.icon}></i>{actionsModule.create.description} {namePage}</>))
    setModalSize('');
    setModalHeader('');

    Controllers[nameController]['get_' + nameController + '_id']({ p_id: id }).then(res => {
      setForm(<RowForm data={res.data} viewDisabled={parseInt(disabled) === 1} onSubmit={onSubmit} idUnit={idUnit} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }

  const onSubmit = ({ e, dataSubmit }) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers[nameController][nameController + '_insert_update'](dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        setModalShow(false);
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
      setReload(true)
    });
  }

  const onDelete = e => {
    const { id = '' } = e.currentTarget.dataset;
    setModalTitle(<><i className="fa fa-trash mr-2"></i> Eliminar {namePage}</>);
    setModalHeader('bg-danger');
    setModalSize('sm');
    setForm(<div className="d-flex flex-column align-items-center">
      <p><strong>¿Eliminar {namePage} #{id}?</strong></p>

      <div>
        <Button variant="danger"  size="sm" onClick={e => {
          let button = e.currentTarget
          let buttonHTML = button.innerHTML;
          button.innerHTML = Helpers.icon.spin()

          Controllers[nameController][nameController + '_delete']({
            p_id: id
          }).then(res => {
            Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
          }).catch(req => {
            Helpers.promise.catch({ req, toastr });
          }).finally(() => {
            button.innerHTML = buttonHTML;
            setReload(true)
            setModalShow(false);
          });
        }}>
          <i className="fa fa-check mr-2"></i>Si
        </Button>
        <Button variant="dark" className="ml-2" size="sm" onClick={() => setModalShow(false)}>
          <i className="fa fa-times mr-2"></i>No
        </Button>
      </div>
    </div>);
    setModalShow(true)
  }

  return <> 
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De {nameModulo}</span>
        {actionsModule.create ? <Button onClick={handleUpdate} variant={actionsModule.create.color} size="sm"><i className={ actionsModule.create.icon}></i><span className="ml-2 d-none d-md-inline-block">{actionsModule.create.description} {namePage}</span></Button> : ''}
      </Card.Header>
      <Card.Body>
        <DataTable
          isProcessing={isProcessing}
          tbody={rows}
          thead={thead}
          render={tbody => tbody.map(r => {
            let rowRender = [];
            let i = 0;
            for (let key in r) {
              if (key === 'state') {
                rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
                  <strong className={'text-' + (parseInt(r.state) === 1 ? 'success': 'danger')}>
                    {parseInt(r.state) === 1 ? 'ACTIVO': 'INACTIVO'}
                  </strong>
                </td>);
              } else if (key === 'main') {
                rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
                  <strong className={'text-' + (parseInt(r.main) === 1 ? 'success': 'danger')}>
                    {parseInt(r.main) === 1 ? 'SI': 'NO'}
                  </strong>
                </td>);
              } else if (key === 'in_acts') {
                rowRender.push(<td align="center">
                  <strong className={'text-' + (parseInt(r.in_acts) === 1 ? 'success': 'danger')}>
                    {parseInt(r.in_acts) === 1 ? 'SI': 'NO'}
                  </strong>
                </td>);
              } else if (key === 'unops') {
                rowRender.push(<td align="center">
                  <strong className={'text-' + (parseInt(r.unops) === 1 ? 'success': 'danger')}>
                    {parseInt(r.unops) === 1 ? 'SI': 'NO'}
                  </strong>
                </td>);
              } else {
                rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
              }
              i++;
            }

            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
              {actionsModule.view ?  <OverlayTrigger
                placement="top"
                overlay={props => <Tooltip {...props}>
                  {actionsModule.view.description}
                </Tooltip>}
              >
                <Button variant={actionsModule.view.color} data-disabled={1} size="xs" className="mr-1" data-id={r.id} onClick={handleUpdate}>
                  <i className={actionsModule.view.icon}></i>
                </Button>
              </OverlayTrigger> : ''}
              {actionsModule.update ?  <OverlayTrigger
                placement="top"
                overlay={props => <Tooltip {...props}>
                  {actionsModule.update.description}
                </Tooltip>}
              >
                <Button variant={actionsModule.update.color} size="xs" className="mr-1" data-id={r.id} onClick={handleUpdate}>
                  <i className={actionsModule.update.icon}></i>
                </Button>
              </OverlayTrigger> : ''}
              {actionsModule.delete ? <OverlayTrigger
                placement="top"
                overlay={props => <Tooltip {...props}>
                  {actionsModule.delete.description}
                </Tooltip>}
              >
                <Button variant={actionsModule.delete.color} size="xs" data-id={r.id} onClick={onDelete}>
                  <i className={actionsModule.delete.icon}></i>
                </Button>
              </OverlayTrigger> : ''}
            </td>);
            return (<tr>
              {React.Children.toArray(rowRender)}
            </tr>);
          })}
        />
      </Card.Body>
    </Card>
    <Modal show={modalShow} onHide={() => setModalShow(false)} size={modalSize} backdrop="static">
      <Modal.Header closeButton className={modalHeader}>
        <Modal.Title as="div">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
  </>
}

export default Row;