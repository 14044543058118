import React, { useState, useCallback } from 'react';
import { Input, CheckBox, Select } from '../../Components/Form/Form';
import Button from 'react-bootstrap/Button';
import Helpers from '../../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import Controllers from '../../Api/Controllers';
import './Login.scss';

const Login = () => {
  const years = []
  const date = new Date();
  const currentYear = date.getFullYear()
  const [session, setSession] = useState({
    year: currentYear,
    username: Helpers.config.isDeveloperWeb() ? 'administrador' : '',
    password: Helpers.config.isDeveloperWeb() ? '1234' : ''
  })

  for (let i = 2020; i <= (currentYear+1); i++) {
    years.push(i)
  }

  const dataSubmit = useCallback(() => {
    return { 
      p_username: session.username,
      p_password: session.password,
      p_year: 2021 //session.year
    }
  }, [session]);

  const handleSubmit = e => {
    e.preventDefault();
    let $this = e.currentTarget;

    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect({ form: $this, toastr });
    } else {
      $this.classList.remove('was-validated');

      let button = $this.querySelector('button');
      let buttonHTML = button.innerHTML;
      button.innerHTML = Helpers.icon.spin();
      
      Controllers.user.get_login(dataSubmit()).then(res => {
        Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
        if (res.response === 'success') {
          Helpers.localStorage.setSession({
            jwt: res.data.jwt
          });
          window.location.href = Helpers.config.folder_path;
        } else {
          button.innerHTML = buttonHTML;
        }
      }).catch(req => {
        Helpers.promise.catch({req, toastr });
        button.innerHTML = buttonHTML;
      });
    }
  }

  return <div className="bg-box" style={{
    background: `url(${Helpers.config.api.images + '/dashboard/background-auth.png'})`
  }}>
    <div className="box-bg-container">
      <div className="box-bg-content bg-white form-row rounded">
        <div className="col-12 text-center mb-4">
          <img src={Helpers.config.api.images + '/logos/isotipo_logotipo.png'} alt={Helpers.config.company.name} style={{
            maxHeight: '200px'
          }} />
        </div>

        <form onSubmit={handleSubmit} autoComplete="off" className="col-12 needs-validation" noValidate>
          <div className="form-row">
            <Input text="Nombre de usuario"
              placeholder="Nombre de usuario"
              icon="fa fa-user"
              classNameParent="col-12 mb-2"
              required={true}
              defaultValue={session.username}
              onChange={e => setSession({ ...session, username: e.currentTarget.value })}
            />
            <Input type="password" 
              text="Contraseña"
              placeholder="Contraseña"
              icon="fa fa-lock"
              classNameParent="col-12 mb-2"
              required={true}
              defaultValue={session.password}
              onChange={e => setSession({ ...session, password: e.currentTarget.value })}
            />
            <Select
              required
              text="Año"
              classNameParent="col-12 mb-2"
              defaultValue={{ value: currentYear , label: currentYear }}
              options={years.sort((a, b) => {
                if (a > b) return -1
                return 1
              }).map(y => {
                return {
                  value: y, label: y
                }
              })}
              icon="fa fa-calendar"
              onChange={value => setSession({ ...session, year: value.value })}
            />
            <CheckBox text="Recordarme" defaultChecked={false} id="remember_me" />
            <div className="col-12 mt-2">
              <Button type="primary" size="sm" block>
                <i className="fa fa-check mr-2"></i> Iniciar Sesión
              </Button>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
}

export default Login;