import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Controllers from '../Api/Controllers';
import { FormCustom, Input, Select, TextArea, CheckBox } from '../Components/Form/Form';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import { useSelector } from 'react-redux';
import Supplies from './Supplies';
import ReactPlaceholder from 'react-placeholder/lib';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Accordion from 'react-bootstrap/Accordion'

const SuppliesPublic = ({showModal, hideModal, added, onAdd }) => {
  return <>
    <Modal show={showModal} onHide={hideModal} size={'lg'} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title as="div"><i className="fa fa-list mr-2"></i> Listado De Insumos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Supplies isPublic={true} added={added} onAdd={onAdd} hasPrice newSupplie={false} />
      </Modal.Body>
    </Modal>
  </>
}

const PacksAnnualForm = ({ data, onSubmit, disabled }) => {
  const [subProducts, setSubProducts] = useState([])
  const [unit,setUnit] = useState([])
  const yearCurrent = useSelector(store => store.session.year)
  const [modalShowSupplies, setModalShowSupplies] = useState(false);
  const [listSuppliesSelection, setListSuppliesSelection] = useState([])
  const [modalShowFile, setModalShowFile] = useState(false);
  const [modalShowSelection, setModalShowSelection] = useState(false);
  const [formFile, setFormFile] = useState(false);

  const {
    id_unit = '',
    year = yearCurrent,
    description = '',
    user_created = '',
    date_created = '',
    user_updated = '',
    date_updated = '',
    justification = '',
  } = data

  const [modalShow, setModalShow] = useState(false);
  const [form, setForm] = useState('')
  const [isOk, setIsOk] = useState(false)
  const [unitSelected, setUnitSelected] = useState(id_unit)
  const [details, setDetails] = useState([])
  const dataSumbit = useCallback(() => {
    let p_details = []
    details.forEach(d => {
      p_details.push({
        p_id: d.id,
        p_cod_internal: d.cod_internal,
        p_id_supplie: d.id_supplie,
        p_id_sub_products: d.id_sub_products,
        p_qty_start: d.qty_start,
        p_price: d.price,
        p_subtotal: parseFloat(d.qty_start * d.price),
      })
    })
    return {
      p_id_unit: unitSelected,
      p_year: year ? year : yearCurrent,
      p_details
    }
  }, [year, yearCurrent, details, unitSelected])

  useEffect(() => {
    let promises = [
      Controllers.packs_annual.get_packs_annual_details({
        p_year: year,
        p_id_unit: id_unit
      }),
      Controllers.sub_products.get_sub_products_public(),
      Controllers.unit.get_unit_public_without_pack({
        p_year: yearCurrent
      })
    ]
    Promise.all(promises).then(res => {
      setDetails(res[0].data.map(r => {
        return {
          ...r,
          id_row: parseInt(r.id_row)
        }
      }))
      setSubProducts(res[1].data)
      if (res[2].data.length === 1 && !id_unit) {
        setUnitSelected(res[2].data[0].id)
      }
      setUnit(res[2].data)
      setIsOk(true)
    })
  }, [year, id_unit, yearCurrent])

  const total = useCallback(() => {
    let total = 0;
    details.forEach(d => {
      if (!isNaN(parseFloat(d.price)) && !isNaN(parseInt(d.qty_start))) {
        total += (d.price * d.qty_start)
      }
    })
    
    return parseFloat(total).toFixed(2)
  }, [details])

  const totalQty = useCallback(() => {
    let total = 0;
    details.forEach(d => {
      if (!isNaN(parseInt(d.qty_start))) {
        total += parseInt(d.qty_start)
      }
    })
    
    return parseFloat(total)
  }, [details])

  const listSelectedAll = useCallback(() => {
    let selectedAll = true
    listSuppliesSelection.forEach(l => {
      if (!l.supplies.some(s => parseInt(s.selected) === 1)) {
        selectedAll = false
      }
    })
    return selectedAll
  }, [listSuppliesSelection])

  return <ReactPlaceholder showLoadingAnimation rows={7}  type='text' ready={isOk}>
    {justification ? <p className="text-muted">El PAC fue rechazado por el siguiente motivo: <br/><strong>{justification}</strong></p> : ''}

    <FormCustom dataSubmit={dataSumbit()} onSubmit={onSubmit} viewSubmit={!disabled}>
      {description !== '' || disabled ? <Input
        text="Centro de costo"
        icon="far fa-building"
        disabled
        classNameParent="col-8 mb-2"
        defaultValue={id_unit + ' - ' + description}
      /> : <Select
        options={unit.map(s => {
          return {
            value: s.id,
            label: s.id + ' - ' + s.description
          }
        })}
        value={unit.some(u => parseInt(u.id) === parseInt(unitSelected)) ? { value: unitSelected, label: unit.find(u => parseInt(u.id) === parseInt(unitSelected)).description } : ''}
        onChange={e => setUnitSelected(e.value)}
        text="Centro de costo"
        icon="far fa-building"
        disabled={id_unit !== ''}
        classNameParent="col-8 mb-2"
      />}
      <Input
        defaultValue={yearCurrent}
        className="text-center"
        disabled={false}
        classNameParent="col-4 mb-2"
        readOnly
        text="Año PAC"
        icon="far fa-calendar"
      />
       <input onChange={e => {
         if (e.currentTarget.files.length === 1) {
          let button = document.getElementById('btn-process-excel')
          let buttonHTML = button.innerHTML
          button.innerHTML = Helpers.icon.spin()

          let formData = new FormData()
          formData.append('file_excel_pac', e.currentTarget.files[0])

          Controllers.packs_annual.read_excel(formData).then(res => {
            if (res.response !== 'success') {
              Helpers.toastr.construct({ response: res.response, message: res.message, toastr })
              return
            }

            let suppliesSelection = []
            let suppliesNotExists = []
            let addDet = []
            let promises = [
              Controllers.supplies.get_supplies(),
              Controllers.sub_products.get_sub_products_public()
            ]

            Promise.all(promises).then(res2 => {
              let listSupplies = res2[0].data
              let listSubProducts = res2[1].data

              res.data.forEach(p => {
                let supplieFinded = listSupplies.filter(s => {
                  return s.cod_ppr.includes(p[1] + '-') && parseInt(s.id_row) === parseInt(p[2])
                })

                let subProductsFinded = listSubProducts.find(s => {
                  return s.id === '' + p[0]
                })
                
                if (!supplieFinded.length) {
                  suppliesNotExists.push({
                    id: p[1],
                    subProduct: p[0],
                    type: 'not_exists_supplie'
                  })
                } else if (!subProductsFinded) {
                  suppliesNotExists.push({
                    id: p[1],
                    subProduct: p[0],
                    type: 'not_exists_subproducts'
                  })
                } else {
                  if (supplieFinded.length === 1) {
                    if (parseInt(supplieFinded[0].state) !== 1) {
                      suppliesNotExists.push({
                        id: p[1],
                        subProduct: p[0],
                        type: 'supplie_inactive'
                      })
                    }
                    else if (isNaN(parseFloat(supplieFinded[0].price))) {
                      suppliesNotExists.push({
                        id: p[1],
                        subProduct: p[0],
                        type: 'supplie_without_price'
                      })
                    } else {
                      if (!details.some(d => parseInt(d.id_supplie) === parseInt(supplieFinded[0].id) && d.id_sub_products === p[0])) {
                        addDet.push({
                          id: '',
                          cod_internal: supplieFinded[0].cod_internal,
                          description_internal: supplieFinded[0].description_internal,
                          id_row: parseInt(supplieFinded[0].id_row),
                          id_sub_products: p[0],
                          id_supplie: supplieFinded[0].id,
                          price: parseFloat(supplieFinded[0].price).toFixed(2),
                          qty_start: parseInt(p[3]),
                          subtotal: ''
                        }) 
                      }
                    }
                  } else {
                    let supplieFindedActive = []
                    supplieFinded.forEach(s => {
                      if (parseInt(s.state) === 1 && !isNaN(parseFloat(s.price))) {
                        supplieFindedActive.push({
                          cod_internal: s.cod_internal,
                          cod_ppr: s.cod_ppr,
                          description_internal: s.description_internal,
                          id_row: s.id_row,
                          id_supplie: s.id,
                          price: parseFloat(s.price).toFixed(2),
                          description_row: s.description_row,
                          description_presentation: s.description_presentation
                        })
                      }
                    })

                    if (!supplieFindedActive.length) {
                      suppliesNotExists.push({
                        id: p[1],
                        subProduct: p[0],
                        type: 'supplie_inactive'
                      })
                    } else {
                      suppliesSelection.push({
                        id: p[1],
                        subProduct: p[0],
                        qty_start: parseInt(p[3]),
                        supplies: supplieFindedActive
                      })
                    }
                  }
                }
              })
              setListSuppliesSelection(suppliesSelection)
              setFormFile(<>
                <div className="d-flex flex-column">
                  <div>
                    <strong>Insumos agregados al PAC: {addDet.length}</strong>
                  </div>
                  {suppliesSelection.length ? <div>
                    <strong className="mr-2">Registros con coincidencias multiples: {suppliesSelection.length}</strong> <Button size="xs" onClick={() => setModalShowSelection(true)}><i className="fa fa-edit mr-2"></i>Seleccionar Correctos</Button>
                  </div> : ''}
                  {suppliesNotExists.length ? <div className="mt-2">
                  <strong>Resumen Insumos No Encontrados ({suppliesNotExists.length})</strong>
                  <DataTable
                    thead={[
                      { name: 'Insumo' },
                      { name: 'No existe Insumo', align: 'center' },
                      { name: 'No existe Subproducto', align: 'center' },
                      { name: 'Inactivo', align: 'center' },
                      { name: 'Sin Precio', align: 'center' }
                    ]}
                    tbody={suppliesNotExists}
                    render={tbody => {
                      return React.Children.toArray(suppliesNotExists.map(r => <tr>
                        <td>Cod Insumo: {r.id}, SubProducto: {r.subProduct}</td>
                        <td align="center">{r.type === 'not_exists_supplie' ? <i className="fa fa-times"></i> : ''}</td>
                        <td align="center">{r.type === 'not_exists_subproducts' ? <i className="fa fa-times"></i> : ''}</td>
                        <td align="center">{r.type === 'supplie_inactive' ? <i className="fa fa-times"></i> : ''}</td>
                        <td align="center">{r.type === 'supplie_without_price' ? <i className="fa fa-times"></i> : ''}</td>
                      </tr>))
                    }}
                  />
                  </div> : ''}
                </div>
              </>)
              setModalShowFile(true)
              setDetails([...details, ...addDet])
            }).finally(() => {
              button.innerHTML = buttonHTML
              document.getElementById('file-excel-pac').value = ''
            })

          }).catch(req => {
            button.innerHTML = buttonHTML
            document.getElementById('file-excel-pac').value = ''
            Helpers.promise.catch({ req, toastr });
          })
         }
       }} type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" id="file-excel-pac" className="d-none" />
      <SuppliesPublic showModal={modalShowSupplies} hideModal={() => setModalShowSupplies(false)} added={[]} onAdd={e => {
        const { id, description, codInternal, idRow, price } = e.currentTarget.dataset;

        setForm(<div className="table table-responsive">
          <div className="mb-2"><strong>Insumo: </strong>{codInternal} - {description}</div>
          <table className="table table-sm table-hover table-striped table-bordered" id="tbl-subproducts">
            <thead>
              <tr>
                <th width="25%">SubProducto</th>
                <th width="25%">Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {React.Children.toArray(subProducts.map(s => {
                return <tr>
                  <td align="center">{s.id} - {s.description_short}</td>
                  <td>
                  <Input
                    data-sub-product={s.id}
                    defaultValue={''}
                    className="text-center qty"
                    type="number"
                    min="1"
                    step="1"
                    required
                    onKeyUp={(k) => {
                      if (k.keyCode !== 13) {
                        return;
                      }
                      let tbl = document.getElementById('tbl-subproducts')
                      let qt = tbl.querySelectorAll('.qty')
                      
                      let addDet = []
                      for (let i = 0; i < qt.length; i++) {
                        if (!isNaN(parseInt(qt[i].value))) {
                          if (!details.some(d => parseInt(d.id_supplie) === parseInt(id) && d.id_sub_products === qt[i].dataset.subProduct)) {
                            addDet.push({
                              id: '',
                              cod_internal: codInternal,
                              description_internal: description,
                              id_row: parseInt(idRow),
                              id_sub_products: qt[i].dataset.subProduct,
                              id_supplie: id,
                              price: parseFloat(price).toFixed(2),
                              qty_start: parseInt(qt[i].value),
                              subtotal: ''
                            }) 
                          }
                        }
                      }
          
                      if (addDet.length) {
                        setDetails([...details, ...addDet])
                      }
                      setModalShow(false)
                    }}
                  />
                  </td>
                </tr>
              }))}
            </tbody>
          </table>

          <Button block size="sm" onClick={() => {
            let tbl = document.getElementById('tbl-subproducts')
            let qt = tbl.querySelectorAll('.qty')
            
            let addDet = []
            for (let i = 0; i < qt.length; i++) {
              if (!isNaN(parseInt(qt[i].value))) {
                if (!details.some(d => parseInt(d.id_supplie) === parseInt(id) && d.id_sub_products === qt[i].dataset.subProduct)) {
                  addDet.push({
                    id: '',
                    cod_internal: codInternal,
                    description_internal: description,
                    id_row: parseInt(idRow),
                    id_sub_products: qt[i].dataset.subProduct,
                    id_supplie: id,
                    price: parseFloat(price).toFixed(2),
                    qty_start: parseInt(qt[i].value),
                    subtotal: ''
                  }) 
                }
              }
            }

            if (addDet.length) {
              setDetails([...details, ...addDet])
            }
            setModalShow(false)
          }}>
              <i className="fa fa-check mr-2"></i>Agregar Insumo
          </Button>
        </div>)
        setModalShow(true)
      }} />
      <div className="col-12 mt-2 mb-2 d-flex justify-content-between">
        {disabled ? '' : <>
          <Button size="sm" onClick={() => setModalShowSupplies(true)}><i className="fa fa-search mr-2"></i> Agregar Insumos</Button>
          <Button size="sm" id="btn-process-excel" onClick={() => {
            document.getElementById('file-excel-pac').click()
          }} variant="success"><i className="fa fa-file-excel mr-2"></i> Carga Masiva</Button>
        </>}
      </div>
      <div className="col-12 mt-2 mb-2 d-flex flex-column">
        <span>Presupuesto Total: <strong>{Helpers.number.float.format({
          number: parseFloat(total())
        })}</strong></span>
        <span>Cantidad Total: <strong>{totalQty()}</strong></span>
      </div>
      <div className="col-12">
        <DataTable
          tableScroll={details.length >= 5}
          tbody={details.map(d => {
            return {
              id_sub_products: d.id_sub_products,
              id_row: parseInt(d.id_row),
              description_internal: d.description_internal,
              qty_start: isNaN(parseInt(d.qty_start)) ? '' : parseInt(d.qty_start),
              price: isNaN(parseFloat(d.price)) ? '' : parseFloat(d.price),
              subtotal: !isNaN(parseInt(d.qty_start)) && !isNaN(parseFloat(d.price)) ? parseFloat(d.qty_start * d.price) : '',
              cod_internal: d.cod_internal,
              id: d.id,
              id_supplie: d.id_supplie
            }
          })}
          thead={[
            { name: 'SubProducto', align: 'center', width: '155px' },
            { name: 'Renglon', align: 'center', width: '100px' },
            { name: 'Insumo', width: '305px' },
            { name: 'Cantidad Inicial', align: 'center', width: '155px' },
            { name: 'Precio', align: 'center', width: '115px' },
            { name: 'Subtotal.', align: 'center', width: '155px' },
            { name: 'Acc.', align: 'center', width: '100px', sort: false }
          ]}
          render={(tbody) => {
            return tbody.map((d, i) => {
              return <tr>
                <td align="center" width="155px">
                  {d.id_sub_products}
                </td>
                <td align="center" width="100px">
                  {d.id_row}
                </td>
                <td width="305px">
                  {d.cod_internal} - {d.description_internal}
                </td>
                <td align="center" width="155px">
                  <Input
                    value={d.qty_start}
                    className="text-center"
                    type="number"
                    min="1"
                    step="1"
                    required
                    onChange={e => {
                      setDetails(details.map((d2, i2) => {
                        if (i2 === i) {
                          d2.qty_start = e.currentTarget.value
                        }
                        return d2;
                      }))
                    }}
                    disabled={disabled}
                  />
                </td>
                <td align="center" width="115px">
                  {d.price}
                </td>
                <td align="center" width="155px">
                    {d.subtotal}
                </td>
                <td width="100px" align="center">
                  {disabled ? '' : <Button size="xs" variant="danger" onClick={e => {
                    let newDet = []
                    details.forEach((d2, i2) => {
                      if (i2 !== i) {
                        newDet.push(d2)
                      }
                    })
                    setDetails(newDet)
                  }}>
                    <i className="fa fa-trash"></i>
                  </Button>}
                </td>
              </tr>
            })
          }}
        />
      </div>
    </FormCustom>
    
    {id_unit === '' ? '' : <>
      <hr />
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <span className="text-muted">Usuario Creación: <strong>{user_created}</strong></span>
          <span className="text-muted">Fecha Creación: <strong>{date_created}</strong></span>
        </div>
        {user_updated === '' ? '' : <div className="d-flex flex-column">
          <span className="text-muted">Usuario última actualización: <strong>{user_updated}</strong></span>
          <span className="text-muted">Fecha última actualización: <strong>{date_updated}</strong></span>
        </div>}
      </div>
    </>}

    <Modal show={modalShow} size="lg" onHide={() => setModalShow(false)} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title as="div"><i className="fa fa-plus mr-2"></i>Agregar Insumo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
    <Modal centered show={modalShowFile} onHide={() => setModalShowFile(false)} size="xl" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title as="div"><i className="fa fa-file-excel mr-2"></i> Resumen De Archivo Procesado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {formFile}
      </Modal.Body>
    </Modal>
    <Modal centered show={modalShowSelection} onHide={() => setModalShowSelection(false)} size="xl" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title as="div"><i className="fa fa-list mr-2"></i> Seleccionar Insumos Correctos de Incidencias Múltiples</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion defaultActiveKey={0}>
          {React.Children.toArray(listSuppliesSelection.map((l, i) => {
            let hasSelected = l.supplies.some(s => parseInt(s.selected) === 1)

            return <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey={i}>
                  Insumo: {l.id} - SubProducto: {l.subProduct}
                </Accordion.Toggle>
                <i className={'ml-2 fa fa-' + (hasSelected ? 'check text-success' : 'times text-danger')}></i>
              </Card.Header>
              <Accordion.Collapse eventKey={i}>
                <Card.Body>
                  {React.Children.toArray(l.supplies.map((s, j) => {
                    return <CheckBox
                      id={'chk-' + i + '-' + l.id + '-' + l.subProduct + '-' + j}
                      text={s.cod_ppr + ' - ' + s.description_internal + ', Renglón: ' + s.description_row + ', Presentación: ' + s.description_presentation}
                      checked={parseInt(s.selected) === 1}
                      onChange={e => {
                        setListSuppliesSelection(listSuppliesSelection.map((l2, i2) => {
                          if (i === i2) {
                            l2.supplies.map((s2, j2) => {
                              if (j2 === j) {
                                s2.selected = e.currentTarget.checked ? 1 : 0
                              } else {
                                s2.selected = 0
                              }
                              return s2
                            })
                          }
  
                          return l2;
                        }))
                      }}
                    />
                  }))}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          }))}
        </Accordion>

        <Button onClick={() => {
          let addDet = []
          listSuppliesSelection.forEach(l => {
            l.supplies.filter(s => parseInt(s.selected) === 1).forEach(s => {
              if (!details.some(d => parseInt(d.id_supplie) === parseInt(s.id) && d.id_sub_products === l.subProduct)) {
                addDet.push({
                  id: '',
                  cod_internal: s.cod_internal,
                  description_internal: s.description_internal,
                  id_row: s.id_row,
                  id_sub_products: l.subProduct,
                  id_supplie: s.id_supplie,
                  price: parseFloat(s.price).toFixed(2),
                  qty_start: l.qty_start,
                  subtotal: ''
                }) 
              }
            })
          })
          setDetails([...details, ...addDet])
          setModalShowSelection(false)
          setListSuppliesSelection([])
        }} disabled={!listSelectedAll()} variant="success" size="sm" block><i className="fa fa-check mr-2"></i>Agregar Insumos Al PAC</Button>
      </Modal.Body>
    </Modal>
  </ReactPlaceholder>
}

PacksAnnualForm.defaultProps = {
  disabled: false
}

const PacksAnnual = () => {
  const nameModulo = 'PAC Anuales'
  const namePage = 'PAC Anual'
  const nameController = 'packs_annual'

  const p_year = useSelector(store => store.session.year)
  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = useRef(Helpers.getActions({ permissions, name: 'packsanuales' }))

  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [modalSize, setModalSize] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [form, setForm] = useState('');
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      Controllers[nameController]['get_' + nameController]({p_year}).then(res => {
        setRows(res.data)
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload, p_year])

  const handleUpdate = useCallback(e => {
    const { idUnit = '', year = '', disabled = 0 } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()
    
    setModalTitle(idUnit ? <><i className="fa fa-edit mr-2"></i> Modificar {namePage}</> : <><i className="fa fa-plus mr-2"></i> Nuevo {namePage}</>);
    setModalSize('xl');
    setModalHeader('');

    Controllers[nameController]['get_' + nameController + '_id']({ p_id_unit: idUnit, p_year: year ? year : p_year }).then(res => {
      setForm(<PacksAnnualForm disabled={parseInt(disabled) === 1} data={res.data} onSubmit={onSubmit} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }, [p_year])

  const onSubmit = ({ e, dataSubmit }) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }

      let hasDuplicate = false
      dataSubmit.p_details.forEach(d => {
        if (dataSubmit.p_details.filter(d2 => d2.p_id_sub_products === d.p_id_sub_products && parseInt(d2.p_id_supplie) === parseInt(d.p_id_supplie)).length > 1 && !hasDuplicate) {
          hasDuplicate = {
            p_cod_internal: d.p_cod_internal,
            p_id_sub_products: d.p_id_sub_products,
            
          };
        }
      })

      if (hasDuplicate) {
        Helpers.toastr.construct({ response: 'warning', message: 'No puede repetir Insumo/SubProducto. Corregir. Insumo ' + hasDuplicate.p_cod_internal + ' y Subproducto ' +  hasDuplicate.p_id_sub_products, toastr })
        return;
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers[nameController][nameController + '_insert_update'](dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        setModalShow(false);
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
      setReload(true)
    });
  }

  const handleSendApproval = e => {
    let button = e.currentTarget;
    button.innerHTML = Helpers.icon.spin();
    const { idUnit, year } = e.currentTarget.dataset;
    Controllers.packs_annual.packs_annual_send_approval({
      p_id_unit: idUnit,
      p_year: year
    }).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
    }).finally(() => {
      setReload(true)
    })
  }

  /* const onDelete = e => {
    const { id = '' } = e.currentTarget.dataset;
    setModalTitle(<><i className="fa fa-trash mr-2"></i> Eliminar {namePage}</>);
    setModalHeader('bg-danger');
    setModalSize('sm');
    setForm(<div className="d-flex flex-column align-items-center">
      <p><strong>¿Eliminar {namePage} #{id}?</strong></p>

      <div>        
        <Button variant="danger" className="mr-2" size="sm" onClick={e => {
          let button = e.currentTarget
          let buttonHTML = button.innerHTML;
          button.innerHTML = Helpers.icon.spin()

          Controllers[nameController][nameController + '_delete']({
            p_id: id
          }).then(res => {
            Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
          }).catch(req => {
            Helpers.promise.catch({ req, toastr });
          }).finally(() => {
            button.innerHTML = buttonHTML;
            setReload(true)
            setModalShow(false);
          });
        }}>
          <i className="fa fa-check mr-2"></i>Si
        </Button>
        <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalShow(false)}>
          <i className="fa fa-times mr-2"></i>No
        </Button>
      </div>
    </div>);
    setModalShow(true)
  } */

  const dtRows = useMemo(() => {
    const thead = [
      { name: 'Centro De Costo' },
      { name: 'Año', align: 'center' },
      { name: 'Cantidad Inicial', align: 'center' },
      { name: 'Precio Inicial', align: 'center' },
      { name: 'Estado', align: 'center' },
      { name: 'Acc.', align: 'center', sort: false }
    ];

    return <DataTable
      isProcessing={isProcessing}
      tbody={rows.map(r => {
        return {
          description: r.description,
          year: r.year,
          qty_start: parseInt(r.qty_start),
          budget: parseFloat(r.budget),
          state: r.state,
          budget_balance: r.budget_balance,
          id_unit: r.id_unit,
          qty_balance: r.qty_balance,
        }
      })}
      thead={thead}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;
        for (let key in r) {
          if (key === 'id_unit' || key === 'budget_balance' || key === 'qty_balance') {
            continue
          }
          if (key === 'state') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''} className={'text-' + (parseInt(r.state) === 0 ? 'warning' : (parseInt(r.state) === 1 ? 'info' : (parseInt(r.state) === 2 ) ? 'danger' : 'success'))}>
              <strong>{parseInt(r.state) === 0 ? 'PENDIENTE' : (parseInt(r.state) === 1 ? 'PENDIENTE DE APROBACIÓN' : (parseInt(r.state) === 2 ) ? 'RECHAZADO' : 'APROBADO')}</strong>
            </td>);
          } else if (key === 'budget') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{Helpers.number.float.format({
              number: parseFloat(r[key])
            })}</td>);
          } else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }

        rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
          {actionsModule.current.view ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.view.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.view.color} data-disabled={1} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={handleUpdate}>
              <i className={actionsModule.current.view.icon}></i>
            </Button>
          </OverlayTrigger> :''}

          {parseInt(r.state) === 0 || parseInt(r.state) === 2 ? <>
            {actionsModule.current.update ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.update.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.update.color} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={handleUpdate}>
                <i className={actionsModule.current.update.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.current.sendrequest ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.sendrequest.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.sendrequest.color} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={handleSendApproval}>
                <i className={actionsModule.current.sendrequest.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
          </> : ''}

          {parseInt(r.state) === 1 ? <>
            {actionsModule.current.approve ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.approve.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.approve.color} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
                let button = e.currentTarget;
                button.innerHTML = Helpers.icon.spin();
                const { idUnit, year } = e.currentTarget.dataset;
                Controllers.packs_annual.packs_annual_approve({
                  p_id_unit: idUnit,
                  p_year: year
                }).then(res => {
                  Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
                }).finally(() => {
                  setReload(true)
                })
              }}>
                <i className={actionsModule.current.approve.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.current.refuse ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.refuse.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.refuse.color} size="xs" data-description={r.description} data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
                setModalTitle(<><i className="fa fa-times mr-2"></i>Rechazar PAC Anual</>)
                setModalHeader('bg-danger')
                setModalShow(true)
                setModalSize('sm')
                setForm(<form className="form-row needs-validation" onSubmit={e => {
                  e.preventDefault();
                  let $this = e.currentTarget

                  let button = $this.querySelector('button[type=submit]');
                  let buttonHTML = button.innerHTML
                  button.innerHTML = Helpers.icon.spin();

                  if (!$this.checkValidity()) {
                    $this.classList.add('was-validated');
                  } else {
                    Controllers.packs_annual.packs_annual_disapprove({
                      p_id_unit: r.id_unit,
                      p_year: r.year,
                      p_justification: document.getElementById('txt_justify_disapprove').value,
                    }).then(res => {
                      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
                      setReload(true)
                      setModalShow(false);
                    }).catch(req => {
                      setModalShow(false)
                      Helpers.promise.catch({ req, toastr });
                    }).finally(() => button.innerHTML = buttonHTML)
                  }
                }}>
                  <p className="col-12 text-center">Rechazar PAC <strong>{e.currentTarget.dataset.description}/{e.currentTarget.dataset.year}</strong></p>

                  <TextArea
                    text="Justificación"
                    placeholder="Ingresar..."
                    classNameParent="col-12 mb-2"
                    icon="fas fa-comment-alt"
                    required
                    autoFocus
                    id="txt_justify_disapprove"
                    rows={4}
                  />

                <div className="col-12 d-flex justify-content-center">        
                  <Button type="submit" variant="danger" className="mr-2" size="sm">
                    <i className="fa fa-check mr-2"></i>Rechazar
                  </Button>
                  <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalShow(false)}>
                    <i className="fa fa-times mr-2"></i>Cancelar
                  </Button>
                </div>
                </form>)
              }}>
                <i className={actionsModule.current.refuse.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
          </> : ''}
          {/* {actionsModule.current.delete ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.delete.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.delete.color} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id}>
              <i className={actionsModule.current.delete.icon}></i>
            </Button>
          </OverlayTrigger> : ''} */}

          {actionsModule.current.downloadexcel ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.current.downloadexcel.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.current.downloadexcel.color} data-disabled={1} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
              let { idUnit, year } = e.currentTarget.dataset
              let $this = e.currentTarget
              let button = $this;
              let buttonHTML = button.innerHTML
              button.innerHTML = Helpers.icon.spin();

              Controllers.packs_annual.get_pac_excel({
                p_id_unit: idUnit,
                p_year: year
              }).then(res => {
                let tagA = document.createElement('a')
                tagA.setAttribute('download', res.data.filename)
                tagA.setAttribute('href', res.data.file)
                tagA.click()
              }).finally(() => button.innerHTML = buttonHTML)
            }}>
              <i className={actionsModule.current.downloadexcel.icon}></i>
            </Button>
          </OverlayTrigger> :''}
        </td>);
        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, rows, handleUpdate, actionsModule])

  return <>
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De {nameModulo}</span>
        <div>
          <a href={Helpers.config.api.url + '/PLANTILLA_PAC_ ANNUAL.xlsx'} download><Button variant="success" size="sm"><i className="fa fa-file-excel"></i><span className="ml-2 d-none d-md-inline-block">Descargar Plantilla</span></Button></a>
          {actionsModule.current.create ? <Button onClick={handleUpdate} variant={actionsModule.current.create.color} size="sm" className="ml-2"><i className={actionsModule.current.create.icon}></i><span className="ml-2 d-none d-md-inline-block">{actionsModule.current.create.description} {namePage}</span></Button> : ''}
        </div>
      </Card.Header>
      <Card.Body>
        {dtRows}
      </Card.Body>
    </Card>
    <Modal show={modalShow} onHide={() => setModalShow(false)} size={modalSize} backdrop="static">
      <Modal.Header closeButton className={modalHeader}>
        <Modal.Title as="div">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
  </>
}

export default PacksAnnual;