import React, { useState, useEffect, useMemo } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Controllers from '../Api/Controllers';
import { Input, FormCustom, Select } from '../Components/Form/Form';
import Button from 'react-bootstrap/Button';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import { useSelector } from 'react-redux';

const Provider = () => {
  const [rows, setRows] = useState([]);
  const [file, setFile] = useState('')
  const [filename, setFilename] = useState('')
  const [isProcessing, setIsProcessing] = useState(true);
  const [reload, setReload] = useState(true);
  const [unit, setUnit] = useState([])
  const [unitSelected, setUnitSelected] = useState('')
  const currentYear = useSelector(store => store.session.year)
  let p_date_end = Helpers.date.get()
  let p_date_start = Helpers.date.get({ addDays: -7 })
  
  const [dates, setDates] = useState({
    p_date_start: '',
    p_date_end: '',
  })

  useEffect(() => {
    Controllers.unit.get_unit_public_with_pack({
      p_year: currentYear
    }).then(res => {
      res.data.unshift({
        id: '',
        description: 'TODAS'
      })
      setUnit(res.data)
    })
  }, [currentYear])

  useEffect(() => {
    if (reload) {
      setIsProcessing(true)
      Controllers.orders.get_reporte_deuda_flotante({
        ...dates,
        p_id_unit: unitSelected
      }).then(res => {
        setRows(res.data.data)
        if (res.data.data) {
          if (res.data.data.length > 0) {
            setFile(res.data.file)
            setFilename(res.data.filename)
          }
        } else {
          setFile('')
          setFilename('')
        }
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload, dates, unitSelected])

  const cboUnit = useMemo(() => {
    let unitFind = unit.find(u => u.id === unitSelected)

    return <Select
      value={unitFind ? {value: unitFind.id, label: unitFind.id + (unitFind.id ? ' - ' : '') + unitFind.description} : ''}
      options={unit.map(s => {
        return {
          value: s.id,
          label: s.id + (s.id ? ' - ' : '') + s.description
        }
      })}
      onChange={e => {
        setUnitSelected(e.value)
        setFile('')
      }}
      text="Centro de costo"
      icon="far fa-building"
      classNameParent="col-12 col-md-4 mb-2"
    />
  }, [unit, unitSelected])

  const dtRows = useMemo(() => {
    let thead = [
      { name: 'Centro Costo'},
      { name: 'Nro OC'},
      { name: 'Proveedor'},
      { name: 'Serie-Nro Factura', align: 'center'  },
      { name: 'Fecha Factura', align: 'center'  },
      { name: 'Renglon', align: 'center' },
      { name: 'Nro Recibo Unidad', align: 'center' },
      { name: 'Fecha Emisión', align: 'center' },
      { name: 'Total', align: 'center' },
      { name: 'Estado', align: 'center' }
    ]

    return <DataTable
      columnOrder={1}
      isProcessing={isProcessing}
      tbody={rows.map(r => {
        return {
          unit: r.unit,
          number_purchase: parseInt(r.number_purchase),
          provider: r.provider,
          invoice: r.invoice,
          date_invoice: r.date_invoice,
          id_row: parseInt(r.id_row),
          number_invoice: r.number_invoice,
          date_issue: r.date_issue,
          total: parseFloat(r.total),
          state: parseInt(r.state)
        }
      })}
      thead={thead}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;

        for (let key in r) {
          let color = ''
          let texto = ''
          switch (parseInt(r.state)) {
            case 0:
              color = 'warning'
              texto = 'SIN ORDEN DE COMPRA'
              break;
            case 1:
              color = 'info'
              texto = 'CON ORDEN DE COMPRA'
              break;
            case 2:
              color = 'primary'
              texto = 'COMPROMISO'
              break;
            case 3:
              color = 'dark'
              texto = 'COMPLETADO'
              break;
            case 4:
              color = 'info'
              texto = 'DEVENGADO'
              break;
            case 5:
              color = 'primary'
              texto = 'ENVIADO DAF'
              break;
            case 6:
              color = 'danger'
              texto = 'RECHAZADO'
              break;
            case 7:
              color = 'success'
              texto = 'PAGADO'
              break;
            case 8:
              color = 'danger'
              texto = 'ANULADO'
              break;
            case 9:
              color = 'primary'
              texto = 'CON DOCUMENTACIÓN';
              break;
            default:
              break;
          }

          if (key === 'state') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
            <strong className={'text-' + color}>{texto}</strong>
          </td>);
          }
          else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }

        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, rows])

  const submitForm = ({e}) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }
    }
    setDates({
      p_date_start: document.getElementById('p_date_start').value,
      p_date_end: document.getElementById('p_date_end').value
    })
    setReload(true)
  }

  return <> 
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De Pedidos</span>
      </Card.Header>
      <Card.Body >
        <div className="d-flex justify-content-between mb-2 form-row">
          <FormCustom className="form-row col-12" viewSubmit={false} onSubmit={submitForm}>
            {cboUnit}
            <Input
              type="date"
              defaultValue={p_date_start}
              icon="fa fa-calendar"
              text="Fecha Inicio"
              classNameParent="col-6 col-md-4 mb-2"
              required
              id="p_date_start"
              onChange={() => setFile('')}
            />
            <Input
              type="date"
              defaultValue={p_date_end}
              value=""
              icon="fa fa-calendar"
              text="Fecha Fin"
              classNameParent="col-6 col-md-4 mb-2"
              required
              id="p_date_end"
              append={[<Button type="submit"><i className="fa fa-search"></i></Button>]}
              onChange={() => setFile('')}
            />
            
          </FormCustom>
          {file !== '' ? <a className="align-self-center col-12" href={file} download={filename}><Button variant="success" size="sm" className="align-self-center">
            <i className="fa fa-file-excel mr-2"></i> Descargar Reporte
          </Button></a> : ''}
        </div>
        
        {dtRows}
      </Card.Body>
    </Card>
  </>
}

Provider.defaultProps = {
  isPublic: false,
  added: [],
  onAdd: () => {}
}

export default Provider;