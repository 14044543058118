import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Controllers from '../Api/Controllers';
import { FormCustom, Input, CheckBox } from '../Components/Form/Form';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import Firms from './Firms'
import {useSelector} from 'react-redux'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Address from './Address';

const UnidadForm = ({ data, onSubmit, viewDisabled }) => {
  const {
    id = '',
    description = '',
    appear_in_acts = 0,
    name_request = '',
    profession_request = '',
    name_boss = '',
    profession_boss = '',
    address = ''
  } = data

  const [row, setRow] = useState({
    id,
    description,
    appear_in_acts,
    name_request: '',
    profession_request: '',
    name_boss: '',
    profession_boss: '',
    address
  })
  const [exists, setExists] = useState(id !== '')

  const dataSumbit = useCallback(() => {
    return {
      p_id: row.id,
      p_description: row.description,
      p_appear_in_acts: parseInt(row.appear_in_acts),
      p_name_request: row.name_request,
      p_profession_request: row.profession_request,
      p_name_boss: row.name_boss,
      p_profession_boss: row.profession_boss,
      p_address: row.address
    }
  }, [row])

  return <><FormCustom dataSubmit={dataSumbit()} viewSubmit={!viewDisabled} onSubmit={onSubmit} callbackSuccess={r => {
    if (r.id) {
      setExists(true)
    }
  }}>
    <Input
      text="ID"
      placeholder="ID"
      classNameParent="col-12 mb-2"
      icon="fa fa-code"
      required
      defaultValue={id}
      onChange={e => setRow({ ...row, id: e.currentTarget.value })}
      disabled={id !== '' || viewDisabled}
      pattern="[0-9]{1,11}"
      invalid="Solo números. Ej. 100100"
      maxLength={9}
    />
    <Input
      text="Descripción"
      placeholder="Descripción"
      classNameParent="col-12 mb-2"
      icon="far fa-comment-alt"
      required
      defaultValue={description}
      onChange={e => setRow({ ...row, description: e.currentTarget.value })}
      disabled={viewDisabled}
    />
    <CheckBox
      text="En Actas"
      classNameParent="col-12 mb-2"
      defaultChecked={parseInt(appear_in_acts) === 1}
      onChange={e => setRow({ ...row, appear_in_acts: e.currentTarget.checked ? 1 : 0 })}
      id="appear_in_acts-type-purchase"
      disabled={viewDisabled}
    />
    <Input
      text="Dirección"
      placeholder="Dirección"
      classNameParent="col-12 mb-2"
      icon="far fa-comment-alt"
      required
      defaultValue={address}
      onChange={e => setRow({ ...row, address: e.currentTarget.value })}
      disabled={viewDisabled}
    />
    <Input
      text="Nombre Solicitante"
      placeholder="Nombre Solicitante"
      classNameParent="col-6 mb-2 d-none"
      icon="far fa-comment-alt"
      defaultValue={name_request}
      onChange={e => setRow({ ...row, name_request: e.currentTarget.value })}
      maxLength={255}
      disabled={viewDisabled}
    />
    <Input
      text="Cargo Solicitante"
      placeholder="Cargo Solicitante"
      classNameParent="col-6 mb-2 d-none"
      icon="far fa-comment-alt"
      defaultValue={profession_request}
      onChange={e => setRow({ ...row, profession_request: e.currentTarget.value })}
      maxLength={255}
      disabled={viewDisabled}
    />
    <Input
      text="Nombre Jefe"
      placeholder="Nombre Jefe"
      classNameParent="col-6 mb-2 d-none"
      icon="far fa-comment-alt"
      defaultValue={name_boss}
      onChange={e => setRow({ ...row, name_boss: e.currentTarget.value })}
      maxLength={255}
      disabled={viewDisabled}
    />
    <Input
      text="Cargo Jefe"
      placeholder="Cargo Jefe"
      classNameParent="col-6 mb-2 d-none"
      icon="far fa-comment-alt"
      defaultValue={profession_boss}
      onChange={e => setRow({ ...row, profession_boss: e.currentTarget.value })}
      maxLength={255}
      disabled={viewDisabled}
    />
  </FormCustom>
  {exists ? <>
    <div className="mt-4"></div>
    <Firms idUnit={row.id} />
    <div className="mt-4"></div>
    <Address idUnit={row.id} />
  </> : ''}
  </>
}

const Unidad = () => {
  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = Helpers.getActions({ permissions, name: 'centroscosto' })

  const nameModulo = 'Centros De Costo'
  const namePage = 'Centro De Costo'
  const nameController = 'unit'

  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [modalSize, setModalSize] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [form, setForm] = useState('');
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      Controllers[nameController]['get_' + nameController]().then(res => {
        setRows(res.data)
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload])

  const handleUpdate = useCallback(e => {
    const { id = '', disabled = 0 } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()
    
    setModalTitle(parseInt(disabled) === 1 ? <><i className={'mr-2 ' + actionsModule.view.icon}></i>{actionsModule.view.description} {namePage}</> : (id ? <><i className={'mr-2 ' + actionsModule.update.icon}></i>{actionsModule.update.description} {namePage}</> : <><i className={'mr-2 ' + actionsModule.create.icon}></i>{actionsModule.create.description} {namePage}</>))
    setModalSize('xl');
    setModalHeader('');

    Controllers[nameController]['get_' + nameController + '_id']({ p_id: id }).then(res => {
      setForm(<UnidadForm data={res.data} viewDisabled={parseInt(disabled) === 1} onSubmit={onSubmit} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  }, [actionsModule])

  const onSubmit = ({ e, dataSubmit, callbackSuccess }) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers[nameController][nameController + '_insert_update'](dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      callbackSuccess(res.data)
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
      setReload(true)
    });
  }

  const onDelete = e => {
    const { id = '' } = e.currentTarget.dataset;
    setModalTitle(<><i className="fa fa-trash mr-2"></i> Eliminar {namePage}</>);
    setModalHeader('bg-danger');
    setModalSize('sm');
    setForm(<div className="d-flex flex-column align-items-center">
      <p><strong>¿Eliminar {namePage} #{id}?</strong></p>

      <div>        
        <Button variant="danger" className="mr-2" size="sm" onClick={e => {
          let button = e.currentTarget
          let buttonHTML = button.innerHTML;
          button.innerHTML = Helpers.icon.spin()

          Controllers[nameController][nameController + '_delete']({
            p_id: id
          }).then(res => {
            Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
          }).catch(req => {
            Helpers.promise.catch({ req, toastr });
          }).finally(() => {
            button.innerHTML = buttonHTML;
            setReload(true)
            setModalShow(false);
          });
        }}>
          <i className="fa fa-check mr-2"></i>Si
        </Button>
        <Button variant="dark" className="mr-2" size="sm" onClick={() => setModalShow(false)}>
          <i className="fa fa-times mr-2"></i>No
        </Button>
      </div>
    </div>);
    setModalShow(true)
  }

  const dtRows = useMemo(() => {
    const thead = [
      { name: 'ID', align: 'center' },
      { name: 'Descripción' },
      { name: 'En Actas', align: 'center' },
      { name: 'Acc.', align: 'center' }
    ];

    return <DataTable
      isProcessing={isProcessing}
      tbody={rows}
      thead={thead}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;
        for (let key in r) {
          if (key === 'appear_in_acts') {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
              <strong className={'text-' + (parseInt(r.appear_in_acts) === 1 ? 'success': 'danger')}>
                {parseInt(r.appear_in_acts) === 1 ? 'SI': 'NO'}
              </strong>
            </td>);
          } else {
            rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }

        rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
          {actionsModule.view ?  <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.view.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.view.color} data-disabled={1} size="xs" className="mr-1" data-id={r.id} onClick={handleUpdate}>
              <i className={actionsModule.view.icon}></i>
            </Button>
          </OverlayTrigger> : ''}
          {actionsModule.update ?  <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.update.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.update.color} size="xs" className="mr-1" data-id={r.id} onClick={handleUpdate}>
              <i className={actionsModule.update.icon}></i>
            </Button>
          </OverlayTrigger> : ''}
          {actionsModule.delete ? <OverlayTrigger
            placement="top"
            overlay={props => <Tooltip {...props}>
              {actionsModule.delete.description}
            </Tooltip>}
          >
            <Button variant={actionsModule.delete.color} size="xs" data-id={r.id} onClick={onDelete}>
              <i className={actionsModule.delete.icon}></i>
            </Button>
          </OverlayTrigger> : ''}
        </td>);
        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [isProcessing, rows, handleUpdate, actionsModule])

  return <> 
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De {nameModulo}</span>
        {actionsModule.create ? <Button onClick={handleUpdate} variant={actionsModule.create.color} size="sm"><i className={ actionsModule.create.icon}></i><span className="ml-2 d-none d-md-inline-block">{actionsModule.create.description} {namePage}</span></Button> : ''}
      </Card.Header>
      <Card.Body>
        {dtRows}
      </Card.Body>
    </Card>
    <Modal show={modalShow} onHide={() => setModalShow(false)} size={modalSize} backdrop="static">
      <Modal.Header closeButton className={modalHeader}>
        <Modal.Title as="div">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
  </>
}

export default Unidad;