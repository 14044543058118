import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Controllers from '../Api/Controllers';
import { FormCustom, Input, SwitchToggle, CheckBox, Select } from '../Components/Form/Form';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';
import ReactPlaceholder from 'react-placeholder';
import { useSelector } from 'react-redux'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const UsersForm = ({ data, onSubmit, viewDisabled }) => {
  const {
    id = '',
    full_name = '',
    email = '',
    phone = '',
    username = '',
    state = 0,
    id_profile = ''
  } = data

  const [user, setUser] = useState({
    id,
    full_name,
    email,
    phone,
    username,
    password: '',
    state: parseInt(state) === 1,
    id_profile
  })

  const [unit, setUnit] = useState([])
  const dataSumbit = useCallback(() => {
    let p_unit = []
    unit.forEach(u => {
      if (parseInt(u.state) === 1 || parseInt(u.exists_unit) === 1) {
        p_unit.push({
          p_id_unit: u.id,
          p_state: u.state
        })
      }
    })
    return {
      p_id: user.id,
      p_full_name: user.full_name,
      p_email: user.email,
      p_phone: user.phone,
      p_username: user.username,
      p_password: user.password,
      p_state: user.state ? 1 : 0,
      p_id_profile: user.id_profile,
      p_unit
    }
  }, [user, unit])

  const [change, setChange] = useState(id !== '');
  const [profile, setProfile] = useState([])
  const [isOk, setIsOk] = useState(false)


  useEffect(() => {
    let promises = [
      Controllers.profile.get_profile(),
      Controllers.user.get_user_unit({
        p_id: id
      })
    ]
    Promise.all(promises).then(res => {
      setProfile(res[0].data)
      setUnit(res[1].data)
      setIsOk(true)
    })
  }, [id])

  const cboProfile = useMemo(() => {
    let findP = profile.find(p => parseInt(p.id) === parseInt(id_profile))
    return <Select
      defaultValue={id === '' ? '' : (findP ? { id: findP.id, label: findP.id + ' - ' + findP.description } : '')}
      text="Perfil"
      options={profile.map(p => { return { value: p.id, label: p.id + ' - ' + p.description } })}
      classNameParent="col-12 mb-2"
      required
      icon="fas fa-clipboard-list"
      onChange={e => setUser({ ...user, id_profile: e.value })}
      disabled={viewDisabled}
    />
  }, [profile, user, id_profile, id, viewDisabled])

  return <ReactPlaceholder showLoadingAnimation rows={7} type='text' ready={isOk}>
    <FormCustom dataSubmit={dataSumbit()} onSubmit={onSubmit} viewSubmit={!viewDisabled}>
      <Input
        text="Nombres"
        placeholder="Nombres"
        classNameParent="col-12 mb-2"
        icon="fas fa-user-tie"
        required
        defaultValue={full_name}
        onChange={e => setUser({ ...user, full_name: e.currentTarget.value })}
        disabled={viewDisabled}
      />
      <Input
        type="email"
        text="Email"
        placeholder="Email"
        pattern="[A-Za-z0-9._-]{1,20}@[A-Za-z0-9._-]{1,20}[.]{1}[A-Za-z0-9._-]{1,5}"
        classNameParent="col-12 mb-2"
        icon="far fa-envelope"
        required
        defaultValue={email}
        onChange={e => setUser({ ...user, email: e.currentTarget.value })}
        disabled={viewDisabled}
      />
      <Input
        text="Telefono"
        placeholder="Telefono"
        classNameParent="col-12 mb-2"
        iconText="+(502)"
        required
        defaultValue={phone}
        onChange={e => setUser({ ...user, phone: e.currentTarget.value })}
        pattern="[0-9]{8}"
        maxLength={8}
        invalid="Formato incorrecto: Ej. 12345678"
        disabled={viewDisabled}
      />
      {cboProfile}
      <Input
        text="Nombre de usuario"
        placeholder="Nombre de usuario"
        classNameParent="col-12 mb-2"
        icon="far fa-user"
        required
        defaultValue={username}
        onChange={e => setUser({ ...user, username: e.currentTarget.value })}
        disabled={id !== ''}
      />
      {id !== '' ? <CheckBox
        id="chk-change-password"
        text="Cambiar Contraseña"
        onChange={e => {
          setChange(!e.currentTarget.checked)
          setUser({ ...user, password: '' })
        }}
        disabled={viewDisabled}
      /> : ''}
      <Input
        type="password"
        text="Contraseña"
        placeholder="Contraseña"
        classNameParent="col-12 mb-2"
        icon="fas fa-shield-alt"
        required
        onChange={e => setUser({ ...user, password: e.currentTarget.value })}
        disabled={change}
      />
      <SwitchToggle
        id="switch-user"
        defaultChecked={parseInt(state) === 1}
        onChange={e => setUser({ ...user, state: e.currentTarget.checked })}
        disabled={viewDisabled}
      />
      <div className="col-12 mt-2 mb-1">Seleccionar Centro De Costo</div>
      {React.Children.toArray(unit.map((u, i) => {
        return <CheckBox
          id={'chk-' + u.id}
          text={u.id + ' - ' + u.description}
          checked={parseInt(u.state) === 1}
          onChange={e => {
            setUnit(unit.map((u2, i2) => {
              if (i === i2) {
                u2.state = e.currentTarget.checked ? 1 : 0
              }
              return u2;
            }))
          }}
          disabled={viewDisabled}
        />
      }))}
    </FormCustom>
  </ReactPlaceholder>
}

const Users = () => {
  const nameModulo = 'Usuarios'
  const namePage = 'Usuario'
  const permissions = useSelector(store => store.session.permissions)
  const actionsModule = useRef(Helpers.getActions({ permissions, name: 'usuarios' }))

  const thead = useRef([
    { name: 'ID', align: 'center' },
    { name: 'Nombres' },
    { name: 'Email' },
    { name: 'Telefono', align: 'center' },
    { name: 'Perfil', align: 'center' },
    { name: 'Username', align: 'center' },
    { name: 'Estado', align: 'center' },
    { name: 'Acc.', align: 'center', sort: false }
  ]);

  const [users, setUsers] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [modalSize, setModalSize] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [form, setForm] = useState('');
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      Controllers.user.get_users().then(res => {
        setUsers(res.data.map(r => {
          return {
            ...r,
            id: parseInt(r.id)
          }
        }))
      })
        .finally(() => {
          setIsProcessing(false)
          setReload(false);
        });
    }
  }, [reload])

  const handleUpdate = useRef(e => {
    const { id = '', disabled = 0 } = e.currentTarget.dataset;
    let button = e.currentTarget
    let buttonHTML = button.innerHTML;
    button.innerHTML = Helpers.icon.spin()

    setModalTitle(parseInt(disabled) === 1 ? <><i className={'mr-2 ' + actionsModule.current.view.icon}></i>{actionsModule.current.view.description} {namePage}</> : (id ? <><i className={'mr-2 ' + actionsModule.current.update.icon}></i>{actionsModule.current.update.description} {namePage}</> : <><i className={'mr-2 ' + actionsModule.current.create.icon}></i>{actionsModule.current.create.description} {namePage}</>))
    setModalSize('');
    setModalHeader('');

    Controllers.user.get_users_id({ p_id: id }).then(res => {
      setForm(<UsersForm data={res.data} onSubmit={onSubmit} viewDisabled={parseInt(disabled) === 1} />);
      setModalShow(true);
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
      setModalShow(false);
    }).finally(() => button.innerHTML = buttonHTML)
  })

  const onSubmit = ({ e, dataSubmit }) => {
    let $this = e.currentTarget

    let propsValidateForm = { form: $this, toastr };
    if (!$this.checkValidity()) {
      $this.classList.add('was-validated');
      Helpers.form.isFormCorrect(propsValidateForm);
      return;
    } else {
      $this.classList.remove('was-validated');
      if (!Helpers.form.isFormCorrect(propsValidateForm)) {
        return;
      }

      if (dataSubmit.p_id_profile === '') {
        Helpers.toastr.construct({ response: 'warning', message: 'Por favor, seleccionar perfil', toastr })
        return
      }
    }

    let button = $this.querySelector('button[type=submit]');
    let buttonHTML = button.innerHTML
    button.innerHTML = Helpers.icon.spin();

    Controllers.user.users_insert_update(dataSubmit).then(res => {
      Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
      if (res.response === 'success') {
        setModalShow(false);
      }
    }).catch(req => {
      Helpers.promise.catch({ req, toastr });
    }).finally(() => {
      button.innerHTML = buttonHTML;
      setReload(true)
    });
  }

  const onDelete = e => {
    const { id = '' } = e.currentTarget.dataset;
    setModalTitle(<><i className="fa fa-trash mr-2"></i> Eliminar {namePage}</>);
    setModalHeader('bg-danger');
    setModalSize('sm');
    setForm(<div className="d-flex flex-column align-items-center">
      <p><strong>¿Eliminar {namePage} #{id}?</strong></p>

      <div>
        <Button variant="danger" size="sm" onClick={e => {
          let button = e.currentTarget
          let buttonHTML = button.innerHTML;
          button.innerHTML = Helpers.icon.spin()

          Controllers.user.users_delete({
            p_id: id
          }).then(res => {
            Helpers.toastr.construct({ response: res.response, message: res.message, toastr });
          }).catch(req => {
            Helpers.promise.catch({ req, toastr });
          }).finally(() => {
            button.innerHTML = buttonHTML;
            setReload(true)
            setModalShow(false);
          });
        }}>
          <i className="fa fa-check mr-2"></i>Si
        </Button>
        <Button variant="dark" className="ml-2" size="sm" onClick={() => setModalShow(false)}>
          <i className="fa fa-times mr-2"></i>No
        </Button>
      </div>
    </div>);
    setModalShow(true)
  }

  const dtRows = useMemo(() => {
    return <DataTable
      isProcessing={isProcessing}
      tbody={users}
      thead={thead.current}
      render={tbody => tbody.map(r => {
        let rowRender = [];
        let i = 0;
        for (let key in r) {
          if (key === 'state') {
            rowRender.push(<td align={thead.current[i].align ? thead.current[i].align : ''} className={'text-' + (parseInt(r.state) === 1 ? 'success' : 'danger')}>
              <strong>{parseInt(r.state) === 1 ? 'ACTIVO' : 'INACTIVO'}</strong>
            </td>);
          }
          else {
            rowRender.push(<td align={thead.current[i].align ? thead.current[i].align : ''}>{r[key]}</td>);
          }
          i++;
        }

        if (parseInt(r.id) !== 1 && Helpers.config.isDeveloperWeb()) {
          rowRender.push(<td></td>)
        } else {
          rowRender.push(<td align={thead.current[i].align ? thead.current[i].align : ''}>
            {actionsModule.current.view ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.view.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.view.color} data-disabled={1} size="xs" className="mr-1" data-id={r.id} onClick={handleUpdate.current}>
                <i className={actionsModule.current.view.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.current.update ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.update.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.update.color} size="xs" className="mr-1" data-id={r.id} onClick={handleUpdate.current}>
                <i className={actionsModule.current.update.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
            {actionsModule.current.delete ? <OverlayTrigger
              placement="top"
              overlay={props => <Tooltip {...props}>
                {actionsModule.current.delete.description}
              </Tooltip>}
            >
              <Button variant={actionsModule.current.delete.color} size="xs" data-id={r.id} onClick={onDelete}>
                <i className={actionsModule.current.delete.icon}></i>
              </Button>
            </OverlayTrigger> : ''}
          </td>);
        }
        return (<tr>
          {React.Children.toArray(rowRender)}
        </tr>);
      })}
    />
  }, [users, isProcessing, actionsModule, handleUpdate, thead])

  return <>
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De {nameModulo}</span>
        {actionsModule.current.create ? <Button onClick={handleUpdate.current} variant={actionsModule.current.create.color} size="sm"><i className={actionsModule.current.create.icon}></i><span className="ml-2 d-none d-md-inline-block">{actionsModule.current.create.description} {namePage}</span></Button> : ''}
      </Card.Header>
      <Card.Body>
        {dtRows}
      </Card.Body>
    </Card>
    <Modal show={modalShow} onHide={() => setModalShow(false)} size={modalSize} backdrop="static">
      <Modal.Header closeButton className={modalHeader}>
        <Modal.Title as="div">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
  </>
}

export default Users;