import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Controllers from '../Api/Controllers';
import { Input,/*  Select,  */FormCustom } from '../Components/Form/Form';
import Button from 'react-bootstrap/Button';
import Helpers from '../Helpers/Helpers';
import { toast as toastr } from 'react-toastify';

const Provider = () => {
  const [file, setFile] = useState('')
  const [filename, setFilename] = useState('')
  let p_date_end = Helpers.date.get()
  let p_date_start = Helpers.date.get({
    addDays: -7
  })

  /* const [state, setState] = useState(0) */

  /* const optionsState = [
    { value: 0, label: 'SIN ORDEN DE COMPRA' },
    { value: 1, label: 'CON ORDEN DE COMPRA' },
    { value: 2, label: 'COMPROMISO' },
    { value: 3, label: 'COMPLETADO' },
    { value: 4, label: 'DEVENGADO' },
    { value: 5, label: 'ENVIADO DAF' },
    { value: 6, label: 'RECHAZADO' },
    { value: 7, label: 'PAGADO' },
    { value: 8, label: 'ANULADO' },
  ] */

  return <> 
    <Card>
      <Card.Header className="justify-content-between">
        <span>Generar Reporte Por Fechas</span>
      </Card.Header>
      <Card.Body >
        <div className="d-flex justify-content-between mb-2 form-row">
          <FormCustom className="form-row" viewSubmit={false} onSubmit={({e}) => {
            let $this = e.currentTarget
            let button = $this.querySelector('button[type=submit]');
            let buttonHTML = button.innerHTML;
            button.innerHTML = Helpers.icon.spin()

            setFile('')
            let propsValidateForm = { form: $this, toastr };
            if (!$this.checkValidity()) {
              $this.classList.add('was-validated');
              Helpers.form.isFormCorrect(propsValidateForm);
              return;
            } else {
              $this.classList.remove('was-validated');
              if (!Helpers.form.isFormCorrect(propsValidateForm)) {
                return;
              }
            }
            Controllers.orders.get_reporte_general({
              p_date_start: document.getElementById('p_date_start').value,
              p_date_end: document.getElementById('p_date_end').value
            }).then(res => {
              setFile(res.data.file)
              setFilename(res.data.filename)
            }).finally(() => button.innerHTML = buttonHTML)
          }}>
            <Input
              type="date"
              defaultValue={p_date_start}
              icon="fa fa-calendar"
              text="Fecha Inicio"
              classNameParent="col-12 col-md-6 mb-2"
              required
              id="p_date_start"
              onChange={() => setFile('')}
            />
            <Input
              type="date"
              defaultValue={p_date_end}
              value=""
              icon="fa fa-calendar"
              text="Fecha Fin"
              classNameParent="col-12 col-md-6 mb-2"
              required
              id="p_date_end"
              append={[<Button type="submit"><i className="fa fa-search"></i></Button>]}
              onChange={() => setFile('')}
            />
            <div className="col-12">
            {file !== '' ? <a href={file} download={filename}><Button variant="success" size="sm" className="align-self-center">
              <i className="fa fa-file-excel mr-2"></i> Descargar Reporte
            </Button></a> : ''}
            </div>
          </FormCustom>
          
          {/* <Select
            defaultValue={optionsState.find(o => o.value === state)}
            classNameParent="col-6"
            icon="fa fa-list"
            text="Estado"
            options={optionsState}
            onChange={e => setState(e.value)}
          /> */}
        </div>
      </Card.Body>
    </Card>
  </>
}

Provider.defaultProps = {
  isPublic: false,
  added: [],
  onAdd: () => {}
}

export default Provider;