import React, { useState, useEffect, useMemo } from 'react';
import DataTable from '../Components/DataTable/DataTable';
import Card from 'react-bootstrap/Card';
import Controllers from '../Api/Controllers';
import { useSelector } from 'react-redux';
import { Select } from '../Components/Form/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Helpers from '../Helpers/Helpers';

const Nivel = () => {
  const nameModulo = 'PAC'
  const nameController = 'packs_annual'
  const currentYear = useSelector(store => store.session.year)

  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [reload, setReload] = useState(true);
  const [unit, setUnit] = useState([])
  const [unitSelected, setUnitSelected] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [form] = useState(false)

  useEffect(() => {
    Controllers.unit.get_unit_public_with_pack({
      p_year: currentYear
    }).then(res => {
      res.data.unshift({
        id: '',
        description: 'TODAS'
      })
      setUnit(res.data)
      /* if (res.data.length === 2) {
        let unitFind = res.data.find(e => e.id !== '')
        if (unitFind) {
          setUnitSelected(unitFind.id)
        }
      } */
    })
  }, [currentYear])

  useEffect(() => {
    if (reload) {
      Controllers[nameController]['get_' + nameController]({
        p_year: currentYear
      }).then(res => {
        setRows(res.data)
      })
      .finally(() => {
        setIsProcessing(false)
        setReload(false);
      });
    }
  }, [reload, currentYear])

  const cboUnit = useMemo(() => {
    let unitFind = unit.find(u => u.id === unitSelected)

    return <Select
      value={unitFind ? {value: unitFind.id, label: unitFind.id + (unitFind.id ? ' - ' : '') + unitFind.description} : ''}
      options={unit.map(s => {
        return {
          value: s.id,
          label: s.id + (s.id ? ' - ' : '') + s.description
        }
      })}
      onChange={e => {
        setUnitSelected(e.value)
        setReload(true)
      }}
      text="Centro de costo"
      icon="far fa-building"
      classNameParent="col-12 col-md-6 mb-2"
    />
  }, [unit, unitSelected])

  const jwt = useSelector(store => store.session.jwt)

  const dtRows = useMemo(() => {
    const thead = [
      { name: 'Centro De Costo' },
      { name: 'Año', align: 'center' },
      { name: 'Cantidad Inicial', align: 'center' },
      { name: 'Presupuesto Inicial', align: 'center' },
      { name: 'Estado', align: 'center' },
      { name: 'Cantidad Saldo', align: 'center' },
      { name: 'Presupuesto Saldo', align: 'center' },
      { name: 'Acc.', align: 'center', sort: false }
    ];

    return <DataTable
    isProcessing={isProcessing}
    tbody={rows.map(r => {
      return {
        description: r.description,
        year: r.year,
        qty_start: parseInt(r.qty_start),
        budget: parseFloat(r.budget),
        state: r.state,
        qty_balance: parseInt(r.qty_balance),
        budget_balance: parseFloat(r.budget_balance),
        id_unit: r.id_unit
      }
    }).filter(r => {
      if (unitSelected !== '') {
        return r.id_unit === unitSelected
      }

      return r
    })}
    thead={thead}
    render={tbody => tbody.map(r => {
      let rowRender = [];
      let i = 0;
      for (let key in r) {
        if (key === 'id_unit') {
          continue
        }
        if (key === 'state') {
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''} className={'text-' + (parseInt(r.state) === 0 ? 'warning' : (parseInt(r.state) === 1 ? 'info' : (parseInt(r.state) === 2 ) ? 'danger' : 'success'))}>
            <strong>{parseInt(r.state) === 0 ? 'PENDIENTE' : (parseInt(r.state) === 1 ? 'PENDIENTE DE APROBACIÓN' : (parseInt(r.state) === 2 ) ? 'RECHAZADO' : 'APROBADO')}</strong>
          </td>);
        } else {
          rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>{r[key]}</td>);
        }
        i++;
      }

      rowRender.push(<td align={thead[i].align ? thead[i].align : ''}>
        {parseInt(r.state) > 0 ? <><OverlayTrigger
          placement="top"
          overlay={props => <Tooltip {...props}>
            PDF
          </Tooltip>}
        >
          <Button variant="danger" size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
            setModalShow(false)
            window.open(Helpers.config.api.url + '/packsannual/history/pdf/' + e.currentTarget.dataset.idUnit + '/' + e.currentTarget.dataset.year + '?jwt=' + jwt, '_blank')
          }}>
            <i className="fa fa-file-pdf"></i>
          </Button>
        </OverlayTrigger>
        
        <Button variant={'success'} data-disabled={1} size="xs" data-id-unit={r.id_unit} data-year={r.year} className="mr-1" data-id={r.id} onClick={e => {
          let { idUnit, year } = e.currentTarget.dataset
          let $this = e.currentTarget
          let button = $this;
          let buttonHTML = button.innerHTML
          button.innerHTML = Helpers.icon.spin();

          Controllers.packs_annual.get_pac_excel_history({
            p_id_unit: idUnit,
            p_year: year
          }).then(res => {
            let tagA = document.createElement('a')
            tagA.setAttribute('download', res.data.filename)
            tagA.setAttribute('href', res.data.file)
            tagA.click()
          }).finally(() => button.innerHTML = buttonHTML)
        }}>
          <i className={'fa fa-file-excel'}></i>
        </Button>
        </>
        :''}


        {/* <Button variant="danger" size="xs" data-id={r.id} onClick={onDelete}>
          <i className="fa fa-trash"></i>
        </Button> */}
      </td>);
      return (<tr>
        {React.Children.toArray(rowRender)}
      </tr>);
    })}
  />
  }, [isProcessing, rows, unitSelected, jwt])

  return <> 
    <Card>
      <Card.Header className="justify-content-between">
        <span>Listado De {nameModulo}</span>
      </Card.Header>
      <Card.Body>
        <div className="form-row">
          {cboUnit}
        </div>
        {dtRows}
      </Card.Body>
    </Card>
    <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg" backdrop="static">
      <Modal.Header closeButton >
        <Modal.Title as="div"><i className="fa fa-list mr-2"></i>Reporte Saldos PAC</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form}
      </Modal.Body>
    </Modal>
  </>
}

export default Nivel;