import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { useCallback } from 'react';

const FormCustom = ({ onSubmit, dataSubmit, children, viewSubmit, callbackSuccess, disablebSubmit, textButton, className }) => {
  const handleSubmit = useCallback(e => {
    e.preventDefault();
    onSubmit({ e, dataSubmit, callbackSuccess });
  }, [onSubmit, dataSubmit, callbackSuccess]);

  return(<form className={'needs-validation form-row ' + className} noValidate onSubmit={handleSubmit} autoComplete="off">
    {children}
    {viewSubmit ? <div className="col-12">
      <Button variant="success" disabled={disablebSubmit} className="mt-2" size="sm" block type="submit">
        <i className="fa fa-check mr-2"></i>{textButton}
      </Button>
    </div> : ''}
  </form>);
}
FormCustom.defaultProps = {
  onSubmit: () => {},
  viewSubmit: true,
  dataSubmit: [],
  callbackSuccess: () => {},
  disablebSubmit: false,
  textButton: 'Guardar Información',
  className: ''
}
FormCustom.propTypes = {
  onSubmit: PropTypes.func,
  viewSubmit: PropTypes.bool,
  dataSubmit: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  callbackSuccess: PropTypes.func,
  disablebSubmit: PropTypes.bool
}

export default FormCustom;