import React, { useEffect, useCallback, useReducer, useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';

const DataTable = ({ 
  pageLenght, orderDirection, tbody, isProcessing, columnOrder, render, thead, textFilter, tableScroll
}) => {
  //
  // ─── ESTADOS CON REDUCER ────────────────────────────────────────────────────────
  //
  const [store, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'CHANGE_PAGE_LENGHT': return { ...state, pageLenght: action.pageLenght }
      case 'CHANGE_ORDER_DIRECTION': return { ...state, orderDirection: action.orderDirection }
      case 'CHANGE_COLUMN_ORDER': return { ...state, columnOrder: action.columnOrder }
      case 'CHANGE_NUMBER_PAGE': return { ...state, numberPage: action.numberPage }
      case 'SET_ROWS_RENDER': return { ...state, rowsRender: action.rowsRender }
      case 'SET_ROWS_RENDER_FULL': return { ...state, rowsRenderFull: action.rowsRenderFull }
      case 'CHANGE_TEXT_FILTER': return { ...state, textFilter: action.textFilter, numberPage: 1 }
      case 'CHANGE_IS_PROCESSING': return { ...state, isProcessing: action.isProcessing }
      default: return state;
    }
  }, { pageLenght, orderDirection, columnOrder, textFilter, numberPage: 1, rowsRenderFull: [], rowsRender: [] });

  //
  // ─── ESTADOS CALLBACK ───────────────────────────────────────────────────────────
  //
  // Número de items por página
  const handleChangePageLenght = useCallback(e => {
    dispatch({ type: 'CHANGE_PAGE_LENGHT', pageLenght: parseInt(e.currentTarget.value) })
  }, []);

  // Select con la lista de item por página
  const cboPageLenght = useMemo(() => {
    let arrLenghts = [10, 25, 50, 100];
    return (<select onChange={handleChangePageLenght} value={store.pageLenght} className="align-self-center form-control form-control-sm">
      {React.Children.toArray(arrLenghts.map(l => {
        return (<option value={l}>{l}</option>);
      }))}
    </select>);
  }, [handleChangePageLenght, store.pageLenght]);
  
  // Cambiar dirección de columna y número de columna por la cual se ordena
  const changeOrder = useCallback(e => {
    if (store.columnOrder === parseInt(e.currentTarget.dataset.order)) {
      dispatch({ type: 'CHANGE_ORDER_DIRECTION', orderDirection: store.orderDirection === 'asc' ? 'desc' : 'asc' });
    } else {
      dispatch({ type: 'CHANGE_COLUMN_ORDER', columnOrder: parseInt(e.currentTarget.dataset.order) });
    }
  }, [store.columnOrder, store.orderDirection]);

  // Cabecera de la tabla
  const getHeader = useMemo(() => {
    return <thead>
      <tr>
        {React.Children.toArray(thead.map((h, i) => {
          h.sort = h.sort === false ? false : true
          let th = {}
          if (h.sort) {
            th.className = 'cur-pointer sort' + (store.columnOrder === i ? (store.orderDirection === 'desc' ? ' active-desc' : ' active-asc') : '')
            th.onClick = changeOrder
          }
          return (<th {...th} data-order={i} width={h.width ? h.width : ''}>{h.name}</th>);
        }))}
      </tr>
    </thead>
  }, [thead, store.columnOrder, store.orderDirection, changeOrder]);

  // Mostrar el listado de registros en la tabla
  const getBody = useMemo(() => {
    if (isProcessing) {
      return (<tr>
        <td colSpan={thead.length} className="text-center">Procesando...</td>
      </tr>);
    } else {
      if (store.rowsRender.length) {
        return React.Children.toArray(store.rowsRender.slice(store.numberPage === 1 ? 0 : (store.numberPage - 1) * store.pageLenght, store.pageLenght * store.numberPage));
      } else {
        return (<tr>
          <td colSpan={thead.length} className="text-center" style={{
            width: '3000px'
          }}>No hay registros para mostrar.</td>
        </tr>);
      }
    }
  }, [isProcessing, thead, store.rowsRender, store.numberPage, store.pageLenght]);

  // Mostrar leyenda de cantidad de resultados
  const getLegend = useMemo(() => {
    return (<>
      Mostrando {!store.rowsRender.length ? 0 : ((store.numberPage - 1) * store.pageLenght) + 1} a {
        store.rowsRender.slice(store.numberPage === 1 ? 0 : (store.numberPage - 1) * store.pageLenght, store.pageLenght * store.numberPage).length - store.pageLenght === 0 ?
          store.pageLenght * store.numberPage : 
          store.rowsRender.slice(0, store.pageLenght * store.numberPage).length
      } de {store.rowsRenderFull.length !== store.rowsRender.length && store.textFilter.trim() !== '' ? store.rowsRender.length + ' filtrados de '  : ''}
      {tbody.length} registros
    </>);
  }, [store.rowsRender, store.numberPage, store.pageLenght, store.rowsRenderFull, store.textFilter, tbody]);

  // Cambiar número de página
  const changeNumberPage = useCallback(e => {
    dispatch({ type: 'CHANGE_NUMBER_PAGE', numberPage: parseInt(e.selected + 1) });
  }, []);

  // Mostrar Paginación
  const getPaginate = useMemo(() => {
    let calculate = Math.floor(store.rowsRender.length / store.pageLenght) - store.rowsRender.length / store.pageLenght !== 0 ? Math.floor(store.rowsRender.length / store.pageLenght) + 1 : store.rowsRender.length / store.pageLenght

    return (<ReactPaginate
      pageRangeDisplayed={2}
      marginPagesDisplayed={1}
      previousLabel={'Anterior'}
      nextLabel={'Siguiente'}
      breakLabel={'...'}
      pageCount={calculate}
      onPageChange={changeNumberPage}
      containerClassName={'pagination-react align-self-center'}
      previousClassName={''}
      previousLinkClassName={''}
      nextClassName={calculate === 0 ? 'disabled' : ''}
      nextLinkClassName={''}
      breakClassName={''}
      breakLinkClassName={''}
      pageLinkClassName={''}
      pageClassName={''}
      forcePage={store.numberPage - 1}
    />);
  }, [store.rowsRender, store.pageLenght, changeNumberPage, store.numberPage]);

  //
  // ─── EFECTOS ────────────────────────────────────────────────────────────────────
  //
  useEffect(() => dispatch({ type: 'CHANGE_PAGE_LENGHT', pageLenght }), [pageLenght]);
  useEffect(() => dispatch({ type: 'CHANGE_ORDER_DIRECTION', orderDirection: orderDirection }), [orderDirection]);
  useEffect(() => dispatch({ type: 'CHANGE_COLUMN_ORDER', columnOrder }), [columnOrder]);
  useEffect(() => {
    dispatch({ type: 'SET_ROWS_RENDER', rowsRender: render(tbody) });
    dispatch({ type: 'SET_ROWS_RENDER_FULL', rowsRenderFull: tbody });
  }, [tbody, render]);
  useEffect(() => dispatch({ type: 'CHANGE_TEXT_FILTER', textFilter }), [textFilter]);
  useEffect(() => dispatch({ type: 'CHANGE_IS_PROCESSING', isProcessing }), [isProcessing]);

  useEffect(() => {
    let name = ''
    let z = -1
    if (tbody.some(a => a)) {
      let f = tbody.find(a => a)
      for (const key in f) {
        z++
        if (z === store.columnOrder) {
          name = key
          break;
        }
      }
    }

    dispatch({ type: 'SET_ROWS_RENDER', rowsRender: render(tbody.sort((a, b) =>
      {
        if (store.columnOrder >= 0 && store.columnOrder !== null) {
          if (store.orderDirection === 'asc') {
            if (a[name]> b[name])
              return 1
            else
              return -1
          } else {
            if (a[name] < b[name])
              return 1
            else
              return -1
          }
        }
        return 1;
      }).filter((r => {
        let has = false
        for (const key in r) {
          if (r.hasOwnProperty(key)) {
            
            if ((''+r[key]).toString().toUpperCase().includes(store.textFilter.toUpperCase())) {
              has = true
              break
            }
          }
        }
        return has ? r : null;
      })))
    });
  }, [store.textFilter, store.columnOrder, store.orderDirection, render, tbody]);

  //
  // ─── FUNCIONES CREADAS ──────────────────────────────────────────────────────────
  //
  const filterBy = e => {
    dispatch({ type: 'CHANGE_TEXT_FILTER', textFilter: e.currentTarget.value });
  }

  return (<>
    <div className="d-flex flex-column flex-md-row justify-content-between mb-2">
      <div className="d-flex justify-content-center">
        <div className="d-flex">
          <span className="align-self-center mr-2">Mostrar</span>
          {cboPageLenght}
        </div>
      </div>
      <div className="d-flex justify-content-center mt-2 mt-md-0">
        <div className="d-flex">
          <span className="align-self-center mr-2">Buscar</span>
          <input onChange={filterBy} className="align-self-center form-control form-control-sm" placeholder="Buscar" />
        </div>
      </div>
    </div>
    <div className="table-responsive">
      <table className={'dt-react table table-sm table-hover table-striped table-bordered ' + (tableScroll ? 'table-scroll' : '')}>
        {getHeader}
        <tbody>
          {getBody}
        </tbody>
      </table>
    </div>
    <div className="d-flex flex-column flex-md-row justify-content-between">
      <div className="align-self-center">
        {getLegend}
      </div>
      {getPaginate}
    </div>
  </>);
}

DataTable.defaultProps = {
  render: tbody => {
    return tbody.map(r => {
      let rowRender = [];
      for (let key in r) {
        rowRender.push(<td>{r[key]}</td>);
      }
      return (<tr>
        {React.Children.toArray(rowRender)}
      </tr>);
    });
  },
  tbody: [],
  thead: [],
  isProcessing: false,
  columnOrder: 0,
  orderDirection: 'asc',
  pageLenght: 10,
  textFilter: '',
  tableScroll: ''
}

DataTable.propTypes = {
  render: PropTypes.func,
  tbody: PropTypes.array,
  thead: PropTypes.array.isRequired,
  isProcessing: PropTypes.bool,
  columnOrder: PropTypes.number,
  orderDirection: PropTypes.oneOf(['asc', 'desc']),
  pageLenght: PropTypes.oneOf([10, 25, 50, 100, -1]),
  textFilter: PropTypes.string
}

export default DataTable;